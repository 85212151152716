import React from 'react'
import { ReactComponent as BtnSVG } from "./img/settings-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/settings-active-btn.svg";
import styles from './button.module.css'


const SettingsBtn = ({isActive = false, isDisabled = false, onClick}) => {
  return <button className={styles.btn} disabled={isDisabled} onClick={onClick}>
    { (!isActive || isDisabled) ? <BtnSVG /> : <BtnActiveSVG /> }
  </button>
}

export default SettingsBtn
