import React from "react";
import cn from "classnames";
import { ReactComponent as CheckMark } from "./img/check.svg";

import styles from "./checkbox.module.css";

const Checkbox = ({ defaultValue, onChange, classWrap, label }) => {
    const handleChange = (e) => {
        const value = e.target.checked;
        onChange(value);
    };

    const wrapCn = cn(styles.wrap, classWrap);
    return (
        <label className={wrapCn} key={defaultValue}>
            <span className={styles.txt}>{label}</span>
            <input
                className={styles.input}
                type="checkbox"
                defaultChecked={defaultValue}
                onChange={handleChange}
            />
            <span className={styles.checkmark}>
                <CheckMark className={styles.checkmarkInner} />
            </span>
        </label>
    );
};

Checkbox.defaultProps = {
    defaultValue: false,
    onChange: () => {},
};

export default Checkbox;
