import React, { useState, useEffect } from "react";
import styPreScr from "../../presenterScreen.module.scss";
import cn from "classnames";
import { ReactComponent as PointerIcon } from "../../../components/Epub/img/pointer.svg";
import { ReactComponent as PointerActiveIcon } from "../../../components/Epub/img/pointer-active.svg";
import { ReactComponent as PointerBySelectIcon } from "../../../components/Epub/img/pointer-by-select.svg";
import { ReactComponent as PointerBySelectActiveIcon } from "../../../components/Epub/img/pointer-by-select-active.svg";
import CurtainMenu from "./../../../components/CurtainMenu/CurtainMenu";

const EpubTools = ({
  handleMode = () => {},
  selectedGroupId,
  getTooltip = () => {},
  modeByGroupId,
  curtainDirection,
  handleChangeCurtainDirection = () => {},
  curtainTop,
}) => {
  useEffect(() => {
    if (!selectedGroupId) {
      return;
    }
  }, [selectedGroupId]);

  return (
    <div className={styPreScr.buttons}>
      <button
        onClick={() => handleMode("pointerBySelect")}
        className={cn(styPreScr.modeOptionBtn, {
          [styPreScr.selected]:
            modeByGroupId[selectedGroupId] === "pointerBySelect",
        })}
        data-tip="Инструмент «Указка выделением»"
        data-for="pointerBySelect"
      >
        {modeByGroupId[selectedGroupId] === "pointerBySelect" ? (
          <PointerActiveIcon />
        ) : (
          <PointerIcon />
        )}
      </button>
      {getTooltip("pointerBySelect")}
      <button
        onClick={() => handleMode("pointer")}
        className={cn(styPreScr.modeOptionBtn, {
          [styPreScr.selected]: modeByGroupId[selectedGroupId] === "pointer",
        })}
        data-tip="Инструмент «Указка»"
        data-for="pointer"
      >
        {modeByGroupId[selectedGroupId] === "pointer" ? (
          <PointerBySelectActiveIcon />
        ) : (
          <PointerBySelectIcon />
        )}
        {getTooltip("pointer")}
      </button>
      <CurtainMenu
        slideType={"epub"}
        currentDirection={curtainDirection[selectedGroupId]}
        handleMode={() => handleMode("curtain")}
        selected={modeByGroupId[selectedGroupId] === "curtain"}
        handleChangeCurtainDirection={handleChangeCurtainDirection}
        handleClear={() => handleMode("")}
        curtainTop={curtainTop}
      />
    </div>
  );
};

export default EpubTools;
