import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './Home.module.scss'
import logo from './img/logo.png'
import kidFox from './img/kid_fox.png'
import fox from './img/fox.png'
import kid from './img/kid.png'


function Card({imgSrc, url, textButton, title, external}) {

  return(
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <div className={styles.imageBackground}>
        <img src={imgSrc} />
      </div>
      </div>
      <p>{title}</p>
      {external ? <a className={cn(styles.btn, styles.buttonNext, styles['buttonNext--fill'])} href={url}>{textButton}</a> :
        <Link className={cn(styles.btn, styles.buttonNext, styles['buttonNext--fill'])} to={url}>{textButton}</Link>}
    </div>
  )
}

export default function Home() {

  return (
    <div className={styles.container}>
        <header className={styles.header}>
        <img className={styles.logo} src={logo}/>
        <span className={styles.span}>Школа скорочтения, каллиграфии, развития памяти и интеллекта</span>
        <a href="tel:88002222007">8 800 2222 007</a>
      </header>
      <div className={styles.content}>
        <h1>Кажется, вы заблудились</h1>
        <div className={styles.cards}>
          <Card title={<>Я потерялся и<br/>хочу на урок</>} textButton={'Войти как ученик'} url={'/cabinet'} imgSrc={kidFox}/>
          <Card title={<>Я ищу вход в<br/>учительскую</>} textButton={'Войти как учитель'} url={'/admin-next'} imgSrc={fox} external/>
          <Card title={<>Я хочу записать<br/>ребёнка на урок</>} textButton={'Записать ребёнка'} url={'https://iq007.ru/online'} imgSrc={kid} external />
        </div>
      </div>
    </div>
  )
}
