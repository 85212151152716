import React from "react";
import { viewTypes } from "../../ViewStylePopupMenu/viewStyle";
import styles from './button.module.css'
import { ReactComponent as GallerySVG } from "../../ViewStylePopupMenu/img/gallery-icon.svg";
import { ReactComponent as MosaicSVG } from "../../ViewStylePopupMenu/img/mosaic-icon.svg";
import { ReactComponent as ReportSVG } from "../../ViewStylePopupMenu/img/report-icon.svg";

const ViewStyleBtn = ({onClick, currentViewType}) => {
  const renderViewIcon = () => {
    switch (currentViewType) {
      case viewTypes.GALLERY:
        return <GallerySVG />
      case viewTypes.MOSAIC:
        return <MosaicSVG />
      case viewTypes.REPORT:
        return <ReportSVG />
      default:
        return <GallerySVG />
    }
  }

  return (
    <button className={styles.btn} onClick={onClick}>
      {renderViewIcon()}
    </button>
  )
}

export default ViewStyleBtn