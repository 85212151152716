import superagent from 'superagent'

export async function getLessonEvents() {
  const resp = await superagent.get('/api/events/lesson-events');
  return resp.body;
}

export async function createLessonEvents(payload) {
  const resp = await superagent.post('/api/events/lesson-events').send(payload)
  return resp.body;
}

export async function getLessonEventsForFranchisee(franchisee) {
  const resp = await superagent.get('/api/events/events-by-franchisee')
    .query({ franchisee })
  return resp.body;
}

export async function getPupilTasks(pupilId) {
  const resp = await superagent.get(`/api/events/tasks/for-pupil/${pupilId}`);
  return resp.body;
}

export async function getUniquePupilTasks(pupilId) {
  const resp = await superagent.get(`/api/events/tasks/for-pupil/unique/${pupilId}`);
  return resp.body;
}

export async function getNextTasksForPupil(pupilId, groupId) {
  const resp = await superagent.get(`/api/events/tasks/for-pupil/next-task/${pupilId}/${groupId}`);
  return resp.body;
}

export async function getNextTasksForPupil2(pupilId, groupId) {
  const resp = await superagent.get(`/api/events/tasks/for-pupil/next-task2/${pupilId}/${groupId}`);
  return resp.body;
}

export async function getReportLessonsByPupil(pupilId) {
  const resp = await superagent.get(`/api/events/tasks/lesson-reports-for-pupil/${pupilId}`)
  return resp.body
}