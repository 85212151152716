import React, { useRef, useEffect, useState } from 'react'
import cn from 'classnames'
import findIndex from 'lodash/findIndex'

import { getResizedSlideUrl } from '../../func'
import styles from './carousel.module.css'
import initCarousel from './initCarousel.js'

const renderSlides = (slides = [], onChange, selectIndex, selectedIndexSaved) => {
  return slides.map((i, index) => {
    const src = i.type === 'giveIqTokens' ? i.carouselSrc : getResizedSlideUrl(i, 56, 90) || 'images/slides/slideNotFound.svg'
    return (
      <li
        // title={`Слайд ${ i.slideNum } из ${ slides.length }: ${ i.name }`}
        key={`${i.slideNum}_${index}`}
        className={cn(styles.item, {
          [styles.selected]: selectIndex === Number(i.slideNum) || Number(i.slideNum) === selectedIndexSaved,
          [styles.visited]: (selectIndex !== Number(i.slideNum) && i.visitedSeconds >= 10) || (i.type === 'giveIqTokens' && i.iqTokenCount > 0),
        })}
        onClick={() => onChange(i, index)}
        data-tip={`Слайд ${i.slideNum} из ${slides.length}: ${i.name}`}
        data-for="global"
      >
        <img src={src} alt={i.name} data-tip={i.name} />
        {/*<ReactTooltip id="carousel" className={cn("tooltip", styles.tooltip)} textColor="#4DA3F7" backgroundColor="#fff" place={'bottom'} aria-haspopup="true"/>*/}
      </li>
    )
  })
}

const Carousel = ({ slides, onChange, className, selectIndex, keyEvent, LargeTaskModal }) => {
  const [selectedIndexSaved, setSelectedIndexSaved] = useState(false)
  // const [vectorIdSaved, setVectorIdSaved] = useState(false)
  const [carousel, setCarousel] = useState(null)
  const wrapRef = useRef(null)
  const listRef = useRef(null)
  useEffect(() => {
    if (!listRef.current || !wrapRef.current || !slides || !slides[0]) {
      return
    }

    const carousel = initCarousel(listRef.current, wrapRef.current)
    setCarousel(carousel)

    const selectedIndex = findIndex(slides, (i) => i.selected)

    carousel.scrollToSelected(selectedIndex)
    return () => {
      carousel.destroy()
    }
  }, [wrapRef, listRef])

  useEffect(() => {
    if (carousel && (selectIndex || selectIndex === 0) && selectIndex !== selectedIndexSaved) {
      setSelectedIndexSaved(selectIndex)
      carousel.scrollToSelected(selectIndex)
    }

    if (!keyEvent) {
      return
    }
    const currIndex = selectedIndexSaved || selectIndex

    const onkeyDown = (e) => {
      const keyCode = e.keyCode
      if (keyCode === 37 || keyCode === 39) {
        const prevSlide = currIndex > 0 ? currIndex - 1 : 0
        const nextSlide =
          currIndex < slides.length - 1 ? currIndex + 1 : 0

        if (keyCode === 37) {
          onChange(slides[prevSlide])
        }
        if (keyCode === 39) {
          onChange(slides[nextSlide])
        }
      }
    }

    document.addEventListener('keydown', onkeyDown)

    return () => {
      document.removeEventListener('keydown', onkeyDown)
    }
  }, [selectIndex, selectedIndexSaved, carousel])

  if (!slides || !slides[0]) {
    return null
  }

  const startStyle = {
    left: 0,
  }

  const cleanScreen = () => {
    onChange({})
  }

  return (
    <div className={cn(styles.wrap, className, {[styles.wrapModal] : LargeTaskModal})}>
      {slides && slides[0] && (
        <>
          <div
            className={cn(styles.itemClean, styles.item, {[styles.itemCleanModal] : LargeTaskModal})}
            onClick={cleanScreen}
            data-tip="Пустой экран"
            data-for="global"
          >
            {/*<CleanScreen/>*/}
          </div>
          <div className={cn(styles.slider, {[styles.wrapModal] : LargeTaskModal})} ref={wrapRef}>
            <ul
              className={styles.list}
              ref={listRef}
              style={startStyle}
            >
              {renderSlides(slides, onChange, selectIndex, selectedIndexSaved) }
            </ul>
          </div>
        </>
      )}
      {/*<ReactTooltip id="carousel" className={cn("tooltip", styles.tooltip)} textColor="#4DA3F7" backgroundColor="#fff" place={'bottom'} aria-haspopup="true"/>*/}
    </div>
  )
}

export default Carousel
