import './CanvasDemoNext.css'

import React, { useRef, useState, useEffect } from 'react'

import { createDrawingContext } from './drawing'

const demoPic = 'https://iqclass.g-next.jetstyle.in/content/vector/qDhM4MJ3Npn-9QLNb1d6P/4.png'
const containerSize = {
  width: 500,
  height: 500,
}


function CanvasTeacher() {
  const canvasRef = useRef(null)
  const [imageSize, setImageSize] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {      
      setImageSize({
        width: img.width,
        height: img.height,
      })
    }
    img.src = demoPic
  }, [])

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }
    const canvas = canvasRef.current

    const ctx = createDrawingContext({
      canvas,
      imageSize,
      color: '#4DA3F7',
      onLineUpdate: (lineUpdate) => {
        // получить обновления из канваса
      },
    })

    return () => {
      ctx.destory()
    }
  }, [canvasRef, imageSize])

  const styles = {
    backgroundImage: `url(${demoPic})`
  }
  return (
    <div className="b-demo-canvas" style={styles} >
      <canvas ref={canvasRef} width="500" height="500" className="b-demo-canvas_canvas" />
    </div>
  )
}

function CanvasPupil() {
  const canvasRef = useRef(null)
  const styles = {
    backgroundImage: `url(${demoPic})`
  }
  return (
    <div className="b-demo-canvas" style={styles} >
      <canvas ref={canvasRef} className="b-demo-canvas_canvas" />
    </div>
  )
}

export default function CanvasDemoNext() {
  return (
    <div>
      <h1>Canvas Demo Next</h1>
      <div className="b-canvas-demo-list">
        <CanvasTeacher />
        <CanvasPupil />
      </div>
    </div>
  )
}