import React, { useState, useEffect } from "react";
import cn from "classnames";

import { ReactComponent as NoWifi } from "./nowifi.svg";

import styles from "./connectProblem.module.css";

function ConnectProblem({isShow}) {

    const classWrap = cn(styles.wrap, {
        [styles.offline]: isShow,
    });
    return (
        <div className={classWrap}>
            {isShow && (
                <div className={styles.inner}>
                    <NoWifi className={styles.icon} />
                    <div className={styles.txt}>
                        Проблемы со связью. Проверьте ваше соединение.
                    </div>
                </div>
            )}
        </div>
    );
}

export default ConnectProblem;
