import { useEffect, useState } from "react";
import { createOffer, createPeerConnectionNext } from "../webrtc";


const usePresenterVideo = ({ socket, videoRef, roomId, isPresenterCame }) => {
  const [stream, setStream] = useState(null)

  useEffect(() => {
    if (!socket || !roomId || !isPresenterCame || !videoRef?.current) {
      return
    }

    const getVideo = async () => {
      const { pc, connectionId } = await createPeerConnectionNext(
        socket,
        videoRef.current,
        { input: `presenter:${roomId}` },
        (streamRes) => {
          // videoRef.current.srcObj = stream
          /* on got stream */
          console.log('teacher stream', streamRes)
          setStream(streamRes)
          // setTeacherStream(stream)
        },
      )

      pc.addTransceiver('audio', { direction: 'recvonly' })
      pc.addTransceiver('video', { direction: 'recvonly' })

      createOffer(pc)
        .then((offer) => socket.emit('ask-video:presenter', { connectionId, offer: offer.sdp }))
    }

    getVideo()

    return () => {
      setStream(null)
    }

  }, [socket, roomId, isPresenterCame, videoRef])

  return { stream, setStream }
}


export default usePresenterVideo
