export function selectAll(event) {
  const input = event.target

   /* Select the text field */
   input.select();
   input.setSelectionRange(0, input.value.length)

   /* Copy the text inside the text field */
   document.execCommand("copy");

   /* Alert the copied text */
   alert("Ссылка скопирована в буфер обмена: " + input.value);
}

export function getImgStyle(task) {
  if (!task) {
    return null
  }

  const { vectorId, slideNum, file } = task
  if (!vectorId || !slideNum) {
    return null
  }
  let url = `/content/vector/${vectorId}/${slideNum}.png`

  if (file) {
    url = `/content/vector/${vectorId}/${file}`
  }

  return {
    backgroundImage: `url(${url})`,
  }
}

export function getImgUrl(task, answer) {
  if (!task) {
    return null
  }
  const { vectorStrId, vectorId, slideNum, slide, file, answerFile } = task
  const num = slideNum || (slide && slide.slideNum)
  if (!vectorId || !num) {
    return null
  }

  let url = `/content/vector/${vectorStrId || vectorId}/${num}.png`

  if (answerFile && answer) {
    url = `/content/vector/${vectorStrId || vectorId}/${answerFile}`
  } else if (file) {
    url = `/content/vector/${vectorStrId || vectorId}/${file}`
  }

  return url
}

function getVectorImgHost() {
  const currentHost = window.location.host
  
  if (currentHost.indexOf('localhost') === 0) {
    return 'https://stg.iq007-online.com'//'https://iqclass.g-next.jetstyle.in'
  }
  if (currentHost.indexOf('stg.') === 0) {
    return 'https://stg.iq007-online.com'
  }
  if (currentHost.indexOf('iqclass.') === 0) {
    return 'https://iqclass.g-next.jetstyle.in'
  }
  return 'https://iq007-online.com'
}

export function getResizedSlideUrl(task, width, height, answer) {
  const { vectorId, vectorStrId, slideNum, slide, thumbnail, file, answerFile } = task
  const num = slideNum || (slide && slide.slideNum)
  if (!vectorId || !num) {
    return null
  }
  const host = getVectorImgHost()

  const common = `${host}/vectors/secure/fill/${width}/${height}/ce/0/plain/local:///${vectorStrId || vectorId}`

  if (answer) {
    if (answerFile) {
      return `${common}/${answerFile}`
    }
    return null
  }

  if (file) {
    return `${common}/${file}`
  }

  if (thumbnail) {
    return `${common}/${thumbnail}`
  }

  return `${common}/${num}.png`
}

export function getVideoUrl(task) {
  if (!task) {
    return null
  }

  const { vectorId, video, slide } = task
  const src = video || (slide && slide.video)
  const url = `/content/vector/${vectorId}/${src}`
  return url
}

export function getAudioUrl(task) {
  if (!task) {
    return null
  }

  console.log()

  const { vectorId, audio, slide } = task
  const src = audio || (slide && slide.audio)
  const url = `/content/vector/${vectorId}/${src}`
  return url
}
