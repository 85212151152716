import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import ClientOnlyPortal from '../ClientOnlyPortal/ClientOnlyPortal'

import styles from './popupNext.module.sass'

import { ReactComponent as Close } from './img/close.svg'


const PopupNext = ({ isOpen, onClose, secondButton, children, widthInnerAuto, title, className, fixHeight }) => {
  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    setIsShow(isOpen)
  }, [isOpen])

  const handleClose = (e) => {
    setIsShow(false)
    onClose()
  }

  const stopClick = (e) => {
    e.stopPropagation()
  }

  const classWrap = cn(styles.wrap, { [styles.open]: isShow })
  return (
    <div className={classWrap} onClick={handleClose}>
      <div className={cn(styles.inner, className, {
        [styles.innerAuto]: widthInnerAuto,
      })} onClick={stopClick}>
        <button
          type="button"
          onClick={handleClose}
          className={styles.close}
        >
          <Close/>
        </button>
        {
          title && <h1 className={styles.title}>{title}</h1>
        }
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

PopupNext.defaultProps = {
  onClose: () => {
  },
}

export default PopupNext
