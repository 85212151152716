import { useEffect, useState } from "react";


const useRecordScreen = ({roomStrId, socket, isRoomCreated, localStream, isNeedRecord = true}) => {
  const [mediaStream, setMediaStream] = useState(null)
  const [mediaRecorder, setMediaRecorder] = useState(null)

  useEffect(() => {
    if (!roomStrId || !socket || !isRoomCreated || !localStream || !isNeedRecord || mediaStream) {
      return
    }
    console.log('start create mediaStream')

    getMediaStream()

  }, [roomStrId, socket, isRoomCreated, localStream, isNeedRecord])

  useEffect(() => {
    if (!mediaStream) {
      return
    }

    const mediaRecorderForSet = getMediaRecorder()
    setMediaRecorder(mediaRecorderForSet)
  }, [mediaStream])

  useEffect(() => {
    if (!mediaRecorder || !mediaStream || isNeedRecord) {
      return
    }

    mediaRecorder.stop()
  }, [mediaRecorder, mediaStream, isNeedRecord])

  const sendChunk = async (blob) => {
    const binary = await blob.arrayBuffer()
    console.log('send chunk')
    socket.emit('presenter:save-screen-chunk', {roomStrId, chunk: binary})
  }

  const getMediaStream = async () => {
    let combineMediaStream = null;

    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia({preferCurrentTab: true, video: { mediaSource: "screen"}, audio: {sampleRate: 44100}})
      //Соберем из 2 аудио треков один стрим для записи:
      const audioContext = new AudioContext();

      var OutgoingAudioMediaStream = new MediaStream();
      OutgoingAudioMediaStream.addTrack(captureStream.getAudioTracks()[0]);

      var IncomingAudioMediaStream = new MediaStream();
      IncomingAudioMediaStream.addTrack(localStream.getAudioTracks()[0]);

      const audioIn_01 = audioContext.createMediaStreamSource(OutgoingAudioMediaStream);
      const audioIn_02 = audioContext.createMediaStreamSource(IncomingAudioMediaStream);

      const dest = audioContext.createMediaStreamDestination();

      audioIn_01.connect(dest);
      audioIn_02.connect(dest);
      //Собрали
      //Соберём общий стрим для записи
      combineMediaStream = new MediaStream([...captureStream.getVideoTracks(), ...dest.stream.getAudioTracks()])

      console.log('media stream created')
    } catch (err) {
      console.error(`create captureStream error: ${err}`)
    }

    setMediaStream(combineMediaStream)
  }


  const getMediaRecorder = () => {
    let chunks = []
    const recorder = new MediaRecorder(mediaStream)

    recorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        sendChunk(e.data)
        // chunks.push(e.data)
      }
    }

    recorder.onstop = () => {
      if (chunks.length > 0){
        saveFile(chunks)
      }
      chunks=[]
    }

    recorder.start(500)

    return recorder
  }

  function saveFile(recordedChunks){

    const blob = new Blob(recordedChunks, {
      type: 'video/webm'
    });
    let filename = window.prompt('Enter file name'),
      downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${filename}.webm`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(blob); // clear from memory
    document.body.removeChild(downloadLink);
  }
}

export default useRecordScreen
