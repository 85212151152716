import { useState } from 'react'


const DELAY = 10

export default function useSimpleTimer(delaySeconds = DELAY) {
  const [seconds, setSeconds] = useState(null)
  const [intId, setIntId] = useState(null)

  const start = () => {
    setSeconds(delaySeconds)
    const newIntId = setInterval(() => {
      setSeconds(sec => sec - 1)
    }, 1000)
    setIntId(newIntId)
    setTimeout(() => clearInterval(newIntId), (delaySeconds + 1) * 1000)
  }

  const reset = () => {
    if (intId) {
      clearInterval(intId)
    }
    start()
  }

  const stop = () => {
    if (intId) {
      clearInterval(intId)
    }
  }

  return [seconds, start, reset, stop]
}
