import React, { useState, useRef, useEffect, useMemo } from "react"
import cn from "classnames"
import ReactTooltip from "react-tooltip"
import useResizeObserver from "use-resize-observer/polyfilled"
import CurtainMenu from "../CurtainMenu/CurtainMenu"
import { ReactComponent as Redo } from "./img/redo.svg"
import { ReactComponent as Undo } from "./img/undo.svg"
import { ReactComponent as Pencil } from "./img/pencil.svg"
import { ReactComponent as PencilLight } from "./img/pencil-light.svg"
import { ReactComponent as Pointer } from "./img/pointer.svg"
import { ReactComponent as PointerLight } from "./img/pointer-light.svg"
import { ReactComponent as MagnifierIcon } from "./img/magnifier.svg"
import { ReactComponent as MagnifierIconLight } from "./img/magnifier-light.svg"
import { ReactComponent as Rub } from "./img/rub.svg"
import Curtain from "../Curtain/Curtain"

import styles from "./canvas.module.scss"
import presenterScreenStyles from "../../PresenterScreen/presenterScreen.module.scss"
import Magnifier from "./Magnifier/Magnifier"
import { useMediaQuery } from "react-responsive"
import useDrawing from "./useDrawing"
import CallToSupportImg from "../../img/call_to_support.png";

export default function Canvas({
 type,
 pic,
 socket,
 name,
 task,
 color = "#4DA3F7",
 showButtons = false,
 readOnly = false,
 modal,
 callToSupportBtn,
 screenSizeButton,
 infoTooltip,
 isPreview = false,
  needCallToSupport = false,
}) {
 const canvasRef = useRef(null)
 const wrapRef = useRef(null)
 const innerRef = useRef(null)
 const renderPicRef = useRef(null)
 const [imageSize, setImageSize] = useState({ width: 500, height: 500 })
 const [tools, setTools] = useState([])
 const [initCurtainState, setInitCurtainState] = useState({
  width: 0,
  height: 0,
  direction: "top",
 })
 const [curtainDirection, setCurtainDirection] = useState("top")
 const isDesktopOrLaptop = useMediaQuery({
  query: "(min-width: 1024px)",
 })

 // сейчас мы слушаем размер канваса и ставим его в размер канваса :)
 //  - да при ресайзе это помогает
 const { width, height } = useResizeObserver({ ref: canvasRef })
 const { width: wrapWidth, height: wrapHeight } = useResizeObserver({
  ref: innerRef,
 })
 const { width: renderPicWidth, height: renderPicHeight } = useResizeObserver({
  ref: renderPicRef,
 })

 const nextType = useMemo(() => {
  switch (type) {
   case "pupil":
    return "viewer"
   case "teacher":
    return "presenter"
   default:
    return type
  }
 }, [type])

 useEffect(() => {
  if (!renderPicWidth || !renderPicHeight) {
   return
  }

  innerRef.current.width = renderPicWidth
  innerRef.current.height = renderPicHeight
 }, [renderPicWidth, renderPicHeight])

 useEffect(() => {
  if (
   renderPicRef?.current?.naturalWidth === 0 ||
   renderPicRef?.current?.naturalHeight === 0
  ) {
   return
  }

  setImageSize({
   width: renderPicRef?.current?.naturalWidth,
   height: renderPicRef?.current?.naturalHeight,
  })
 }, [renderPicRef?.current?.naturalWidth, renderPicRef?.current?.naturalHeight])

 console.log('canvas Task', task)

 const { context: ctx } = useDrawing({
  canvasRef,
  name,
  task,
  color,
  imageSize,
  isPreview,
  readOnly,
  type: nextType,
  setCurtainDirection,
  setTools,
  socket,
  setInitCurtainState,
  wrapRef,
 })

 const inlineStyles = {
  width: renderPicWidth || 0,
  height: renderPicHeight || 0,
 }

 const classWrap = cn(styles.wrap, {
  [styles[`wrap_pencil`]]: tools.pencil || tools.pointer,
  [styles.readOnly]: readOnly,
 })

 const handleClear = () => {
  if (readOnly) {
   return
  }

  socket.emit(`canvas:${nextType}:clear-all`, { name, task })
 }

 const handleTools = (toolName) => {
  if (readOnly) {
   return
  }

  const toolsForSet = { ...tools, [toolName]: !tools[toolName] }

  //todo: избавиться от этого перебора!
  if (toolName === "pencil") {
   toolsForSet.pointer = false
  }
  if (toolName === "pointer") {
   toolsForSet.pencil = false
  }
  // if (toolName === "magnifier") {
  //  toolsForSet.pencil = false
  //  toolsForSet.pointer = false
  // }

  if (ctx) {
   setTools(toolsForSet)
   ctx.setModeLine(toolsForSet)

   if (toolName === "curtain") {
   }

   if (type === "teacher") {
    socket.emit("canvas:teacher:tools", { name, tools: toolsForSet, task })
   }
  }
 }

 const closeCurtain = () => {
  const toolsForSet = { ...tools, curtain: false }

  if (ctx) {
   setTools(toolsForSet)
   ctx.setModeLine(toolsForSet)

   if (type === "teacher") {
    socket.emit("canvas:teacher:tools", { name, tools: toolsForSet, task })
   }
  }
 }
 const handleChangeCurtainDirection = (value) => {
  setCurtainDirection(value)
  socket.emit("canvas:teacher:curtain-direction", { name, task, value })
 }

 const handleUndo = () => {
  if (readOnly) {
   return
  }

  socket.emit("canvas:presenter:un-do", { name, task })
 }

 const handleRedo = () => {
  if (readOnly) {
   return
  }

  socket.emit("canvas:presenter:re-do", { name, task })
 }

 const getTooltip = (id) => {
  return (
   <ReactTooltip
    id={id}
    className={presenterScreenStyles.tooltip}
    place='right'
    textColor='#393F61'
    backgroundColor='#fff'
    aria-haspopup='true'
   />
  )
 }

 return (
  <div className={classWrap} ref={wrapRef}>
   {showButtons && (
    <div className={styles.buttons}>
     <button
      className={styles.modeOptionBtn}
      onClick={handleUndo}
      disabled={!tools.pencil}
      data-tip='Отменить действие'
      data-for='undo'
     >
      <Undo />
      {getTooltip("undo")}
     </button>
     <button
      className={styles.modeOptionBtn}
      onClick={handleRedo}
      disabled={!tools.pencil}
      data-tip='Вернуть действие'
      data-for='redo'
     >
      <Redo />
      {getTooltip("redo")}
     </button>
     <button
      className={cn(styles.modeOptionBtn, { [styles.selected]: tools.pencil })}
      onClick={() => handleTools("pencil")}
      data-tip='Инструмент «Карандаш»'
      data-for='pencil'
     >
      {tools.pencil ? <PencilLight /> : <Pencil />}
      {getTooltip("pencil")}
     </button>
     <button
      className={styles.modeOptionBtn}
      onClick={handleClear}
      disabled={false}
      data-tip='Инструмент «Ластик»'
      data-for='rub'
     >
      <Rub />
      {getTooltip("rub")}
     </button>
     <button
      className={cn(styles.modeOptionBtn, { [styles.selected]: tools.pointer })}
      onClick={() => handleTools("pointer")}
      data-tip='Инструмент «Указка»'
      data-for='pointer'
     >
      {tools.pointer ? <PointerLight /> : <Pointer />}
      {getTooltip("pointer")}
     </button>
     <CurtainMenu
      handleChangeCurtainDirection={handleChangeCurtainDirection}
      currentDirection={curtainDirection}
      handleMode={() => handleTools("curtain")}
      handleClear={closeCurtain}
      selected={tools.curtain}
      readOnly={readOnly}
     />
     {screenSizeButton}
     <button
      className={cn(styles.modeOptionBtn, {
       [styles.selected]: tools.magnifier,
      })}
      onClick={() => handleTools("magnifier")}
      data-tip='Инструмент «Лупа»'
      data-for='magnifier'
     >
      {tools.magnifier ? <MagnifierIconLight /> : <MagnifierIcon />}
      {getTooltip("magnifier")}
     </button>
     {callToSupportBtn}
     {infoTooltip}
    </div>
   )}
   <div
    className={cn(styles.imgContainer, {
     [styles.imgContainerForLargeTask]: showButtons,
    })}
   >
     { needCallToSupport && <img src={CallToSupportImg} className={styles.callToSupportImg} /> }
    <img
     src={pic}
     ref={renderPicRef}
     className={cn({ [styles.hide]: needCallToSupport })}
     style={{
      visibility: "visible",
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
     }}
    />
    <div ref={innerRef} className={cn(styles.inner, { [styles.hide]: needCallToSupport })} style={inlineStyles}>
     {ctx && (
      <Curtain
       initialState={initCurtainState}
       direction={curtainDirection}
       isShow={tools.curtain?.isOpen}
       socket={socket}
       name={name}
       maxWidth={width}
       maxHeight={height}
       type={isPreview ? 'supervisor' : type}
       ctx={ctx}
       task={task}
       readOnly={
        readOnly || (nextType === "viewer" && !task?.viewerTools?.curtain)
       }
      />
     )}
     <canvas
      ref={canvasRef}
      className={cn(styles.canvas, { [styles.frame]: modal})}
      width={width}
      height={height}
      style={{
       left: isNaN(wrapWidth / 2 - width / 2) ? 0 : wrapWidth / 2 - width / 2,
      }}
     />
     {!isPreview && tools.magnifier && isDesktopOrLaptop && (
      <Magnifier
       name={name}
       type={nextType}
       task={task}
       socket={socket}
       color={color}
       imageSize={imageSize}
       isPreview={isPreview}
       readOnly={readOnly}
       tools={tools}
       setTools={setTools}
       mainCanvasRef={canvasRef}
       picSrc={pic}
       zoomRatio={1.5}
       onAddLineMainCtx={ctx.addLine}
       onUpdateLineMainCtx={ctx.updateLine}
      />
     )}
    </div>
   </div>
  </div>
 )
}
