import get from "lodash/get"
import React, { useEffect, useRef, useState, useReducer, useMemo } from "react"
import { useParams } from "react-router-dom"
import find from "lodash/find"
import max from "lodash/max"
import map from "lodash/map"
import isEmpty from "lodash/isEmpty"
import cn from "classnames"
import { fastFindByName } from "../utils/util"
import { getGradesByTask } from "../api/grades"

import Sidebar from "./components/Sidebar/Sidebar"

import { ReactComponent as IconAuthorization } from "./img/authorization.svg"
import { ReactComponent as Info } from "./components/LargeTaskModal/img/info.svg"
import { ReactComponent as Redo } from "../components/Canvas/img/redo.svg"
import { ReactComponent as Undo } from "../components/Canvas/img/undo.svg"
import { ReactComponent as Pencil } from "../components/Canvas/img/pencil.svg"
import { ReactComponent as PencilLight } from "../components/Canvas/img/pencil-light.svg"
import { ReactComponent as Pointer } from "../components/Canvas/img/pointer.svg"
import { ReactComponent as PointerLight } from "../components/Canvas/img/pointer-light.svg"
import { ReactComponent as MagnifierIcon } from "../components/Canvas/img/magnifier.svg"
import { ReactComponent as MagnifierIconLight } from "../components/Canvas/img/magnifier-light.svg"
import { ReactComponent as Rub } from "../components/Canvas/img/rub.svg"

import styles from "./presenterScreen.module.scss"
import { initialTimer, reducerTimers } from "./reduserTimers"

import {
 addStreamToConnection,
 createOffer,
 initSignaling,
 getStreams,
 createPeerConnectionNext,
} from "../webrtc"

import BrowserBanner from "../components/BrowserBanner/BrowserBanner"

import { getTitleVectorList } from "../apiVectors"

import { getGroupByHash } from "../api/groups"
import { getLastLesson } from "../api/vectors"

import PupilForPresenter from "./components/PupilForPresenter/PupilForPresenter"
import LargeTaskModal from "./components/LargeTaskModal/LargeTaskModal"
import Switch from "../components/Switch/Switch"
import Checkbox from "../components/CheckBox/Checkbox"
import ConnectProblem from "../components/ConnectProblem/ConnectProblem"
import useBrowserInfo from "../useBrowserInfo"

import { Loader } from "../components/Loader/Loader"
import ChatWidget from "../components/ChatWidget"

import useTeacherSockets from "../hooks/useTeacherSockets"
import ReactTooltip from "react-tooltip"
import useRecordScreen from "../hooks/useRecordScreen"
import { useUserWithStopPendingFlag } from "../context/user"
import PresenterVideo from "./components/PresenterVideo/PresenterVideo"
import EmojiBlock from "./components/EmojiBlock/EmojiBlock"
import GroupedPupils from "./components/GroupedPupils/GroupedPupils"
import Canvas from "../components/Canvas/Canvas"
import CallToSupportBtn from "./components/CallToSupportBtn/CallToSupportBtn"
import { getImgUrl } from "../func"
import useDrawing from "./../components/Canvas/useDrawing"
import CurtainMenu from "./../components/CurtainMenu/CurtainMenu"

let senders = {}

const errors = {
 stream: "Видео или звук не доступны",
}

export default function PresenterScreen({ mode = "demo" }) {
 const { roomId } = useParams()
 const { user, userIsStopPending } = useUserWithStopPendingFlag()

 const [socket, setSocket] = useState(null)
 const [roomStrId, setRoomStrId] = useState("")
 const [connectionState, setConnectionState] = useState("initial")
 const [error, setError] = useState()
 const videoRef = useRef(null)
 const { browserName } = useBrowserInfo()
 const [viewers, setViewers] = useState([])
 const [viewersSettings, setViewersSettings] = useState({})
 const [viewersNeedHelp, setViewersNeedHelp] = useState([])
 const [viewersMuted, setViewersMuted] = useState([])
 const [viewersChatMuted, setViewersChatMuted] = useState([])
 const [vectors, setVectors] = useState([])
 const [vectorDirs, setVectorDirs] = useState([])
 const [tasks, setTasks] = useState({})
 const [vectorTasks, setVectorTasks] = useState({})
 const [tasksByGroup, setTasksByGroup] = useState({})
 const [grades, setGrades] = useState({})
 const [localStream, setLocalStream] = useState(null)
 const [directWith, setDirectWith] = useState(null)
 const [largePreview, setLargePreview] = useState(null)
 const [newGroupId, setNewGroupId] = useState(null) // id для новой группы
 const [selectedGroupId, setSelectedGroupId] = useState(null) // id выбранной группы, которую будем группировать или нет
 const [tags, setTags] = useState({}) //тут мы будем хранить по ключу [viewer.name] строку "selected" - ученик выделен и число - псевдо id группы (сгруппированных учеников на уроке) внутри этой комнаты (почему так? - исторически сложилось =))
 const [settingsByTag, setSettingsByTag] = useState({})
 const [directTag, setDirectTag] = useState(null)
 const [settings, setSettings] = useState({})
 const [openedGradeModal, setOpenedGradeModal] = useState(null)
 const [iceFetchPending, setIceFetchPending] = useState(null)
 const [viewerInfo, setViewerInfo] = useState({})
 const [lastBreadCrumbs, setLastBreadCrumbs] = useState({})
 const [isPupilWait, setPupilWait] = useState(false)
 const [timers, dispatchTimer] = useReducer(reducerTimers, {})
 const [directMuteWith, setDirectMuteWith] = useState(null)
 const [group, setGroup] = useState(null)
 const [viewersReactions, setViewersReactions] = useState({})
 const [isShowChat, setIsShowChat] = useState(false)
 const [isShowBanner, setShowBanner] = useState(false)
 const [showChatFlag, setShowChatFlag] = useState(true)
 const [messageHistory, setMessageHistory] = useState([])
 const [audioSource, setAudioSource] = useState(
  localStorage.getItem("audioSource")
 )
 const [videoSource, setVideoSource] = useState(
  localStorage.getItem("videoSource")
 )
 useRecordScreen({
  isRoomCreated: iceFetchPending === false,
  socket,
  roomStrId: roomId,
  localStream: localStream,
  isNeedRecord: !settings.finishedAt,
 })

 //Если шаринг текущей вкладки опять отвалиться, то попробовать сделать через попап...
 const openPopup = () => {
  const popupUrl = `${window.location.protocol}//${window.location.host}/teacher/${roomId}/record-lesson`
  console.log({ popupUrl })
  // window.open(popupUrl, 'record-lesson', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,\n' +
  //   'width=600,height=300,left=100,top=100')

  const newwindow = window.open(
   popupUrl,
   "record-lesson",
   "height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
  )
  console.log({ newwindow })
  if (window.focus) {
   newwindow.focus()
  }
 }

 useEffect(() => {
  const flag = localStorage.getItem("IQ007_CHAT_FLAG")
  if (flag === "enable") {
   setShowChatFlag(true)
  }
 }, [])

 useEffect(() => {
  if (browserName && browserName.toLowerCase() !== "chrome") {
   setShowBanner(true)
  }
 }, [browserName])

 useEffect(() => {
  if (!user) {
   return
  }

  getTitleVectorList().then((res) => {
   setVectors(res.vectors)
   setVectorDirs(res.vectorDirs)
  })

  // TODO это нужно делать сразу после начала урока
  localStorage.removeItem("epub:selection")
 }, [user])

 useMemo(() => {
  if (mode !== "auth" || !roomId) {
   return
  }

  getGroupByHash(roomId)
   .then((group) => setGroup(group))
   .catch((e) => console.error("Cannot load group", group, e))
 }, [roomId, mode])

 useTeacherSockets({
  setConnectionState,
  userMe: user,
  initSignaling,
  roomId,
  setRoomStrId,
  setError,
  setTasks,
  setVectors,
  setSettings,
  setTags,
  setSettingsByTag,
  setMessageHistory,
  setPupilWait,
  setViewers,
  setViewersSettings,
  setViewersNeedHelp,
  setViewersReactions,
  viewersReactions,
  setViewersMuted,
  setViewersChatMuted,
  setSocket,
 })

 useEffect(() => {
  if (localStream && settings) {
   localStream.getAudioTracks()[0].enabled = settings.presenterAudio
   localStream.getTracks()[1].enabled = settings.presenterVideo
  }
 }, [localStream, settings])

 useEffect(() => {
  if (!socket || !videoRef.current || !roomId) {
   return
  }
  if (connectionState !== "connected") {
   return
  }

  if (mode === "auth" && !user) {
   return
  }
  setIceFetchPending(true)
  socket.emit("room:set-id", { roomId })
  getStreams(audioSource, videoSource)
   .then(async (stream) => {
    videoRef.current.srcObject = stream

    setLocalStream(stream)

    const { pc, connectionId } = await createPeerConnectionNext(
     socket,
     videoRef.current,
     { output: `presenter:${roomId}` }
    )
    setIceFetchPending(false)

    const { sender, tracks } = addStreamToConnection(pc, stream)

    senders["audioSender"] = pc.getSenders().find(function (s) {
     return s.track.kind == "audio"
    })

    senders["videoSender"] = pc.getSenders().find(function (s) {
     return s.track.kind == "video"
    })

    const offer = await createOffer(pc)
    socket.emit("presenter:init", {
     mode,
     roomId,
     connectionId,
     offer: offer.sdp,
     secret: user ? user.secret : null,
    })
   })
   .catch((e) => {
    console.log(e)
    if (
     e.toString() === `TypeError: Cannot read property 'getTracks' of null`
    ) {
     setError(errors.stream)
    } else {
     setError(e.toString())
    }
   })

  // TODO: сценарий очистки
 }, [socket, videoRef, roomId, connectionState, mode, user])

 useEffect(() => {
  if (isEmpty(tasks) || isEmpty(viewers)) {
   return
  }
  for (const name of Object.keys(tasks)) {
   if (!tasks[name].result) {
    continue
   }
   if (
    !grades[name] ||
    Number(tasks[name].slideNum) !== Number(grades[name].taskId) ||
    tasks[name].vectorId !== grades[name].vectorHashId
   ) {
    getGradeAfterGotTask({ name, task: tasks[name] }).then((grade) =>
     setGrades({
      ...grades,
      [name]: grade,
     })
    )
   }
  }
 }, [tasks, viewers])

 useEffect(() => {
  if (!group || !group.id) {
   return
  }
  ;(async () => {
   viewers.map(async (viewer) => {
    if (!viewerInfo[viewer.pupilId]) {
     const [info] = await getLastLesson(group.id, viewer.pupilId)
     setViewerInfo({
      ...viewerInfo,
      [viewer.pupilId]: info,
     })
     console.log("viewer.id", viewer.pupilId, group.id)
    }
   })
  })()
 }, [viewers])

 useEffect(() => {
  if (
   Object.keys(viewerInfo).length !== viewers.length ||
   vectors.length === 0 ||
   vectorDirs.length === 0
  ) {
   return
  }

  viewers.map((viewer) => {
   const info = viewerInfo[viewer.pupilId]
   if (!info) {
    setLastBreadCrumbs({
     ...lastBreadCrumbs,
     [viewer.pupilId]: null,
    })

    return
   }

   const lastVectorDirStrId = get(info, "vectorDirStrId")
   const lastVectorStrId = get(info, "vectorStrId")
   const lastSlideNum = get(info, "slideNum")

   const dirBreadCrumbs = getBreadCrumbs({ value: lastVectorDirStrId })
   const lastVector = vectors.find((vector) => vector.id === lastVectorStrId)
   if (!dirBreadCrumbs || !lastVector) {
    return
   }
   const lastTask = lastVector.tasks[Number(lastSlideNum)]
   if (!lastTask) {
    return
   }

   if (!lastTask) {
    return
   }

   const breadCrumbs = dirBreadCrumbs.map(
    (dir) => dir.title || "Корневая папка"
   )
   breadCrumbs.push(`Занятие: ${lastVector.title}`)
   breadCrumbs.push(`Задание: ${lastTask.name}`)

   setLastBreadCrumbs({
    ...lastBreadCrumbs,
    [viewer.pupilId]: breadCrumbs,
   })
  })
 }, [viewers, viewerInfo, vectors, vectorDirs])

 /**
  * @typedef TOnChangeTask
  * @function
  * @param {string} name
  * @param {Object} task
  * @return {void}
  * */
 const onChangeTask = (name, task = {}) => {
  setTasks((tasks) => {
   return {
    ...tasks,
    [name]: task,
   }
  })

  socket.emit("set-task", { name, task })
  onNeedHelpChanged({ name, doNeedHelp: false })

  /* TODO при получение нового таска подставим значение из task */
  if (task.timerSec) {
   dispatchTimer({
    type: "setTimer",
    payload: {
     name: name,
     ...initialTimer,
     time_int: task.timerSec * 1000,
    },
   })
  } else {
   dispatchTimer({ type: "resetTimer", payload: { name: name } })
  }
 }

 const getGradeAfterGotTask = async ({ task, name }) => {
  const pos = fastFindByName(viewers, name)
  const pupil = viewers[pos]

  if (!pupil) {
   return
  }

  try {
   const [err, grade] = await getGradesByTask(
    pupil.pupilId,
    task.vectorStrId,
    task.slideNum
   )
   return grade
  } catch (err) {
   console.error(err)
  }
 }

 const updateGrades = ({ name, grade }) => {
  setGrades({
   ...grades,
   [name]: grade,
  })
 }

 const onClickShowCallToSupport = (viewerName, showCallToSupport) => {
  if (!viewerName) {
   return
  }

  if (showCallToSupport) {
   socket.emit("call-to-support:on", { name: viewerName })
  } else {
   socket.emit("call-to-support:off", { name: viewerName })
  }

  setViewersSettings((prevState) => ({
   ...prevState,
   [viewerName]: {
    ...(prevState[viewerName] || {}),
    needCallToSupport: showCallToSupport,
   },
  }))
 }

 const onDirectRequested = (name) => {
  if (directWith === name) {
   setDirectWith(null)
   socket.emit("direct-off")
  } else {
   setDirectWith(name)
   socket.emit("direct-on", { name })
  }
 }

 const onNeedHelpChanged = ({ name, doNeedHelp }) => {
  socket.emit("ask-for-help", { name, doNeedHelp })
  if (doNeedHelp) {
   setViewersNeedHelp((viewersNeedHelp) => {
    if (viewersNeedHelp.indexOf(name) > -1) {
     return viewersNeedHelp
    }
    return viewersNeedHelp.concat([name])
   })
  } else {
   setViewersNeedHelp((viewersNeedHelp) =>
    viewersNeedHelp.filter((i) => i !== name)
   )
  }
 }

 const onShowLargeTask = (pupil) => {
  setLargePreview(pupil)
 }

 const onCloseLargeTask = () => {
  setLargePreview(null)
 }

 const updateAndGetNewGroupId = () => {
  const allTags = []
  map(viewers, (item) => {
   if (tags[item.name]) {
    const groupTag = tags[item.name].filter((i) => i !== "selected")
    allTags.push(groupTag)
   }
  })

  const lastGroupId = max(allTags)

  const newId = Number(lastGroupId) + 1
  setNewGroupId(newId)
  return newId
 }

 const handleSelectPupil = (name, e, isLikeCtrl = false) => {
  /*
     В OSX e.ctrlKey не работает вообще, и привычный клик на e.metaKey
     Проверь, пожалуйста, скорее всего в Windows e.metaKey это CTRL и есть.
    */

  if (!tags[name]) {
   tags[name] = []
  }

  const isSelected = tags[name].indexOf("selected") > -1
  let selectedPupilsGroupId = tags[name].find((i) => i !== "selected")
  let newId = newGroupId || updateAndGetNewGroupId()

  // Снять выделение с выделенной группы
  const groupUnselected = () => {
   map(viewers, (i) => {
    const tagGroupItem =
     tags[i.name] && tags[i.name].find((i) => i !== "selected")
    if (tagGroupItem === selectedGroupId) {
     tags[i.name] = tags[i.name].filter((i) => i !== "selected")
     socket.emit("tag:remove", { name: i.name, tag: "selected" })
    }
    return i
   })
   setSelectedGroupId(false)
  }

  // Снять выделение со всех
  const unSelectedAll = () => {
   map(viewers, (i) => {
    if (tags[i.name] && tags[i.name].indexOf("selected") > -1) {
     tags[i.name] = tags[i.name].filter((i) => i !== "selected")
     socket.emit("tag:remove", { name: i.name, tag: "selected" })
    }

    return i
   })
   setSelectedGroupId(false)
  }

  if (e.metaKey || e.ctrlKey || isLikeCtrl) {
   unSelectedAll()
   // }
   tags[name].push("selected")
   socket.emit("tag:add", { name: name, tag: "selected" })

   if (!selectedPupilsGroupId) {
    setSelectedGroupId(newId)
   } else {
    setSelectedGroupId(selectedPupilsGroupId)
   }
  } else if (isSelected) {
   // Если ученик уже выделен отменим выделение
   // Если ученик принадлежит к выделенной группе (ученик так же может принадлежать к другой группе)
   if (selectedPupilsGroupId === selectedGroupId) {
    // то снимем с этой группы выделение
    groupUnselected()
   }

   tags[name] = tags[name].filter((i) => i !== "selected")

   socket.emit("tag:remove", { name: name, tag: "selected" })

   const isSelectedItem = find(
    viewers,
    (i) => tags[i.name] && tags[i.name].find((i) => i === "selected")
   )

   if (!isSelectedItem) {
    setSelectedGroupId(false)
   }
   // Ученик не выделен и он в группе
  } else if (selectedPupilsGroupId) {
   // еще нет выбранной группы. отметим всех в этой группе
   if (!selectedGroupId) {
    map(viewers, (i) => {
     if (!tags[i.name]) tags[i.name] = []
     const tagGroupItem = tags[i.name].find((i) => i !== "selected")
     const isSelectedItem = tags[i.name].indexOf("selected") > -1
     if (tagGroupItem === selectedPupilsGroupId && !isSelectedItem) {
      tags[i.name].push("selected")
      socket.emit("tag:add", { name: i.name, tag: "selected" })
     }
     return i
    })
    setSelectedGroupId(selectedPupilsGroupId)
   } else {
    unSelectedAll()
    map(viewers, (i) => {
     if (!tags[i.name]) tags[i.name] = []
     const tagGroupItem = tags[i.name].find((i) => i !== "selected")
     const isSelectedItem = tags[i.name].indexOf("selected") > -1
     if (tagGroupItem === selectedPupilsGroupId && !isSelectedItem) {
      tags[i.name].push("selected")
      socket.emit("tag:add", { name: i.name, tag: "selected" })
     }
     return i
    })
    setSelectedGroupId(selectedPupilsGroupId)
   }
  } else {
   // есть выбранная группа. но нажали без ctrl снимем выделение с группы
   if (selectedGroupId) {
    // !e.ctrlKey &&
    groupUnselected()
   }

   tags[name].push("selected")
   socket.emit("tag:add", { name: name, tag: "selected" })
   setSelectedGroupId(newId)
  }

  setTags((tags) => {
   return { ...tags }
  })
 }

 const onChangeSelectedSound = (option) => {
  // TODO тут нужно говорить только с выбранными
  const { value } = option
  if (value === "selected") {
   socket.emit("tag:direct:on", { tag: "selected" })
   setDirectTag("selected")
  } else {
   socket.emit("tag:direct:off", { tag: "selected" })
   setDirectTag(null)
  }
 }

 const onChangeGroupSound = (value) => {
  if (value) {
   settingsByTag[selectedGroupId] = { sound: "all" }
   socket.emit("tag:set-setting", {
    tag: selectedGroupId,
    settings: { sound: "all" },
   })
  } else {
   settingsByTag[selectedGroupId] = { sound: "teacher" }
   socket.emit("tag:set-setting", {
    tag: selectedGroupId,
    settings: { sound: "teacher" },
   })
  }

  setSettingsByTag((settingsByTag) => {
   return { ...settingsByTag }
  })
 }

 const onChangeSoundSetting = (value) => {
  if (value) {
   settings.sound = "all"
   socket.emit("room:set-setting", {
    settings: { sound: "all" },
   })
  } else {
   settings.sound = "teacher"
   socket.emit("room:set-setting", {
    settings: { sound: "teacher" },
   })
  }

  setSettings((settings) => {
   return { ...settings }
  })
 }

 const getBreadCrumbs = (value) => {
  const currentDir = find(vectorDirs, (vd) => vd.strId === value.value)
  if (!currentDir) {
   return null
  }
  let parentStrId = currentDir.parentStrId
  const result = [
   {
    value: currentDir.strId,
    type: "vectorDir",
    title: currentDir.title,
   },
  ]

  let parent = null
  do {
   parent = find(vectorDirs, (vd) => vd.strId === parentStrId)
   if (!parent) {
    result.unshift({
     value: null,
     type: "vectorDir",
     title: null,
    })
   } else {
    result.unshift({
     value: parent.strId,
     type: "vectorDir",
     title: parent.title,
    })
    parentStrId = parent.parentStrId
   }
  } while (parent)

  return result
 }

 const closeBanner = () => {
  setShowBanner(false)
 }

 const closeChat = () => {
  setIsShowChat(false)
 }

 const toLogIn = () => {
  const thisUrl = document.location.href
  sessionStorage.setItem("redirectUrl", thisUrl)
  window.location.replace("/login")
 }

 let largePreviewName = largePreview?.name
 const task = largePreview && largePreview.name && tasks[largePreview.name]
 const viewerSettings =
  (largePreview && largePreview.name && viewersSettings[largePreview.name]) ||
  {}
 const lastBreadCrumbsPresenter =
  largePreview && largePreview.pupilId && lastBreadCrumbs[largePreview.pupilId]
console.log("viewers: ", viewers);
 return (
  <div className={styles.pageWrap}>
   {isShowBanner && (
    <BrowserBanner currentBrowser={browserName} onClose={closeBanner} inner />
   )}

   <div className={styles.presenterWrap}>
    <Sidebar
     error={error}
     errors={errors}
     setError={setError}
     senders={senders}
     presenterVideoRef={videoRef}
     audioSource={audioSource}
     setAudioSource={setAudioSource}
     videoSource={videoSource}
     setVideoSource={setVideoSource}
     onChangeSoundSetting={onChangeSoundSetting}
     localStream={localStream}
     messageHistory={messageHistory}
     isShowChat={isShowChat}
     setIsShowChat={setIsShowChat}
     settings={settings}
     group={group}
     socket={socket}
    />
    <div className={styles.presenterContent}>
     {iceFetchPending && <Loader text={"Идёт создание урока..."} />}
     <ConnectProblem isShow={connectionState === "disconnected"} />
     <LargeTaskModal
      task={task}
      onClose={onCloseLargeTask}
      pupil={largePreview}
      onGotTask={onChangeTask}
      setOpenedGradeModal={setOpenedGradeModal}
      timer={largePreview && largePreview.name && timers[largePreview.name]}
      grade={largePreview && largePreview.name && grades[largePreview.name]}
      updateGrades={updateGrades}
      lastBreadCrumbs={
       largePreview &&
       largePreview.pupilId &&
       lastBreadCrumbs[largePreview.pupilId]
      }
      vectorDirs={largePreview?.accessVectorDirs || vectorDirs}
      onChangeTask={onChangeTask}
      socket={socket}
      group={group}
      setVectorTasks={setVectorTasks}
      vectors={vectors}
      vectorTasks={vectorTasks}
      roomStrId={roomStrId}
      viewerSettings={viewerSettings}
      onClickShowCallToSupport={() =>
       onClickShowCallToSupport(
        largePreview?.name,
        !viewersSettings?.[largePreview?.name]?.needCallToSupport
       )
      }
     />
     <div className={styles.presenterLeft}>
      <div className={styles.sticky}>
       {!user && userIsStopPending && (
        <button
         onClick={toLogIn}
         className={cn(styles.button, styles.authorizationBtn)}
        >
         <IconAuthorization className={styles.marginRight10} /> Пожалуйста,
         авторизуйтесь
        </button>
       )}
       <div className={styles.presenterMeta}>
        <PresenterVideo videoRef={videoRef} settings={settings} />
       </div>
       <div
        style={{
         display: isShowChat ? "none" : "flex",
         flexDirection: "column",
         height: "100%",
        }}
       >
        <div className={styles.switchWrap}>
         <Switch
          title='С кем говорит учитель:'
          option1={{ value: "all", label: "Со всеми" }}
          option2={{ value: "selected", label: "С выбранными" }}
          onChange={onChangeSelectedSound}
         />
         <Checkbox
          label='все видят и слышат всех'
          onChange={onChangeSoundSetting}
          defaultValue={settings.sound === "all"}
         />
        </div>
        <GroupedPupils
         tags={tags}
         setTags={setTags}
         viewers={viewers}
         viewersNeedHelp={viewersNeedHelp}
         timers={timers}
         onSelectPupil={handleSelectPupil}
         newGroupId={newGroupId}
         setNewGroupId={setNewGroupId}
         selectedGroupId={selectedGroupId}
         setSelectedGroupId={setSelectedGroupId}
         tasksByGroup={tasksByGroup}
         setTasksByGroup={setTasksByGroup}
         allVectorDirs={vectorDirs}
         socket={socket}
         onChangeTask={onChangeTask}
         setVectorTasks={setVectorTasks}
         vectors={vectors}
         vectorTasks={vectorTasks}
         group={group}
         largePreview={largePreview}
         largePreviewName={largePreviewName}
         task={task}
         lastBreadCrumbsPresenter={lastBreadCrumbsPresenter}
         tasks={tasks}
        />
        <EmojiBlock />
       </div>
      </div>
      {showChatFlag && isShowChat && user && (
       <ChatWidget
        socket={socket}
        allViewers={viewers}
        onClose={closeChat}
        displayName={user?.nameForPupils}
        theme='whiteTheme'
        isPresenterScreen
        userId={user.id}
        from={"presenter"}
        messageHistory={messageHistory}
        setMessageHistory={setMessageHistory}
       />
      )}
     </div>
     <div className={styles.presenterRight}>
      <ul className={styles.presenterPupils}>
       {isPupilWait && <li>Ученик пришел</li>}
       {viewers.map((viewer, key) => (
        <li
         className={styles.presenterPupilsItem}
         key={`pupil_${key}_${viewer.name}`}
        >
         <PupilForPresenter
          pupil={viewer}
          reaction={viewersReactions[viewer.name]}
          task={tasks[viewer.name]}
          onDirectRequested={onDirectRequested}
          isDirectEnabled={directWith === viewer.name}
          directMuteWith={directMuteWith}
          setDirectMuteWith={setDirectMuteWith}
          isNeedHelp={viewersNeedHelp.includes(viewer.name)}
          isMuted={viewersMuted.includes(viewer.name)}
          isChatMuted={viewersChatMuted.includes(viewer.name)}
          onNeedHelpChanged={onNeedHelpChanged}
          onShowLargeTask={onShowLargeTask}
          timer={timers[viewer.name]}
          dispatchTimer={dispatchTimer}
          directTag={directTag}
          tags={tags[viewer.name] || []}
          selectedGroupId={selectedGroupId}
          onSelectPupil={(e) => handleSelectPupil(viewer.name, e, true)}
          showGradeBtn={mode === "auth"}
          isOpenedGradeModal={openedGradeModal === viewer.pupilId}
          setOpenedGradeModal={setOpenedGradeModal}
          viwerInfo={viewerInfo[viewer.pupilId]}
          grade={grades[viewer.name]}
          updateGrades={updateGrades}
          lastBreadCrumbs={lastBreadCrumbs[viewer.pupilId]}
          settings={settings}
          roomStrId={roomStrId}
          vectorDirs={viewer.accessVectorDirs || vectorDirs}
          onChangeTask={onChangeTask}
          socket={socket}
          group={group}
          setVectorTasks={setVectorTasks}
          vectors={vectors}
          vectorTasks={vectorTasks}
          viewerSettings={ viewersSettings[viewer.name] }
         />
        </li>
       ))}
      </ul>
      <div className={styles.bottomInfo}>
       <div className={styles.phoneNumber}>
        Техническая поддержка: 8 800 500 74 17
       </div>
      </div>
     </div>
    </div>
   </div>
   <ReactTooltip
    id='global'
    className={cn("tooltip", styles.tooltip)}
    textColor='#4DA3F7'
    backgroundColor='#fff'
    place={"bottom"}
    aria-haspopup='true'
    globalEventOff='click'
   />
  </div>
 )
}
