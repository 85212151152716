import React, { useEffect, useState } from 'react'
import styles from './viewerEndLesson.module.css'
import { useParams } from 'react-router'
import cn from "classnames";
import foxImg from './imgs/fox.png'
import sumBy from 'lodash/sumBy'
import { getIqTokensByRoomStrId, getIqTokenTotalBalanceByPupilGroupHash } from "../../api/iqTokensHistory";
import { ReactComponent as IqTokenSvg } from "./imgs/iqToken.svg";
import { createPupilReactionByPupilGroupHashId } from "../../api/pupilReactions";
import { Link } from "react-router-dom";
import { usePupil } from "../../context/user";
import { eventLog } from "../../api/apiEventLog";


const IqTokensContainer = ({ iqTokensByRoom = [], totalBalance = 0 }) => {
  const [iqTokenCountByRoom, setIqTokenCountByRoom] = useState(0)

  useEffect(() => {
    if (!iqTokensByRoom || iqTokensByRoom.length === 0) {
      return
    }

    const sumIqToken = sumBy(iqTokensByRoom, 'count')
    setIqTokenCountByRoom(sumIqToken)
  }, [iqTokensByRoom])


  return <div className={styles.iqTokenContainer}>
    <div className={styles.columnContainer}>
      <span className={styles.title}>Сегодня:</span>
      <span className={styles.iqTokenCount}>{iqTokenCountByRoom} <IqTokenSvg/></span>
    </div>
    <div className={styles.divider}/>
    <div className={styles.columnContainer}>
      <span className={styles.title}>Всего IQ:</span>
      <span className={styles.iqTokenCount}>{totalBalance} <IqTokenSvg/></span>
    </div>
  </div>
}

const PupilReactionContainer = ({ reaction = '', onClickReaction= () => {} }) => {
  const renderEmoji = (reactionVal)=> {
    switch (reactionVal) {
      case 'red-heart':
        return '❤'
      case 'thumbs-up':
        return '👍'
      case 'red-angry-face':
        return '😡'
      default:
        return null
    }
  }


  return <div className={styles.pupilReactionContainer}>
    <span className={styles.title}>{ !!reaction ? 'Твой урок был' :'Как прошёл урок?' }</span>
    { !!reaction ? <span className={cn(styles.chosenReaction, 'emoji')}>{renderEmoji(reaction)}</span> : <div className={ styles.reactionsBtn }>
      <button className={ cn(styles.reactionButton, 'emoji') } onClick={ () => onClickReaction('red-heart') }>❤</button>
      <button className={ cn(styles.reactionButton, 'emoji') } onClick={ () => onClickReaction('thumbs-up') }>👍</button>
      <button className={ cn(styles.reactionButton, 'emoji') } onClick={ () => onClickReaction('red-angry-face') }>😡
      </button>
    </div> }

    { !!reaction && <>
      <Link to={'/cabinet'} className={styles.buttonLink}>Вернуться в личный кабинет</Link>
      <Link to={'/cabinet/timetable'} className={styles.link}>Посмотреть расписание</Link>
    </> }
  </div>
}


const ViewerEndLesson = () => {
  const { roomStrId, roomId: pupilGroupHashId } = useParams()
  const pupil = usePupil()

  const [iqTokenTotalBalance, setIqTokenTotalBalance] = useState()
  const [iqTokensByRoom, setIqTokensByRoom] = useState([])
  const [reaction, setReaction] = useState('') //red-heart, thumbs-up, red-angry-face

  useEffect(() => {
    if (!roomStrId || !pupilGroupHashId) {
      return
    }

    getIqTokenTotalBalanceByPupilGroupHash(pupilGroupHashId).then(({ iqTokenBalance }) => setIqTokenTotalBalance(iqTokenBalance))
      .catch(err => console.error('getIqTokenTotalBalanceByPupilGroupHash error', err))

    getIqTokensByRoomStrId(roomStrId, pupilGroupHashId).then(setIqTokensByRoom)
      .catch(err => console.error('getIqTokensByRoomStrId error', err))
  }, [roomStrId, pupilGroupHashId])

  useEffect(() => {
    if (!roomStrId || !pupilGroupHashId) {
      return
    }

    const data = {
      userId: pupil ? pupil.id : null,
      from: 'viewerEndLesson',
      action: 'enter end lesson page',
      description: 'end lesson page for make reaction',
      meta: {
        roomStrId,
        pupilGroupHashId,
      }
    }

    eventLog(data).catch(err => console.error('eventLog error', err))
  }, [roomStrId, pupilGroupHashId])


  const onClickReaction = (reaction) => {
    createPupilReactionByPupilGroupHashId({payload: { roomStrId, reaction }, pupilGroupHashId})
      .then(pupilReaction => setReaction(pupilReaction.reaction))
      .catch(err => console.error('createPupilReactionByPupilGroupHashId error', err))
  }


  return <div className={cn(styles.pcContent, styles.pcWrapBG)}>
    <h1>Урок окончен!</h1>
    <div className={styles.fox}>
      <img src={foxImg}/>
      <div className={cn(styles.leftContainer, { [styles.withReaction]: !!reaction })}>
        <IqTokensContainer iqTokensByRoom={iqTokensByRoom} totalBalance={iqTokenTotalBalance}/>
        <PupilReactionContainer onClickReaction={onClickReaction} reaction={reaction}/>
      </div>
      <div className={cn(styles.foxSay, { [styles.orangeFoxSay]: reaction })}>{ !!reaction ? 'Спасибо за оценку' : 'Ты большой молодец' }</div>
    </div>
  </div>
}

export default ViewerEndLesson