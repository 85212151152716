import React from 'react'
import { ReactComponent as IconUnMute } from '../../img/icon-unmute.svg'
import plashka from '../../img/plashka_x2.png'
import styles from './teacherUnavailable.module.sass'
import TeacherFox from '../../img/teacher-fox.svg'
import TeacherText from '../../img/teacher-text.svg'


export default function TeacherUnavailable({ isSoundMuted }) {
console.log("isSoundMuted",isSoundMuted);
  return (
    <div className={styles.teacherUnavailable}>
      {/*<img src={plashka} alt="teacher-unavailable-img" className={styles.img}/>*/}
      <img src={TeacherText} alt="teacher-unavailable-text" className={styles.text}/>
      <img src={TeacherFox} alt="teacher-unavailable" className={styles.foxImage}/>
      {isSoundMuted && <div className="face-item-mute-icon">
        <IconUnMute className="icon" />
      </div>}
    </div>
  )
}