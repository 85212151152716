import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import sortBy from 'lodash/sortBy'
import io from 'socket.io-client'

import { initSignaling } from '../webrtc'

import { getForDemo } from '../api/pupilgroup'
import { setOffer } from '../api/pupils'
import { pad } from '../utils/util'
import DemoFirstScreen from '../DemoFirstScreen/DemoFirstScreen'
import ViewerScreen from '../ViewerScreen/ViewerScreen'

const DemoLesson = (props) => {
  const [socket, setSocket] = useState(null);
  const [viewerInfo, setViewerInfo] = useState({});
  const [error, setError] = useState();
  const [settings, setSettings] = useState({});
  const [settingsLoad, setSettingsLoad] = useState(false);
  const [lastScreen, setLastScreen] = useState(false)
  const [paymentScreen, setPaymentScreen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { lessonId } = useParams();

  const [connectionState, setConnectionState] = useState('initial')
  const [demoTimer, setDemoTimer] = useState({
    total: 0,
    days: pad(0),
    hours: pad(0),
    minutes: pad(0),
    seconds: pad(0),
  });
  const [timerIsEnd, setTimerIsEnd] = useState(false)
  const [loadGroup, setLoadGroup] = useState(false);
  const [demoData, setDemoData] = useState({});

  // посмотрим что за группа
  useEffect(() => {
    // нам нужно посмотреть на время демо урока

    getForDemo(lessonId).then((data) => {
      const lessonsArray = JSON.parse(data.lessons);
      setDemoData({
        ...data,
        lessons: sortBy(lessonsArray, (i) => {
            return Number(i.priceForAll);
        }),
      });
      setLoadGroup(true);
    });
  }, []);

  useEffect(() => {
    if (location.hash === '#last-screen') {
      setLastScreen(true)
    }
    if (location.hash === '#price') {
      setPaymentScreen(true);
    }
  }, [demoData])

  useEffect(() => {
    if (lastScreen && !demoData.offerDate) {
      setOffer(demoData.pupilId).then((data) => {
        setDemoData({
          ...demoData,
          offerDate: data.offerDate,
        });
        history.push(
          `${location.pathname}#last-screen`
        );
        setLastScreen(true);
      })
      .catch(e => console.error(e));
    }
  }, [demoData, lastScreen])

  useEffect(() => {
      let socket;
      try {
        socket = io();
        initSignaling(socket, setError);
      } catch (error) {
        console.error(error);
      }

      setSocket(socket);

      return () => {
          socket.close();
          setSocket(null);
      };
  }, [lessonId]);

  useEffect(() => {
      if (!socket || !lessonId) {
        return;
      }

      socket.on('connect', () => {
        console.log('socket connected')
        setConnectionState('connected')
      })

      socket.on('disconnect', () => {
        console.log('socket disconnected')
        setConnectionState('disconnected')
      })

      socket.emit("supervisor:init", { roomId: demoData.roomId });

      socket.emit('pupil-demo-come')

      socket.emit("room:want-state");

      socket.on("room:state", (state) => {
        console.log("room:state", state);
        const { settings } = state;
        if (settings.startedAt && settings.finishedAt) {
          history.push(`${location.pathname}#last-screen`);
          setLastScreen(true)
        }
        setSettings(settings);
        setSettingsLoad(true);
      });
  }, [socket, demoData.roomId]);

  useEffect(() => {
    if (Date.parse(demoData.demoDate) - Date.parse(new Date()) > 0) {
      const demoTimerInterval = setInterval(() => {
        const t = Date.parse(demoData.demoDate) - Date.parse(new Date());

        if (t <= 0) {
          setTimerIsEnd(true)
        }

        const seconds = Math.floor((t / 1000) % 60);
        const minutes = Math.floor((t / 1000 / 60) % 60);
        const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        const days = Math.floor(t / (1000 * 60 * 60 * 24));

        setDemoTimer({
          total: t,
          days: pad(days),
          hours: pad(hours),
          minutes: pad(minutes),
          seconds: pad(seconds),
        });
      }, 1000);
      return () => {
        clearInterval(demoTimerInterval);
      };
    } else if (demoData.demoDate) {
      setTimerIsEnd(true)
    }
  }, [demoData.demoDate]);

  const handleVieverInit = (data) => {
    setViewerInfo(data);
  };

  if (!loadGroup && !settingsLoad) {
    return <div><div className="loader" /></div>;
  }

  if (settings.startedAt || lastScreen || paymentScreen || timerIsEnd) {
    history.push(`/lesson/${lessonId}`)
    return null
  }

  return (
    <DemoFirstScreen
      demoData={demoData}
      demoTimer={demoTimer}
      viewerInfo={viewerInfo}
      roomId={demoData.roomId}
      socket={socket}
      connectionState={connectionState}
    />
  );
};

export default DemoLesson;
