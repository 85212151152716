import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./switch.module.css";

export default function Switch({
    title,
    option1,
    option2,
    current = option1,
    onChange,
}) {
    const [currOption, setCurrOption] = useState(current);

    if (!option1 || !option2) {
        return null;
    }

    const onChangeSetting = () => {
        if (currOption.value === option1.value) {
            setCurrOption(option2);
            onChange(option2);
        }
        if (currOption.value === option2.value) {
            setCurrOption(option1);
            onChange(option1);
        }
    };

    const cnOption1 = cn(styles.option, {
        [styles.current]: (currOption.value === option1.value),
    });
    const cnOption2 = cn(styles.option, {
        [styles.current]: (currOption.value === option2.value),
    });
    const cnSwitch = cn(styles.switch, {
        [styles.option1]: (currOption.value === option1.value),
        [styles.option2]: (currOption.value === option2.value),
    });
    return (
        <div className={styles.wrap}>
            <div className={styles.title}>{title}</div>
            <div className={styles.options}>
                <button
                    className={cnOption1}
                    onClick={
                        currOption.value !== option1.value
                            ? onChangeSetting
                            : null
                    }
                >
                    {option1.label}
                </button>
                <button className={cnSwitch} onClick={onChangeSetting} />
                <button
                    className={cnOption2}
                    onClick={
                        currOption.value !== option2.value
                            ? onChangeSetting
                            : null
                    }
                >
                    {option2.label}
                </button>
            </div>
        </div>
    );
}

Switch.propTypes = {
    option1: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
        ]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    option2: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
        ]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
};
