import React from "react";
import cn from "classnames";
import styles from './buttonView.module.css'
import { ReactComponent as IconviewGallery } from "../../img/view-gallery.svg";
import { ReactComponent as IconviewMosaic } from "../../img/view-mosaic.svg";
import { ReactComponent as IconviewReport } from "../../img/view-report.svg";


const views = {
  gallery: {
    label: 'Галерея',
  },
  mosaic: {
    label: 'Мозаика',
  },
  report: {
    label: 'Учитель',
  },
}

const ButtonView = ({
  currentView = 'gallery',
  theme = 'light',
  onClick = () => {},
}) => {
  const renderIcon = () => {
    const classIcon = cn('icon', `icon_${theme}`, styles.icon)
    switch (currentView) {
      case 'gallery':
        return <IconviewGallery className={classIcon}/>
      case 'mosaic':
        return <IconviewMosaic className={classIcon}/>
      case 'report':
        return <IconviewReport className={classIcon}/>
      default:
        break
    }
  }

  return <button
    onClick={onClick}
    className={styles.button}
    data-tip={views[currentView].label}
    data-for="global"
  >
    {renderIcon()}
  </button>
}

export default ButtonView