import cn from 'classnames'
import React, { useContext, useEffect } from 'react'
import get from 'lodash/get'
import { useHistory, useLocation } from 'react-router'
import { eventLog } from '../api/apiEventLog'
import { PupilContext } from '../context/user'
import styles from './NotFound.module.css'

export default function NotFound() {
  const { pupil } = useContext(PupilContext)
  const history = useHistory();

  useEffect(() => {
    const data = {
      userId: pupil && pupil.id,
      from: '404_not_found',
      action: 'open_404_not_found',
      description: 'load_data_404',
      meta : {
        notFoundPath: get(history, 'location.state.referrer', null) || `${window.location.protocol}//${window.location.host}${history.location.pathname}`,
        pupil: pupil
      }
    }
    eventLog(data).catch(err => console.log('eventLog error', err))
  }, [pupil]);


  return (
    <div className={cn(styles.pcContent, styles.pcWrapBG)}>
      <h1>Ошибка 404</h1>
      <p>
        Здесь раньше была страница, но теперь её нет
        <br/>
        или она в другом месте
      </p>
      <div className={styles.buttonContainer}>
        <button className={cn(styles.btn, 'buttonNext buttonNext--fill')} onClick={() => history.go(-1)}>Назад</button>
        <button className={cn(styles.btn, 'buttonNext')} onClick={() => history.push('/cabinet')}>В личный кабинет</button>
      </div>
      <div className={styles.footer}>Техподдержка: <a href={'tel:8 800 500 74 17'}>8 800 500 74 17</a></div>
    </div>
  )
}
