import superagent from 'superagent'

export async function createIqTokenHistoryRecord(payload) {
  const resp = await superagent.post('/api/iq-tokens-history').send(payload);
  return resp.body;
}

export async function getIqTokenHistoryByPupil(pupilId) {
  const reps = await superagent.get(`/api/iq-tokens-history/list/by-pupil/${pupilId}`)
  return reps.body
}

export async function getIqTokenTotalBalanceByPupilGroupHash(pupilGroupHashId) {
  const reps = await superagent.get(`/api/iq-tokens-history/total-balance/by-pupil-hash/${pupilGroupHashId}`)
  return reps.body
}

export async function getIqTokensByRoomStrId(roomStrId, pupilGroupHashId) {
  const reps = await superagent.get(`/api/iq-tokens-history/list/by-room/${roomStrId}/${pupilGroupHashId}`)
  return reps.body
}