import superagent from 'superagent'
import compact from 'lodash.compact'


export async function getVectors() {
  const resp = await superagent.get('/api/vector/list')
  return resp.body
}

export async function getParsedVectors() {
  const vectors = await getVectors()

  const vectorsWithTasks = compact(vectors.map((vector) => {
    if (!vector.metaJson) {
      return null
    }

    const meta = JSON.parse(vector.metaJson)

    if (!meta || !meta.tasks || meta.tasks.length === 0) {
      return null
    }

    return [vector, meta]
  }))

  return vectorsWithTasks
}

export async function getVectorsByPupilId(pupilId) {
  const resp = await superagent.get(`/api/vector/list-by-pupil/${pupilId}`)
  return resp.body
}

export async function getVectorsByDir(vectorDirStrId) {
  const resp = await superagent.get(`/api/vector/list-by-vector-dir/${vectorDirStrId}`)
  return resp.body
}

export async function getVector(vectorId) {
  const resp = await superagent.get(`/api/vector/${vectorId}`)
  return resp.body
}

export async function createVector(vector) {
  const resp = await superagent.post('/api/vector')
    .send(vector)
  return resp.body
}

export async function uploadVector(zipFile, onProgress = () => {
}) {
  const resp = await superagent.post('/api/vector/upload')
    .attach('vector', zipFile)
    .on('progress', onProgress)
  return resp.body
}

export async function uploadDocx(docxFile, onProgress = () => {
}) {
  const resp = await superagent.post('/docx/api/docx')
    .attach('file', docxFile)
    .on('progress', onProgress)

  return resp.body
}

export async function loadDocx(uri) {
  const resp = await superagent.get(uri)
  return resp.text
}

export async function loadParsedDocx(uri) {
  const resp = await superagent.get(`/docx/parse${uri}`)
  return resp.body
}

export async function updateVector(vectorId, vector) {
  const resp = await superagent.put(`/api/vector/${vectorId}`)
    .send(vector)
  return resp.body
}

export async function updateVectorMeta(vectorId, meta) {
  const resp = await superagent.put(`/api/vector/${vectorId}/update-meta`)
    .send(meta)
  return resp.body
}

export async function postRawImage(fileId, dataUrl) {
  const resp = await superagent.post(`/api/vector/${fileId}/image-raw`)
    .set('Content-Type', 'text/plain')
    .send(dataUrl)
  return resp.body
}

export async function removeVector(vectorId) {
  const resp = await superagent.del(`/api/vector/${vectorId}`)
  return resp.body
}

export async function getVectorDirList() {
  const resp = await superagent.get('/api/vector-dir')
  return resp.body
}

export async function getVectorDir(strId) {
  const resp = await superagent.get(`/api/vector-dir/${strId}`)
  return resp.body
}

export async function getVectorDirsByVectorDir(strId) {
  const resp = await superagent.get(`/api/vector-dir/list-by-vector-dir/${strId}`)
  return resp.body
}

export async function getBreadcrumbByVectorDir(strId) {
  const resp = await superagent.get(`/api/vector-dir/breadcrumb-by-vector-dir/${strId}`)
  return resp.body
}

export async function postVectorDir(plain) {
  const resp = await superagent.post('/api/vector-dir')
    .send(plain)
  return resp.body
}

export async function putVectorDir(strId, plain) {
  const resp = await superagent.put(`/api/vector-dir/${strId}`)
    .send(plain)
  return resp.body
}

export async function delVectorDir(strId) {
  const resp = await superagent.del(`/api/vector-dir/${strId}`)
  return resp.body
}

export async function getLastLesson(groupId, pupilId) {
  const { body } = await superagent.get(`/api/events/tasks/for-pupil/${pupilId}/${groupId}`)
  return body
}

export async function getNextVector(groupId, pupilId) {
  try {
    const { body } = await superagent.get(`/api/events/tasks/for-pupil/next-task2/${pupilId}/${groupId}`)
    return body
  } catch (error) {
    console.error('error', error)
  }
}

export  async function getLastTask(groupId, pupilId) {
  const { body } = await superagent.get(`/api/events/tasks/last-task-for-pupil/${pupilId}/${groupId}`)
  return body
}

export async function uploadSlideImage(vectorId, image, onProgress = () => {
}) {
  const resp = await superagent.post(`/api/vector/${vectorId}/slide-image`)
    .attach('slide-image', image)
    .on('progress', onProgress)
  return resp.body
}

export async function getVectorDirsByType({ type, parent }) {
  const resp = parent ? await superagent.get(`/api/vector-dir/list-by-type/${type}/${parent}`) : await superagent.get(`/api/vector-dir/list-by-type/${type}`)
  return resp.body
}

export async function getAccessVectorDirs(pupilId) {
  const resp = await superagent.get(`/api/vector-dir/access-list-by-pupil/${pupilId}`)
  return resp.body
}

export default {
  getVectors,
  getVector,
  getVectorsByPupilId,
  getVectorsByDir,
  getVectorDirList,
  createVector,
  updateVector,
  uploadVector,
  removeVector,
  uploadSlideImage,
  getVectorDirsByVectorDir,
  getBreadcrumbByVectorDir,
  getVectorDirsByType,
  getAccessVectorDirs,
}
