import React from "react";
import MenuPopup from "../MenuPopup/MenuPopup";
import PopupBtn from "../MenuPopup/PopupBtn/PopupBtn";
import find from 'lodash/find'
import { ReactComponent as UpdateDevicesSvg } from "./img/update-devices-icon.svg";
import { ReactComponent as MicroSVG } from "./img/micro-icon.svg";
import { ReactComponent as CameraSVG } from "./img/camera-icon.svg";
import useMDSettings from "../../../../components/SettingMD/useMDSettings";
import PopupBtnSelect from "../MenuPopup/PopupBtnSelect/PopupBtnSelect";

const DeviceSettingsPopupMenu = ({onClose, onChangeSettingMediaDevice}) => {
  const {
    defaultSources,
    setDefaultSources,
    optionsAudioSource,
    optionsVideoSource,
    updateDevices,
  } = useMDSettings()

  const onSelectChange = ({ target }) => {
    if (!target || !onChangeSettingMediaDevice) {
      return
    }
    const kind = target.id
    const source = target.value


    setDefaultSources({...defaultSources, [kind]: source })
    onChangeSettingMediaDevice({ kind, source });
  };

  return (
    <MenuPopup
      title={'Настройки'}
      onClose={onClose}
    >
      <PopupBtn
        title={'Обновить список устройств'}
        svgIcon={<UpdateDevicesSvg/>}
        onClick={updateDevices}
      />

      <PopupBtnSelect
        title={'Микрофон'}
        svgIcon={<MicroSVG />}
        value={defaultSources.audioSource || 'default'}
        valueText={find(optionsAudioSource, { deviceId: defaultSources.audioSource })?.text || 'По-умолчанию'}
        selectId={'audioSource'}
        onSelectChange={onSelectChange}
      >
        <option value={'default'} key={'default'}>По-умолчанию</option>
        {optionsAudioSource.map(audioSource => <option value={audioSource.deviceId} key={audioSource.deviceId}>{audioSource.text}</option> )}
      </PopupBtnSelect>

      <PopupBtnSelect
        title={'Камера'}
        svgIcon={<CameraSVG />}
        value={defaultSources.videoSource || 'default'}
        valueText={find(optionsVideoSource, { deviceId: defaultSources.videoSource })?.text  || 'По-умолчанию'}
        selectId={'videoSource'}
        onSelectChange={onSelectChange}
      >
        <option value={'default'} key={'default'}>По-умолчанию</option>
        {optionsVideoSource.map(videoSource => <option value={videoSource.deviceId} key={videoSource.deviceId}>{videoSource.text}</option> )}
      </PopupBtnSelect>
    </MenuPopup>
  )
}

export default DeviceSettingsPopupMenu