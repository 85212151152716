import React, { useEffect, useMemo, useState } from "react";
import PupilList from "../PupilList/PupilList";
import styles from "./groupedPupils.module.css";
import find from "lodash/find";
import filter from "lodash/filter";
import SelectTask from "../../../components/SelectTask/SelectTask";
import cn from "classnames";
import { ReactComponent as IconViewGallery } from "../../img/view-gallery.svg";
import { ReactComponent as IconViewMosaic } from "../../img/view-mosaic.svg";
import { ReactComponent as IconViewReport } from "../../img/view-report.svg";
import union from "lodash/union";
import ReactTooltip from "react-tooltip";
import EpubTools from "./EpubTools";
import CanvasTools from "./CanvasTools";

const viewValues = [
  {
    value: "gallery",
    label: "Вид галереи",
  },
  {
    value: "mosaic",
    label: "Вид мозайки",
  },
  {
    value: "report",
    label: "Демонстрация учителя",
  },
];

const ViewIcon = ({ viewIndex }) => {
  const currentView = viewValues[viewIndex];
  const classIcon = cn("icon", `icon_dark`, styles.viewIcon);
  switch (currentView.value) {
    case "gallery":
      return <IconViewGallery className={classIcon} />;
    case "mosaic":
      return <IconViewMosaic className={classIcon} />;
    case "report":
      return <IconViewReport className={classIcon} />;
    default:
      break;
  }
};
const GroupedPupils = ({
  tags,
  setTags,
  viewers,
  selectedGroupId,
  setSelectedGroupId,
  tasksByGroup,
  setTasksByGroup,
  newGroupId,
  setNewGroupId,
  onSelectPupil,
  timers,
  viewersNeedHelp,
  allVectorDirs,
  socket,
  vectors,
  vectorTasks,
  setVectorTasks,
  onChangeTask,
  group,
  task,
}) => {
  const [viewIndex, setViewIndex] = useState(0);
  const [viewersNameFromSelectedGroup, setViewersNameFromSelectedGroup] =
    useState([]);
  const [toolsByGroupId, setToolsByGroupId] = useState({});
  const [modeByGroupId, setModeByGroupId] = useState({});
  const [curtainDirection, setCurtainDirection] = useState({});

  useEffect(() => {
    if (!selectedGroupId) {
      return;
    }

    const selectedNames = [];
    for (const viewerName in tags) {
      if (tags[viewerName].includes(selectedGroupId)) {
        selectedNames.push(viewerName);
      }
    }

    setViewersNameFromSelectedGroup(selectedNames);
    if (!task) {
      setToolsByGroupId({
        ...toolsByGroupId,
        [selectedGroupId]: {
          curtain: null,
          pencil: false,
          pointer: false,
          magnifier: false,
        },
      });
    }

    if (!task) {
      setModeByGroupId({
        ...modeByGroupId,
        [selectedGroupId]: {},
      });
    }

    if (task) {
      setCurtainDirection({
        ...curtainDirection,
        [selectedGroupId]: {},
      });
    }
  }, [selectedGroupId, task]);

  const vectorDirs = useMemo(() => {
    if (viewersNameFromSelectedGroup.length === 0) {
      return [];
    }

    const currentViewersAccessVectorDirs = viewers
      .filter((v) => viewersNameFromSelectedGroup.includes(v.name))
      .map((v) => (v.accessVectorDirs ? v.accessVectorDirs : []));
    const accessVectorDirs = union(...currentViewersAccessVectorDirs);
    return accessVectorDirs.length > 0 ? accessVectorDirs : allVectorDirs;
  }, [viewers, viewersNameFromSelectedGroup, allVectorDirs]);

  const isButtonToGroup = useMemo(
    () =>
      find(viewers, (viewer) => {
        if (!tags[viewer.name]) return false;
        const isSelected = tags[viewer.name].indexOf("selected") > -1;
        const pupilGroupId = tags[viewer.name].find((i) => i !== "selected");
        return isSelected && pupilGroupId !== selectedGroupId;
      }),
    [viewers, tags, selectedGroupId]
  );

  const isSelectedViewers = useMemo(
    () =>
      filter(
        viewers,
        (viewer) =>
          tags[viewer.name] && tags[viewer.name].find((i) => i === "selected")
      ),
    [tags, viewers]
  );

  const isButtonUnGroup = useMemo(
    () =>
      find(
        viewers,
        (viewer) =>
          tags[viewer.name] &&
          tags[viewer.name].find((viewer) => viewer !== "selected")
      ),
    [viewers, tags]
  );

  const clearAllTools = () => {
    closeCurtain();
    setToolsByGroupId({
      [selectedGroupId]: {
        curtain: null,
        pencil: false,
        pointer: false,
        magnifier: false,
      },
    });
    setModeByGroupId({ [selectedGroupId]: "" });
  };

  const changeView = () => {
    let viewIndexForSet = viewIndex + 1;
    if (viewIndexForSet >= viewValues.length) {
      viewIndexForSet = 0;
      setViewIndex(0);
    } else {
      setViewIndex(viewIndexForSet);
    }

    socket.emit("view:pupil:change", {
      viewIndex: viewIndexForSet,
      tag: "selected",
    });
  };

  const makeGroup = () => {
    const tagsForSet = { ...tags };
    for (const viewer of viewers) {
      if (tags[viewer.name] && tags[viewer.name].indexOf("selected") > -1) {
        tagsForSet[viewer.name] = [selectedGroupId];
        socket.emit("tag:remove", { name: viewer.name, tag: "selected" });
        socket.emit("tag:add", { name: viewer.name, tag: selectedGroupId });
      }
    }

    setTags(tagsForSet);
    setSelectedGroupId(null);
    setNewGroupId(null);
  };

  const ungroupAll = () => {
    const tagsForSet = { ...tags };

    for (const viewer of viewers) {
      const groupPupilsId =
        tags[viewer.name] &&
        tags[viewer.name].find((tag) => tag !== "selected");
      tagsForSet[viewer.name] = [];

      socket.emit("tag:remove", { name: viewer.name, tag: groupPupilsId });
      socket.emit("tag:remove", { name: viewer.name, tag: "selected" });
    }

    setTags(tagsForSet);
    setSelectedGroupId(null);
    setNewGroupId(null);
  };

  const changeTaskForGroup = (task) => {
    setTasksByGroup({ ...tasksByGroup, [selectedGroupId]: task });
    clearAllTools();
  };

  const handleMode = (mode) => {
    const modeForSetByGroup = { ...modeByGroupId, [selectedGroupId]: mode };
    setModeByGroupId(modeForSetByGroup);
    for (const viewerName of viewersNameFromSelectedGroup) {
      socket.emit("epub:teacher:mode", {
        name: viewerName,
        task: tasksByGroup?.[selectedGroupId],
        mode: modeForSetByGroup[selectedGroupId],
      });
    }
  };

  const handleTools = (toolName) => {
    const toolsForSetByGroup = {
      ...toolsByGroupId,
      [selectedGroupId]: {
        ...toolsByGroupId[selectedGroupId],
        [toolName]: true,
      },
    };
    //todo: избавиться от этого перебора!
    if (toolName === "pencil") {
      toolsForSetByGroup[selectedGroupId].pointer = false;
      // toolsForSetByGroup[selectedGroupId].magnifier = false;
    }
    if (toolName === "pointer") {
      toolsForSetByGroup[selectedGroupId].pencil = false;
      // toolsForSetByGroup[selectedGroupId].magnifier = false;
    }
    // if (toolName === "magnifier") {
    //   toolsForSetByGroup[selectedGroupId].pencil = false;
    //   toolsForSetByGroup[selectedGroupId].pointer = false;
    // }
    setToolsByGroupId(toolsForSetByGroup);
    for (const viewerName of viewersNameFromSelectedGroup) {
      socket.emit("canvas:teacher:tools:group", {
        name: viewerName,
        tools: toolsForSetByGroup[selectedGroupId],
        task: tasksByGroup?.[selectedGroupId],
      });
    }
  };
  const closeCurtain = () => {
    const toolsForSetByGroup = {
      ...toolsByGroupId,
      [selectedGroupId]: { ...toolsByGroupId[selectedGroupId], curtain: false },
    };
    setToolsByGroupId(toolsForSetByGroup);
    const curtainDirectionForSetByGroup = {
      ...curtainDirection,
      [selectedGroupId]: "",
    };
    setCurtainDirection(curtainDirectionForSetByGroup);
    for (const viewerName of viewersNameFromSelectedGroup) {
      socket.emit("canvas:teacher:tools:group", {
        name: viewerName,
        tools: toolsForSetByGroup[selectedGroupId],
        task: tasksByGroup?.[selectedGroupId],
      });
    }
  };

  const handleChangeCurtainDirection = (value) => {
    const changeCurtainDirectionForSetByGroup = {
      ...curtainDirection,
      [selectedGroupId]: value,
    };
    setCurtainDirection(changeCurtainDirectionForSetByGroup);
    for (const viewerName of viewersNameFromSelectedGroup) {
      socket.emit("canvas:teacher:curtain-direction", {
        name: viewerName,
        task: tasksByGroup?.[selectedGroupId],
        value,
      });
    }
  };

  const getTooltip = (id) => {
    return (
      <ReactTooltip
        id={id}
        className={styles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    );
  };
  const curtainTop = true;
  return (
    <>
      <PupilList
        tags={tags}
        viewers={viewers}
        newGroupId={newGroupId}
        onSelectPupil={onSelectPupil}
        timers={timers}
        viewersNeedHelp={viewersNeedHelp}
      />

      {isButtonToGroup && (
        <button className={styles.button} onClick={makeGroup}>
          Сгруппировать
        </button>
      )}
      {!isButtonToGroup && isButtonUnGroup && (
        <button className={styles.button} onClick={ungroupAll}>
          Разгруппировать всех
        </button>
      )}

      {isSelectedViewers && (
        <>
          {/*TODO служебная кнопка */}
          <span>Переключить вид выбранным ученикам:</span>
          <br />
          <button
            className={cn("button_no-style", styles.viewToggle)}
            onClick={changeView}
          >
            <ViewIcon viewIndex={viewIndex} />
            <span className={styles.viewLabel}>
              {viewValues[viewIndex].label}
            </span>
          </button>
        </>
      )}
      {selectedGroupId && !isButtonToGroup && (
        <div
          className={cn(
            styles.selectTaskWrap,
            styles[`selectTaskWrap_${selectedGroupId}`]
          )}
        >
          <SelectTask
            task={tasksByGroup[selectedGroupId]}
            dropdownPosition={"top"}
            names={viewersNameFromSelectedGroup}
            isGroupedPupils
            vectorDirs={vectorDirs}
            vectors={vectors}
            vectorTasks={vectorTasks}
            setVectorTasks={setVectorTasks}
            onChangeTask={onChangeTask}
            socket={socket}
            group={group}
            changeTaskForGroup={changeTaskForGroup}
          />
        </div>
      )}
      {selectedGroupId &&
        !isButtonToGroup &&
        tasksByGroup[selectedGroupId] &&
        toolsByGroupId[selectedGroupId] && (
          <>
            {tasksByGroup?.[selectedGroupId]?.slide ? (
              <EpubTools
                handleMode={handleMode}
                selectedGroupId={selectedGroupId}
                getTooltip={getTooltip}
                modeByGroupId={modeByGroupId}
                curtainDirection={curtainDirection}
                handleChangeCurtainDirection={handleChangeCurtainDirection}
                curtainTop={curtainTop}
              />
            ) : (
              <CanvasTools
                handleTools={handleTools}
                selectedGroupId={selectedGroupId}
                getTooltip={getTooltip}
                toolsByGroupId={toolsByGroupId}
                curtainDirection={curtainDirection}
                handleChangeCurtainDirection={handleChangeCurtainDirection}
                closeCurtain={closeCurtain}
                curtainTop={curtainTop}
              />
            )}
          </>
        )}
    </>
  );
};

export default GroupedPupils;
