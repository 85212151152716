import superagent from 'superagent'

export async function getPupils(params = {}) {
  const {
    search = '',
    offset = 0,
    status, //active, no-active
  } = params;
  const resp = await superagent.get('/api/pupil/list').query({ search, status }); //limit, offset
  return resp.body;
}
export async function getPupilsEasyList() {
  const resp = await superagent.get('/api/pupil/easy-list')
  return resp.body
}
export async function getPupil(pupilId) {
  const resp = await superagent.get(`/api/pupil/${pupilId}`);
  return resp.body;
}
export async function getPupilByContactPhone(contactPhone) {
  const resp = await superagent.get(`/api/pupil/by-contact-phone/${contactPhone}`);
  return resp.body;
}
export async function getPupilForPayment(pupilId) {
  const resp = await superagent.get(`/api/pupil/for-payment/${pupilId}`);
  return resp.body;
}

export async function createPupil(pupil) {
  const resp = await superagent.post('/api/pupil')
    .send(pupil);
  return resp.body;
}
export async function setOffer(pupilId) {
  const resp = await superagent.post('/api/pupil/set-offer')
    .send({ pupilId });
  return resp.body;
}
export async function updatePupil(pupilId, pupil) {
  const resp = await superagent.put(`/api/pupil/${pupilId}`)
    .send(pupil);
  return resp.body;
}

export async function setTariffPlanForPupil(pupilId, tariffPlanStrId) {
  const resp = await superagent.put('/api/pupil/set-tariff-plan').send({pupilId, tariffPlanStrId})
  return resp.body
}

export async function removePupil(pupilId) {
  const resp = await superagent.del(`/api/pupil/${pupilId}`)
  return resp.body;
}

export async function sendSms(payload) {
  const resp = await superagent.post('/api/pupil/send-sms').send(payload)
  return resp.body;
}
export async function sendSmsByPhone(payload) {
  const resp = await superagent.post('/api/pupil/send-code-by-phone').send(payload)
  return resp.body;
}
export async function getPupilsBySmsCode(payload) {
  const resp = await superagent.post('/api/pupil/check-code-and-get-pupils').send(payload)
  return resp.body;
}
export async function sendSmsLink(payload) {
  const resp = await superagent.post('').send(payload)
  return resp.body
}

export async function sendSmsTest(payload) {
  const resp = await superagent.post('/api/pupil/send-sms-test').send(payload)
  return resp.body;
}

export async function sendEmailTest(pupilId) {
  const resp = await superagent.get(`/api/pupil/send-email-test/${pupilId}`)
  return resp.body;
}

export async function sendDemoSms(pupilId) {
  const resp = await superagent.get(`/api/pupil/send-demo-sms/${pupilId}`)
  return resp.body;
}

export async function sendDemoEmail(pupilId) {
  const resp = await superagent.get(`/api/pupil/send-demo-email/${pupilId}`)
  return resp.body;
}

export async function sendLoginEmail(data) {
  const resp = await superagent.post('/api/pupil/send-login-email').send(data)
  return resp.body
}

export async function sendLoginSms(data) {
  const resp = await superagent.post('/api/pupil/send-login-sms').send(data)
  return resp.body
}

export async function checkSms(payload) {
  const resp = await superagent.post('/api/pupil/check-sms').send(payload)
  return resp.body;
}

export async function checkRecoveryCode(payload) {
  const resp = await superagent.post('/api/pupil/check-recovery-code').send(payload)
  return resp.body;
}

export async function saveNewPassword(payload) {
  const resp = await superagent.post('/api/pupil/save-new-password').send(payload)
  return resp.body;
}

export async function checkCode(payload) {
  const resp = await superagent.post('/api/pupil/check-code').send(payload)
  return resp.body;
}

export async function authBySecret(hashId) {
  const resp = await superagent.get(`/api/pupil/auth-by-secret/${hashId}`)
  return resp.body;
}

export async function sendEmail(payload) {
  const resp = await superagent.post('/api/pupil/send-email').send(payload)
  return resp.body;
}

export async function checkLogin(payload) {
  const resp = await superagent.post('/api/pupil/check-login').send(payload)
  return resp.body;
}

export async function sendCodeByLogin(payload) {
  const resp = await superagent.post('/api/pupil/send-code-by-login').send(payload)
  return resp.body;
}

export async function generateLogin(payload) {
  const resp = await superagent.post('/api/pupil/generate-login').send(payload)
  return resp.body
}

export async function checkPassword(payload) {
  const resp = await superagent.post('/api/pupil/check-password').send(payload)
  return resp.body;
}

export async function sendSmsReminder(payload) {
  const resp = await superagent.post('/api/pupil/send-sms-reminder').send(payload)
  return resp.body;
}

export async function getLessons() {
  const resp = await superagent.get('/api/pupilmeeting/by-pupil')
  return resp.body;
}

export async function getInfo() {
  const resp = await superagent.get('/api/pupil/cabinet/info')
  return resp.body;
}

export async function updateInfo(payload) {
  const resp = await superagent.post('/api/pupil/cabinet/update').send(payload)
  return resp.body;
}

export async function getDemoLink(pupilId) {
  const resp = await superagent.get(`/api/pupil/get-demo-link/${pupilId}`);
  return resp.body;
}

export async function sendEmailLesson(data) {
  const resp = await superagent.post('/api/pupil/send-lesson-email').send(data)
  return resp.body
}

export default {
  getPupils,
  getPupil,
  getPupilByContactPhone,
  createPupil,
  updatePupil,
  removePupil,
  setOffer,
  sendSms,
  checkSms,
  sendSmsTest,
  sendEmailTest,
  sendDemoSms,
  sendDemoEmail,
  getDemoLink,
  sendEmailLesson,
  sendLoginEmail,
  sendLoginSms,
}
