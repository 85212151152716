import React from 'react'
import styles from './chatPopupMenu.module.css'
import MenuPopup from "../MenuPopup/MenuPopup";
import MainMenu from "../MainMenu/MainMenu";
import ChatWidget from "../../../ChatWidget";
import Timer from "../Timer/Timer";


const ChatPopupMenu = ({
  onClosePopup,
  disabledButtons,
  activeBtn,
  messages,
  name,
  isChatMuted,
  socket,
  displayName,
  pupilId,
  timerPercent,
  task,
}) => {
  return (
    <MenuPopup
      title={'Чат'}
      onClose={onClosePopup}
      mainContainerClassName={styles.popupMenuMainContainer}
      contentContainerClassName={styles.popupMenuContentContainer}
    >
      <ChatWidget
        messages={messages}
        isMobile={true}
        isChatMuted={isChatMuted}
        socket={socket}
        displayName={displayName}
        roomName={name}
        userId={pupilId}
      />

      {timerPercent !== null && timerPercent !== undefined && <Timer timerPercent={timerPercent} />}

      <MainMenu
        activeBtn={activeBtn}
        disabledButtons={disabledButtons}
        onClickChatBtn={onClosePopup}
        socket={socket}
        task={task}
        name={name}
      />
    </MenuPopup>
  )
}

export default ChatPopupMenu