import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations"

import './index.css'
import App from './App'
import ErrorBoundary from './ErrorBoundary'
import * as serviceWorker from './serviceWorker'

let serverEnv = {}

try {
  serverEnv = JSON.parse(document.getElementById('SERVER_ENV').innerText)
} catch(err){
  console.error('ENV_SERVER IS EMPTY', err)
}

const IQ_ENV = serverEnv.REACT_APP_IQ_ENV || 'development'

console.log('@k IQ_ENV ', IQ_ENV, serverEnv, window.SERVER_ENV)
if (IQ_ENV === 'staging' || IQ_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3ae7654c93ab46ca9b3cd5e3dda45ade@logs.corp.jetstyle.in/27',
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['info'],
      })
    ],
    tracesSampleRate: 1.0,
    environment: IQ_ENV,
  })

  console.log('@k sentry init ', IQ_ENV)
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App/>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
