import React from 'react'
import cn from 'classnames'
import styles from './popupBtn.module.css'
import { ReactComponent as ArrowRightSVG } from "../PopupBtnSelect/img/arrow-right.svg";


const PopupBtn = ({
  isActive,
  onClick,
  svgIcon,
  svgIconActive,
  title,
  description,
}) => {
  return (
    <button className={cn(styles.btn, {[styles.activeBtn]: isActive} )} onClick={onClick}>
      <div className={styles.contentContainer}>
        {!isActive ? svgIcon : svgIconActive || svgIcon}
        <div className={styles.titleAndDescriptionContainer}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
      </div>
    </button>
  )
}

export default PopupBtn