import superagent from 'superagent'
import Promise from 'bluebird'
import compact from 'lodash.compact'

export async function getGroups() {
  const resp = await superagent.get('/api/group/list')
  return resp.body
}

export async function getGroupsNext() {
  const resp = await superagent.get('/api/group/list-v2')
  return resp.body
}

export async function finishDemoLesson(groupId) {
  const resp = await superagent.post('/api/group/finishedDemoLesson')
    .send({ groupId })
  return resp.body
}

export async function getGroup(groupId) {
  const resp = await superagent.get(`/api/group/${groupId}`)
  return resp.body
}

export async function getGroupByHash(hashId) {
  const resp = await superagent.get(`/api/group/by-hash/${hashId}`)
  return resp.body
}

export async function createGroup(group) {
  const resp = await superagent.post('/api/group')
    .send(group)
  return resp.body
}

export async function updateGroup(groupId, group) {
  const resp = await superagent.put(`/api/group/${groupId}`)
    .send(group)
  return resp.body
}

export async function removeGroup(groupId) {
  const resp = await superagent.del(`/api/group/${groupId}`)
  return resp.body;
}

export async function getDemoGroups() {
  const resp = await superagent.get('/api/group/list-demo')
  return resp.body
}

export async function createDemoGroup(payload) {
  const resp = await superagent.post('/api/group/by-pupil/create-demo').send(payload)
  return resp.body
}

export async function createDemoGroupNext(payload) {
  const resp = await superagent.post('/api/group/by-pupil/create-demo-next').send(payload)
  return resp.body
}

export async function getMeetingsByGroup(groupId) {
  const resp = await superagent.get(`/api/groupmeeting/by-group/${groupId}`)
  return resp.body
}

export async function getMeetingsByGroupForPupil(groupId, pupilId) {
  const resp = await superagent.get(`/api/groupmeeting/by-group-for-pupil/${groupId}/${pupilId}`)
  return resp.body
}

export async function addGroupMeeting(groupMeeting) {
  const resp = await superagent.post('/api/groupmeeting')
    .send(groupMeeting)
  return resp.body
}

export async function subscribePupilOnMeeting(groupMeeting, pupilGroup) {
  const resp = await superagent.post('/api/groupmeeting/subscribe').send({ groupMeeting, pupilGroup})
  return resp.body
}

export async function removeGroupMeeting(groupMeetingId) {
  const resp = await superagent.del(`/api/groupmeeting/${groupMeetingId}`)
  return resp.body;
}

export async function getMeetingOverridesByGroup(groupId) {
  const resp = await superagent.get(`/api/groupmeetingoverride/by-group/${groupId}`)
  return resp.body
}

export async function getMeetingOverridesByGroupForPupil(groupId, pupilId) {
  const resp = await superagent.get(`/api/groupmeetingoverride/by-group-for-pupil/${groupId}/${pupilId}`)
  return resp.body
}

export async function addGroupMeetingOverride(groupMeeting) {
  const resp = await superagent.post('/api/groupmeetingoverride')
    .send(groupMeeting)
  return resp.body
}

export async function removeGroupMeetingOverride(groupMeetingId) {
  const resp = await superagent.del(`/api/groupmeetingoverride/${groupMeetingId}`)
  return resp.body;
}

export async function fetchGroupsByPupil(pupilId) {
  const resp = await superagent.get(`/api/group/by-pupil/${pupilId}`)
  return resp.body
}

export async function fetchDemoGroupsByPupil(pupilId) {
  const resp = await superagent.get(`/api/group/by-pupil-demo/${pupilId}`)
  return resp.body
}

export async function fetchMeetingsByPupil(pupilId) {
  const resp = await superagent.get(`/api/schedule/by-pupil/${pupilId}`).query({ time: Date.now() })
  return resp.body
}

export async function getNearestLessonByPupilGroup(pupilGroupHash) {
  const resp = await superagent.get(`/api/schedule/nearest-lesson-by-pupil-group/${pupilGroupHash}`).query({ time: Date.now() })
  return resp.body
}

export async function getNearestLessonByPupil(pupilId) {
  const resp = await superagent.get(`/api/schedule/nearest-lesson-by-pupil/${pupilId}`).query({ time: Date.now() })
  return resp.body
}

export async function fetchScheduleByPupil(pupilId) {
  const { groups }  = await fetchGroupsByPupil(pupilId)
  console.log('timetable groups', groups)

  const scheduleByGroupRaw = await Promise.map(
      groups,
      async (group) => {
          const groupMeetings = await getMeetingsByGroupForPupil(group.id, pupilId)
          const groupMeetingOverrides = await getMeetingOverridesByGroupForPupil(group.id, pupilId)
          if (
              (!groupMeetings || groupMeetings.length === 0)
              &&
              (!groupMeetingOverrides || groupMeetingOverrides.length === 0)
          ) {
              return null
          }

          return { group, groupMeetings, groupMeetingOverrides }
      },
      { concurrency: 3 },
  )
  return compact(scheduleByGroupRaw)
}

export default {
  getGroups,
  getGroup,
  createGroup,
  updateGroup,
  removeGroup,
  getDemoGroups,
  getMeetingsByGroup,
  addGroupMeeting,
  removeGroupMeeting,
  fetchGroupsByPupil,
  fetchDemoGroupsByPupil,
  createDemoGroup,
  getGroupsNext,
  createDemoGroupNext,
  fetchScheduleByPupil,
  subscribePupilOnMeeting
}
