import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './keyboard.module.scss'


const valuesNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

const KeyBoard = ({ values, handleChange, isNum }) => {
  const keys = values || (isNum && valuesNum)

  return (
    <div className={styles.wrap}>
      {keys.map((i) => (
        <div className={styles.buttonWrap} key={i}>
          <button className={styles.button} onClick={() => handleChange(i)}>
            {i}
          </button>
        </div>
      ))}
    </div>
  )
}

KeyBoard.propTypes = {
  values: PropTypes.array,
}

export default KeyBoard
