import { createSoundMeter } from "../soundMeter"

const VOLUME_UPDATE_RATE = 500
const SPEAKING_THRESHOLD = 0.01

export default function checkPupilSpeaking(stream, callback) {
  const meter = createSoundMeter()
  meter.connectToSource(stream, function(e) {
    if (e) {
      console.log("error", e)
      return
    }

    setInterval(() => {
      const value = meter.instant
      if (value >= SPEAKING_THRESHOLD) {
        callback(true)
      } else {
        callback(false)
      }
    }, VOLUME_UPDATE_RATE)
  })
}
