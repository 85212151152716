import React from 'react'
import styles from './button.module.css'
import { ReactComponent as BtnSVG } from "./img/clean-screen-btn.svg";
import ReactTooltip from 'react-tooltip';

const CleanScreenBtn = ({ isDisabled, onClick }) => {
  return (<>
    <button className={styles.btn} disabled={isDisabled} onClick={onClick}  data-tip={'Ластик'}
    data-for="rub">
      <BtnSVG />
    </button>
     <ReactTooltip
       id="rub"
       className={styles.tooltip}
       place="top"
       textColor="#393F61"
       backgroundColor="#fff"
       aria-haspopup="true"
     /></>
  )
}

export default CleanScreenBtn