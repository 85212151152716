import cn from 'classnames'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import styles from './NotNearestLessonLobbyView.module.scss'
import fox from './img/fox.png'


export default function NotNearestLessonLobbyView({
  lessonLink,
  setShowTestLobbyAnyway,
}) {
  const isMobile = useMediaQuery({
    query: 'only screen and (max-width: 767px)',
  })

  return (
    <div className={cn(styles.pcContent, styles.pcWrapBG)}>
      <h1>{isMobile ? <span>Упс!<br/>Это не та ссылка</span> : <span>Упс! Это не та ссылка</span>}</h1>
      <div className={styles.fox}>
        <img src={fox} alt=""/>
        <div className={styles.infoContainer}>
          <div className={styles.foxSay}>
            <div className={styles.title}>Твоё занятие здесь:</div>
            <Link to={lessonLink}>
              <div className={cn(styles.buttonNext, styles['buttonNext--fill'])}>
                Перейти на урок
              </div>
            </Link>
            <button className={cn( styles.buttonNext)} onClick={() => setShowTestLobbyAnyway(true)}>
              Нет, ссылка верная
            </button>
            <Link className={styles.link} to={`/cabinet`}>Перейти в личный кабинет</Link>
          </div>
          <p>
            {isMobile ? <span>Не получается? Позвони в техподдержку:</span> : <span>Не получается?<br/>Позвони в техподдержку:</span>}
            <br/>
            <a href={'tel: 8 800 500 74 17'}>8 800 500 74 17</a>
          </p>
        </div>
      </div>
    </div>
  )
}