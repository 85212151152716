import React, { useContext } from 'react'

export const UserContext = React.createContext({ user: null, setUser: null, userIsStopPending: false })

export const PupilContext = React.createContext({ pupil: null, setPupil: null })

export function useUser() {
  const { user } = useContext(UserContext)
  return user
}

export function useUserWithStopPendingFlag() {
  const userContext = useContext(UserContext)
  return userContext
}

export function usePupil() {
  const { pupil } = useContext(PupilContext)
  return pupil
}