import React, { useRef, useState } from 'react'
import cn from 'classnames'
import styles from './presenterVideo.module.css'
import usePresenterVideo from "../../../usePresenterVideo";
import plashka from "../../../img/plashka_x2.png";
import TeacherUnavailable from "../../../components/TeacherUnavailable/TeacherUnavailable";


const PresenterVideo = ({className, socket, isPresenterCame, presenterRoomId, isMuted, isVideoMuted}) => {
  const videoRef = useRef(null)
  const [isVideoDone, setIsVideoDone] = useState(false)

  usePresenterVideo({ socket, videoRef, isPresenterCame, roomId: presenterRoomId })
  return <div className={cn(styles.container, className)}>
    {!isVideoDone && <img className={cn(styles.video, styles.plug)} src={plashka}/>}
    { isVideoDone && (isVideoMuted) && <div className={cn(styles.video, styles.plug)}><TeacherUnavailable /></div> }

    <video
      className={styles.video}
      ref={videoRef}
      autoPlay
      playsInline

      muted={isMuted}
      onLoadedData={() => setIsVideoDone(true)}
    />
  </div>
}

export default PresenterVideo