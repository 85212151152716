import React from "react";
import cn from "classnames";

import styles from "./pupilsListItem.module.css";

import ProgressRing from "../../../components/ProgressRing/ProgressRing";
import { ReactComponent as FoxIcon } from "./img/fox.svg";
import { ReactComponent as FoxIconRed } from "./img/redfox.svg";
import { ReactComponent as HelpIcon } from "./img/hand.svg";

const PupilsListItem = ({ name, timer, onClick, isNeedHelp, tags, newGroupId }) => {
    let timerPercent = 0;

    if (timer) {
        timerPercent = timer.time_int
            ? (timer.count / timer.time_int) * 100
            : 100;
    }

    const selected = tags.indexOf('selected') > -1;
    const groupId = tags.find(i => i !== 'selected')

    const className = cn({
        [styles.wrap]: true,
        [styles.danger]: timer && timerPercent > timer.dangerPercent,
        [styles.selected]: selected,
        [styles[`selected_${newGroupId}`]]: selected && !groupId,
        [styles.group]: groupId,
        [styles[`group_${groupId}`]]: groupId,
    });



    const color =
        timer && timerPercent > timer.dangerPercent ? "#ff0000" : "#4DA3F7";

    const foxComponent =
        timer && timerPercent > timer.dangerPercent ? (
            <FoxIconRed className={styles.icon} />
        ) : (
            <FoxIcon className={styles.icon} />
        );
    return (
        <div className={className} onClick={onClick}>
            <div className={styles.inner}>
                <div className={styles.name} title={name}>
                    <span>{name}</span>
                </div>
                <div className={styles.iconContainer}>
                    {timer && timer.time_int >= 0 && (
                        <div className={styles.ring}>
                            <ProgressRing
                                radius={22}
                                progress={timerPercent}
                                stroke={2}
                                color={color}
                            />
                        </div>
                    )}

                    {isNeedHelp ? (
                        <HelpIcon className={styles.icon} />
                    ) : foxComponent}
                </div>
                {timer && <div className={styles.timerValue}>{timer.value}</div>}
            </div>

        </div>
    );
};

PupilsListItem.defaultProps = {
    tags: []
}

export default PupilsListItem;
