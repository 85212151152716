import React, { useState } from 'react'
import { nanoid } from 'nanoid'

import { Redirect } from 'react-router-dom'

export default function Start() {
  const [redirectTo, setRedirectTo] = useState(null)

  const onCreateRoom = () => {
    const code = nanoid(4)
    setRedirectTo(`/present/${code}`)
  }

  // const onCreateCall = () => {
  //   const code = nanoid(8)
  //   setRedirectTo(`/call/${code}`)
  // }

  if (redirectTo) {
    return (
      <Redirect to={{ pathname: redirectTo }} />
    )
  }

  return (
    <section className="promo-screen">
      <div className="promo-screen_message">
        <button className="promo-screen_cta" onClick={onCreateRoom} >Создать урок</button><br/>
      </div>      
    </section>
  )
}