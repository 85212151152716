import { useEffect, useState } from "react";

import { getLowQualityVideoAndAudioStreams } from '../webrtc'
import { initViewerVideoNext } from '../ViewerScreen/viewerFuncs'

const useVideoAudioStreamPublicNext = ({
  socket,
  videoRef,
  roomId,
  name,
  mode,
  connectionState,
  audioSource,
  videoSource,
  isMobile,
  onStartFetchICEServer,
  onFinishFetchICEServer,
}) => {
  const [localAudioStream, setLocalAudioStream] = useState(null);
  const [isVideoDone, setInitVideoDone] = useState(false);
  const [error, setError]  = useState(null)
  const [pupilStream, setPupilStream] = useState(null)

  // Публикуем видео и аудио потоки
  useEffect(() => {
    if (
      !socket ||
      !videoRef.current ||
      !roomId ||
      !name
    ) {
      console.error('not ready to start!')
      return;
    }
    if (connectionState !== "connected") {
      return;
    }
    try {
      getLowQualityVideoAndAudioStreams({ audio: audioSource, video: videoSource }, isMobile)
        .then(async ({ videoStream, audioStream }) => {
          console.log('@update video')
          if (!videoStream && !audioStream) {
            setError("Ни звук, ни видео не доступны");
            // return;
          }

          if (audioStream) {
            setLocalAudioStream(audioStream);
          }

          setPupilStream({ audio: audioStream, video: videoStream })

          const mainStream = videoStream || audioStream;

          await initViewerVideoNext({
            roomId,
            name,
            socket,

            videoStream: mainStream,
            audioStream,

            videoRef,
            mode,

            onStartFetchICEServer,
            onFinishFetchICEServer,
          })

        })
        .then(() => {
          setInitVideoDone(true);
        })
        .catch((e) => setError(e.toString()));
    } catch (e) {
      setError(e.toString());
    }
  }, [socket, videoRef, roomId, name, mode, connectionState]);


  const stopStreaming = () => {
    if (pupilStream) {
      pupilStream.audio.getTracks().map(track => track.stop())
      pupilStream.video.getTracks().map(track => track.stop())
    }
  }

  return {
    localAudioStream,
    isVideoDone,
    error,
    pupilStream,
    stopStreaming,
  }
};


export default useVideoAudioStreamPublicNext