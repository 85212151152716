import React, { useState } from 'react'
import PasswordRecovery from './passwordRecovery'
import LoginComponent from './login'
import './styles.sass'


export default function Login() {
  const [isRecoveryState, setRecoveryState] = useState(false)

  return (
    <section className="page-login">
      {isRecoveryState ?
        <PasswordRecovery goToDefaultState={() => setRecoveryState(false)}/> :
        <LoginComponent goToRecoveryState={() => setRecoveryState(true)}/>}
    </section>
  )
}
