import React from 'react'
import styles from './buttonEraser.module.css'
import cn from "classnames";
import {ReactComponent as IconEraser} from "../../img/eraser.svg";


const ButtonEraser = ({onClick = () => {}}) => {
  return <button
    onClick={onClick}
    type={"button"}
    className={styles.button}
    data-tip={'Ластик'}
    data-for="global"
  >
    <IconEraser className={cn('icon', styles.icon)}/>
  </button>
}

export default ButtonEraser