import { useEffect, useState } from "react";

const useMDSettings = () => {
  const [optionsAudioSource, setOptionsAudioSource] = useState([]);
  const [optionsAudioOutput, setOptionsAudioOutput] = useState([]);
  const [optionsVideoSource, setOptionsVideoSource] = useState([]);
  const [defaultSources, setDefaultSources] = useState({})

  const getDevices = async () => {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices()
    console.log('deviceInfos', deviceInfos)
    let optionsAudioSource = []
    let optionsAudioOutput = []
    let optionsVideoSource = []
    const storedAudioSource = localStorage.getItem('audioSource')
    const storedVideoSource = localStorage.getItem('videoSource')
    const defaultValues = {}
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = {};
      option.deviceId = deviceInfo.deviceId;
      if (deviceInfo.kind === "audioinput") {
        if (deviceInfo.deviceId === storedAudioSource) {
          defaultValues.audioSource = deviceInfo.deviceId
          // setDefaultSources({ ...defaultSources, audioSource: deviceInfo.deviceId })
        }
        option.text =
          deviceInfo.label ||
          `microphone ${optionsAudioSource.length + 1}`;
        optionsAudioSource.push(option);
      } else if (deviceInfo.kind === "audiooutput") {
        option.text =
          deviceInfo.label ||
          `speaker ${optionsAudioOutput.length + 1}`;
        optionsAudioOutput.push(option);
      } else if (deviceInfo.kind === "videoinput") {
        if (deviceInfo.deviceId === storedVideoSource) {
          defaultValues.videoSource = deviceInfo.deviceId
          // setDefaultSources({ ...defaultSources, videoSource: deviceInfo.deviceId })
        }
        option.text =
          deviceInfo.label || `camera ${optionsAudioOutput.length + 1}`;
        optionsVideoSource.push(option);
      } else {
        console.log("Some other kind of source/device: ", deviceInfo);
      }
    }

    setDefaultSources(defaultValues)
    return { optionsAudioSource, optionsAudioOutput, optionsVideoSource }
  }

  useEffect(() => {
    (async () => {
      const newDevices = await getDevices()
      setOptionsAudioOutput(newDevices.optionsAudioOutput)
      setOptionsAudioSource(newDevices.optionsAudioSource)
      setOptionsVideoSource(newDevices.optionsVideoSource)
    })()
  }, [])

  const updateDevices = () => {
    (async () => {
      const newDevices = await getDevices()
      setOptionsAudioOutput(newDevices.optionsAudioOutput)
      setOptionsAudioSource(newDevices.optionsAudioSource)
      setOptionsVideoSource(newDevices.optionsVideoSource)
    })()
  }

  return {
    optionsAudioSource,
    setOptionsAudioSource,
    optionsVideoSource,
    setOptionsVideoSource,
    optionsAudioOutput,
    setOptionsAudioOutput,
    defaultSources,
    setDefaultSources,

    getDevices,
    updateDevices,
  }
}

export default useMDSettings