import React, { useState } from 'react'
import DropMenu from "../../../components/Dropmenu";
import cn from "classnames";
import stylesNext from "../../viewerScreenNext.module.css";
import { ReactComponent as IconSettings } from "../../../img/settings.svg";
import { ReactComponent as IconInfo } from "../../../img/info.svg";
import Popup from "../../../components/Popup/Popup";
import SettingMD from "../../../components/SettingMD/SettingMd";
import styles from './buttonSettings.module.css'


const ButtonSettings = ({
  theme = 'light', //light, dark
  remoteVideoRef = {},
  onChangeSettingMediaDevice = () => {},
}) => {
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false)

  return <DropMenu
    data-tip={'Настройки'}
    data-for="global"
    thema={ theme }
    toggleClass={ cn(styles.control) }
    slotToggle={
      <span
        className={ cn('button', 'button_no-style')
        }
      >
        <IconSettings className={ cn('icon', styles.controlIcon) }/>
      </span>
    }>
    <button className={ cn('button', 'button_no-style') } onClick={ () => setIsInfoPopupOpen(true) }>
      <IconInfo
        className={ cn('icon', styles.controlIcon) }
      />
    </button>
    <Popup isOpen={ isInfoPopupOpen } onClose={ () => setIsInfoPopupOpen(false) } widthInnerAuto>
      <div className={ stylesNext.infoPopupInner }>
        Телефон техподдержки: <br/> 8 800 500 74 17
      </div>
    </Popup>
    <SettingMD
      videoElement={ remoteVideoRef.current }
      onChangeSettingMediaDevice={ onChangeSettingMediaDevice }
    />
  </DropMenu>
}

export default ButtonSettings