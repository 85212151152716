import React from 'react'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as IconTalkingAll } from '../img/talkingAll.svg'
import { ReactComponent as IconTalkingAllFill } from '../img/talkingAllFill.svg'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import styles from './buttons.module.sass'


const ButtonAudio = ({ defaultValue, onChange }) => {
  const changeTalk = () => {
    if(defaultValue === true) {
      onChange(null)
    } else {
      onChange(true)
    }
  }
  return (
    <div className={styles.button} onClick={changeTalk} data-tip="Вкл./Выкл. все видят и слышат всех" data-for="talking-one">
      {!defaultValue ? <IconTalkingAll data-tip="Вкл./Выкл. все видят и слышат всех" data-for="talking-one"/> :
        <IconTalkingAllFill data-tip="Вкл./Выкл. все видят и слышат всех" data-for="talking-one"/>}
      <ReactTooltip
        id="talking-one"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}

export default ButtonAudio
