import React, { useState } from 'react'
import styles from './callToSupportBtn.module.css'
import ReactTooltip from "react-tooltip";
import presenterScreenStyles from "../../presenterScreen.module.scss";
import { ReactComponent as CallToSupportSVG } from "./img/call-to-support.svg";
import { ReactComponent as CallToSupportPressedSVG } from "./img/call-to-support-pressed.svg";


const CallToSupportBtn = ({ onClick, isPressed = false }) => {
  const [isDisabled, setIsDisabled] = useState(false)

  const onClickBtn = () => {
    if (isDisabled || !onClick) {
      return
    }

    setIsDisabled(true)
    onClick()
    setTimeout(() => setIsDisabled(false), 200 )
  }

  return <button
    className={styles.btn}
    onClick={onClickBtn}
    disabled={isDisabled}
    data-tip={isPressed ? 'Убрать ученику экран "позвоните в тех поддержку"' : 'Показать ученику экран "позвоните в тех поддержку"'}
    data-for="call-to-support-btn"
  >
    {isPressed ? <CallToSupportPressedSVG /> : <CallToSupportSVG/>}
    <ReactTooltip
      id="call-to-support-btn"
      className={presenterScreenStyles.tooltip}
      place="right"
      textColor="#393F61"
      backgroundColor="#fff"
      aria-haspopup="true"
    />
  </button>
}

export default CallToSupportBtn
