import React from 'react'
import ReactTooltip from 'react-tooltip'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import { ReactComponent as IconVideo } from '../img/video.svg'
import { ReactComponent as IconVideoOff } from '../img/video-off.svg'

import styles from './buttons.module.sass'


const ButtonVideo = ({ isMuted, mute }) => {
  return (
    <div className={styles.button} onClick={mute} data-tip="Вкл./Выкл. видео учителя" data-for="video">
      {isMuted ? <IconVideoOff data-tip="Вкл./Выкл. видео учителя" data-for="video"/> :
        <IconVideo data-tip="Вкл./Выкл. видео учителя" data-for="video"/>}
      <ReactTooltip
        id="video"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}

export default ButtonVideo
