import React, { useState } from "react";
import Draggable from "react-draggable";

const DraggableWrap = ({
    onClick,
    onStop,
    onStart,
    onDrag,
    children,
    onDrop,
    ...rest
}) => {
    const [dragging, setDragging] = useState(false);
    const [startX, setX] = useState(0);
    const [startY, setY] = useState(0);
    const handleClick = (e) => {
        // your code
        console.log("DraggableWrap click");
        onClick(e);
    };

    const handleDrop = (e) => {
        // your code
        onDrop(e);
    };

    const handleStart = (e) => {
        const touch = e.touches[0];
        const x = touch.pageX;
        const y = touch.pageY;

        setX(x);
        setY(y);

        onStart(e);
    };

    const handleDrag = (e) => {
        try {
            const touch = e.touches[0];
            const x = touch.pageX;
            const y = touch.pageY;

            if (Math.abs(startX - x) > 5 && Math.abs(startY - y) > 5) {
                setDragging(true);
            }

            onDrag(e);
        } catch (error) {
            console.error(error);
        }
    };

    const handleStop = (e) => {
        if (dragging) {
            handleDrop(e);
        } else {
            handleClick(e);
        }
        setDragging(false);
        onStop(e);
    };
    return (
        <Draggable
            {...rest}
            onStart={handleStart}
            onDrag={handleDrag}
            onStop={handleStop}
        >
            {children}
        </Draggable>
    );
};

DraggableWrap.defaultProps = {
    onClick: () => {},
    onDrop: () => {},
    onStop: () => {},
    onDrag: () => {},
    onStart: () => {},
};

export default DraggableWrap;
