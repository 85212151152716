import React, { useState, useEffect, useRef } from 'react'

async function getVideoAndAudioStreams() {
  /*
  DOESN'T WORK
  videoStream doesn't get an audio, only video

  const promises = [
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    }),
    navigator.mediaDevices.getUserMedia({
      audio: true,
    }),
  ]
  const [videoStream, audioStream] = await Promise.all(promises)
  return { videoStream, audioStream }
  */

  /*
    working way -- to separate video and audio
  */
  return navigator.mediaDevices.getUserMedia({
    // audio: true,
    video: true,
  })
  .then((videoStream) => {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
    })
    .then((audioStream) => {
      return { videoStream, audioStream }
    })
  })
}

export default function SoundTest() {
  const videoRef = useRef(null)
  const audioRef = useRef(null)
  const [devices, setDevices] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(setDevices).catch(setError)
  }, [])

  useEffect(() => {
    if (!videoRef.current || !audioRef.current) {
      return
    }

    getVideoAndAudioStreams()
    .then(({ videoStream, audioStream }) => {
      videoRef.current.srcObject = videoStream
      audioRef.current.srcObject = audioStream
    })
    .catch((err) => setError(err.toString()))
  }, [videoRef, audioRef])

  return (
    <div className="sample-vector">
      <h1>Sound Test Suite 1</h1>
      { error && <div className="presenter-error">
        { error }
      </div> }
      <div>
        Видео
        <video className="local-video" ref={videoRef} autoPlay playsInline controls />
      </div>
      <div>
        Аудио
        <video className="local-video" ref={audioRef} autoPlay playsInline controls />
      </div>
      { devices.map((i,n) => <Device key={n} device={i} />)}
    </div>
  )
}

function Device({ device }) {
  return (
    <div className="sample-device" >
      <div>
        <span>{device.kind}</span> — <span>{device.label}</span>
      </div>
      <div className="sample-device_id">{device.deviceId}</div>
    </div>
  )
}