import { useEffect, useState } from "react"
import { createDrawingContext } from "../../drawing"

function fitToContainer(canvas) {
 // Make it visually fill the positioned parent
 canvas.style.width = "100%"
 canvas.style.height = "100%"
 // ...then set the internal size to match
 canvas.width = canvas.offsetWidth
 canvas.height = canvas.offsetHeight
}

const useDrawing = ({
 socket,
 canvasRef,
 imageSize,
 name,
 task,
 type,
 wrapRef,
 readOnly,
 isPreview,
 color,
 updateCanvas,
 setTools = () => {},
 setInitCurtainState = () => {},
 setCurtainDirection = () => {},
  //handleOnAddLine и handleOnUpdateLine нужны для синхронизации контекста под лупой и основного контекста, в новую версию лучше не тащить
  handleOnAddLine = (line) => {},
  handleOnUpdateLine = ({ id, point }) => {},
}) => {
 const [context, setContext] = useState(null)

 useEffect(() => {
  if (!canvasRef.current || !socket || !imageSize | !task) {
   return
  }

  const canvas = canvasRef.current

  fitToContainer(canvas)

  const onAddLine = (line) => {
   socket.emit(`canvas:${type}:add-line`, { name, task, line })
   handleOnAddLine(line)
  }

  const onLineUpdate = ({ id: lineId, point }) => {
   socket.emit(`canvas:${type}:add-point`, { name, task, lineId, point })
   handleOnUpdateLine({ id: lineId, point })
  }

  const ctx = createDrawingContext({
   name,
   canvas,
   readOnly,
   imageSize,
   color,
   isPreview,
   // allowedViewerTools: task.viewerTools,
   type,
   onLineUpdate,
   onLineAdd: onAddLine,
  })

  const pupilChangeTools = ({ name, tools: teacherTools }) => {
   ctx.setModeLine(teacherTools)

   setTools(teacherTools)
  }

   const onTeacherTools = ({ name: viewerName, tools: teacherTools }) => {
     if (viewerName !== name) {
       return
     }

     setTools(teacherTools)
   }

  const onViewerTools = ({ name: viewerName, tools: viewerTools }) => {

   if (name !== viewerName) {
    return
   }

   ctx.setModeLine(viewerTools)
   setTools(viewerTools)
  }

  const onChangeCurtainDirection = (msg) => {
   if (msg.name === name || type === "teacher") {
    setCurtainDirection(msg.value)
   }
  }

  const supervisorSetMode = (msg) => {
   setTools(msg.tools)
  }

  const askTaskStorage = () => {
   socket.emit("room:task-storage:ask", { name, task })
  }

  const onGetTaskStorage = ({ name: viewerName, taskStorage }) => {
   if (viewerName !== name) {
    return
   }

   ctx.onGetStorage(taskStorage)

   ctx.setModeLine(
    type === "viewer" ? taskStorage.viewerTools : taskStorage.presenterTools
   )
   setTools(
    type === "viewer" ? taskStorage.viewerTools : taskStorage.presenterTools
   )

   setInitCurtainState(taskStorage.curtainData)
   setCurtainDirection(taskStorage.curtainData.direction)
  }

  const onAddLineFromServer = ({ name: viewerName, line }) => {
   if (viewerName !== name) {
    return
   }

   ctx.addLine(line)
  }

  const onAddPointFromServer = ({ name: viewerName, lineId, point }) => {
   if (viewerName !== name) {
    return
   }

   ctx.updateLine({ id: lineId, point })
  }

  if (socket) {
   askTaskStorage()
   socket.on("room:task-storage", onGetTaskStorage)

   socket.on('canvas:teacher:curtain-direction', onChangeCurtainDirection)
   if (isPreview) {
    socket.on('canvas:teacher:tools', onTeacherTools)
   }

   if (type === "viewer") {
    socket.on("canvas:presenter:add-line", onAddLineFromServer)
    socket.on("canvas:presenter:add-point", onAddPointFromServer)
    socket.on("canvas:viewer:tools", onViewerTools)
    // socket.on('canvas:teacher:tools', pupilChangeTools)
   }

   if (type === "presenter") {
    socket.on("canvas:viewer:add-line", onAddLineFromServer)
    socket.on("canvas:viewer:add-point", onAddPointFromServer)
    socket.on("canvas:teacher:tools:group", pupilChangeTools)
   }
  }

  setContext(ctx)
  return () => {
   socket.off("canvas:teacher:curtain-direction", onChangeCurtainDirection)
   socket.off("canvas:teacher:tools", supervisorSetMode)
   socket.off("room:task-storage", onGetTaskStorage)
   socket.off("canvas:viewer:tools", onViewerTools)
   socket.off("canvas:presenter:add-line", onAddLineFromServer)
   socket.off("canvas:presenter:add-point", onAddPointFromServer)
   socket.off("canvas:viewer:add-line", onAddLineFromServer)
   socket.off("canvas:viewer:add-point", onAddPointFromServer)
   socket.off("canvas:teacher:tools:group", pupilChangeTools)

   ctx.destroy()
   wrapRef.current.onresize = null
  }
 }, [
  wrapRef,
  canvasRef,
  imageSize,
  socket,
  name,
  color,
  task,
  isPreview,
  updateCanvas,
 ])

 return { context }
}

export default useDrawing
