import  React from 'react'
import PupilsListItem from "../PupilsListItem/PupilsListItem";
import styles from './pupilList.module.css'


const PupilList = ({viewers, tags, newGroupId, viewersNeedHelp, timers, onSelectPupil}) => {
  return <ul className={styles.container}>
    {viewers.map((viewer, index) => <li key={index}>
      <PupilsListItem
        name={viewer.displayName}
        tags={tags[viewer.name]}
        newGroupId={newGroupId}
        isNeedHelp={viewersNeedHelp[viewer.name]}
        timer={timers[viewer.name]}
        onClick={(event) => onSelectPupil(viewer.name, event)}
      />
    </li>)}
  </ul>
}

export default PupilList