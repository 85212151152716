import dayjs from "dayjs";
// Всё в милисекундах
const TIME_INT = 90000;
const ADD_TIME = 30000;

export const initialTimer = {
    time_int: TIME_INT,
    count: 0,
    isStart: false, // запустить как только так сразу
    isFinish: false, // ставим флаг только если таймер истёк
    dangerPercent: 70 // когда 70 процентов то таймер красим в красный цвет
};

export function reducerTimers(state, action) {
    const { name, time, time_int, isFinish } = action.payload;

    let newTimeInt;
    let valueNew;
    let valueSetTimer;
    switch (action.type) {
        case "setTimer":
            const newState = {
                ...initialTimer,
                ...state[name],
                ...action.payload,
            }
            valueSetTimer = dayjs(newState.time_int - newState.count).format("mm:ss")
            console.log('setTimer', action.payload)
            return {
                ...state,
                [name]: {
                    ...newState,
                    value: valueSetTimer,
                },
            };

        case "plusTime":
            newTimeInt =
                Number(state[name].time_int) + (Number(time) || ADD_TIME);
            valueNew = dayjs(newTimeInt - state[name].count).format("mm:ss");

            let isStartNext = state[name].isFinish && (state[name].count < newTimeInt && !state[name].isStart) || state[name].isStart

            return {
                ...state,
                [name]: {
                    ...state[name],
                    time_int: newTimeInt,
                    value: valueNew,
                    isStart: isStartNext,
                },
            };

        case "minusTime":
            newTimeInt =
                Number(state[name].time_int) - (Number(time) || ADD_TIME);

            const diff = newTimeInt - state[name].count;
            valueNew =
                diff > 0
                    ? dayjs(newTimeInt - state[name].count).format("mm:ss")
                    : "00:00";
            return {
                ...state,
                [name]: {
                    ...state[name],
                    time_int: diff < 0 ? state[name].count : newTimeInt,
                    value: valueNew,
                },
            };

        case "resetTimer":
            return {
                ...state,
                [name]: {},
            };

        case "countUp":
            const countNew =
                state[name].count >= state[name].time_int
                    ? state[name].time_int
                    : state[name].count + 1000;
            const value = dayjs(state[name].time_int - countNew).format(
                "mm:ss"
            );

            return {
                ...state,
                [name]: {
                    ...state[name],
                    count: countNew,
                    value,
                },
            };

        default:
            throw new Error();
    }
}
