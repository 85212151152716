import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import styles from './localViewerVideo.module.css'
import useVideoAudioStreamPublicNext from "../../../../hooks/useVideoAudioStreamPublicNext";


const LocalViewerVideo = ({
  className,
  socket,
  roomId,
  name,
  mode,
  connectionState,
  onStartFetchICEServer,
  onFinishFetchICEServer,
  isMobile,
  audioSource,
  videoSource,
  setError,
  viewerSettings = {},
  needStopStreaming,
  isMuted,
  pupilStream,
  setPupilStream,
}) => {
  const videoRef = useRef(null)

  const {
    isVideoDone,
    error,
    localAudioStream,
    pupilStream: viewerStream,
    stopStreaming
  } = useVideoAudioStreamPublicNext({
    socket,
    videoRef,
    audioSource,
    videoSource,
    mode,
    connectionState,
    name,
    isMobile,
    roomId,
    onStartFetchICEServer,
    onFinishFetchICEServer,
  })

  useEffect(() => {
    if (!viewerStream) {
      return
    }

    setPupilStream(viewerStream)
  }, [viewerStream])

  useEffect(() => {
    setError(error)
  }, [error])

  useEffect(() => {
    if (!needStopStreaming) {
      return
    }

    stopStreaming()
  }, [needStopStreaming])

  useEffect(() => {
    if (!pupilStream) {
      return
    }

    try {
      if (pupilStream.audio) {
        pupilStream.audio.getAudioTracks()[0].enabled = !isMuted
      }
    } catch (err) {
      setError(err.toString())
    }
  }, [isMuted, pupilStream])

  useEffect(() => {
    if (!videoRef || !pupilStream) {
      return
    }

    videoRef.current.srcObject = pupilStream.video
  }, [videoRef, pupilStream])

  return (
    <div className={cn(styles.container, className)}>
      <video
        className={styles.video}
        ref={videoRef}
        autoPlay
        playsInline
      />
    </div>
  )
}

export default LocalViewerVideo