import React from 'react'
import { ReactComponent as BtnSVG } from "./img/magnifier-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/magnifier-btn-active.svg";
import styles from './button.module.css'
import presenterScreenStyles from '../../../../../PresenterScreen/presenterScreen.module.scss'
import ReactTooltip from 'react-tooltip';

const MagnifierBtn = ({isActive = false, isDisabled = false, onClick}) => {
  return (<>
    <button className={styles.btn} disabled={isDisabled} onClick={onClick} data-tip={'Лупа'}
    data-for="magnifier">
      {(!isActive) ? <BtnSVG/> : <BtnActiveSVG/>}
    </button>
         <ReactTooltip
         id="magnifier"
         className={presenterScreenStyles.tooltip}
         place="right"
         textColor="#393F61"
         backgroundColor="#fff"
         aria-haspopup="true"
       /></>
  )
}

export default MagnifierBtn
