import React from 'react'
import cn from 'classnames'
import styles from './menuPopup.module.css'
import { ReactComponent as CloseSVG } from "./img/close-btn.svg";


//стили хорошо работают, если в children класть PopupBtn, иначе лучше переопределить contentContainerClassName
const MenuPopup = ({children, title, onClose, contentContainerClassName, mainContainerClassName}) => {
  return (
    <div className={cn(styles.container, mainContainerClassName)}>
      <span className={styles.title}>{title}</span>
      <button className={styles.btnClose} onClick={onClose}><CloseSVG/></button>
      <div className={cn({[styles.contentContainer]: !contentContainerClassName}, contentContainerClassName)}>
        {children}
      </div>
    </div>
  )
}

export default MenuPopup