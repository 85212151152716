import React, { Children, useState } from "react";
import cn from 'classnames'
import { viewTypes } from "../ViewStylePopupMenu/viewStyle";
import styles from './videosContainer.module.css'
import { ReactComponent as ArrowRightSVG } from "./img/arrow-right.svg";
import { ReactComponent as ArrowLeftSVG } from "./img/arrow-left.svg";


const VideosContainer = ({children, currentView, settings = {}, otherVisibleViewersCount = 0}) => {
  const [isListOpen, setIsListOpen] = useState(false)

  const onOpenList = () => {
    setIsListOpen(!isListOpen)
  }

  return (
    <div className={cn(styles.container, {
      [styles.viewGallery]: !currentView || currentView === viewTypes.GALLERY,
      [styles.viewGalleryOpened]: (!currentView || currentView === viewTypes.GALLERY) && isListOpen,

      [styles.viewMosaic]: currentView === viewTypes.MOSAIC,
      [styles.viewMosaicWithOtherViewers]: currentView === viewTypes.MOSAIC && otherVisibleViewersCount > 0,
      [styles.viewMosaicWithOtherViewers1]: currentView === viewTypes.MOSAIC && otherVisibleViewersCount === 1,
      [styles.viewMosaicWithOtherViewers2or3]: currentView === viewTypes.MOSAIC && (otherVisibleViewersCount === 2 || otherVisibleViewersCount === 3),
      [styles.viewMosaicWithOtherViewers4or5]: currentView === viewTypes.MOSAIC && (otherVisibleViewersCount === 4 || otherVisibleViewersCount === 5),
      [styles.viewMosaicWithOtherViewersMore5]: currentView === viewTypes.MOSAIC && otherVisibleViewersCount > 5,

      [styles.viewReport]: currentView === viewTypes.REPORT,
    })
    }>
      {children}
      {(!currentView || currentView === viewTypes.GALLERY) && otherVisibleViewersCount > 0 && (
        <button onClick={onOpenList} className={styles.openListBtn}>{isListOpen ? <ArrowLeftSVG/> : <ArrowRightSVG />}</button>
      )}
    </div>
  )
}

export default VideosContainer
