import cn from 'classnames'
import moment from 'moment'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styles from './chatMessage.module.scss'
import Linkify from 'linkify-react';
import { ReactComponent as DeletePic } from './img/delete-pic.svg'
import { ReactComponent as EditPic } from './img/edit-pic.svg'
import { nanoid } from "nanoid";

export default function ChatMessage({ message, type = 'classic', theme = 'darkTheme', isMobile, currentUser, onClose, isNewAuthor, onEditMessage, onDeleteMessage }) {
  let authorName;

  switch (message.from) {
    case 'system':
      authorName = 'iq007-online'
      break;
    case 'supervisor':
      authorName = 'Супервайзер'
      break;
    case 'presenter':
      const isTeacher = type === 'cloud' ? ' (учитель)' : ''
      authorName = message.userName+isTeacher
      break;
    default:
      authorName = message.userName
      break;
  }


  const sentAt = moment(message.createdAt).format('hh:mm')

  const direction = message.userId === currentUser ? 'from' : 'to'

  const textCloud = (text) =>
    text.split(/ |\n/).map( (word, index) => {
      let space = index > 0 ? ' ' : ''
      if (word.startsWith("@")) {
        return <span className={styles.mentionedPupil} key={ `${message.id}_${index}`}>{space + word.slice(1)}</span>
      }
      // if (word.search(/(https?:\/\/[^\s]+)/g) !== -1) {
      //   console.log('ссылка тип', word, word.search(/(https?:\/\/[^\s]+)/g))
      //   return <a href={word}>{word}</a>
      // }
      return space + word
    })

  const handleCloseMessage = () => {
    return onClose(message.id)
  }

  return (
    type === 'cloud' ?
      message.from === 'system' ?
        <div className={cn(styles.message, styles.cloud, styles.to, styles[theme], styles.systemMessage, styles.newAuthor, {
          [styles.mobileWrap]: isMobile
        })} key={message.id}>
          <div className={styles.msgBody}>
            <div className={styles.bodyText}>
              <Linkify><span className={styles.text}>{textCloud(message.text)}</span></Linkify>
            </div>
          </div>
        </div>
      :
      <div className={cn(styles.message, styles.cloud, styles[direction], styles[theme], {
          [styles.supervisorMessage]: message.from === 'supervisor',
          [styles.mobileWrap]: isMobile,
          [styles.newAuthor]: isNewAuthor
      })} key={message.id}>
        {direction === 'to' && <div className={styles.supIcon}/>}
        <div className={styles.msgBody}>
          <div className={styles.bodyText}>
            <span className={styles.author}>{authorName}</span>
            <br />
            <Linkify><span className={styles.text}>{textCloud(message.text)}</span></Linkify>
          </div>
          <div className={styles.time}>{sentAt}</div>
        </div>
      </div>
    : type === 'cloud-outside' ?
      <div className={cn(styles.message, styles.cloud, styles.cloudOutside)} key={message.id}>
        <div className={styles.msgBody}>
          <div className={styles.bodyText}>
            <span className={styles.author}>{authorName}</span>
            <br />
            <Linkify><span className={styles.text}>{textCloud(message.text)}</span></Linkify>
          </div>
          <div className={styles.closeIcon} onClick={handleCloseMessage}/>
        </div>
      </div>
    :<div className={cn(styles.message, styles.classic, {
      [styles.systemMessage]: message.from === 'system',
      [styles.supervisorMessage]: message.from === 'supervisor'}
    )} key={message.id}>
      {message.from !== 'system' && <div className={styles.supIcon}/>}
      <div className={styles.msgBody}>
        <span className={styles.author}>{authorName}{message.userId === currentUser && ' (вы)'}</span>
        <br />
        <Linkify><span className={styles.text}>{
          // message.from === 'system' ? textCloud(message.text) : message.text
          textCloud(message.text)
        }</span></Linkify>
      </div>
      <div className={styles.timeAndButtonsContainer}>
        <div className={styles.time}>{sentAt}</div>
          {(message.userId === currentUser || message.from === 'viewer') &&
            <div className={styles.buttons}>
              {message.userId === currentUser && <EditPic data-for="button" data-tip={'Редактировать'} style={{ cursor: 'pointer' }}
                        onClick={() => onEditMessage(message.id)}/>}
              <DeletePic data-for={`${message.id}_button`} data-tip={'Удалить'} style={{cursor: 'pointer'}} onClick={() => onDeleteMessage(message.id)}/>
              <ReactTooltip
                id={`${message.id}_button`}
                className={styles.tooltip}
                place="left"
                backgroundColor="#fff"
                aria-haspopup="true"
              />
            </div>
          }
      </div>
    </div>
  )

}
