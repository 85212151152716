import React, { useState } from 'react'
import get from 'lodash/get'

import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import PopupNext from '../../../../components/PopupNext/PopupNext'
import RadioButton from '../../../../components/RadioButton/RadioButton'
import TeacherButton from '../../../../components/TeacherButton/TeacherButton'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import styles from './buttons.module.sass'
import { createLessonEvents } from '../../../../api/events'
import { ReactComponent as IconHelp } from '../img/help.svg'


const WITHOUT_PROBLEM = 'end-without-problem'
const WITH_PROBLEM = 'end-with-problem'
const LESSON_CANCELED = 'lesson-canceled'

export default function ButtonProblem(props) {
  const { isShowFeedback, handleCloseFeedback, setShowFeedback, user, group } = props

  const [lessonProblem, setLessonProblem] = useState(WITHOUT_PROBLEM)

  const openModalButton = () => {
    setShowFeedback(true)
  }

  const handleRadio = ({ target }) => {
    setLessonProblem(target.value)
  }

  const saveResult = () => {
    createLessonEvents({
      kind: lessonProblem,
      teacher: get(user, 'id'),
      group: get(group, 'id'),
      meta: {
        user,
        group,
        lessonProblem,
      },
    }).then(res => {
      console.log('ok', res)
      handleCloseFeedback()
    }).catch(e => {
      console.error(e)
      handleCloseFeedback()
      throw e
    })
  }

  return (
    <div className={styles.wrapWidget} onClick={openModalButton}>
      <IconHelp className={styles.pointer} data-tip="Расскажите о проблеме, возникшей на уроке" data-for="help"/>
      <ReactTooltip
        id="help"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
      <PopupNext
        isOpen={isShowFeedback}
        onClose={handleCloseFeedback}
        title="Обратная связь"
      >
        <h3 className={styles.mb35px}>Как прошёл ваш урок?</h3>
        <div className={cn(styles.radioBlock, styles.mb35px)}>
          <RadioButton
            onChange={handleRadio}
            id="withOutProblem"
            name="problem"
            value={WITHOUT_PROBLEM}
            checked={lessonProblem === WITHOUT_PROBLEM}
            text="Все прошло без проблем"
          />
          <div className={styles.mb20px} />
          <RadioButton
            onChange={handleRadio}
            id="withProblem"
            name="problem"
            value={WITH_PROBLEM}
            checked={lessonProblem === WITH_PROBLEM}
            text="Были незначительные проблемы, но урок состоялся"
          />
          <div className={styles.mb20px} />
          <RadioButton
            onChange={handleRadio}
            id="lessonCanceled"
            name="problem"
            value={LESSON_CANCELED}
            checked={lessonProblem === LESSON_CANCELED}
            text="Были технические проблемы, урок сорвался"
          />
        </div>
        <TeacherButton onClick={saveResult} title="Ответить" fill long/>
        {/*<button onClick={saveResult}>Ответить</button>*/}
        <p className={styles.paragraph}>
          Уважаемые педагоги, если у вас есть возможность более подробно
          поделиться своими замечаниями и впечатлениями от урока и платформы то
          это можно сделать в следующей форме{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdNyjMzq1wkxwiKV54NrF5SfapuINegL30IQaILQsCaPybPoQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            открыть форму в новом окне
          </a>
        </p>
      </PopupNext>
    </div>
  )
}
