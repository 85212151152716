import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { ReactComponent as HandUp1 } from "./img/hand-1.svg";
import { ReactComponent as HandUp } from "./img/hand.svg";
// TODO: HandUpYes зеленая рука когда учитель отметил что увидел руку
import { ReactComponent as HandUpYes } from "./img/hand-1.svg";
import ProgressRing from "../../../components/ProgressRing/ProgressRing";

import styles from "./buttonDone.module.css";
import filter from "lodash/filter";
import map from "lodash/map";

const reactions = [
    { key: "good", label: "Понятно!" },
    { key: "sad", label: "Всё плохо" },
    { key: "glad", label: "Всё хорошо" },
    { key: "alarm", label: "Я закончил" },
    { key: "hand", label: "" },
];

const ButtonDone = ({
    handleClick,
    className,
    timer,
    active,
    thema,
    reaction,
}) => {
    const wrapRef = useRef(null);
    const [isOpen, setOpen] = useState(false);

    // default currentReaction
    let currentReaction = {
        key: 'hand',
        label: ''
    }


    const reactionMenu = filter(reactions, (i) => {
        if (i.key === reaction) {
            currentReaction = i;
        } else {
            if (i.key === currentReaction.key) {
                return false
            }
            return true;
        }
    });




    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const cn = classNames(styles.button, className, {
        [styles.buttonDoneNeedHelp]: active,
    });

    const title = active ? "Опустить руку" : "Поднять руку";

    let timerPercent = 0;

    if (timer) {
        timerPercent = timer.time_int
            ? (timer.count / timer.time_int) * 100
            : 100;
    }

    const color =
        timer && timerPercent > timer.dangerPercent ? "#ff0000" : "currentColor";

    const onClick = (reaction) => {
        handleClose();
        handleClick(reaction);
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (wrapRef.current && !wrapRef.current.contains(event.target)) {
            handleClose();
        }
    };
    return (
        <div
            className={classNames(styles.wrap, styles[`wrap_${thema}`], {
                [styles.isOpen]: isOpen,
            })}
        >
            <div className={styles.wrapInner} ref={wrapRef}>
                <div className={styles.inner}>
                    {map(reactionMenu, (i) => (
                        
                        <button
                            onClick={() => onClick(i.key)}
                            className="button_no-style"
                        >
                            <div
                                className={classNames(
                                    "icon",
                                    styles.icon,
                                    `icon_${i.key}`,
                                    'icon_dark'
                                    // `icon_${thema}`
                                )}
                            />
                            <span className={styles.btnTxt}>{i.label}</span>
                        </button>
                    ))}
                    <button
                        onClick={
                            isOpen
                                ? () => onClick(currentReaction.key)
                                : handleOpen
                        }
                        className={cn}
                        title={title}
                    >
                        {timer && timer.time_int >= 0 && (
                            <div className={styles.ring}>
                                <ProgressRing
                                    radius={36}
                                    progress={timerPercent}
                                    stroke={2}
                                    color={color}
                                />
                            </div>
                        )}
                        <div
                            className={classNames(
                                "icon",
                                styles.icon,
                                `icon_${currentReaction.key}`,
                                'icon_dark'
                                // `icon_${thema}`
                            )}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ButtonDone;
