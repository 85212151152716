import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import map from 'lodash/map'
import find from 'lodash/find'
import styles from "./audioTask.module.css";

const AudioTask = ({ src, socket, name }) => {
  const [start, setStart] = useState(false);
  const [videosStorage, setVideoStorage] = useState([]);
  const videoRef = useRef(null);
  useEffect(() => {
    if (!src) {
      return;
    }

    const canceledVideos = JSON.parse(
      localStorage.getItem("canceledVideos")
    );

    setVideoStorage(canceledVideos)

    const canceledVideosSrc = map(canceledVideos, (i) => i.src)

    console.log('canceledVideosSrc', canceledVideosSrc.includes(src))
    if (canceledVideosSrc && canceledVideosSrc.includes(src)) {
      setStart(false);
      onChangeState(false);
    } else {
      onStart();
    }
  }, [src]);

  useEffect(() => {
    if (!socket || !src) {
      return;
    }

    socket.on("video:state", onChangeRemoteState);

    return () => {
      socket.off("video:state", onChangeRemoteState);
    };
  }, [socket, src, start]);

  const onChangeRemoteState = (state) => {
    const { isStart } = state;
    if (name === state.name && isStart !== start) {
      isStart ? onStart() : handleCancel();
    }
  };

  if (!src) return null;
  const handleCancel = () => {
    let canceledVideos =
      JSON.parse(localStorage.getItem("canceledVideos")) || [];

    const canceledVideosSrc = map(canceledVideos, (i) => i.src)
    if (canceledVideosSrc && !canceledVideosSrc.includes(src)) {
      canceledVideos.push({
        src,
        currentTime: videoRef.current.currentTime
      });
    }

    if (canceledVideosSrc.includes(src)) {
      const currentVideo = find(canceledVideos, (i) => i.src === src)
      currentVideo.currentTime = videoRef.current.currentTime
    }

    localStorage.setItem("canceledVideos", JSON.stringify(canceledVideos));

    setVideoStorage(canceledVideos)
    setStart(false);
    onChangeState(false);
  };

  const onStart = () => {
    setStart(true);
    onChangeState(true);
  };

  const onChangeState = (state) => {
    if (socket) {
      console.log("video:state", { name, isStart: state });
      socket.emit("video:state", { name, isStart: state });
    }
  };

  const onLoaded = () => {
    const currentVideo = find(videosStorage, (i) => i.src === src)

    const video = videoRef.current

    if (video && currentVideo) {
      video.currentTime = currentVideo.currentTime
    }
  }

  const handleEnded = () => {
    const currentVideo = find(videosStorage, (i) => i.src === src)

    const video = videoRef.current

    if (video && currentVideo) {
      video.currentTime = 0
    }

    handleCancel()
  }
  return (
    <div className={cn(styles.wrap, { [styles.isStart]: start })}>
      <div className={styles.controls}>
        <button
          className={styles.button}
          type="button"
          onClick={start ? handleCancel : onStart}
        >
          {start ? "Пропустить" : "Послушать аудио"}
        </button>
      </div>

      <div className={styles.audio}>
        {start && (
          <audio
            ref={videoRef}
            autoPlay
            playsInline
            onEnded={handleEnded}
            controls
            onLoadedData={onLoaded}
          >
            <source src={src} />
          </audio>
        )}
      </div>
    </div>
  );
};

export default AudioTask;
