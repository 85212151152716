const emptyCanvasTask = {
  slideNum: 0,
  viewerTools: {
    curtain: true, magnifier: true, pencil: true, pointer: true,
  },
  presenterTools: {
    curtain: true, magnifier: true, pencil: true, pointer: true,
  },
  name: 'Пустой лист',
  type: 'canvas',
  src: '/canvas-empty-slide.png',
  srcThumbnails: '/canvas-empty-slide.png',
  teacherDesc: 'Пустой лист',
  selected: true,
  vectorStrId: 'empty-canvas-slide',
  vectorId: 'empty-canvas-slide',
}

export default emptyCanvasTask
