import cn from "classnames"
import filter from "lodash/filter"
import find from "lodash/find"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import map from "lodash/map"
import sortBy from "lodash/sortBy"
import React, { useEffect, useRef, useState } from "react"
import ReactTooltip from "react-tooltip"
import Carousel from "../Carousel/Carousel"

import { ReactComponent as Arrow } from "./img/arrow.svg"
import { ReactComponent as ArrowOn } from "./img/arrowOn.svg"
import styles from "./selectTask.module.css"

import giveIqTokensImg from "./img/giveIqTokens.png"
import giveIqTokensImgBig from "./img/giveIqTokens_big.png"
import { fetchVectorTasks } from "../../apiVectors"
import { nanoid } from "nanoid"

const renderOptions = (options = [], onClick = () => {}, idForKey) => {
 const result = options.map((i = {}, index) => {
  const handleClick = () => onClick(i, index)
  return (
   <li
    data-for={`${idForKey}_panel`}
    className={cn(styles.item, {
     [styles.itemOn]: i.selected,
     [styles.itemVisited]: !i.selected && i.visitedSeconds >= 10,
    })}
    onClick={handleClick}
    key={`${idForKey}_${index}`}
    data-click='inside'
    data-index={get(i, "slideNum")}
   >
    <span className={styles.itemTxt} data-click='inside'>
     {get(i, "label") || get(i, "name")}
    </span>
   </li>
  )
 })
 return (
  <>
   <ul className={styles.list}>{result}</ul>
   <ReactTooltip
    id={`${idForKey}_panel`}
    className='tooltip'
    textColor='#4DA3F7'
    backgroundColor='#fff'
    place={"bottom"}
    aria-haspopup='true'
   />
  </>
 )
}

const SelectTask = ({
 task,
 dropdownPosition,
 classSelect,
 classCarousel,
 keyEvent,
 hideBreadcrumbs,
 names = [],
 lastTask,
 vectorDirs,
 LargeTaskModal,
 isPupilForPresenter,
 isGroupedPupils,
 onChangeTask, //изменяем для каждого ученика
 changeTaskForGroup, //изменяем для группы
 socket,
 vectors,
 group,
 tags = [],
 vectorTasks,
 setVectorTasks,
 isSpeaking,
}) => {
 const [idForKey] = useState(nanoid(3))
 const [selectedVectorDir, setSelectedVectorDir] = useState(null)
 const [selectedVector, setSelectedVector] = useState(null)
 const [iqTokenOfSelectedVector, setIqTokenOfSelectedVector] = useState({
  vectorStrId: "",
  iqTokenCount: 0,
 })
 const [selectedTask, setSelectedTask] = useState(null)
 const [showPanel, setShowPanel] = useState(false)
 const [slideSelectTime, setSlideSelectTime] = useState(null)
 const [breadCrumbs, setBreadCrumbs] = useState([
  {
   value: null,
   type: "vectorDir",
   title: null,
  },
 ])
 const [currentParent, setCurrentParent] = useState({
  value: null,
  type: "vectorDir",
 })
 const [currentPupilTasks, setCurrentPupilTasks] = useState([])
 const [slides, setSlides] = useState([])
 const [slidesIsPending, setSlidesIsPending] = useState(false)
 const [optionsDir, setOptionsDir] = useState([])
 const [optionsVector, setOptionsVector] = useState([])
 const wrapRef = useRef(null)

 useEffect(() => {
  if (!lastTask || selectedVectorDir || vectorDirs.length === 0) {
   return
  }

  if (!vectorDirs.find((dir) => dir.strId === lastTask.vectorDirStrId)) {
   return
  }

  setSelectedVectorDir({
   value: lastTask.vectorDirStrId,
   label: lastTask.vectorDirTitle,
  })

  setSelectedVector({
   value: lastTask.vectorId,
   label: lastTask.vectorTitle,
   vectorDirStrId: lastTask.vectorDirStrId,
   strId: lastTask.vectorStrId,
  })

  const vectorDirsBreadCrumbs = lastTask.vectorDirsBreadCrumbs.map((dir) => {
   return {
    value: dir.strId,
    type: "vectorDir",
    title: dir.title,
   }
  })

  setBreadCrumbs([
   ...breadCrumbs,
   ...vectorDirsBreadCrumbs,
   { value: lastTask.vectorId, type: "vector", title: lastTask.vectorTitle },
  ])
 }, [lastTask])

 useEffect(() => {
  const handleClickOutside = (event) => {
   if (wrapRef.current && !wrapRef.current.contains(event.target)) {
    const dataClick = event.target.getAttribute("data-click")
    if (dataClick !== "inside") {
     setShowPanel(false)
    }
   }
  }
  document.addEventListener("click", handleClickOutside, false)
  return () => {
   document.removeEventListener("click", handleClickOutside, false)
  }
 }, [wrapRef])

 useEffect(() => {
  let cleanfunc = false
  if (
   !task ||
   !vectorDirs ||
   !vectorDirs.length ||
   !vectors ||
   !vectors.length
  ) {
   return
  }
  if (!cleanfunc) {
   console.log("SelectedTask UPD TASK", task)
   if (
    get(selectedVector, "strId") !== task.vectorStrId &&
    get(task, "slideNum") !== get(selectedTask, "slideNum")
   ) {
    const vector = find(vectors, (i) => get(i, "id") === task.vectorId)
    if (!vector) {
     return
    }

    const vectorDir = find(vectorDirs, (i) => i.strId === vector.vectorDirStrId)
    if (!vectorDir) {
     return
    }

    const vectorValue = {
     value: vector.id,
     label: vector.title,
     vectorDirStrId: vector.vectorDirStrId,
     strId: vector.strId,
    }

    const vectorDirValue = {
     value: vectorDir.strId,
     label: vectorDir.title,
    }

    const breadCrumbsForSet = []
    breadCrumbsForSet.unshift({
     value: task.slideNum,
     type: "slide",
     title: task.name,
    })
    breadCrumbsForSet.unshift({
     value: vector.value,
     type: "vector",
     title: vector.title,
    })
    const breadCrumbsFolders = getBreadCrumbs({ value: vector.vectorDirStrId })

    setBreadCrumbs([...breadCrumbsFolders, ...breadCrumbsForSet])
    setSelectedVectorDir(vectorDirValue)
    setSelectedVector(vectorValue)
    setSelectedTask({ ...task, vectorStrId: vectorValue.strId })
   } else if (get(task, "slideNum") !== get(selectedTask, "slideNum")) {
    setBreadCrumbs([
     ...breadCrumbs.slice(0, -1),
     { value: task.slideNum, type: "slide", title: task.name },
    ])
    setSelectedTask({ ...task, vectorStrId: selectedVector.strId })
   }
  }

  return () => {
   cleanfunc = true
  }
 }, [task?.slideNum, task?.vectorStrId, vectorDirs?.length, vectors?.length])

 useEffect(() => {
  setSlideSelectTime(Date.now())
 }, [selectedTask])

 const updateCurrentTasksBySocket = (data) => {
  if (data.name !== names[0]) {
   return
  }

  setCurrentPupilTasks(data.pupilTasks)
  setIqTokenOfSelectedVector(data.iqToken)
 }

 useEffect(() => {
  if (!socket) {
   return
  }

  socket.on("ask-pupil-tasks", updateCurrentTasksBySocket)
  return () => {
   socket.off("ask-pupil-tasks", updateCurrentTasksBySocket)
  }
 }, [socket, selectedVector])

 useEffect(() => {
  if (!selectedVector || names.length !== 1 || !socket) {
   return
  }

  socket.emit("ask-pupil-tasks", {
   name: names[0],
   vectorId: selectedVector.strId,
  })
 }, [selectedVector?.value, selectedVector?.strId, names?.length, socket])

 const getVectorTasks = async (vectorId) => {
  if (!vectorId) {
   return {}
  }

  let tasks = vectorTasks[vectorId]
  if (tasks) {
   return tasks
  }

  tasks = await fetchVectorTasks(vectorId).catch((err) =>
   console.error("fetchVectorTasks error", err)
  )
  setVectorTasks({ ...vectorTasks, [vectorId]: tasks })

  return tasks
 }

 useEffect(() => {
  setSlides([])
  if (
   !selectedVector ||
   (iqTokenOfSelectedVector.vectorStrId !== selectedVector?.strId &&
    names.length <= 1)
  ) {
   return
  }

  setSlidesIsPending(true)

  getVectorTasks(selectedVector.value)
   .then(({ tasks }) => {
    const slidesForSet = map(tasks, (vectorTask, index) => {
     const pupilTask = find(
      currentPupilTasks,
      (pt) => Number(pt.slideNum) === Number(vectorTask.slideNum)
     )

     return {
      ...vectorTask,
      index,
      // selected: vectorTask.slideNum === get(task, 'slideNum'),
      name: `Задание №${vectorTask.slideNum}`,
      visitedSeconds: pupilTask?.visitedSeconds,
      vectorId: selectedVector.value,
      vectorStrId: selectedVector.strId,
     }
    })

    if (
     !group?.isDemo &&
     !selectedVector.label.toLowerCase().includes("пбз") &&
     !selectedVector.label.toLowerCase().includes("бпз")
    ) {
     slidesForSet.push({
      index: slidesForSet.length,
      slideNum: slidesForSet.length + 1,
      name: "Оценка ученика",
      type: "giveIqTokens",
      vectorStrId: selectedVector.strId,
      vectorId: selectedVector.value,
      teacherDesc: `<section><p class="font-small">Педагог предоставляет обратную связь ребёнку по заданию.</p></section>`,
      iqTokenCount: iqTokenOfSelectedVector.iqTokenCount,
      src: giveIqTokensImgBig,
      carouselSrc: giveIqTokensImg,
     })
    }

    setSlides(slidesForSet)
    setSlidesIsPending(false)
   })
   .catch((err) => console.error("getVectorTasks with error", err))

  return () => setSlidesIsPending(false)
 }, [selectedVector?.strId, currentPupilTasks, iqTokenOfSelectedVector])

 useEffect(() => {
  const vectorDirsFiltered = filter(
   vectorDirs,
   (vd) => vd.parentStrId === currentParent.value
  )

  const optionsDirForSet = sortBy(
   vectorDirsFiltered.map((i = {}, index) => {
    let indexStr = i.number || index + 1 + ""
    if (indexStr.length <= 1) {
     indexStr = "0" + indexStr
    }
    return {
     value: i.strId,
     label: i.title,
     index: indexStr,
     selected: i.strId === get(selectedVectorDir, "value"),
    }
   }),
   (i) => i.index
  )

  setOptionsDir(optionsDirForSet)
 }, [currentParent.value, vectorDirs.length, selectedVectorDir?.value])

 useEffect(() => {
  const vectorsFiltered = filter(
   vectors,
   (vec) => vec.vectorDirStrId === currentParent.value
  )

  const unsortedVectorOptions = vectorsFiltered.map((i = {}, index) => {
   let indexStr = i.number || index + 1 + ""
   if (indexStr.length <= 1) {
    indexStr = "0" + indexStr
   }
   return {
    value: i.id,
    strId: i.strId,
    label: i.title,
    index: indexStr,
    vectorDirStrId: i.vectorDirStrId,
    selected: i.id === get(selectedVector, "value"),
   }
  })

  const optionsVectorForSet = sortBy(unsortedVectorOptions, (v) => v.index)
  setOptionsVector(optionsVectorForSet)
 }, [currentParent.value, vectors?.length, selectedVector?.value])

 const togglePanel = (panelId, selectedParent = null) => {
  if (selectedParent && selectedParent.type === "slide") {
   return
  }

  if (selectedParent) {
   setCurrentParent({
    value: selectedParent.value,
    type: selectedParent.type,
   })
  }

  if (showPanel === panelId) {
   setShowPanel(false)
  } else {
   setShowPanel(panelId)
  }
 }

 const getBreadCrumbs = (value) => {
  const currentDir = find(vectorDirs, (vd) => vd.strId === value.value)
  let parentStrId = currentDir.parentStrId
  const result = [
   {
    value: currentDir.strId,
    type: "vectorDir",
    title: currentDir.title,
   },
  ]

  let parent = null
  do {
   parent = find(vectorDirs, (vd) => vd.strId === parentStrId)
   if (!parent) {
    result.unshift({
     value: null,
     type: "vectorDir",
     title: null,
    })
   } else {
    result.unshift({
     value: parent.strId,
     type: "vectorDir",
     title: parent.title,
    })
    parentStrId = parent.parentStrId
   }
  } while (parent)

  return result
 }

 const onChangeVectorDir = (value) => {
  try {
   setShowPanel(breadCrumbs.length)
   setSelectedVectorDir(value)
   setSelectedVector(null)
   setSelectedTask(null)
   setBreadCrumbs(getBreadCrumbs(value))
   setCurrentParent({ value: value.value, type: "vectorDir" })
  } catch (err) {
   console.info("Error in onChangeVectorDir by set states", err)
  }
 }

 const onChangeVector = (value, index) => {
  let indexStr = index + 1 + ""
  if (indexStr.length <= 1) {
   indexStr = "0" + indexStr
  }
  value.index = indexStr

  setShowPanel(breadCrumbs.length)
  setSelectedVector(value)
  setSelectedTask(null)
  const breadCrumbsForSave = getBreadCrumbs({ value: value.vectorDirStrId })
  setBreadCrumbs([
   ...breadCrumbsForSave,
   {
    value: value.value,
    type: "vector",
    title: value.label,
   },
  ])
  setCurrentParent({
   value: value.value,
   type: "vector",
  })
 }

 const onChangeSlide = (value) => {
  setSelectedTask((prevTask) => {
   if (prevTask && !isEmpty(prevTask) && slides.length > 0) {
    const visitedSeconds = (Date.now() - slideSelectTime) / 1000
    const prevSlide = slides.find((task) => task.slideNum === prevTask.slideNum)
    if (prevSlide) {
     const prevVisitedSeconds = prevSlide.visitedSeconds
     prevSlide.visitedSeconds = visitedSeconds

     if (socket) {
      for (const name of names) {
       socket.emit("update-task", { name, task: { ...prevTask, ...prevSlide } })
      }
     }
     if (
      (prevVisitedSeconds >= 10 || visitedSeconds >= 10) &&
      Number(prevSlide.index) > 0
     ) {
      prevSlide.visitedSeconds = 31 //костыль, чтобы на фронте не сбрасывался посещённый слайд, если на него кликнуть ещё раз
      setCurrentPupilTasks([...currentPupilTasks, prevSlide])
     }
    }
   }

   return { value, vectorStrId: selectedVector.strId }
  })
  setShowPanel(false)

  const breadCrumbsForSave =
   breadCrumbs[breadCrumbs.length - 1].type === "slide"
    ? breadCrumbs.slice(0, -1)
    : breadCrumbs
  setBreadCrumbs([
   ...breadCrumbsForSave,
   {
    value: value.slideNum,
    type: "slide",
    title: value.name,
   },
  ])

  for (const viewerName of names) {
   onChangeTask(viewerName, {
    ...value,
    vectorId: selectedVector.value,
    vectorStrId: selectedVector.strId,
   })
  }

  if (isGroupedPupils && changeTaskForGroup) {
   changeTaskForGroup({
    ...value,
    vectorId: selectedVector.value,
    vectorStrId: selectedVector.strId,
   })
  }
 }

 const getBreadCrumbsTooltip = (crumb) => {
  if (crumb.type === "vectorDir") {
   return `Папка: ${crumb.title}`
  }

  if (crumb.type === "vector") {
   return `Программа: ${crumb.title}`
  }

  if (crumb.type === "slide") {
   return `Задание: ${crumb.title}`
  }
 }

 const renderCrumbButton = ({
  crumb,
  dataTip,
  index,
  arrowOnLeftShow = true,
  arrowOnRightShow = true,
 }) => {
  return (
   <button
    key={`${idForKey}-crumbButton-${names.join("-")}-${index}`}
    data-for='global'
    data-tip={dataTip}
    className={cn(styles.value, {
     [styles.valueOn]: showPanel === index,
    })}
    onClick={() => togglePanel(index, crumb)}
   >
    <span className={styles.valueInner}>
     <span className={styles.valueTxt}>{index}</span>
    </span>
    {arrowOnLeftShow && (
     <div className={styles.arrowContainerLeft}>
      <ArrowOn className={cn(styles.svg, styles.arrowOn, styles.arrowOnLeft)} />
     </div>
    )}
    {arrowOnRightShow && (
     <div className={styles.arrowContainer}>
      <ArrowOn
       className={cn(styles.svg, styles.arrowOn, styles.arrowOnRight)}
      />
      <Arrow className={cn(styles.svg, styles.arrow)} />
     </div>
    )}
   </button>
  )
 }

 ReactTooltip.rebuild()

 return (
  <>
   <div
    className={cn(styles.wrap, classSelect, {
     [styles.isSelected]:
      (!isGroupedPupils && tags?.includes("selected")) || isSpeaking,
     [styles.pupilForPresenter]: isPupilForPresenter,
    })}
    ref={wrapRef}
   >
    {!hideBreadcrumbs && (
     <div className={styles.values}>
      {breadCrumbs.map((crumb, index) => {
       switch (index) {
        case 0:
         return renderCrumbButton({
          crumb,
          dataTip: "Корневая папка",
          index,
          arrowOnLeftShow: false,
          arrowOnRightShow: true,
         })

        case breadCrumbs.length - 1:
         return renderCrumbButton({
          crumb,
          index,
          dataTip: getBreadCrumbsTooltip(crumb),
          arrowOnLeftShow: true,
          arrowOnRightShow: false,
         })

        default:
         return renderCrumbButton({
          crumb,
          index,
          dataTip: getBreadCrumbsTooltip(crumb),
         })
       }
      })}
      {(vectors.length === 0 || vectorDirs.length === 0 || slidesIsPending) && (
       <div
        className={"loader"}
        style={{
         width: "10px",
         height: "10px",
         position: "static",
         margin: 0,
         marginLeft: "8px",
         borderTopColor: "#4da3f7",
        }}
       ></div>
      )}
     </div>
    )}
    <div
     className={cn(styles.panels, {
      [styles[`panels_${dropdownPosition}`]]: dropdownPosition,
     })}
    >
     <div
      className={cn(styles.panel, {
       [styles.panelOpen]: showPanel || showPanel === 0,
      })}
     >
      <div className={styles.panelInner}>
       {optionsDir.length > 0 && (
        <>
         <div className={styles.panelTitle}>Папки</div>
         {renderOptions(optionsDir, onChangeVectorDir, idForKey)}
        </>
       )}

       {vectorDirs.length === 0 && (
        <>
         <div className={styles.panelTitle}>Папки</div>
         <div
          className={"loader"}
          style={{
           width: "10px",
           height: "10px",
           borderTopColor: "#4da3f7",
          }}
         ></div>
        </>
       )}

       {optionsVector.length > 0 && (
        <>
         <div className={styles.panelTitle}>Уроки</div>
         {renderOptions(optionsVector, onChangeVector, idForKey)}
        </>
       )}

       {vectors.length === 0 && (
        <>
         <div className={styles.panelTitle}>Уроки</div>
         <div
          className={"loader"}
          style={{
           width: "10px",
           height: "10px",
           borderTopColor: "#4da3f7",
          }}
         ></div>
        </>
       )}

       {slides.length > 0 &&
        !slidesIsPending &&
        currentParent.type === "vector" && (
         <>
          <div className={styles.panelTitle}>Задания</div>
          {renderOptions(slides, onChangeSlide, idForKey)}
         </>
        )}

       {slidesIsPending && (
        <>
         <div className={styles.panelTitle}>Уроки</div>
         <div
          className={"loader"}
          style={{
           width: "10px",
           height: "10px",
           borderTopColor: "#4da3f7",
          }}
         ></div>
        </>
       )}
      </div>
     </div>
    </div>
   </div>
   <div
    className={cn(styles.carouselSlot, {
     [styles.pupilForPresenter]: isPupilForPresenter,
    })}
   >
    {slides && slides[0] && (
     <Carousel
      slides={slides}
      onChange={onChangeSlide}
      className={classCarousel}
      keyEvent={keyEvent}
      selectIndex={Number(selectedTask?.slideNum)}
      LargeTaskModal={LargeTaskModal}
     />
    )}
   </div>
  </>
 )
}

export default SelectTask
