import superagent from 'superagent'
import { eventLog } from './api/apiEventLog'

import nanoid from './utils/custom-nanoid'

function getVideoConstraints(isMobile) {
  if (isMobile) {
    return {
      width: {
        max: 320
      },
      frameRate: { max: "10" },
      maxBitrate: { max: 20 },
      facingMode: {
        exact: "user"
      }
    }
  }

  return {
    width: { exact: 320 },
    frameRate: { max: "10" },
    maxBitrate: 20
  }

}

async function getNavMedia(kind, constraints, source) {
  return await navigator.mediaDevices.getUserMedia({
    [kind]: {
      ...constraints,
      deviceId: source ? { exact: source } : undefined,
    },
  })
}

function removeLocalStorageItem(key, value) {
  if (localStorage.getItem(key) === value) {
    localStorage.removeItem(key)
  }
}

export const ice = {
  iceServers: [
    {
      "urls":['turn:84.201.156.20?transport=tcp'],
      "username":"iq",
      "credential":"1"
    },
  ],
}

export const connections = {}

const VIDEO = 'video'
const AUDIO = 'audio'

export async function getLowQualityVideoAndAudioStreams(sources, isMobile) {
  let videoStream = null
  let audioStream = null

  const videoConstraints = getVideoConstraints(isMobile)

  try {
    videoStream = await getNavMedia(VIDEO, videoConstraints, sources.video)
  } catch (e) {
    console.error(e, 'first attempt')
    removeLocalStorageItem('videoSource', sources.video)
    try {
      videoStream = await getNavMedia(VIDEO,{ video: true })
    } catch (e) {
      console.error('getUserMedia: video not available', e)
    }
  }

  try {
    audioStream = await getNavMedia(AUDIO, {}, sources.audio)
  } catch (e) {
    console.error(e, 'first attempt')
    removeLocalStorageItem('audioSource', sources.audio)
    try {
      audioStream = await getNavMedia(AUDIO, { audio: true })
    }  catch (e) {
      console.log('getUserMedia: audio not available', e)
    }
  }

  return { videoStream, audioStream }
}

/*
* DEPRECATED
*/
export async function getVideoAndAudioStreams(audioSource, videoSource) {
  let videoStream = null
  let audioStream = null

  if (videoSource !== 'none') {
    try {
      videoStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: videoSource ? { exact: videoSource } : true,
        },
      })
    } catch (e) {
      console.log('getUserMedia: video not available')
    }
  }

  if (audioSource !== 'none') {
    try {
      audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: audioSource ? { exact: audioSource } : true,
        },
      })
    } catch (e) {
      console.log('getUserMedia: audio not available')
    }
  }

  return { videoStream, audioStream }
}

export async function getStreams(audioSource, videoSource) {
  let stream = null

  try {
    stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: videoSource ? { exact: videoSource } : undefined,
      },
      audio: {
        deviceId: audioSource ? { exact: audioSource } : undefined,
      },
    })
  } catch (e) {
    console.log('getUserMedia: stream not available', e)
  }
  
  return stream
}

export function fOnIceCandidate(socket, connectionId, onCandidate) {
  return async (evt) => {
    if (evt.candidate) {
      socket.emit('add-ice-candidate', { connectionId, candidate: evt.candidate })
      if (onCandidate) {
        onCandidate(evt.candidate)
      }
    }
  }
}

export function createPeerConntectionEx(socket, outputVideo, options = {}) {
  const currentConnectionId = nanoid()

  // console.log('Creating connection', currentConnectionId)
  const pc = new RTCPeerConnection(ice)

  connections[currentConnectionId] = { pc, outputVideo, options }

  pc.onconnectionstatechange = () => {
    console.log(
      'connection state for',
      currentConnectionId,
      'is',
      pc.connectionState
    )
  }

  pc.onicecandidate = fOnIceCandidate(socket, currentConnectionId, options.onLocalIceCandidate)

  return { pc, connectionId: currentConnectionId }
}

export function createPeerConntection(socket, outputVideo, onGotStream) {
  const currentConnectionId = nanoid()

  // console.log('Creating connection', currentConnectionId)
  const pc = new RTCPeerConnection(ice)

  connections[currentConnectionId] = { pc, outputVideo, onGotStream, options: {} }

  pc.onconnectionstatechange = () => {
    console.log(
      'connection state for',
      currentConnectionId,
      'is',
      pc.connectionState
    )
  }

  pc.onicecandidate = fOnIceCandidate(socket, currentConnectionId)

  return { pc, connectionId: currentConnectionId }
}

export async function createPeerConnectionNext(socket, outputVideo, options, onGotStream) {
  const currentConnectionId = nanoid(21)

  const { output, input } = options || {}
  const query = {
    ...input && { input },
    ...output && { output },
  }

  socket.emit('add-connection-id', { connectionId: currentConnectionId })
  const vm = await getCoturnVM(query)
  const ice = await getICECandidate(vm, currentConnectionId, query)

  const pc = new RTCPeerConnection({ iceServers: [ice] })

  connections[currentConnectionId] = {
    pc,
    outputVideo,
    onGotStream,
    ice,
    options: {},
  }

  pc.onconnectionstatechange = () => {
    console.log('connection state for', currentConnectionId, 'is', pc.connectionState)
    socket.emit('connection-state-changed', {
      connectionId: currentConnectionId,
      connectionState: pc.connectionState,
    })
    //TODO если pc.connectionState === 'failed' то надо на сервер отправить об этом сообщение
    if (pc.connectionState === 'failed') {
      const data = {
        userId: NaN,
        from: 'webrtc',
        action: 'pc.connectionState is failed',
        description: 'connection failed',
        meta : {
          pc: pc,
        }
      }
      eventLog(data).catch(err => console.log('eventLog error', err))

      throw Error('pc.connectionState is failed')
    }
  }

  pc.onicecandidate = fOnIceCandidate(socket, currentConnectionId)

  return { pc, connectionId: currentConnectionId }
}

async function getICECandidate(vm, connectId, query) {
  if (query.input && !vm.id) {
    await new Promise(resolve => setTimeout(resolve, 5000))
    const vm = await getCoturnVM(query)
    return getICECandidate(vm, connectId, query)
  }
  try {
    const { body } = await superagent
      .get(`/api/ice-server/candidate/${vm.id}/${connectId}`)
      .query(query)
    if (!body.urls) {
      await new Promise(resolve => setTimeout(resolve, 5000))
      return getICECandidate(vm, connectId, query)
    }
    console.log('getICECandidate result', connectId, body)
    return body
  } catch (e) {
    console.error('getICECandidate_error', e)
    const newVm = await getCoturnVM(query)
    return getICECandidate(newVm, connectId, query)
  }
}

async function getCoturnVM(query) {
  const { body } = await superagent
    .get('/api/ice-server/vm')
    .query(query)
  console.log('getCoturnVM', body)
  return body
}

export function addStreamToConnectionLQ(pc, stream) {
  const tracks = []
  let sender
  for (const track of stream.getTracks()) {
    sender = pc.addTrack(track, stream)
    tracks.push(sender)
  }
  return { tracks, sender }
}

export function addStreamToConnection(pc, stream) {
  const tracks = []
  let sender = null
  if (stream) {
    for (const track of stream.getTracks()) {
      sender = pc.addTrack(track, stream)
      tracks.push(sender)
    }
  }
  return { tracks, sender }
}

export async function createOffer(pc) {
  const offer = await pc.createOffer()
  await pc.setLocalDescription(offer)
  return offer
}

export function getConnection(connectionId) {
  return connections[connectionId]
}

export async function processSDPAnswer(connection, sdpAnswer) {
  const { pc, outputVideo, onGotStream } = connection
  const answer = new RTCSessionDescription({
    type: 'answer',
    sdp: sdpAnswer
  })
  await pc.setRemoteDescription(answer)

  if (outputVideo) {
    // console.log('start remote video to', outputVideo)

    const remoteStream = new MediaStream()
    for(const sender of pc.getReceivers()) {
      remoteStream.addTrack(sender.track)
    }
    outputVideo.srcObject = remoteStream

    if (onGotStream) {
      onGotStream(remoteStream)
    }
  }
}

export function initSignaling(socket, onError) {
  // console.log('init signaling')
  socket.on('debug', (a) => console.log("socket debug", a))

  socket.on('v-error', ({ kind, desc }) => {
    onError(desc)
  })

  socket.on('add-ice-candidate', async ({ connectionId, candidate }) => {
    const connection = getConnection(connectionId)
    if (!connection) {
      return
    }
    const { pc, options } = connection

    // console.log('addIceCandidate for', connectionId)
    if (options.onRemoteIceCandidate) {
      options.onRemoteIceCandidate(candidate)
    }

    pc.addIceCandidate(candidate)
    .catch((e) => {
      if (options.onAddIceCandidateError) {
        options.onAddIceCandidateError(e)
      }
      // onError('addIceCandidate error: ' + e.toString())
    })
  })

  socket.on('sdp-answer', async ({ connectionId, sdpAnswer }) => {
    const connection = getConnection(connectionId)
    if (!connection) {
      return
    }

    // console.log('try to process sdp-answer', connectionId, sdpAnswer)
    if (connection.options && connection.options.onSdpAnswer) {
      connection.options.onSdpAnswer(sdpAnswer)
    }

    try {
      await processSDPAnswer(connection, sdpAnswer)
      // console.log('setRemoteDescription by track done')
    } catch(err) {
      onError('spd-answer error: ' + err.toString())
    }
  })
}
