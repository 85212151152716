import cn from 'classnames'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import { nanoid } from 'nanoid'
import React, { useState, useEffect, useRef } from 'react'
import ChatMessage from '../ChatMessage/ChatMessage'
import { ReactComponent as EditPic } from './img/editMessage.svg'

import styles from './chatWidget.module.css'


export default function ChatWidget(props) {
  const { socket, onClose, displayName, theme = 'whiteTheme', userId, messageHistory = [], setMessageHistory, allViewers, from, isPresenterScreen } = props
  const [messages, setMessages] = useState([])
  const [messageInput, setMessageInput] = useState('')
  const [isShowMention, setShowMention] = useState(false)
  const [editMessage, setEditMessage] = useState({ })
  const [mentionedPupils, setMentionedPupils] = useState([])
  const [currentMention, setCurrentMention] = useState(null)
  const [viewers, setViewers] = useState([])
  const [caretPosition, setCaretPosition] = useState(0)
  const chatBodyRef = useRef(null)

  const handleMessages = (data) => {
    setMessages((prevState) => [...prevState, data])
  }

  const onViewerCame = ({ name, displayName }) => {
    setViewers((prevState) => {
      if (prevState.some(pupil => pupil.roomId === name)) {
        return prevState
      }
      return ([
        ...prevState,
        {
          roomId: name,
          name: displayName,
        },
      ])
    })
  }

  const onViewerLeave = ({ name }) => {
    setViewers((prevState) =>
      prevState.filter(viewer => viewer.roomId !== name),
    )
  }

  const onChatChangeMessage = (message) => {
    setMessages((prevState) => {
      const index =  findIndex(prevState, (prevMessage) => prevMessage.id === message.id)
      if (index === -1) {
        return prevState
      }
      prevState[index] = message
      return [...prevState]
    })
  }

  useEffect(() => {
    if (!socket) {
      return
    }
    socket.on('chat_receive_message', handleMessages)

    socket.on('viewer:came', onViewerCame)

    socket.on('viewer:leave', onViewerLeave)

    socket.on('chat_change_message', onChatChangeMessage)

    return () => {
      socket.off('chat_receive_message', handleMessages)
      socket.off('chat_change_message', onChatChangeMessage)
      socket.off('viewer:came', onViewerCame)
      socket.off('viewer:leave', onViewerLeave)
    }
  }, [socket, messages])

  useEffect(() => {
    if (!Array.isArray(messageHistory)) {
      return
    }

    setMessages((prevState) => {
      if (prevState.length === 0 || prevState.length < messageHistory.length) {
        return [...messageHistory]
      }
      return prevState
    })
  }, [messageHistory])

  useEffect(() => {
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight - chatBodyRef.current.clientHeight
  }, [messages])

  const handleInput = ({ target }) => {
    const value = target.value
    const index = value.lastIndexOf(' ') + 1
    const lastWord = value.slice(index)

    if (lastWord.startsWith('@')) {
      const foundViewer = viewers.find(viewer => viewer.name.toLowerCase() === lastWord.slice(1).toLowerCase())
      if (foundViewer) {
        setCurrentMention(foundViewer)
        setMentionedPupils(addUniquePupil(foundViewer))
      } else {
        setCurrentMention(null)
      }
      setShowMention(true)
    } else {
      setShowMention(false)
    }

    setMentionedPupils(prevState => {
      if (!prevState.length) {
        return []
      }
      return prevState.filter(pupil => value.toLowerCase().includes(`@${pupil.name.toLowerCase()}`))
    })

    setMessageInput(target.value)
  }

  const onEditMessage = (messageId) => {
    const editMes = messages.find(message => message.id === messageId)
    setEditMessage(editMes)
    setMessageInput(editMes.text)
  }

  const onDeleteMessage = (messageId) => {
    if (!socket) {
      return
    }

    socket.emit('chat_change_message', {
      messageId,
      userId,
      options: { deleted: true, },
    })
  }

  const addUniquePupil = ({ roomId, name }) => (prevState) => {
    if (prevState.some(pupil => pupil.roomId === roomId)) {
      return prevState
    }
    return ([
      ...prevState,
      { roomId, name },
    ])
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isShowMention && currentMention) {
        handleMentionPupil(currentMention, e.target.selectionStart)
      } else {
        sendMessage()
      }
    }
    if (e.key === '@') {
      setShowMention(true)
    }
  }

  const sendMessage = () => {
    if (!socket || !messageInput.trim()) {
      return
    }

    if(isEmpty(editMessage)) {
      let oneUser = null
      if (allViewers.length === 1 && mentionedPupils.length === 0) {
        oneUser = [{ 'roomId': allViewers[0].name, 'name': allViewers[0].displayName }]
      }
      socket.emit('chat_sent_message', {
        message: { text: messageInput, id: nanoid(6), deleted: false },
        userName: displayName || 'Учитель',
        from,
        mentioned: oneUser || mentionedPupils,
        userId,
      })
    } else {
      socket.emit('chat_change_message', {
        messageId: editMessage.id,
        userId,
        options: { text: messageInput, },
      })
      setEditMessage({ })
    }

    setMessageInput('')
    setShowMention(false)
    setMentionedPupils([])
  }

  const handleMentionPupil = ({ roomId, name }, caret) => {
    setMentionedPupils(addUniquePupil({ roomId, name }))

    setMessageInput((prevState) => {
      const index = prevState.slice(0, caret || caretPosition).lastIndexOf('@')
      const startStr = prevState.slice(0, index + 1)
      const endStr = prevState.slice(caret || caretPosition)
      return `${startStr}${name} ${endStr}`
    })
    setShowMention(false)
  }

  const onBlurTextArea = (e) => {
    setCaretPosition(e.target.selectionStart)
  }

  return (
    <div className={cn(styles.fixedWrapper, {[styles.presenterScreenChat]: isPresenterScreen})}>
      <div className={cn(styles.chatWrapper, styles[theme])}>
        <div className={styles.closeBlock}>
          {/*<div className={styles.closeIcon} onClick={onClose}/> */}
        </div>
        <div className={styles.chatBody} ref={chatBodyRef}>
          {!messages.length && <div className={styles.emptyChat}>
            <div className={styles.img}/>
            <div className={styles.note}>Напишите сообщение и начните эту беседу!</div>
          </div>}
          {messages.map((message) => {
            if (!message.deleted){
              return <div key={message.id} style={{marginRight: 5}}>
                <ChatMessage
                  message={message}
                  currentUser={userId} theme="lightTheme"
                  onEditMessage={onEditMessage}
                  onDeleteMessage={onDeleteMessage}/>
              </div>
            }
          }
          )}
        </div>
        <div className={styles.chatControls}>
          {!!viewers.length && isShowMention &&
            <div className={styles.mentionList}>
              {viewers.map(viewer =>
                <div key={viewer.name} className={cn(styles.mentionItem, {
                  [styles.mentioned]: currentMention?.roomId === viewer.roomId,
                })}
                     onClick={() => handleMentionPupil(viewer)}
                >
                  <div className={styles.avatarPlaceholder}/>
                  {viewer.name}
                  <span
                    className={styles.viewerInfo}>{/*можно написать доп информацию, чтоб отличать учеников с одинаковым именем*/}</span>
                </div>,
              )}
            </div>}
          <textarea
            placeholder="Сообщение"
            onChange={handleInput}
            value={messageInput}
            onKeyDown={onKeyDown}
            className={styles.chatInput}
            onBlur={onBlurTextArea}
          />
          {isEmpty(editMessage) ? <div className={styles.sendButton} onClick={sendMessage}/> : <EditPic style={{cursor: 'pointer'}} onClick={sendMessage}/>}
        </div>
      </div>
    </div>
  )
}
