import React from 'react'
import MenuPopup from "../MenuPopup/MenuPopup";
import PopupBtn from "../MenuPopup/PopupBtn/PopupBtn";
import { viewTypes } from "./viewStyle";
import { ReactComponent as GallerySVG } from "./img/gallery-icon.svg";
import { ReactComponent as GalleryActiveSVG } from "./img/gallery-active-icon.svg";
import { ReactComponent as MosaicSVG } from "./img/mosaic-icon.svg";
import { ReactComponent as MosaicActiveSVG } from "./img/mosaic-active-icon.svg";
import { ReactComponent as ReportSVG } from "./img/report-icon.svg";
import { ReactComponent as ReportActiveSVG } from "./img/report-active-icon.svg";


const ViewStylePopupMenu = ({onClose, currentView, onClickBtn = (viewType) => {}}) => {
  return (
    <MenuPopup
      onClose={onClose}
      title={'Настройки'}
    >
      <PopupBtn
        title={'Режим задания'}
        description={'Хорошо видно задание'}
        svgIcon={<GallerySVG/>}
        svgIconActive={<GalleryActiveSVG />}
        isActive={!currentView || currentView === viewTypes.GALLERY}
        onClick={() => onClickBtn(viewTypes.GALLERY)}
      />
      <PopupBtn
        title={'Режим беседа'}
        description={'Видно и задание, и видео'}
        svgIcon={<MosaicSVG />}
        svgIconActive={<MosaicActiveSVG />}
        isActive={currentView === viewTypes.MOSAIC}
        onClick={() => onClickBtn(viewTypes.MOSAIC)}
      />
      <PopupBtn
        title={'Режим видео'}
        description={'Хорошо видно видео'}
        svgIcon={<ReportSVG />}
        svgIconActive={<ReportActiveSVG />}
        isActive={currentView === viewTypes.REPORT}
        onClick={() => onClickBtn(viewTypes.REPORT)}
      />
    </MenuPopup>
  )
}

export default ViewStylePopupMenu