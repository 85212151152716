import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ViewerScreenDesktop from "./ViewerScreenDesktop";
import ViewerScreenMobile from "./ViewerScreenMobile/ViewerScreenMobile";


export default function ViewerScreen({ mode = 'demo' }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  if (isDesktopOrLaptop) {
    return <ViewerScreenDesktop mode={mode} />
  } else {
    return <ViewerScreenMobile mode={mode}/>
  }
}
