import React from 'react'
import styles from './buttonTheme.module.css'
import cn from "classnames";
import { ReactComponent as IconThemeDark } from '../../img/dark-thema.svg'
import {ReactComponent as IconThemeLight} from "../../img/light-thema.svg";


const ButtonTheme = ({
  theme = 'light',
  onClick = () => {},
}) => {
  const renderIcon = () =>{
    const iconClass = cn('icon', `icon_${theme}`)
    switch (theme) {
      case 'light':
        return <IconThemeLight className={iconClass}/>
      case 'dark':
        return <IconThemeDark className={iconClass}/>
      default:
        return
    }
  }

  return <button
    className={styles.button}
    onClick={onClick}
    data-tip={'Сменить тему'}
    data-for="global"
  >
    {renderIcon()}
  </button>
}

export default ButtonTheme