import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import "dayjs/locale/ru";
import dayjs from "dayjs";

import { setOffer } from "../api/pupils";
import { declOfNum, pad } from "../utils/util";
import DemoLayout from './DemoLayout'

import styles from "./demoFirstScreen.module.css";

const DemoFirstScreen = ({ demoTimer, demoData }) => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add('height-auto')
    return () => {
      document.body.classList.remove('height-auto')
    }
  }, [])

  const [lastScreen, setLastScreen] = useState(false);
  const [paymentScreen, setPaymentScreen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (location.hash === '#last-screen' && !lastScreen) {
      history.push(
        `${location.pathname}#last-screen`
      );
      setLastScreen(true);
    }
    if (location.hash === '#price' && !paymentScreen) {
      history.push(
        `${location.pathname}#price`
      );
      setPaymentScreen(true);
    }
  }, [location, lastScreen, paymentScreen])

  return (
    <DemoLayout>
        {!lastScreen && (
          <div className={styles.content}>
            <div className={styles.hello}>
              Ученик<br />
              <b>{demoData.pupilFIO}</b> <br />
              Урок запланирован на {" "}
              {dayjs(demoData.demoDate)
                .locale("ru")
                .format("DD MMMM HH:mm")}
            </div>
            <div className={styles.timerWrap}>
                <div className={styles.timerTop}>
                  До начала урока:
                </div>
                <div className={styles.timer}>
                    <div className={styles.timerItem}>
                        <div className={styles.timerValue}>
                            {demoTimer.days}
                        </div>
                        <div className={styles.timerLabel}>
                            {declOfNum(demoTimer.days, [
                                "день",
                                "дня",
                                "дней",
                            ])}
                        </div>
                    </div>

                    <div className={styles.timerItem}>
                        <div className={styles.timerValue}>
                            {demoTimer.hours}
                        </div>
                        <div className={styles.timerLabel}>
                            {declOfNum(demoTimer.hours, [
                                "час",
                                "часа",
                                "часов",
                            ])}
                        </div>
                    </div>

                    <div className={styles.timerItem}>
                        <div className={styles.timerValue}>
                            {demoTimer.minutes}
                        </div>
                        <div className={styles.timerLabel}>
                            {declOfNum(demoTimer.minutes, [
                                "минута",
                                "минуты",
                                "минут",
                            ])}
                        </div>
                    </div>

                    <div className={styles.timerItem}>
                        <div className={styles.timerValue}>
                            {demoTimer.seconds}
                        </div>
                        <div className={styles.timerLabel}>
                            {declOfNum(demoTimer.seconds, [
                                "секунда",
                                "секунды",
                                "секунд",
                            ])}
                        </div>
                    </div>
                </div>
              </div>
          </div>
      )}
        
    </DemoLayout>
  );
};

export default DemoFirstScreen;
