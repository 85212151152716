import React from 'react'
import Header from './components/Header/Header'
import PaymentScreen from './components/PaymentScreen/PaymentScreen'
import Stories from './components/Stories/Stories'

import styles from './demoFirstScreen.module.css'

export default function DemoLayout(props) {
  const { children, paymentScreen, lastScreen, toggleAside, isAside, franchiseeSlug, customUrlToRobokassa, ...rest } = props
  return (
    <div className={styles.container}>
        <main className={styles.main}>
          <div className={styles.section}>
            <Header toggleAside={toggleAside} isAside={isAside} />
            {paymentScreen && <PaymentScreen state={paymentScreen} {...rest} />}
            {children}
          </div>
        </main>
        <footer className={styles.footer}>
              {!lastScreen && (
                  <div className={styles.section}>
                      <Stories />

                      <div className={styles.footerFirstText}>
                          У вас есть вопросы? Мы ответим
                      </div>
                      <div className={styles.footerFirstPhone}>
                          <a href="tel:+78002222007">8 800 2222 007</a>
                      </div>
                    <div className={styles.footerFirstPhone}>
                      <a href="tel:+78005007417">8 (800) 500-74-17 - Техподдержка</a>
                    </div>
                  </div>
              )}
              {lastScreen && (
                  <div className={styles.section}>
                      <div className={styles.footerTop}>
                          Проблемы с оплатой?
                      </div>
                      <div className={styles.phone}>
                          Позвоните нам, и мы быстро всё решим <br />
                          <a
                              href="tel:+78002222007"
                              className={styles.tel}
                          >
                            8 800 2222 007
                          </a>
                      </div>
                    {franchiseeSlug && <div className={styles.footerLinks}>
                        {/*customUrlToRobokassa временный вариант, пока не подключили Робокассу*/}
                        {
                            customUrlToRobokassa
                                ? <a target="_blank" href={`/kostromina/offerYokassa`}>Публичная оферта</a>
                                : <a target="_blank" href={`/${franchiseeSlug}/offer`}>Публичная оферта</a>
                        }
                      <a target="_blank" href="https://iq007.ru/uploads/programm_2019.pdf">Образовательные программы</a>
                      <a target="_blank" href="https://iq007.ru/rules">Правила оказания образовательных услуг</a>
                    </div>}
                  </div>
              )}
          </footer>
    </div>
  )
}
