export default function ({
    element,
    dragzone = element,
    direction,
    onChangeSize,
}) {
    let isDrag = false;
    function dragElement(event) {
        isDrag = true;
        const heightBox = element.offsetHeight;
        const widthBox = element.offsetWidth;

        const x = event.pageX;
        const y = event.pageY;

        element.style.transition = "none";
        moveAt(event.pageX, event.pageY);

        function moveAt(pageX, pageY) {
            if (isDrag) {
                if (direction === "top") {
                    const heightNext = heightBox + pageY - y;
                    if (heightNext > 0) {
                        element.style.height = heightNext + "px";
                        if (onChangeSize) {
                            onChangeSize(element.offsetWidth, heightNext);
                        }
                    } else {
                        clear();
                    }
                }

              if (direction === "bottom") {
                const heightNext = heightBox + y - pageY;
                if (heightNext > 0) {
                  element.style.height = heightNext + "px";
                  if (onChangeSize) {
                    onChangeSize(element.offsetWidth, heightNext);
                  }
                } else {
                  clear();
                }
              }

                if (direction === "right") {
                    const widthNext = widthBox + x - pageX;
                    if (widthNext > 0) {
                        element.style.width = widthNext + "px";
                        if (onChangeSize) {
                            onChangeSize(widthNext, element.offsetHeight);
                        }
                    } else {
                        clear();
                    }
                }

                if (direction === "left") {
                    const widthNext = widthBox + pageX - x;
                    if (widthNext > 0) {
                        element.style.width = widthNext + "px";
                        if (onChangeSize) {
                            onChangeSize(widthNext, element.offsetHeight);
                        }
                    } else {
                        clear();
                    }
                }
            }
        }

        function onMouseMove(e) {
            moveAt(e.pageX, e.pageY);
        }

        function onTouchMove(e) {
            // e.preventDefault()
            moveAt(e.targetTouches[0].pageX, e.targetTouches[0].pageY);
        }

        // передвигаем элемент при событии mousemove

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("touchmove", onTouchMove);
        document.addEventListener("ontouchend", clear);
        document.addEventListener("mouseup", clear);

        // отпустить элемент, удалить ненужные обработчики
        dragzone.onmouseup = clear;
        dragzone.ontouchend = clear;

        function clear() {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("touchmove", onTouchMove);
            document.removeEventListener("ontouchend", clear);
            document.removeEventListener("mouseup", clear);
            dragzone.onmouseup = null;
            dragzone.ontouchend = null;
            isDrag = false;
        }
    }

    dragzone.onmousedown = dragElement;

    dragzone.ontouchstart = (e) => {
        e.preventDefault()
        dragElement({
            pageX: e.targetTouches[0].pageX,
            pageY: e.targetTouches[0].pageY,
        });
    };

    return {
        makeDragFalse: () => {
            isDrag = false
        }
    }
}
