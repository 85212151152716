import React, { useEffect, useRef, useState } from "react";

import styles from "./paymentScreen.module.css";

const PaymentScreen = ({ state }) => {
    return (
        <div className={styles.container}>
            <div className={styles.h2}>Вы успешно оплатили занятий</div>
            <div className={styles.subtitle}>
                Вы добавлены в группу и скоро мы пришлём вам персональную ссылку
                на урок{" "}
            </div>
        </div>
    );
};

export default PaymentScreen;
