import { useEffect, useState } from "react";
import io from "socket.io-client";
import { initSignaling } from "../webrtc";
import { useHistory } from "react-router-dom";


const useViewerSocket = ({name, dispatchTimer}) => {
  const [socket, setSocket] = useState(null)
  const [error, setError] = useState(null)
  const [connectionState, setConnectionState] = useState('initial')

  const [viewerDisplayName, setViewerDisplayName] = useState('')
  const [pupilId, setPupilId] = useState(null)

  const [task, setTask] = useState(null)

  const [otherViewers, setOtherViewers] = useState([])
  const [viewersMuted, setViewersMuted] = useState([])
  const [viewersDisplayName, setViewersDisplayName] = useState({})

  const [tariffPlanHashId, setTariffPlanHashId] = useState(null)
  const [isDemoLesson, setIsDemoLesson] = useState(false)

  const [directMode, setDirectMode] = useState(null)

  const [tags, setTags] = useState({})
  const [settingsByTag, setSettingsByTag] = useState({})

  const [isPresenterCame, setIsPresenterCame] = useState(false)
  const [roomId, setRoomId] = useState('') //ЭТО НЕ ТО ЖЕ САМОЕ ЧТО И roomStrId! Это хеш комнаты!
  const [presenter, setPresenter] = useState(null)
  const [roomStrId, setRoomStrId] = useState('')
  const [settings, setSettings] = useState({})
  const [viewerSettings, setViewerSettings] = useState({})

  const [messages, setMessages] = useState([])

  const [presentView, setPresentView] = useState({})

  const [isMuted, setMute] = useState(false)
  const [isChatMuted, setChatMute] = useState(false)

  const [needStopLocalStreaming, setNeedStopLocalStreaming] = useState(false)

  const history = useHistory()

  const onConnect = () => {
    console.log('socket connected')
    setConnectionState('connected')
  }

  const onDisconnect = (data) => {
    console.log('socket disconnected', data)
    setConnectionState('disconnected')
  }

  const onGotTask = ({task: nextTask}) => {
    setTask(nextTask)
  }

  const onViewerCame = ({name: viewerName, displayName}) => {
    setOtherViewers((prevState) => {
      if (prevState.indexOf(viewerName) > -1) {
        return prevState
      }

      return prevState.concat([viewerName])
    })

    setViewersDisplayName((prevState) => ({...prevState, [viewerName]: displayName}))
  }

  const onViewerLeave = ({name: viewerName}) => {
    setOtherViewers((prevState) => prevState.filter(otherName => otherName !== viewerName))
  }

  const onGotSecondDemoLesson = ({name: viewerName, redirectHashId}) => {
    if (redirectHashId) {
      setNeedStopLocalStreaming(true)

      history.push(`/lesson/${ redirectHashId }`)
    }
  }

  const onGotDisplayName = ({name: viewerName, displayName, pupilId: viewerPupilId}) => {
    setPupilId(viewerPupilId)
    setViewerDisplayName(displayName)
  }

  const onGotTariffPlanHash = ({name: viewerName, hashId}) => {
    setTariffPlanHashId(hashId)
  }

  const onGotLessonInfo = ({isDemo}) => {
    setIsDemoLesson(() => (isDemo))
  }

  const onTimerOn = ({time_int, name: viewerName}) => {
    if (dispatchTimer) {
      dispatchTimer({type: 'setTimer', payload: {'name': viewerName, 'time_int': time_int, isStart: true}})
    }
  }

  const onTimerOff = ({name: viewerName}) => {
    if (dispatchTimer) {
      dispatchTimer({type: 'resetTimer', payload: {'name': viewerName, isStart: false}})
    }
  }

  const onTagDirectOn = ({tag: viewerTag}) => {
    setDirectMode({type: 'tag', value: viewerTag})
  }

  const onTagDirectOff = ({tag: viewerTag}) => {
    setDirectMode(null)
  }

  //TODO: проверить комментарий в функции + кажется это функция никогда не вызовется
  const onTagSettingUpdate = (settingMsg) => {
    console.log('tag:setting:updated', settingMsg)
    const { tag, endState } = settingMsg

    // Этот сокет же не обновляется, когда меняется settingsByTag
    // Соотвественно это всегда какие-то старые настройки
    // settingsByTag[tag] = endState
    // settingsByTag точно обновляется при этом? Объект то тот же
    // setUpdatedTag(tag)

    setSettingsByTag((settingsByTag) => {
      return {
        ...settingsByTag,
        [tag]: endState,
      }
    })
  }

  const onGetRoomState = (roomState) => {
    const {
      roomStrId: roomStrIdFromServer,
      tags: roomTags,
      settingsByTag: roomSettingsByTag,
      directMode: roomDirectMode,
      settings: roomSettings,
      viewersSettings,
      chatMessages = [],
      presenter: roomPresenter,
      roomId: presenterRoomId,
    } = roomState

    setRoomStrId(roomStrIdFromServer)
    setRoomId(presenterRoomId)
    setTags(roomTags)
    setSettingsByTag(roomSettingsByTag)
    setDirectMode(roomDirectMode)
    setSettings(roomSettings)
    setViewerSettings((prevState) => ({ ...prevState, ...(viewersSettings[name] || {}) }))
    setMessages((prevState) => {
      if (prevState.length === 0) {
        return [...chatMessages]
      }
      return prevState
    })
    setPresenter(roomPresenter || null)
  }

  const onGetSettings = ({roomSettings}) => {
    setSettings(roomSettings)
  }

  const onGetViewerSettings = ({name: viewerName, viewerSettings: viewerSettingFromServer}) => {
    if (viewerName !== name) {
      return
    }

    setViewerSettings((prevState) => ({ ...prevState, ...(viewerSettingFromServer || {}) }))
  }

  const onViewPupilChange = (data) => {
    setPresentView(data)
  }
  const onMuteViewer = ({ name: viewerName, isMuted: isViewerMuted }) => {
    setMute(!isViewerMuted)
  }

  const onMuteViewerChat = ({ name: viewerName, isChatMuted: isViewerChatMuted }) => {
    setChatMute(!isViewerChatMuted)
  }

  const onAskMute = ({ name: viewerName, isMuted: isViewerMuted }) => {
    if (isViewerMuted) {
      setViewersMuted(prevState => {
        if (prevState.indexOf(viewerName) > -1) {
          return prevState
        }
        return prevState.concat([viewerName])
      })
    } else {
      setViewersMuted(prevState =>
        prevState.filter(i => i !== viewerName),
      )
    }
  }

  const makeRedirect = (url) => {
    if (!url || typeof window === 'undefined') {
      return
    }

    window.location = url
  }

  const refreshPage = () => {
    setTimeout(() => {
      document.location.reload()
    }, 2000)
  }

  const onPresenterCame = ({ roomId: presenterRoomId }) => {
    setRoomId(presenterRoomId)
    setIsPresenterCame(true)
  }

  const onPresenterLeave = () => {
    setIsPresenterCame(false)
  }

  useEffect(() => {
    if (!name) {
      return
    }

    const initSocket = io()

    initSocket.on('connect', onConnect)
    initSocket.on('reconnect', onConnect)
    initSocket.on('disconnect', onDisconnect)
    initSocket.on('connect_error', onDisconnect)

    initSocket.on('got-task', onGotTask)

    initSocket.on('viewer:came', onViewerCame)
    initSocket.on('viewer:leave', onViewerLeave)

    initSocket.on('viewer:got-second-demo-lesson', onGotSecondDemoLesson)

    initSocket.on('viewer:got-display-name', onGotDisplayName)

    initSocket.on('viewer:got-tariff-plan-hash', onGotTariffPlanHash)
    initSocket.on('viewer:got-lesson-info', onGotLessonInfo)

    initSocket.on('timer-on', onTimerOn)
    initSocket.on('timer-off', onTimerOff)

    initSocket.on('tag:add', () => initSocket.emit('room:want-state'))
    initSocket.on('tag:remove', () => initSocket.emit('room:want-state'))
    initSocket.on('tag:direct:on', onTagDirectOn)
    initSocket.on('tag:direct:off', onTagDirectOff)
    initSocket.on('tag:setting:updated', onTagSettingUpdate)

    initSocket.on('room:state', onGetRoomState)
    initSocket.on('room:settings', onGetSettings)
    initSocket.on('room:viewers-settings:viewer', onGetViewerSettings)

    //TODO: В будущем убрать это в настройки viewerSettings, чтобы мы хранили эти данные и при перезагрузке не теряли их ни у ученика ни у учителя + избавимся от этих евентов
    initSocket.on('view:pupil:change', onViewPupilChange)
    initSocket.on('presenter:please-mute', onMuteViewer)
    initSocket.on('presenter:please-mute-chat', onMuteViewerChat)
    initSocket.on('ask-mute', onAskMute)

    initSocket.on('redirect-to-lesson', makeRedirect)

    initSocket.on('need_to_refresh', refreshPage)

    initSocket.on('presenter:came', onPresenterCame)
    initSocket.on('presenter:leave', onPresenterLeave)

    initSignaling(initSocket, (desc) => {
      console.error('webrtc initSignaling Error', desc)
      setError(desc)
    })

    setSocket(initSocket)
    return () => {
      initSocket.close()
      setSocket(null)
      console.log('socket close')
    }
  }, [name])
  return {
    socket,
    error,
    needStopLocalStreaming,
    setNeedStopLocalStreaming,
    setError,
    roomId,
    connectionState,
    viewerDisplayName,
    pupilId,
    task,
    otherViewers,
    viewersMuted,
    viewersDisplayName,
    setViewersDisplayName,
    tariffPlanHashId,
    isDemoLesson,
    directMode,
    tags,
    settingsByTag,
    isPresenterCame,
    presenter,
    roomStrId,
    settings,
    viewerSettings,
    setViewerSettings,
    messages,
    setMessages,
    presentView,
    isMuted,
    setMute,
    isChatMuted,
  }
}

export default useViewerSocket
