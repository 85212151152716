import React from 'react'
import cn from 'classnames'
import styles from './teacherButton.module.scss'

export default function TeacherButton({ title, onClick, fill, long, small, medium, disabled}) {
  return (
    <button
      className={cn(styles.button,
        {
          [styles.button_fill]: fill,
          [styles.button_long]: long,
          [styles.button_small]: small,
          [styles.button_medium]: medium,
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >{title}</button>
  )
}