import React from 'react'
import ReactTooltip from 'react-tooltip'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import { ReactComponent as IconChat } from '../img/chat.svg'
import { ReactComponent as IconChatFill } from '../img/chat-fill.svg'
import styles from './buttons.module.sass'


export default function ButtonChat({ onClick, count = 0, isChatShown }) {
  return (
    <div
      className={styles.button}
      onClick={onClick}
      data-tip="Чат с учениками" data-for="chat"
    >
      {isChatShown ? <IconChatFill data-tip="Чат с учениками" data-for="chat"/> :
        <IconChat data-tip="Чат с учениками" data-for="chat"/>}
      {count > 0 && <div className={styles.counter}>{count}</div>}
      {count > 0 &&
        <div className={styles.messageРopup}>
          <div className={styles.messageРopupArrow}></div>
          <div className={styles.messageРopupText}>
            Пришло <span>новое сообщение</span>, нажмите, чтобы прочитать
          </div>
        </div>
      }

      <ReactTooltip
        id="chat"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}