import React, { useEffect, useRef, useState } from "react";
import styles from './magnifier.module.css'
import useResizeObserver from "use-resize-observer/polyfilled";
import { magnify } from "./magnify";
import useDrawing from "../useDrawing";
import { ReactComponent as AimSVG } from "./img/aim.svg";


const Magnifier = ({
  socket,
  task,
  name,
  type,
  mainCanvasRef,
  zoomRatio = 2,
  readOnly,
  color,
  tools,
  imageSize,
  picSrc,
  onAddLineMainCtx = (line) => {},
  onUpdateLineMainCtx = ({ id, point }) => {},
}) => {
  const { width: mainWidth, height: mainHeight } = useResizeObserver({ ref: mainCanvasRef })
  const imgRef = useRef()
  const canvasRef = useRef()
  const containerRef = useRef()
  const innerContainerRef = useRef()
  const { width, height } = useResizeObserver({ ref: canvasRef })

  const { context: ctx } = useDrawing({
    canvasRef,
    name,
    task,
    color,
    imageSize,
    isPreview: false,
    readOnly,
    type,
    socket,
    wrapRef: containerRef,
    handleOnAddLine: onAddLineMainCtx,
    handleOnUpdateLine: onUpdateLineMainCtx,
  })

  useEffect(() => {
    if (!tools || !ctx){
      return
    }
    ctx.setModeLine(tools)
  }, [tools, ctx])

  useEffect(() => {
    if (!imgRef || !innerContainerRef || !containerRef || !mainCanvasRef) {
      return
    }

    const { destroy: destroyMagnify } = magnify( mainCanvasRef.current, containerRef.current, innerContainerRef.current, imgRef.current, zoomRatio)


    return () => { destroyMagnify() }
  }, [imgRef, innerContainerRef, containerRef, mainCanvasRef])

  return <div ref={containerRef} className={styles.container} style={{height: mainWidth * 0.44, width: mainWidth * 0.44}}>
    <AimSVG className={styles.aim} />
    <div ref={innerContainerRef} className={styles.innerContainer} style={{height: mainHeight * zoomRatio, width: mainWidth * zoomRatio}}>
      <img src={picSrc} className={styles.img} ref={imgRef} style={{height: mainHeight * zoomRatio, width: mainWidth * zoomRatio}}/>
      <canvas className={styles.canvas} ref={canvasRef} width={width} height={height} style={{ height: mainHeight * zoomRatio, width: mainWidth * zoomRatio }}/>
    </div>
  </div>
}


export default Magnifier
