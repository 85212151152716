import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './AdminInput.module.css'
import { getSuggestionsFuncs } from '../../api/daData'
import { ReactComponent as Time } from '../../img/time.svg'
import { ReactComponent as Calendar } from '../../img/calendar.svg'


export const AdminInput = ({
  key,
  label,
  defaultValue,
  value,
  inputName,
  onChangeInput,
  isError,
  type,
  min,
  max,
  disabled,
  pattern,
  className,
  placeholder,
  showSuggestions = false,
  typeSuggestions,  //берётся из suggestionType в ../api/daData
  suggestionsFrom = 'daData', //daData, givenArray
  suggestions = [],
  isNewPupil,
  selectHandler,
}) => {
  const [isActive, setActive] = useState(false)
  const [inputData, setInputData] = useState({})
  const [suggestionsArray, setSuggestionsArray] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  const onChangeInputHand = (target) => {
    onChangeValue(target.value)
    if (onChangeInput) {
      onChangeInput(target)
    }
    if (showSuggestions) {
      if (suggestionsFrom === 'daData') {
        getSuggestionsFuncs[typeSuggestions](target.value).then((data) => {
          const suggestionsForSet = data.map(sug => sug.value)
          // setSuggestionsArray(suggestionsForSet)
            setSuggestionsArray([target.value.trim(), ...suggestionsForSet])
        })
      } else {
        setSuggestionsArray([target.value.trim(), ...suggestions])
      }

    }
  }



  const onInputClick = () => {
    if (showSuggestions) {
      if (suggestionsFrom === 'givenArray') {
        setSuggestionsArray(suggestions)
      }
    }
  }

  const onChangeValue = (value) => {
    setInputData({ inputName: value })
    if (value === '') setActive(false)
    else setActive(true)
  }

  useEffect(() => {
    if (defaultValue) {
      setInputData({ inputName: defaultValue })
    }
  }, [defaultValue])

  useEffect(() => {
    if (!value) {
      return
    }

    setInputData({ ...inputData, inputName: value.toString() })
  }, [value])

  useEffect(() => {
    setActiveIndex(0)
  }, [suggestionsArray])

  useEffect(() => {
    if (inputData.inputName === undefined || inputData.inputName === '' || inputData.inputName.trim() === '') {
      return
    }

    setActive(true)
  }, [inputData.inputName])

const setInputValue = (value) => {
  onChangeValue(value)
  if (selectHandler){
    // selectHandler(inputName, value)
    setInputData({ inputName: ' ' })
  }
}
  const onKeyDown = (event) => {
    const key = event.key

    if (!key) {
      return
    }

    switch (key) {
      case 'ArrowDown': {
        const newIndex = activeIndex + 1
        if (newIndex >= suggestionsArray.length) {
          setActiveIndex(0)
        } else {
          setActiveIndex(newIndex)
        }
        break
      }
      case 'ArrowUp': {
        const newIndex = activeIndex - 1
        if (newIndex < 0) {
          setActiveIndex(suggestionsArray.length - 1)
        } else {
          setActiveIndex(newIndex)
        }
        break
      }
      case 'Enter': {
        setInputValue(suggestionsArray[activeIndex])
        if (selectHandler){
          selectHandler(inputName, suggestionsArray[activeIndex])
          setInputData({ inputName: ' ' })
        }
        event.target.blur()
        break
      }
      default: {
        return
      }
    }
  }

  const onClickSuggestion = (suggestion) => {
    console.log('клик', suggestion)
    if (selectHandler){
      selectHandler(inputName, suggestion)
      setInputValue(null)
    } else {
      setInputValue(suggestion)
    }
  }

  return (
    <label
      className={cn('input', {
        active: (isActive || defaultValue || value) && !disabled,
        error: isError,
        disabled: disabled,
        [`${className}`]: className,
      })}
    >
      <input
        key={key}
        type={type}
        max={max}
        min={min}
        onClick={() => onInputClick()}
        onBlur={() => {
          if (inputData.inputName === undefined || inputData.inputName === '' || inputData.inputName.trim() === '') {
            setActive(false)
          }
          setTimeout(() => setSuggestionsArray([]), 200)
        }}
        onFocus={() => setActive(true)}
        name={inputName}
        defaultValue={defaultValue}
        value={inputData.inputName || value}
        onChange={(e) => onChangeInputHand(e.target)}
        disabled={disabled}
        onKeyDown={onKeyDown}
        pattern={pattern}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div
        // className="label"
        className={cn("label", { ["label2"]: inputData.inputName })}
      >
        {label}
        {(label === 'Пароль') &&
          <div className={cn("label3", { ["label4"]: isNewPupil })}></div>}
      </div>

      {type === 'time' && (
        <Time
          className="input-icon"
        />
      )}

      {type === 'date' && (
        <Calendar
          className="input-icon"
        />
      )}

      {showSuggestions && suggestionsArray.length > 0 && <ul className={styles.suggestionsContainer}>
        {suggestionsArray.map((sug, index) =>
          <li onClick={() => onClickSuggestion(sug)}
              className={cn(styles.suggestion, { [styles.suggestionActive]: index === activeIndex })}>{sug}</li>)}
      </ul>}

    </label>
  )
}
