import React, { useRef } from "react";
import cn from "classnames";
import ReactTooltip from "react-tooltip";
import get from "lodash/get";

import styles from "./copyright.module.css";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useEffect } from "react";

const CopyRight = ({ html, isShowMobileMenu, isDesktopOrLaptop }) => {
    const tooltipRef = useRef(null);
    function createMarkup() {
        return { __html: html };
    }

    const handleClose = () => {
        tooltipRef.current.tooltipRef = null;
        ReactTooltip.hide();
    };
    useOnClickOutside(
        { current: get(tooltipRef, "current.tooltipRef") },
        handleClose
    );

    const handleOverridePosition = ({ left, top }, _e, _t, node) => {
        return {
            top,
            left: isDesktopOrLaptop ? left : 0,
        };
    };
    return (
        <div
            className={cn(styles.wrap, {
                [styles.wrapNoZindex]: isShowMobileMenu,
            })}
        >
            <span
                data-for="copy"
                data-event="click"
                data-tip
                className={styles.copy}
            ></span>
            <ReactTooltip
                ref={tooltipRef}
                id="copy"
                className="tooltip tooltip_copy"
                textColor="#393F61"
                backgroundColor="#fff"
                aria-haspopup="true"
                overridePosition={handleOverridePosition}
                arrowColor="transparent"
                clickable

                // offset={{ top: 10, left: 10 }}
            >
                <div>
                    <span className="tooltip_close" onClick={handleClose} />
                    <div className="tooltip_copy_title">
                        Правовая информация
                    </div>
                    <div
                        className="tooltip_copy_content"
                        dangerouslySetInnerHTML={createMarkup()}
                    />
                </div>
            </ReactTooltip>
        </div>
    );
};

export default CopyRight;
