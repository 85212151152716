import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as Cogwhell } from "../../img/settings2.svg";
import { ReactComponent as UpdateIcon } from "./img/update.svg";
import cn from "classnames";
import Popup from "../Popup/Popup";
import styles from "./settingMD.module.css";
import useMDSettings from "./useMDSettings";

const SettingMD = ({ className, videoElement, onChangeSettingMediaDevice }) => {
    const [isOpen, setOpen] = useState(false);
    // const [optionsAudioSource, setOptionsAudioSource] = useState([]);
    // const [optionsAudioOutput, setOptionsAudioOutput] = useState([]);
    // const [optionsVideoSource, setOptionsVideoSource] = useState([]);
    // const [defaultSources, setDefaultSources] = useState({})


    const {
        optionsAudioSource,
        setOptionsAudioSource,
        optionsVideoSource,
        setOptionsVideoSource,
        optionsAudioOutput,
        setOptionsAudioOutput,
        defaultSources,
        setDefaultSources,

        getDevices,
        updateDevices,
    } = useMDSettings()

    const openPopup = () => {
        console.log('open settings')
        setOpen(true);
    };
    const closePopup = () => {
        setOpen(false);
    };

    // const getDevices = async () => {
    //     const deviceInfos = await navigator.mediaDevices.enumerateDevices()
    //     console.log('deviceInfos', deviceInfos)
    //     let optionsAudioSource = []
    //     let optionsAudioOutput = []
    //     let optionsVideoSource = []
    //     const storedAudioSource = localStorage.getItem('audioSource')
    //     const storedVideoSource = localStorage.getItem('videoSource')
    //     const defaultValues = {}
    //     for (let i = 0; i !== deviceInfos.length; ++i) {
    //         const deviceInfo = deviceInfos[i];
    //         const option = {};
    //         option.deviceId = deviceInfo.deviceId;
    //         if (deviceInfo.kind === "audioinput") {
    //             if (deviceInfo.deviceId === storedAudioSource) {
    //                 defaultValues.audioSource = deviceInfo.deviceId
    //                 // setDefaultSources({ ...defaultSources, audioSource: deviceInfo.deviceId })
    //             }
    //             option.text =
    //                 deviceInfo.label ||
    //                 `microphone ${optionsAudioSource.length + 1}`;
    //             optionsAudioSource.push(option);
    //         } else if (deviceInfo.kind === "audiooutput") {
    //             option.text =
    //                 deviceInfo.label ||
    //                 `speaker ${optionsAudioOutput.length + 1}`;
    //             optionsAudioOutput.push(option);
    //         } else if (deviceInfo.kind === "videoinput") {
    //             if (deviceInfo.deviceId === storedVideoSource) {
    //                 defaultValues.videoSource = deviceInfo.deviceId
    //                 // setDefaultSources({ ...defaultSources, videoSource: deviceInfo.deviceId })
    //             }
    //             option.text =
    //                 deviceInfo.label || `camera ${optionsAudioOutput.length + 1}`;
    //             optionsVideoSource.push(option);
    //         } else {
    //             console.log("Some other kind of source/device: ", deviceInfo);
    //         }
    //     }
    //
    //     setDefaultSources(defaultValues)
    //     return { optionsAudioSource, optionsAudioOutput, optionsVideoSource }
    // }
    //
    // useEffect(() => {
    //     (async () => {
    //         const newDevices = await getDevices()
    //         setOptionsAudioOutput(newDevices.optionsAudioOutput)
    //         setOptionsAudioSource(newDevices.optionsAudioSource)
    //         setOptionsVideoSource(newDevices.optionsVideoSource)
    //     })()
    // }, [])


    function handleError(error) {
        console.log(
            "navigator.MediaDevices.getUserMedia error: ",
            error.message,
            error.name
        );
    }

    const onSelectChange = ({ target }) => {
      if (!target) {
        return
      }
        const kind = target.id
        const source = target.value

        
        setDefaultSources({...defaultSources, [kind]: source })
        onChangeSettingMediaDevice({ kind, source });
    };

    // const updateDevices = () => {
    //     (async () => {
    //         const newDevices = await getDevices()
    //         setOptionsAudioOutput(newDevices.optionsAudioOutput)
    //         setOptionsAudioSource(newDevices.optionsAudioSource)
    //         setOptionsVideoSource(newDevices.optionsVideoSource)
    //     })()
    // }

    const wrapClass = cn(styles.wrap, className);
    return (
        <div className={wrapClass}>
            <button type="button" onClick={openPopup} className={styles.btn}>
                <Cogwhell className={styles.icon} />
            </button>
            <Popup isOpen={isOpen} onClose={closePopup} title='Настройки'>
                <button
                    onClick={updateDevices}
                    className={cn('button', 'button_no-style', styles.updateDevices)}
                >
                    <UpdateIcon className={styles.iconUpdate} />
                    <span>
                    Обновить список устройств
                    </span>
                    
                </button>
                <form>
                    <div className={styles.selectField}>
                        <label htmlFor="audioSource" className={styles.label}>
                            Микрофон:{" "}
                        </label>

                        <select
                            id="audioSource"
                            value={defaultSources.audioSource || 'default'}
                            onChange={onSelectChange}
                            className={cn(styles.select, 'select-css')}
                        >
                            <option value={'default'} key={'default'} >По-умолчанию</option>
                            {optionsAudioSource.map(
                                (i) => (<option value={i.deviceId} key={i.deviceId}>
                                    {i.text}
                                </option>)
                            )}
                        </select>
                    </div>
                    <div className={styles.selectField}>
                        <label htmlFor="videoSource" className={styles.label}>
                            Источник видео:{" "}
                        </label>

                        <select
                            id="videoSource"
                            onChange={onSelectChange}
                            value={defaultSources.videoSource || 'default'}
                            className={cn(styles.select, 'select-css')}
                            
                        >
                            <option value={'default'} key={'default'} >По-умолчанию</option>
                            {optionsVideoSource.map((i) => (<option value={i.deviceId} key={i.deviceId}>
                                        {i.text}
                                    </option>))
                            }
                        </select>
                    </div>
                </form>
            </Popup>
        </div>
    );
};

export default SettingMD;


/*

<div className={styles.selectField}>
                        <label htmlFor="audioOutput">
                            Аудио-выход:
                        </label>

                        <select
                            id="audioOutput"
                            onChange={onSelectChange}
                            value={optionsAudioOutput}
                            className={styles.select}
                        >
                            <option value={'default'} key={'default'} >По-умолчанию</option>
                            {optionsAudioOutput.map((i) => (<option value={i.deviceId} key={i.deviceId}>
                                        {i.text}
                                    </option>))
                            }
                        </select>
                    </div>


*/                  
