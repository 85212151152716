/**
 * Склонение числительных
 * @param {Number} number число которое склоняем
 * @param {Array} titles массив с вариантами склонения слова,
 * в сторону увеличения
 * declOfNum(1, ['день', 'дня', 'дней'])
 */
export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
    ]
}

export function isLink(link) {
  if (typeof link !== 'string') {
    return false
  }
  return link.indexOf('http') > -1
}

export function pad(n) {
  if (n < 10) return '0' + n
  return n
}

export const AnchorScroller = (href, speed, offsetTop = 0) => {
  // V скорость, может иметь дробное значение через точку


  const V = speed || 0.5
  const headerHeight = offsetTop
  const w = window.pageYOffset // прокрутка
  let t
  let hash
  if (isNaN(href)) {
    hash = href.replace(/[^#]*(.*)/, '$1') // id элемента, к которому нужно перейти
    t =
      document.querySelector(hash).getBoundingClientRect().top -
      headerHeight
  } else {
    t = href - headerHeight
  }


  let start = null
  let progress
  let r

  function step(time) {
    if (start === null) {
      start = time
    }
    progress = time - start
    r =
      t < 0
        ? Math.max(w - progress / V, w + t)
        : Math.min(w + progress / V, w + t)
    window.scrollTo(0, r)
    if (r !== w + t) {
      requestAnimationFrame(step)
    } else {
      // URL с хэшем
    }
  }

  requestAnimationFrame(step)
}


export function sliceDot(str = '') {
  if (str[str.length - 1] === '.') {
    return str.slice(0, -1)
  }
  return str

}

export function isNumber(number) {
  if (typeof number === 'number') {
    return Number.isInteger(number)
  }
  if (typeof number === 'string') {
    return Number.isInteger(Number(number))
  }
  return false
}

export function fastFindByName(stack, name) {
  for (let i = 0; i < stack.length; i++) {
    if (stack[i].name === name) {
      return i
    }
  }
  return -1
}

export function delay(ms = 2000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(1)
    }, ms)
  })
}