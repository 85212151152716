import React, { useState, useEffect } from 'react'
import LoaderSpinner from 'react-loader-spinner'
import get from 'lodash/get'
import { useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import paymentApi from '../api/payment'
import DemoLayout from '../DemoFirstScreen/DemoLayout'
import styles from './paymentStatus.module.css'
import { cancellationReasons } from '../paymentsDictionary.json'
import { formatPhone } from '../utils/phone'
import { delay } from '../utils/util'

const statusText = {
  succeeded: 'оплачен',
  pending: 'в ожидании',
  canceled: 'отменен',
}

const getStatusText = (text) => {
  return statusText[text]
}

const getPrice = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 2,
})

export default function PaymentStatus() {
  const [payment, setPayment] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isCanceled, setCanceled] = useState(false)

  const { franchiseeSlug, paymentId } = useParams()

  useEffect(() => {
    const poolStatus = async () => {
      let status
      while (!status) {
        const paymentStatus = await paymentApi.checkPayment(paymentId)
        setPayment(paymentStatus)
        if (['succeeded', 'canceled'].includes(paymentStatus.status)) {
          status = paymentStatus.status
          setPayment(paymentStatus)
          setLoading(false)
        }
        await delay()
      }
    }
    setLoading(true)

    poolStatus()
  }, [])

  useEffect(() => {
    if (get(payment, 'status') === 'canceled') {
      setCanceled(true)
      setError(cancellationReasons[get(payment, 'cancellation_details.reason')])
    }
  }, [payment])

  return (
    <div>
      <DemoLayout>
        <h2 className={styles.paymentTitle}>
          Ваш заказ {getStatusText(payment.status)}
        </h2>
        <div className={styles.paymentContainer}>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <LoaderSpinner
                type="Watch"
                color="#00BFFF"
                height={80}
                width={80}
              />
            </div>
          ) : (
            <>
              <div>
                <h3 className={styles.paymentDetailTitle} >Детали заказа</h3>
                <p>
                  Заказ на сумму:{' '}
                  <b>{getPrice.format(get(payment, 'amount') || '')} </b>
                </p>
                <p>
                  Телефон:{' '}
                  {<b>{formatPhone((get(payment, 'meta.phone') || ''))} </b>}
                </p>
                <p>
                  Дата оплаты заказа:{' '}
                  <b>
                    {dayjs(get(payment, 'createdAt')).format(
                      'DD.MM.YYYY HH:mm'
                    )}
                  </b>
                </p>
              </div>
              {error && (
                <div>
                  Во время платежа возникла ошибка: "<strong>{error}</strong>"
                </div>
              )}
              <div className={styles.paymentLinks}>
                <Link className={styles.paymentLink} to="/cabinet">
                  Перейти в кабинет ученика
                </Link>
                <Link
                  className={styles.paymentLink}
                  to={`/${franchiseeSlug}/payment`}
                >
                  Экран оплаты
                </Link>
              </div>
            </>
          )}
        </div>
      </DemoLayout>
    </div>
  )
}
