import React, { useState } from 'react'
import { useLocation } from 'react-router'
import FirstScreenLobby from './FirstScreenLobby'
import SecondScreenLobby from './SecondScreenLobby'
import styles from './testLobby.module.scss'


export default function TestLobby( props ){

  return (
    <div className={styles.outerWrapper}>
      <SecondScreenLobby {...props}/>
    </div>
  )
}