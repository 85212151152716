import React from 'react'
import { buttonsType } from "../ButtonsContainer/buttonsType";
import MoreBtn from "../ButtonsContainer/buttons/MoreBtn";
import ViewerReactionBtn from "../ButtonsContainer/buttons/ViewerReactionBtn";
import ChatBtn from "../ButtonsContainer/buttons/ChatBtn";
import ButtonsContainer from "../ButtonsContainer/ButtonsContainer";
import CleanScreenBtn from "../ButtonsContainer/buttons/CleanScreenBtn";
import PencilBtn from "../ButtonsContainer/buttons/PencilBtn";
import PointerBtn from "../ButtonsContainer/buttons/PointerBtn";
import useTools from "../../../../components/Canvas/useTools";


const MainMenu = ({
  disabledButtons = [],
  activeBtn,

  onClickMoreBtn,
  onClickViewerReactionBtn,
  onClickChatBtn,
  onClickCleanScreenBtn,

  unReadMessagesCount,

  socket,
  name,
  task,
}) => {

  const {
    changeTools,
    tools,
  } = useTools({socket, name, task, personType: 'viewer'})

  return (
    <ButtonsContainer>
      <MoreBtn
        isActive={activeBtn === buttonsType.MORE}
        isDisabled={disabledButtons.includes(buttonsType.MORE)}
        onClick={onClickMoreBtn}
      />

      {(task && task?.viewerTools?.pencil || task && task.slide) && (
        <CleanScreenBtn
          isDisabled={disabledButtons.includes(buttonsType.CLEAN_SCREEN)}
          onClick={onClickCleanScreenBtn}
        />
      )}
      {task && task?.viewerTools?.pencil && tools && (
        <PencilBtn
          isDisabled={disabledButtons.includes(buttonsType.PENCIL_TOOL)}
          isActive={tools.pencil}
          onClick={() => changeTools('pencil')}
        />
      )}
      {task && task?.viewerTools?.pointer && tools && (
        <PointerBtn
          isDisabled={disabledButtons.includes(buttonsType.POINTER_TOOL)}
          isActive={tools.pointer}
          onClick={() => changeTools('pointer')}
        />
      )}

      <ViewerReactionBtn
        isActive={activeBtn === buttonsType.VIEWER_REACTIONS}
        isDisabled={disabledButtons.includes(buttonsType.VIEWER_REACTIONS)}
        onClick={onClickViewerReactionBtn}
      />
      <ChatBtn
        isActive={activeBtn === buttonsType.CHAT}
        isDisabled={disabledButtons.includes(buttonsType.CHAT)}
        onClick={onClickChatBtn}
        unReadMessagesCount={unReadMessagesCount}
      />
    </ButtonsContainer>
  )
}

export default MainMenu
