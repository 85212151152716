import React, { useMemo, useState } from "react";
import { buttonsType } from "../ButtonsContainer/buttonsType";
import styles from './menu.module.css'
import MainMenu from "../MainMenu/MainMenu";
import MoreMenu from "../MoreMenu/MoreMenu";
import ViewerReactionsMenu from "../ViewerReactionsMenu/ViewerReactionsMenu";
import DeviceSettingsPopupMenu from "../DeviceSettingsPopupMenu/DeviceSettingsPopupMenu";
import ViewStylePopupMenu from "../ViewStylePopupMenu/ViewStylePopupMenu";
import ChatPopupMenu from "../ChatPopupMenu/ChatPopupMenu";
import Timer from "../Timer/Timer";


const AllMainButtons = [
  buttonsType.CHAT,
  buttonsType.MORE,
  buttonsType.VIEWER_REACTIONS,
  buttonsType.CLEAN_SCREEN,
  buttonsType.PENCIL_TOOL,
  buttonsType.POINTER_TOOL
]

const Menu = ({
  socket,
  viewerSettings = {},
  setViewerSettings,
  name,
  task,
  messages,
  unReadMessages,
  onOpenChat,
  pupilId,
  displayName,
  isChatMuted,
  timer,
  onClickReaction,
  onCleanScreen,
  onChangeSettingMediaDevice,
}) => {
  const [activeBtn, setActiveBtn] = useState('')
  const [disabledMainMenuButtons, setDisabledMainMenuButtons] = useState([])

  const timerPercent = useMemo(() => timer && (timer.time_int ? (timer.count / timer.time_int) * 100 : 100), [timer?.count, timer?.time_int])

  const onClickBtn = (btnName) => {
    if (btnName === activeBtn) {
      setDisabledMainMenuButtons([])
      setActiveBtn('')
      return
    }

    setActiveBtn(btnName)
    if (AllMainButtons.includes(btnName)) {
      setDisabledMainMenuButtons(AllMainButtons.filter(bn => bn !== btnName))
    }

    if (btnName === buttonsType.CHAT && onOpenChat) {
      onOpenChat()
    }
  }

  const onClosePopup = () => {
    if (activeBtn === buttonsType.CHAT && onOpenChat) {
      onOpenChat()
    }

    setDisabledMainMenuButtons([])
    setActiveBtn('')
  }

  const onClickUpdatePage = () => {
    window.location.reload()
  }

  const onMuteOrUnmute = () => {
    if (!socket) {
      return
    }
    socket.emit('room:viewers-settings:viewer:set', { name, viewerSettings:{isSoundMute:!viewerSettings.isSoundMute} })
  }

  const onChangeViewStyle = (viewType) => {
    if (!setViewerSettings) {
      return
    }
    //TODO: viewType, кажется, нужно добавить на сервак при изменении вида как со стороны учителя так и со стороны ученика
    setViewerSettings((prevState) => ({...prevState, viewType}))
  }

  if (activeBtn === buttonsType.SETTINGS) {
    return (
      <DeviceSettingsPopupMenu
        onClose={onClosePopup}
        onChangeSettingMediaDevice={onChangeSettingMediaDevice}
      />
    )
  }

  if (activeBtn === buttonsType.VIEW_STYLE) {
    return (
      <ViewStylePopupMenu
        onClose={onClosePopup}
        onClickBtn={onChangeViewStyle}
        currentView={viewerSettings.viewType}
      />
    )
  }

  if (activeBtn === buttonsType.CHAT) {
    return (
      <ChatPopupMenu
        socket={socket}
        activeBtn={activeBtn}
        disabledButtons={disabledMainMenuButtons}
        onClosePopup={onClosePopup}
        isChatMuted={isChatMuted}
        messages={messages}
        name={name}
        displayName={displayName}
        pupilId={pupilId}
        timerPercent={timerPercent}
        task={task}
      />
    )
  }

  return (
    <div className={styles.container}>
      {timerPercent !== null && timerPercent !== undefined && <Timer timerPercent={timerPercent} />}

      {activeBtn === buttonsType.VIEWER_REACTIONS && <ViewerReactionsMenu
        onClickReaction={onClickReaction}
      />}

      {activeBtn === buttonsType.MORE && <MoreMenu
        viewerSettings={viewerSettings}

        onClickSettingBtn={() => onClickBtn(buttonsType.SETTINGS)}
        onClickUpdateBtn={onClickUpdatePage}
        onClickMicroBtn={onMuteOrUnmute}
        onClickViewStyleBtn={() => onClickBtn(buttonsType.VIEW_STYLE)}
      />}

      <MainMenu
        activeBtn={activeBtn}
        disabledButtons={disabledMainMenuButtons}

        onClickChatBtn={() => onClickBtn(buttonsType.CHAT)}
        onClickMoreBtn={() => onClickBtn(buttonsType.MORE)}
        onClickViewerReactionBtn={() => onClickBtn(buttonsType.VIEWER_REACTIONS)}
        onClickCleanScreenBtn={onCleanScreen}

        unReadMessagesCount={unReadMessages}

        socket={socket}
        name={name}
        task={task}
      />
    </div>
  )
}

export default Menu