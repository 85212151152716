export const magnify = (mainCanvas, container, innerContainer, canvas, zoomRatio) => {
  const getCursorPos = (e) => {
    const event = e || window.event
    const posOfMain = mainCanvas.getBoundingClientRect()
    const x = event.pageX - posOfMain.left
    const y = event.pageY - posOfMain.top

    return { x, y }
  }

  const moveMagnifier = (e) => {
    // e.preventDefault()

    const cursorPos = getCursorPos(e)
    const halfOffsetWidth = container.offsetWidth / 2
    const halfOffsetHeight = container.offsetHeight / 2


    let newX = cursorPos.x
    let newY = cursorPos.y
    // если нужно учесть границы, чтобы лупа не выходила за границы картинки, то раскоменть код ниже
    // if (cursorPos.x > mainCanvas.clientWidth - (halfOffsetWidth / zoomRatio)) {
    //   newX = mainCanvas.clientWidth - (halfOffsetWidth / zoomRatio)
    // }
    // if (cursorPos.x < halfOffsetWidth / zoomRatio) {
    //   newX = halfOffsetWidth / zoomRatio
    // }
    //
    // if (cursorPos.y > mainCanvas.clientHeight - (halfOffsetHeight / zoomRatio)) {
    //   newY = mainCanvas.clientHeight - (halfOffsetHeight / zoomRatio)
    // }
    // if (cursorPos.y < halfOffsetHeight / zoomRatio) {
    //   newY = halfOffsetHeight / zoomRatio
    // }

    container.style.left = (newX - halfOffsetWidth) + 'px'
    container.style.top = (newY - halfOffsetHeight) + 'px'

    innerContainer.style.left =  -1 * ((newX * zoomRatio) - halfOffsetWidth) + 'px'
    innerContainer.style.top = -1 * ((newY * zoomRatio) - halfOffsetHeight) + 'px'
  }

  mainCanvas.addEventListener('mousemove', moveMagnifier)
  container.addEventListener('mousemove', moveMagnifier)

  return {
    destroy: () => {
      mainCanvas.removeEventListener('mousemove', moveMagnifier)
      container.removeEventListener('mousemove', moveMagnifier)
      innerContainer.removeEventListener('mousemove', moveMagnifier)
      canvas.removeEventListener('mousemove', moveMagnifier)
    }
  }
}
