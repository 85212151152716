import superagent from 'superagent'

async function createPayment(payload) {
  const resp = await superagent.post('/api/payment/create').send(payload);
  return resp.body;
}

async function checkPayment(id) {
  const resp = await superagent.get(`/api/payment/check-status/${id}`);
  return resp.body;
}

export default {
  createPayment,
  checkPayment,
}