import React, {useEffect} from 'react';
import io from 'socket.io-client';
import find from 'lodash/find';

import {fastFindByName} from '../utils/util';
import {eventLog} from '../api/apiEventLog';
import {getPupilTasks} from '../api/events';
import {getAccessVectorDirs} from '../api/vectors';

let socket

export default function useTeacherSockets(props) {
  const {
    setConnectionState,
    userMe,
    initSignaling,
    roomId,
    setError,
    setTasks,
    setVectors,
    setSettings,
    setTags,
    setSettingsByTag,
    setMessageHistory,
    setPupilWait,
    setViewers,
    setViewersSettings,
    setViewersNeedHelp,
    setViewersReactions,
    viewersReactions,
    setViewersMuted,
    setViewersChatMuted,
    setRoomStrId,
    setSocket,
  } = props;

  const handleMessageHistory = (data) => {
    setMessageHistory((oldHistory) => {
      return [...oldHistory, data];
    });
  };

  const handleConnectionError = (err) => {
    setConnectionState('disconnected');

    const data = {
      userId: userMe?.id,
      from: 'PresenterScreen',
      action: 'socket connect_error',
      description: err.message,
      meta: {
        connectError: err,
        groupHash: roomId,
        userMe,
      },
    };
    eventLog(data).catch((err) => console.error('eventLog error', err));
  };

  const handleAskNextTask = ({name}) => {
    // TODO
    // мне не нравится это место тут я не понимаю что происхолит
    // а вернее замем мы так тут выкруживаем
    setTasks((tasks) => {
      setVectors((vectors) => {
        const task = tasks[name];

        if (task) {
          const {vectorId, slide} = task;
          const vector = find(vectors, {id: vectorId});

          if (slide < vector.slideTo) {
            const newTask = {
              vectorId,
              slide: slide + 1,
            };
            socket.emit('set-task', {name, task: newTask});
            setTimeout(() => {
              setTasks((tasks) => {
                return {
                  ...tasks,
                  [name]: newTask,
                };
              });
            }, 0);
          }
        }

        return vectors;
      });
      return tasks;
    });
  };

  const handleRoomState = (state) => {
    const {settings, tasks, tags, settingsByTag, chatMessages, roomStrId, viewersSettings} = state;
    console.log();
    setSettings(settings);
    setViewersSettings(viewersSettings)
    setTags(tags);
    setSettingsByTag(settingsByTag);
    setRoomStrId(roomStrId)
    setTasks((oldTasks) => {
      return {
        ...oldTasks,
        ...tasks,
      };
    });
    setMessageHistory(chatMessages);
  };

  const handleViewerCame = async ({name, displayName, pupilId, groupId}) => {
    let events = null
    let accessVectorDirs = null
    if (pupilId) {
      try {
        events = await getPupilTasks(pupilId);
        accessVectorDirs = await getAccessVectorDirs(pupilId);
      } catch (error) {
        console.error('error_to_get_events_or_dirs', error)
      }
    }

    setPupilWait(false);
    setViewers((viewers) => {
      const pos = fastFindByName(viewers, name);
      if (pos > -1) {
        viewers[pos].connectedAt = Date.now();
        viewers[pos].displayName = displayName;
        viewers[pos].pupilId = pupilId;
        viewers[pos].groupId = groupId;
        viewers[pos].events = events;
        viewers[pos].accessVectorDirs = accessVectorDirs;
        return [].concat(viewers);
      }
      return viewers.concat([
        {
          name,
          connectedAt: Date.now(),
          displayName,
          pupilId,
          groupId,
          events,
          accessVectorDirs,
        },
      ]);
    });

    socket.emit('pupil:init-complete', { name })
  };

  const handleViewerLeave = ({name}) => {
    setViewers((viewers) => viewers.filter((i) => i.name !== name));
  };

  const handleAskForHelp = ({name, doNeedHelp, reaction}) => {
    if (doNeedHelp) {
      setViewersNeedHelp((viewersNeedHelp) => {
        if (viewersNeedHelp.indexOf(name) > -1) {
          return viewersNeedHelp;
        }
        return viewersNeedHelp.concat([name]);
      });
    } else {
      setViewersNeedHelp((viewersNeedHelp) =>
        viewersNeedHelp.filter((i) => i !== name)
      );
    }

    setViewersReactions({
      ...viewersReactions,
      [name]: doNeedHelp && reaction,
    });
  };

  const handleAskMute = ({name, isMuted}) => {
    if (isMuted) {
      setViewersMuted((viewersMuted) => {
        if (viewersMuted.indexOf(name) > -1) {
          return viewersMuted;
        }
        return viewersMuted.concat([name]);
      });
    } else {
      setViewersMuted((viewersMuted) => viewersMuted.filter((i) => i !== name));
    }
  };

  const handleAskChatMute = ({name, isChatMuted}) => {
    if (isChatMuted) {
      setViewersChatMuted((viewersChatMuted) => {
        if (viewersChatMuted.indexOf(name) > -1) {
          return viewersChatMuted;
        }
        return viewersChatMuted.concat([name]);
      });
    } else {
      setViewersChatMuted((viewersChatMuted) =>
        viewersChatMuted.filter((i) => i !== name)
      );
    }
  };

  const handleConnectionState = () => {
    setConnectionState('connected');
  };
  const handleConnectionStateDis = () => {
    setConnectionState('disconnected');
  };
  const handlePupilWait = () => {
    setPupilWait(true);
  };

  useEffect(() => {
    socket = io();

    socket.on('connect', handleConnectionState);
    socket.on('reconnect', handleConnectionState);
    socket.on('disconnect', handleConnectionStateDis);
    socket.on('pupil-demo-wait', handlePupilWait);
    socket.on('connect_error', handleConnectionError);
    socket.on('ask-next-task', handleAskNextTask);
    socket.on('room:state', handleRoomState);
    socket.on('viewer:came', handleViewerCame);
    socket.on('viewer:leave', handleViewerLeave);
    socket.on('ask-for-help', handleAskForHelp);
    socket.on('ask-mute', handleAskMute);
    socket.on('ask-chat-mute', handleAskChatMute);
    socket.on('chat_receive_message', handleMessageHistory);

    initSignaling(socket, setError);
    setSocket(socket);

    return () => {
      socket.off('connect_error', handleConnectionError);
      socket.off('ask-next-task', handleAskNextTask);
      socket.off('room:state', handleRoomState);
      socket.off('viewer:came', handleViewerCame);
      socket.off('viewer:leave', handleViewerLeave);
      socket.off('ask-for-help', handleAskForHelp);
      socket.off('ask-mute', handleAskMute);
      socket.off('ask-chat-mute', handleAskChatMute);
      socket.off('chat_receive_message', handleMessageHistory);
      socket.off('connect', handleConnectionState);
      socket.off('reconnect', handleConnectionState);
      socket.off('disconnect', handleConnectionStateDis);
      socket.off('pupil-demo-wait', handlePupilWait);
      socket.close();
      setSocket(null);
    };
  }, [roomId]);
}
