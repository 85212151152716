import React, { useState, useContext, useRef } from 'react'
import { authorize } from '../api/auth'
import { AdminInput } from '../components/AdminInput/AdminInput'
import { UserContext } from '../context/user'
import checkRedirects from './checkRedirects'
import AdminButton from '../components/AdminButton/AdminButton'


export default function LoginComponent({ goToRecoveryState }) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const { user, setUser } = useContext(UserContext)
  const [loginError, setLoginError] = useState(false)
  const [isUserWithoutRoles, setIsUserWithoutRoles] = useState(false)
  const formRef = useRef(null)
  const redirect = checkRedirects(user)
  if (redirect) return redirect

  const onTryToLogin = (e) => {
    if (!formRef.current) return
    e.preventDefault()

    if (!login) {
      setLoginError(true)
      return
    }
    if (!password) {
      setLoginError(true)
      return
    }

    authorize(login, password)
      .then((me) => {
        if (me.roles.length === 0) {
          setIsUserWithoutRoles(true)
          return
        }
        setUser(me)
        console.log(me)
      })
      .catch(() => {
        setLoginError(true)
      })
  }

  const changeInputLogin = (login) => {
    setLoginError(false)
    setIsUserWithoutRoles(false)
    setLogin(login.value)
  }

  const changeInputPassword = (password) => {
    setLoginError(false)
    setIsUserWithoutRoles(false)
    setPassword(password.value)
  }

  return (
    <>
      <h1 className="title-login">Войти</h1>
      <div className="mb-60"/>
      <form className="form-login" onSubmit={onTryToLogin} ref={formRef}>
        <AdminInput type="text" name="username" value={login} onChangeInput={changeInputLogin} isError={loginError}
                    label="Имя пользователя или Email"/>
        <div className="mb-20"/>
        <AdminInput type="password" name="password" value={password} onChangeInput={changeInputPassword}
                    isError={loginError} label="Пароль"/>
        {isUserWithoutRoles && <div className="login-error">
          У вас нет доступа.
          <br/>
          Обратитесь к администратору, чтобы вам выдали доступ.</div>}
        {loginError ? <div className="login-error">Неверный логин и/или пароль</div> : <div className="mb-60"/>}
        <AdminButton
          title="Войти"
          onClick={onTryToLogin}
          fill
        />
        <div className="mb-20"/>
        <button className="link-login" onClick={goToRecoveryState}>Забыли пароль?</button>
      </form>

    </>
  )
}
