export const  buttonsType = {
  CHAT: 'chat-btn',
  MICRO: 'micro-btn',
  MORE: 'more-btn',
  VIEWER_REACTIONS: 'viewer-reactions-btn',
  SETTINGS: 'settings-btn',
  UPDATE: 'update-btn',
  VIEW_STYLE: 'view-style-btn',
  CLEAN_SCREEN: 'clean-screen-btn',

  PENCIL_TOOL: 'pencil-tool-btn',
  POINTER_TOOL: 'pointer-tool-btn',

  VIEWER_REACTION_DEFAULT: 'viewer-reaction-default-btn',
  VIEWER_REACTION_LIKE: 'viewer-reaction-like-btn',
  VIEWER_REACTION_SAD: 'viewer-reaction-sad-btn',
  VIEWER_REACTION_GOOD: 'viewer-reaction-good-btn',
  VIEWER_REACTION_ALARM: 'viewer-reaction-alarm-btn',
}
