import { useEffect } from 'react'

export default function useTimer(socket, name, timer = {}, dispatch, type) {
    let interval;

    const { time_int, count, isStart } = timer;

    useEffect(() => {
        if (time_int && isStart) {
            console.log("START TIMER", name);
            startTimer(timer);
        } else {
            if (typeof time_int === 'undefined' && socket) {
                socket.emit("timer-off", { name });
            }
        }

        return () => clearInterval(interval);
    }, [isStart, time_int]);

    const startTimer = () => {
        const t1 = Date.now() + time_int;

        if (socket && type !== 'viewer') {
            socket.emit("timer-on", { name, t1, time_int });
        }

        interval = setInterval(() => {
            dispatch({
                type: "countUp",
                payload: {
                    name,
                    isStart: true,
                    isFinish: false,
                },
            });
        }, 1000);
    };

    const stopTimer = () => {
        console.log("STOP TIMER", name);
        clearInterval(interval);
        dispatch({
            type: "setTimer",
            payload: { name, isStart: false, isFinish: true },
        });


    };

    // добавить 30 секунд
    const plusTime = (e, time = 0) => {
        e.stopPropagation()
        dispatch({
            type: "plusTime",
            payload: { name, time },
        });
    };

    const minusTime = (e, time = 0) => {
        e.stopPropagation()
        dispatch({
            type: "minusTime",
            payload: { name, time },
        });
    };

    if (count >= time_int && isStart) {
        stopTimer();
    }

    const timerStart = isStart

    // if (count < time_int && !isStart) {
    //     // restart таймера если например после того как он остановился мы добавили 30 сек.
    //     dispatch({
    //         type: "setTimer",
    //         payload: { name, isStart },
    //     });
    // }

    return [startTimer, stopTimer, plusTime, minusTime, timerStart];
}
