import React from "react";
import cn from "classnames";
import { ReactComponent as AvatarFox } from "./img/pupil.svg";

import styles from "./avatar.module.css";

const Avatar = ({ isRound, name, imgSrc, isSpeaking, reaction, noBg, mini }) => {
    if (!name) {
        return null;
    }
    // imgSrc должен быть квадратным
    const firstCharAt = name[0].toUpperCase();
    const nameSplit = name.split("_");
    const bgColor = !noBg && nameSplit[0];

    const clAvatar = cn(styles.avatar, {
        [styles.round]: isRound,
        [styles.isSpeaking]: isSpeaking,
        [styles.mini]: mini,
    });

    const renderImg = (imgSrc) => {
        if (!imgSrc) {
            return <AvatarFox  className={cn({
                [styles.isRoundSvg]: isRound,
                [styles.isNoRoundSvg]: !isRound,
            })} />;
        } else if (isRound) {
            return <img src={imgSrc} alt="" />;
        } else {
            return (
                <svg
                    className={cn({
                        [styles.isRoundSvg]: isRound,
                        [styles.isNoRoundSvg]: !isRound,
                    })}
                    width="64"
                    height="61"
                    viewBox="0 0 64 61"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <path
                        d="M1.01786 15.1769L30.8721 0.942112C32.5891 0.11129 34.5831 0.277458 36.1339 1.27444L63.1633 18.6663C64.4373 19.4971 64.2157 21.3803 62.7756 21.9342L31.4259 33.3996L1.01786 18.4448C-0.36684 17.7801 -0.311452 15.8415 1.01786 15.1769Z"
                        fill="#1566B7"
                    />
                    <mask
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="3"
                        y="12"
                        width="57"
                        height="49"
                    >
                        <path
                            d="M59.393 40.4354C59.393 40.4354 53.5773 38.0537 49.9217 32.6257C49.9217 32.6257 57.2883 24.3728 49.534 13.2952C49.534 13.2952 43.6628 19.0556 42.555 23.5974C42.555 23.5974 40.1733 21.6034 35.7423 20.7726C33.2498 19.111 28.6526 17.2832 27.7664 16.0092C27.7664 16.0092 27.1018 17.0062 27.4895 19.4987L25.274 18.2802C25.274 18.2802 25.0524 19.6095 25.3293 21.4373C24.3877 21.7142 23.3907 21.9912 22.3384 22.4343C22.3384 22.4343 19.0151 17.0616 13.5317 12.9629C13.5317 12.9629 5.445 17.5047 13.144 31.0748C13.144 31.0748 10.3745 36.2259 3.61719 39.66C3.61719 39.66 4.33723 40.8785 5.44499 41.7093L3.61719 42.0971C3.61719 42.0971 12.147 59.4889 30.4804 60.5413C43.1089 61.3167 54.6296 52.8423 58.6176 45.808H56.4021C56.4575 45.808 58.673 43.3156 59.393 40.4354Z"
                            fill="#4DA3F7"
                        />
                    </mask>
                    <g mask="url(#mask0)">
                        <rect
                            x="0"
                            y="0"
                            width="60"
                            height="60"
                            fill="url(#pattern0)"
                        />
                    </g>
                    <path
                        d="M43.6146 19.4414C43.7253 17.5028 38.6297 15.675 32.2046 15.3427C25.7796 15.0104 20.4623 16.2843 20.3516 18.2229C20.3516 18.2783 20.7947 23.5955 20.8501 23.7063C21.3486 24.9802 25.9458 26.1988 31.54 26.5311C37.5219 26.8634 42.5068 25.9772 42.5622 24.5925C42.5622 24.4817 43.6146 19.4968 43.6146 19.4414Z"
                        fill="#3381DC"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.2803 32.6842L4.78482 34.1797L3.8916 33.2865L5.38709 31.791L6.2803 32.6842Z"
                        fill="#1566B7"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.06054 31.9273H2.90039V30.6641H5.06054V31.9273Z"
                        fill="#1566B7"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.38709 30.8565L3.8916 29.361L4.78482 28.4678L6.2803 29.9633L5.38709 30.8565Z"
                        fill="#1566B7"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M57.2861 29.9633L58.7816 28.4678L59.6748 29.361L58.1793 30.8565L57.2861 29.9633Z"
                        fill="#1566B7"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M58.5645 30.6641H60.6692V31.9273H58.5645V30.6641Z"
                        fill="#1566B7"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M58.1793 31.791L59.6748 33.2865L58.7816 34.1797L57.2861 32.6842L58.1793 31.791Z"
                        fill="#1566B7"
                    />
                    <defs>
                        <pattern
                            id="pattern0"
                            patternContentUnits="userSpaceOnUse"
                            width="60"
                            height="60"
                            viewbox="0 0 60 60"
                        >
                            <use xlinkHref="#image0" />
                        </pattern>
                        <image
                            id="image0"
                            width="60"
                            height="60"
                            xlinkHref={imgSrc}
                        />
                    </defs>
                </svg>
            );
        }
    };

    const thema = 'dark'
    return (
        <span
            style={{ backgroundColor: bgColor }}
            className={clAvatar}
            title={name}
        >
            {reaction && (
             
            <span
                className={cn('icon', `icon_${reaction}`, `icon_${thema}`, styles.reaction)}
            />
             
          )}
            {renderImg(imgSrc)}
        </span>
    );
};

Avatar.defaultProps = {
    name: null,
    isRound: true,
    imgSrc: false,
};

export default Avatar;
