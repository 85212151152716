import includes from 'lodash/includes'
import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive/src'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import TestLobby from '../components/TestLobbyView/TestLobby'
import NotNearestLessonLobbyView from '../components/NotNearestLessonLobbyView/NotNearestLessonLobbyView'
import { getNearestLessonByPupilGroup, getNearestLessonByPupil } from '../api/groups'
import { PupilContext } from '../context/user'
import NotFound from '../NotFound'
import checkMobile from '../utils/checkMobile'
import { getLowQualityVideoAndAudioStreams } from '../webrtc'
import pupilgroupApi from '../api/pupilgroup'


export default function LobbyViewer() {

  const urlParams = useParams()
  const location = useLocation();

  const [isMobile, setMobile] = useState(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const [pupilName, setPupilName] = useState('')

  const [microStatus, setMicroStatus] = useState('pending')
  const [cameraStatus, setCameraStatus] = useState('pending')
  const [videoStream, setVideoStream] = useState(null)
  const [nearestLessonHash, setNearestLessonHash] = useState()
  const [nearestLessonLink, setNearestLessonLink] = useState()
  const [getNearestLessonByPupilContext, setGetNearestLessonByPupilContext] = useState(false)
  const [showNotFoundPage, setShowNotFoundPage] = useState(false)
  const [showTestLobbyAnyway, setShowTestLobbyAnyway] = useState(false)

  const audioSource = localStorage.getItem('audioSource')
  const videoSource = localStorage.getItem('videoSource')

  const roomId = urlParams.roomId || urlParams.lessonId //lessonId для демо-урока
  const isFirstLesson = includes(location.pathname, 'first')

  const linkToLesson = !isFirstLesson ? `/lesson/${roomId}` : `/first/${roomId}`
  const { pupil } = useContext(PupilContext)

  useEffect(() => {
    if (!roomId) {
      return
    }
    pupilgroupApi.getPupilByHash(roomId)
      .then(({ pupilFIO }) => setPupilName(pupilFIO))
      .catch(e => {
        e.toString()
      })

    getNearestLessonByPupilGroup(roomId)
      .then(({ hashId }) => {
        setNearestLessonHash(hashId)
      })
      .catch(err => {
        console.log('getNearestLessonByPupilGroup err', err)
        setGetNearestLessonByPupilContext(true)
      })
  }, [urlParams.roomId])

  useEffect(() => {
    console.log('ученик', pupil)
  }, [pupil])

  useEffect(() => {
    if (!getNearestLessonByPupilContext) {
      return
    }

    if (!pupil) {
      setShowNotFoundPage(true)
      return
    }

    getNearestLessonByPupil(pupil.id)
      .then(({ hashId }) => {
        setNearestLessonHash(hashId)
      })
      .catch(err => {
        console.log('getNearestLessonByPupil err', err)
      })
  }, [pupil, getNearestLessonByPupilContext])

  useEffect(() => {
    setNearestLessonLink(`/lesson/${nearestLessonHash}/test`)
  }, [nearestLessonHash])

  useEffect(() => {
    setMobile(checkMobile())
  }, [isDesktopOrLaptop])

  useEffect(() => {
    getLowQualityVideoAndAudioStreams({ audio: audioSource, video: videoSource }, isMobile)
      .then(({ videoStream, audioStream }) => {
        if (videoStream) {
          setVideoStream(videoStream)
        }
        setCameraStatus(videoStream ? 'succeed' : 'failed')
        setMicroStatus(audioStream ? 'succeed' : 'failed')

      })
      .catch((e) => {
        console.log('ERROR', e.toString())
      })
  }, [])

  if (showTestLobbyAnyway) {
    return <TestLobby microStatus={microStatus} cameraStatus={cameraStatus} videoStream={videoStream}
                      linkToLesson={linkToLesson} pupilName={pupilName}/>
  }

  if (isFirstLesson) { //TODO: сделать проверку на беке для демо-урока тоже)
    return <TestLobby microStatus={microStatus} cameraStatus={cameraStatus} videoStream={videoStream}
                      linkToLesson={linkToLesson} pupilName={pupilName}/>
  }

  if (showNotFoundPage){
    return <NotFound/>
  }

  return (
    <>
      {nearestLessonHash === undefined ? <div className="loader"></div> : nearestLessonHash !== roomId ?
        <NotNearestLessonLobbyView lessonLink={nearestLessonLink} setShowTestLobbyAnyway={setShowTestLobbyAnyway}/> :
        <TestLobby microStatus={microStatus} cameraStatus={cameraStatus} videoStream={videoStream}
                   linkToLesson={linkToLesson} pupilName={pupilName}/>}
    </>
  )
}