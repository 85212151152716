import React, { useState, useEffect } from "react";
import cn from "classnames";

import styles from "./popup.module.css";

import { ReactComponent as Close } from "./img/close.svg";

const Popup = ({ isOpen, onClose, secondButton, children, widthInnerAuto, title, className, fixHeight }) => {
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        setIsShow(isOpen);
    }, [isOpen]);

    const handleClose = (e) => {
        setIsShow(false);
        onClose();
    };

    const stopClick = (e) => {
        e.stopPropagation();
    };
    const classWrap = cn(styles.wrap, { [styles.open]: isShow });
    return (
        <div className={classWrap} onClick={handleClose}>
            <div className={cn(styles.inner, className, { [styles.innerAuto]: widthInnerAuto, [styles.innerFixHeigth]: fixHeight })} onClick={stopClick}>
                <button
                    type="button"
                    onClick={handleClose}
                    className={styles.close}
                >
                    <Close />
                </button>
                {
                    title && <div className={styles.title}>{title}</div>
                }
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
};

Popup.defaultProps = {
    onClose: () => {},
};

export default Popup;
