import React, { useState } from 'react'
import { useParams } from 'react-router'
import TestLobby from '../components/TestLobbyView/TestLobby'

export default function LobbyPresenter() {
  const { roomId } = useParams()

  const [microStatus, setMicroStatus] = useState('pending')
  const [cameraStatus, setCameraStatus] = useState('pending')
  const [videoStream, setVideoStream] = useState(null)

  const linkToLesson = `/teacher/${roomId}`

  navigator.mediaDevices.getUserMedia({
    audio: true,
    video: true,
  })
    .then((stream) => {
      setVideoStream(stream)
      setCameraStatus('succeed')
      setMicroStatus('succeed')
    })
    .catch((e) => {
      e.toString()
      setMicroStatus('failed')
      setCameraStatus('failed')
    })
  return (
    <TestLobby microStatus={microStatus} cameraStatus={cameraStatus} videoStream={videoStream} linkToLesson={linkToLesson}/>
  )
}