import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {ReactComponent as CloseIcon} from "./img/close.svg";
import styles from './clock.module.css'
import useSimpleTimer from "../../hooks/useSimpleTimer";


// import styles from "./dropdown.module.css";

function msToTime(duration) {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
  
    return hours + ":" + minutes + ":" + seconds;
  }

const Clock = ({ createdAt, showReminder = true }) => {
    const [milliseconds, setMilliseconds] = useState(0);
    const [showPopupLessonEnd, setShowPopupLessonEnd] = useState(true)

    const [popupSeconds, startPopupTimer, resetPopupTimer, stopPopupTimer ] = useSimpleTimer(5 * 60)

    const date = Date.now() - createdAt
    if (!milliseconds) {
      setMilliseconds(date)
    }

    useEffect(() => {
      if (popupSeconds === 0) {
        setShowPopupLessonEnd(true)
      }
    }, [popupSeconds])

    useEffect(() => {
      
        let interval = null;
        
          interval = setInterval(() => {
            setMilliseconds(milliseconds => milliseconds + 1000);
          }, 1000);
        
        return () => clearInterval(interval);
      }, []);


    const onClosePopupLessonEnd = () => {
      setShowPopupLessonEnd(false)
      resetPopupTimer()
    }

    const REMINDER_TIMEOUT_MS = 50 * 60 * 1000
    return (
      <div>
        <div className={styles.time}>
          {msToTime(milliseconds)}
        </div>
        { showReminder && milliseconds >= REMINDER_TIMEOUT_MS && showPopupLessonEnd &&
          <div className={styles.popapLessonEnd}>
              <div className={styles.popapLessonEndArrow}></div>
              <div className={styles.popapLessonEndText}>Прошло <span>50 минут</span>. Чтобы завершить урок, нажмите эту кнопку</div>
            <button className={styles.closePopap} onClick={onClosePopupLessonEnd}><CloseIcon/></button>
          </div>
        }
      </div>

    );
};

Clock.defaultProps = {
  createdAt: Date.now()
}

export default Clock;
