import React from 'react'

import styles from './radioButton.module.sass'


const RadioButton = ({ onChange, id, name, value, checked, text }) => {
  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <label className={styles.radioWrapper} htmlFor={id}>
      <input id={id} name={name} type="radio" checked={checked} value={value} onChange={handleChange}/>
      <div>{text}</div>
    </label>
  )
}

export default RadioButton
