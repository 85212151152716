import React from 'react'
import ButtonUpdate from "../ButtonUpdate/ButtonUpdate";
import styles from './desktopControlsNext.module.css'
import cn from "classnames";
import ButtonView from "../ButtonView/ButtonView";
import ButtonTheme from "../ButtonTheme/ButtonTheme";
import ButtonSettings from "../ButtonSettings/ButtonSettings";
import ButtonMicro from "../ButtonMicro/ButtonMicro";
import ButtonEraser from "../ButtonEraser/ButtonEraser";
import ButtonDoneNext from "../ButtonDoneNext/ButtonDone";
import CleanScreenBtn from "../../ViewerScreenMobile/components/ButtonsContainer/buttons/CleanScreenBtn";
import useTools from "../../../components/Canvas/useTools";
import PencilBtn from "../../ViewerScreenMobile/components/ButtonsContainer/buttons/PencilBtn";
import PointerBtn from "../../ViewerScreenMobile/components/ButtonsContainer/buttons/PointerBtn";
import MagnifierBtn from "../../ViewerScreenMobile/components/ButtonsContainer/buttons/MagnifierBtn";


const DesktopControlsNext = ({
  currentView, //gallery, mosaic, report
  gotoNextView = () => {
  },
  theme, //light, dark
  handlerUpdate = () => {
  },
  updateButtonDisabled,
  changeTheme = () => {
  },
  onChangeSettingMediaDevice = () => {
  },
  remoteVideoRef = {},
  isMicroMute,
  muteMicroHandler = () => {
  },
  onClickEraser = () => {
  },
  doneNextTimer,
  isNeedHelp,
  onNeedHelp = () => {
  },
  reaction,

  task,
  socket,
  name,

  ...props
}) => {

  const {
    changeTools,
    tools,
  } = useTools({socket, name, task, personType: 'viewer'})

console.log("isMicroMute",isMicroMute);
  return <div className={ styles.wrap }>
    <div className={ styles.container } { ...props }>
      <div className={ styles.innerContainer }>
        <ButtonUpdate theme={ theme } onClick={ handlerUpdate } disabled={ updateButtonDisabled }/>
        <ButtonView onClick={ gotoNextView } currentView={ currentView } theme={ theme }/>
        <ButtonTheme theme={ theme } onClick={ changeTheme }/>
        <ButtonSettings theme={ theme } onChangeSettingMediaDevice={ onChangeSettingMediaDevice }
                        remoteVideoRef={ remoteVideoRef }/>
      </div>
      <div className={ styles.innerContainer }>

        {task && task?.viewerTools?.pointer && tools && (
          <PointerBtn
            isActive={tools.pointer}
            onClick={() => changeTools('pointer')}
          />
        )}
        {task && task?.viewerTools?.pencil && tools && (
          <PencilBtn
            isActive={tools.pencil}
            onClick={() => changeTools('pencil')}
          />
        )}
        {task && task?.viewerTools && tools && (
          <CleanScreenBtn
            onClick={onClickEraser}
          />
        )}
        { task && task?.viewerTools?.magnifier && tools && (
          <MagnifierBtn
            isActive={tools.magnifier}
            onClick={() => changeTools('magnifier')}
          />
        ) }

        <ButtonMicro isMute={ isMicroMute } onClick={muteMicroHandler}/>
        {/*<ButtonEraser onClick={ onClickEraser }/>*/}
        <ButtonDoneNext
          className={ cn(styles.controlOfButtonDoneNext) }
          reaction={ reaction }
          handleClick={ onNeedHelp }
          active={ isNeedHelp }
          timer={ doneNextTimer }
          thema={ theme }
        />
      </div>
    </div>
  </div>
}

export default DesktopControlsNext
