export const viewTypes = {
  GALLERY: 'gallery', //Режим задания: хорошо видно задание
  MOSAIC: 'mosaic', //Режим беседа: видно задание и видео
  REPORT: 'report', //Режим видео: хорошо видно видео учителя
}

export const viewValues = [
  {
    value: 'gallery',
    label: 'Галерея',
  },
  {
    value: 'mosaic',
    label: 'Мозаика',
  },
  {
    value: 'report',
    label: 'Учитель',
  },
]