import cn from 'classnames'
import React from 'react'
import styles from './messageFox.module.scss'

export default function MessageFox ({count, onClick, isShow, message, author}) {
  const getTextToShow = (text) => {
    if (!text) {
      return ''
    }

    return text.split(' ').map((word, index) => {
      let space = index > 0 ? ' ' : ''
      if (word.startsWith("@")) {
        return <span className={styles.title}>{space + word.slice(1)}</span>
      } else return space + word
    })
  }

  return (
    <div className={cn(styles.wrapper, {
      [styles.isShow]: isShow
    })}>
      <div className={styles.messageWrapper}>
          <div className={styles.title}>
            Тебе пишет учитель, открой чат!
          </div>
          <div className={styles.message}>
              {author}: {getTextToShow(message)}
          </div>
      </div>
      <div className={styles.foxImage}/>
    </div>
  )
}