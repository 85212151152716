import cn from 'classnames'
import React from 'react'
import { ReactComponent as IconChat } from '../../img/chat_icon.svg'
import styles from './buttonChat.module.scss'
import stylesNext from '../../viewerScreenNext.module.css'
import knockAnimation from './img/animation.gif'


export default function ButtonChat({ theme = 'dark', onClick, count = 0 }) {

  return (
    <div
      className={cn('button', 'button_no-style', styles.button, styles[theme])}
      onClick={onClick}
      style={{
        width: 26,
        height: 26,
      }}
      data-tip={'Чат'}
      data-for="global"
    >
      <IconChat className={cn('icon', styles.icon, stylesNext.controlIcon)}/>
      {count > 0 && <div className={styles.counter}>{count}</div>}
    </div>
  )
}