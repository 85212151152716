import superagent from 'superagent'

export async function authorize(username, password) {
  const resp = await superagent
    .post('/api/user/authorize')
    .type('form')
    .send({ username, password })
  return resp.body
}

export async function fetchMe() {
  try {
    const resp = await superagent.get('/api/user/me')
    const _user = resp.body
    return _user
  } catch (e) {
    return null
  }
}

export async function fetchPupilMe() {
  try {
    const resp = await superagent.get('/api/pupil/me')
    const _user = resp.body
    return _user
  } catch (e) {
    return null
  }
}

export async function logout() {
  try {
    await superagent.post('/api/user/logout')
    window.location.reload()
  } catch (e) {
    return null
  }
}

export async function logoutPupil() {
  try {
    await superagent.post('/api/pupil/logout')
    window.location.reload()
  } catch (e) {
    return null
  }
}

export async function requestRecoveryEmail(payload) {
  try {
    console.log('запрашиваем отправку email')
    console.log('@payload', payload)
    const resp = await superagent.post('/api/user/send-recovery-email').send(payload)
    return resp.body
  } catch (e) {
    console.error(e)
    return null
  }
}
