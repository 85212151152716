import React, { useRef, useEffect } from 'react'
import cn from 'classnames'

import dragResize from '../../utils/dragResize'

import styles from './curtain.module.css'


const Curtain = ({
  direction,
  className,
  isShow,
  socket,
  initialState, //if largeTaskModal was closed with curtain active, state will be restored from w & h
  maxWidth,
  maxHeight,
  name,
  task,
  type,
  ctx,
  readOnly = false
}) => {
  const curtainRef = useRef(null)
  const classWrap = cn(
    styles.curtain,
    className,
    styles[`curtain_${direction}`],
    { [styles.isShow]: isShow },
  )


  useEffect(() => {
    if (!curtainRef.current || !socket || !name || !ctx) return

    curtainRef.current.style = {}

    let dragResizeObj
    if (!readOnly) {

      dragResizeObj = dragResize({
        element: curtainRef.current,
        direction,
        onChangeSize: (widthCurtain, heightCurtain) => {
          const imgWH = ctx.canvas2img(widthCurtain, heightCurtain)

          socket.emit(`canvas:${type}:curtain-resize`, {
            name,
            task,
            widthCurtain: imgWH[0],
            heightCurtain: imgWH[1],
          })
        },
      })
    }

    const curtainResize = ({ name: userId, widthCurtain, heightCurtain }) => {
      if (name !== userId)
        return

      try {
        const canvasWH = ctx.img2canvas(widthCurtain, heightCurtain)

        if (direction === 'top' || direction === 'bottom') {
          curtainRef.current.style.height = canvasWH[1] + 'px'
          curtainRef.current.style.width = '100%'
        }

        if (direction === 'right' || direction === 'left') {
          curtainRef.current.style.width = canvasWH[0] + 'px'
          curtainRef.current.style.height = '100%'
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (socket) {
      if (type === 'pupil') {
        socket.on('canvas:teacher:curtain-resize', curtainResize)
      }
      if (type === 'teacher') {
        socket.on('canvas:pupil:curtain-resize', curtainResize)
      }
      if (type === 'supervisor') {
        socket.on('canvas:teacher:curtain-resize', curtainResize)
        socket.on('canvas:pupil:curtain-resize', curtainResize)
      }
    }

    const { width, height } = initialState

    if (curtainRef.current && (width || height)) {
      window.requestAnimationFrame(() => curtainResize({ name, heightCurtain: initialState.height, widthCurtain: initialState.width }))
    }

    return () => {
      console.log('clear socket')

      socket.off('canvas:teacher:curtain-resize', curtainResize)
      socket.off('canvas:pupil:curtain-resize', curtainResize)

      if (dragResizeObj) {
        dragResizeObj.makeDragFalse()
      }
    }
  }, [socket, name, curtainRef, direction, maxWidth, maxHeight, ctx, initialState])

  useEffect(() => {
    if (!curtainRef.current) return

    if (!isShow) {
      curtainRef.current.style = {}
    }
  }, [curtainRef, isShow])

  return <div className={classWrap} ref={curtainRef}/>
}

Curtain.defaultProps = {
  direction: 'top',
}

export default Curtain
