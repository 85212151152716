import React, { useRef, useState } from "react";
import cn from "classnames";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import styles from "./dropmenu.module.css";

const DropMenu = ({ children, slotToggle, thema, toggleClass, position, classOpen, classInner, 'data-tip': dataTip , 'data-for': dataFor}) => {
    const [isOpen, setOpen] = useState(false);
    const wrapRef = useRef(null);

    const handleToggle = () => {
        setOpen(!isOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useOnClickOutside({ current: wrapRef.current }, handleClose);
    
    return (
        <div
            className={cn(styles.wrap, styles[`wrap_${thema}`], styles[`wrap_${position}`], {
                [styles.open]: isOpen,
                [`${classOpen}`]: isOpen,
            })}
            ref={wrapRef}
            data-tip={dataTip}
            data-for={dataFor}

        >
            {isOpen && <div className={cn(styles.innerWrap, classInner)}>
                <div className={styles.inner}>{children}</div>
            </div>}
            <button
                className={cn(styles.toggle, "button", "button_no-style", toggleClass)}
                onClick={handleToggle}
            >
                {slotToggle}
            </button>
        </div>
    );
};

export default DropMenu;
