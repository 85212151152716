import superagent from 'superagent'

export async function createGrade(payload) {
  try {
    const { body } = await superagent.post('/api/grade').send(payload)
    return [null, body.result, body]
  } catch (e) {
    return [e]
  }
}

export async function getGradesByVector(pupilId, vectorId) {
  try {
    const { body } = await superagent.get(`/api/grade/by-vector/${pupilId}/${vectorId}`)
    return [null, body]
  } catch (error) {
    return [error]
  }
}

export async function getGradesByTask(pupilId, vectorStrId, taskId) {
  try {
    const { body } = await superagent.get(`/api/grade/by-task/${pupilId}/${vectorStrId}/${taskId}`)
    return [null, body]
  } catch (error) {
    return [error]
  }
}

export async function getGradesByGroup(groupId) {
  try {
    const { body } = await superagent.get(`/api/grade/by-group/${groupId}`)
    return [null, body]
  } catch (error) {
    return [error]
  }
}
