// https://codepen.io/toddwebdev/pen/yExKoj
export default function (slider, wrap) {
    let isDown = false;
    let startX;
    let scrollLeft;
    const li = slider.querySelector("li");

    const widthLi = li.offsetWidth;

    const mouseDown = (e) => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;

        slider.addEventListener("mouseleave", clear);
        slider.addEventListener("mouseup", clear);
        slider.addEventListener("mousemove", mouseMove);
    };

    const mouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        console.log(walk);
    };

    const clear = () => {
        isDown = false;

        slider.removeEventListener("mouseleave", clear);
        slider.removeEventListener("mouseup", clear);
        slider.removeEventListener("mousemove", mouseMove);
    };
    slider.onmousedown = mouseDown;

    return {
        destroy: () => {
            slider.onmousedown = null;
            slider.onload = null;
            slider.removeEventListener("mouseleave", clear);
            slider.removeEventListener("mouseup", clear);
            slider.removeEventListener("mousemove", mouseMove);
        },
        scrollToSelected: (index) => {
            const indexNext = index ? index - 1 : 0
            slider.scrollLeft = indexNext * widthLi;
        },
    };
}
