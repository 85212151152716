import React, { useState, useEffect, useRef } from 'react'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import styles from './epub.module.css'
import canvasStyles from '../Canvas/canvas.module.scss'

import { ReactComponent as PointerIcon } from './img/pointer.svg'
import { ReactComponent as PointerActiveIcon } from './img/pointer-active.svg'
import { ReactComponent as PointerBySelectIcon } from './img/pointer-by-select.svg'
import { ReactComponent as PointerBySelectActiveIcon } from './img/pointer-by-select-active.svg'
import { ReactComponent as Rub } from "../Canvas/img/rub.svg";
import CallToSupportImg from '../../img/call_to_support.png'
import useHightLight from "../../hooks/useHightLight";
import CurtainMenu from "../CurtainMenu/CurtainMenu";


const Epub = ({
  task,
  socket,
  type,
  name,
  screenSizeButton,
  infoTooltip,
  needCallToSupport = false,
  callToSupportBtn,
  readOnly = false,
  isPreview = false,
}) => {
  const epubRef = useRef()

  const [showButtons, setShowButtons] = useState(false)
  const [epubContent, setEpubContent] = useState({ dataStr: '', charCount: 0 })

  useEffect(() => {
    if (!task?.slide) {
      return
    }

    let index = 0
    const epubContentForSet = task.slide.replaceAll(/<[^<>]+>|([А-я])\u0301|./gu, (substring, args) => {
      let res = substring
      if (!(substring.length > 1 && substring[0] === '<')) {
        res = `<span class="char char_${index}" id="char_${index}" data-index="${index}">${substring}</span>`
        index++
      }

      return res
    })

    setEpubContent({ dataStr: epubContentForSet, charCount: index + 1 })

    return () => { setEpubContent({ dataStr: '', charCount: 0 }) }
  }, [task?.slide])


  const {
    instrument: mode,
    setInstrument: setMode,
    pointerState,
    setCurtainDirection,
    curtainDirection,
  } = useHightLight(epubRef, socket, name, task, type, isPreview, epubContent.charCount)

  useEffect(() => {
    if ((type === 'presenter' || type === 'supervisor') && !isPreview) {
      setShowButtons(true)
    }
  }, [type])

  useEffect(() => {
    const timer = setTimeout(() => {
      const teacherEpub = document.querySelector('.b-teacher-epub')
      if (teacherEpub) {
        teacherEpub.querySelectorAll('.epub-mark').forEach(item => {
          item.style.textDecoration = 'underline'
          item.style.textDecorationColor = 'red'
        })
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [])

  const classWrap = cn(canvasStyles.wrap, {
    [styles[`wrap_${ mode }`]]: mode,
  })

  const getTooltip = (id) => {
    return <ReactTooltip
      id={ id }
      className={ styles.tooltip }
      place="right"
      textColor="#393F61"
      backgroundColor="#fff"
      aria-haspopup="true"
    />
  }
  const handleMode = (value) => {
    if (readOnly) {
      return
    }

    if (pointerState) {
      pointerState.setInstrument(value)
      setMode(value)
    }
    socket.emit('epub:teacher:mode', {name, task, mode: value})
  }

  const handleClear = () => {
    if (readOnly) {
      return
    }

    socket.emit('epub:presenter:clear-all', {name, task})
  }

  const handleChangeCurtainDirection = (direction) => {
    setCurtainDirection(direction)
    if (pointerState) {
      pointerState.setCurtainDirection(direction)
    }

    socket.emit('epub:presenter:curtain-direction', { name, task, direction })
  }

  return (
    <div className={ classWrap }>
      {showButtons && <div className={ canvasStyles.buttons }>
        <button onClick={ () => handleMode('pointerBySelect') }
                                                  className={ cn(canvasStyles.modeOptionBtn, {[canvasStyles.selected]: mode === 'pointerBySelect'}) }
                                                  data-tip="Инструмент «Указка выделением»" data-for="pointerBySelect">
          { mode === 'pointerBySelect' ? <PointerActiveIcon/> : <PointerIcon/> }
        </button>
        { getTooltip('pointerBySelect') }

        <button onClick={ () => handleMode('pointer') }
                                                  className={ cn(canvasStyles.modeOptionBtn, {[canvasStyles.selected]: mode === 'pointer'}) }
                                                  data-tip="Инструмент «Указка»" data-for="pointer">
          { mode === 'pointer' ? <PointerBySelectActiveIcon/> : <PointerBySelectIcon/>  }
          { getTooltip('pointer') }
        </button>

        <button
          className={ canvasStyles.modeOptionBtn } onClick={ handleClear } disabled={ false }
          data-tip="Инструмент «Ластик»" data-for="rub">
          <Rub/>
          { getTooltip('rub') }
        </button>

        <CurtainMenu
          slideType={'epub'}
          readOnly={readOnly}
          currentDirection={curtainDirection}
          handleMode={ () => handleMode('curtain') }
          selected={ mode === 'curtain' }
          handleChangeCurtainDirection={handleChangeCurtainDirection}
          handleClear={() => handleMode('')}
        />

        { screenSizeButton }
        { callToSupportBtn }
        { infoTooltip }
      </div> }
      <div className={ cn({[styles.imgContainer]: type === 'presenter'}) }>
        <div className={ cn({[styles.inner]: type === 'presenter'}) }>
          { needCallToSupport && <img src={CallToSupportImg} className={styles.callToSupportImg} /> }
          <div className={ cn("b-teacher-epub", {['pupil-epub']: type === 'viewer', [styles.hide]: needCallToSupport}) } ref={ epubRef }>
            <div
              className={ cn("b-teacher-epub_content slide-content b-teacher-epub_content__pupil",
                {['b-teacher-epub-with-select']: mode === 'pointerBySelect'}) }
              dangerouslySetInnerHTML={ {__html: epubContent.dataStr} }
            />
          </div>
        </div>
      </div>
    </div>
  )
}


export default Epub
