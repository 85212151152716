import React, { useState, useEffect, useRef } from "react"
import cn from "classnames"

import ReactTooltip from "react-tooltip"
import SelectTask from "../../../components/SelectTask/SelectTask"
import TaskReview from "../TaskReview/TaskReview"

import { ReactComponent as Close } from "./img/close.svg"
import { ReactComponent as Timer } from "./img/timer.svg"
import { ReactComponent as FullScreen } from "./img/full-screen.svg"
import { ReactComponent as ModalScreen } from "./img/modal-screen.svg"
import { ReactComponent as Info } from "./img/info.svg"

import styles from "./largeTaskModal.module.scss"
import presenterScreenStyles from "../../presenterScreen.module.scss"
import { getImgUrl } from "../../../func"
import dragndropElement from "../../../utils/dragndropElement"

import Canvas from "../../../components/Canvas/Canvas"

import Epub from "../../../components/Epub/Epub"
import CallToSupportBtn from "../CallToSupportBtn/CallToSupportBtn"

const LargeTaskModal = ({
 task,
 onClose,
 pupil,
 timer,
 setOpenedGradeModal,
 grade = {},
 updateGrades,
 lastBreadCrumbs,
 roomStrId,
 vectorDirs,
 onChangeTask,
 socket,
 vectors,
 group,
 vectorTasks,
 setVectorTasks,
 onClickShowCallToSupport,
 viewerSettings,
}) => {
 const [fullScreen, setFullScreen] = useState(false)
 const [changeSize, setChangeSize] = useState(false)
 const [sizeModal, setSizeModal] = useState(null)
 const elDragWrap = useRef(null)
 const elDragZone = useRef(null)
 const elDragZoneBottom = useRef(null)

 const classNames = cn(styles.wrap, {
  [styles.isShow]: task,
  [styles.fullScreen]: fullScreen,
 })

 useEffect(() => {
  if (!elDragZone.current || !elDragWrap.current) {
   return
  }
  dragndropElement(elDragWrap.current, elDragZone.current)
  const onKeyDown = (e) => {
   const keyCode = e.keyCode
   if (keyCode === 27) {
    handleClose()
   }
  }
  if (task) {
   document.addEventListener("keydown", onKeyDown)
  }
  return () => {
   document.removeEventListener("keydown", onKeyDown)
  }
 }, [elDragWrap, elDragZone, task])

 useEffect(() => {
  if (!elDragZoneBottom.current || !elDragWrap.current) {
   return
  }
  dragndropElement(elDragWrap.current, elDragZoneBottom.current)
 }, [elDragZoneBottom])

 const saveModalSizes = () => {
  const largeModal = document.getElementById("large-modal")
  let largeWidth = largeModal.offsetWidth
  let largeHeight = largeModal.offsetHeight

  localStorage.setItem("modalWidth", largeWidth)
  localStorage.setItem("modalHeight", largeHeight)
  localStorage.setItem("changeSize", true)

  setSizeModal({ width: `${largeWidth}px`, height: `${largeHeight}px` })
 }

 const handleClose = () => {
  if (!fullScreen) {
   saveModalSizes()
  }

  onClose()
 }

 const onChangeFullScreen = () => {
  if (!fullScreen) {
   saveModalSizes()
  }

  setFullScreen(!fullScreen)
 }

 const getScreenSizeButton = () => {
  return (
   <button
    className={styles.modeOptionBtn}
    onClick={onChangeFullScreen}
    data-tip={
     fullScreen ? "Вернуться в обычный режим" : "Открыть на полный экран"
    }
    data-for='full-screen'
   >
    {fullScreen ? <ModalScreen /> : <FullScreen />}
    <ReactTooltip
     id='full-screen'
     className={presenterScreenStyles.tooltip}
     place='right'
     textColor='#393F61'
     backgroundColor='#fff'
     aria-haspopup='true'
    />
   </button>
  )
 }

 const getInfoTooltip = () => {
  return (
   <div
    className={cn(styles.modeOptionBtn, styles.infoTooltip)}
    data-tip={
     lastBreadCrumbs
      ? lastBreadCrumbs.join(" > ")
      : "Нет данных о предыдущем задании"
    }
    data-for='info'
   >
    <Info />
    <ReactTooltip
     id='info'
     className={presenterScreenStyles.tooltip}
     place='right'
     textColor='#393F61'
     backgroundColor='#fff'
     aria-haspopup='true'
     multiline={true}
    />
   </div>
  )
 }

 let imgStyle = null
 if (task && task.type !== "giveIqTokens" && (!task.slide || task.file)) {
  imgStyle = getImgUrl(task, true)
 }

 useEffect(() => {
  if (localStorage.getItem("changeSize")) {
   setChangeSize(true)
   setSizeModal({
    width: `${localStorage.getItem("modalWidth")}px`,
    height: `${localStorage.getItem("modalHeight")}px`,
   })
  }
 }, [task])

 const getStyleForModal = () => {
  return fullScreen
   ? { width: "100vw", height: "100vh", resize: "none" }
   : changeSize
   ? { width: sizeModal.width, height: sizeModal.height }
   : {}
 }
 return (
  <div
   className={classNames}
   ref={elDragWrap}
   id={"large-modal"}
   style={getStyleForModal()}
  >
   <div className={styles.top}>
    <div ref={elDragZone} className={styles.dragZone} />
    <button onClick={handleClose} className={styles.close}>
     <Close />
    </button>
   </div>
   <div className={cn(styles.timer, { [styles.started]: timer })}>
    <div className={styles.time} data-tip='Таймер задания' data-for='timer'>
     {timer && timer.value}
    </div>
    <Timer
     className={styles.timerIcon}
     data-tip='Таймер задания'
     data-for='timer'
    />
    <ReactTooltip
     id='timer'
     className={presenterScreenStyles.tooltip}
     place='right'
     textColor='#393F61'
     backgroundColor='#fff'
     aria-haspopup='true'
    />
   </div>
   <div className={styles.taskContent}>
    <div className={styles.content}>
     {task && task.slide && (
      <Epub
       socket={socket}
       task={task}
       type={"presenter"}
       name={pupil.name}
       isPreview={false}
       readOnly={false}
       screenSizeButton={getScreenSizeButton()}
       infoTooltip={getInfoTooltip()}
       callToSupportBtn={
        <CallToSupportBtn
         onClick={onClickShowCallToSupport}
         isPressed={viewerSettings?.needCallToSupport}
        />
       }
       needCallToSupport={viewerSettings?.needCallToSupport}
      />
     )}
     {imgStyle && (
      <Canvas
       color='#4DA3F7'
       type='teacher'
       pic={imgStyle}
       socket={socket}
       name={pupil.name}
       showButtons={true}
       modal
       task={task}
       screenSizeButton={getScreenSizeButton()}
       infoTooltip={getInfoTooltip()}
       callToSupportBtn={
        <CallToSupportBtn
         onClick={onClickShowCallToSupport}
         isPressed={viewerSettings?.needCallToSupport}
        />
       }
       needCallToSupport={viewerSettings?.needCallToSupport}
      />
     )}
     {task && task.type === "giveIqTokens" && <img src={task.src} />}
    </div>
    {task && (
     <div className={styles.taskDescription}>
      {task && (
       <div className={styles.taskSelectContainer}>
        <SelectTask
         task={task}
         classSelect={styles.select}
         keyEvent
         hideBreadcrumbs
         LargeTaskModal
         names={[pupil.name]}
         isLargeTask={true}
         vectorDirs={vectorDirs}
         onChangeTask={onChangeTask}
         socket={socket}
         vectors={vectors}
         group={group}
         vectorTasks={vectorTasks}
         setVectorTasks={setVectorTasks}
        />
       </div>
      )}
      <div className={styles.descriptionWrap}>
       <div className={styles.description}>
        <h3 className={styles.taskName}>{task.name}</h3>
        <div
         className={styles.teacherDesc}
         dangerouslySetInnerHTML={{ __html: task.teacherDesc }}
        />
        {task.answer && (
         <>
          <h3 className={styles.taskAnswer}>Правильный ответ:</h3>
          <b dangerouslySetInnerHTML={{ __html: task.answer }}></b>
         </>
        )}
       </div>
       <TaskReview
        className={styles.review}
        pupil={pupil}
        task={task}
        closeGradeModal={() => setOpenedGradeModal(null)}
        grade={grade}
        updateGrades={updateGrades}
        roomStrId={roomStrId}
        socket={socket}
        onChangeTask={onChangeTask}
       />
      </div>
     </div>
    )}
   </div>
   <div
    ref={elDragZoneBottom}
    className={styles.dragZone}
    style={{ height: "13px" }}
   />
  </div>
 )
}

export default LargeTaskModal
