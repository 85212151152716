import React from 'react'
import styles from './task.module.css'
import callToSupportImg from "../../../../img/call_to_support.png";
import bigLessonSoonImg from "../../../../img/big_lessonSoon.jpg";
import { getAudioUrl, getImgUrl, getVideoUrl } from "../../../../func";
import { ReactComponent as IconBgTask } from '../../../img/bg-task.svg'
import cn from "classnames";
import CopyRight from "../../../../components/CopyRight/CopyRight";
import VideoTask from "../../../components/VideoTask/VideoTask";
import AudioTask from "../../../components/AudioTask/AudioTask";
import Epub from "../../../../components/Epub/Epub";
import Canvas from "../../../../components/Canvas/Canvas";
import emptyCanvasTask from "../../../../utils/emptyCanvasTask";


const canvasColor = '#ff2b2b'

const Task = ({viewerSettings, connectionState, error, socket, name, task, settings, hide}) => {
  //TODO: возможно лучше сделать через useMemo
  let imgStyle = null
  if (task && task.type !== 'giveIqTokens' && (!task.slide || task.file)) {
    imgStyle = getImgUrl(task)
  }

  return (
    <div className={cn(styles.container, {[styles.hide]: hide})}>
      {(viewerSettings.needCallToSupport || connectionState === 'disconnected' || error) &&
        <div className={styles.img}><img src={callToSupportImg} className={styles.img}/></div>}
      {!settings?.startedAt && <img src={bigLessonSoonImg} className={styles.img}/>}

      {!task && settings?.startedAt && <IconBgTask className={cn('position-center')}/>}
      {task && task.type === 'giveIqTokens' && (
        <img src={task.src} style={{
          height: '100%',
          maxWidth: '100%',
          position: 'absolute',
          margin: 'auto',
          left: 0,
          right: 0
        }}
        />
      )}
      {task && task.copyright && (
        <CopyRight
          isShowMobileMenu={true}
          isDesktopOrLaptop={false}
          html={task.copyright}
        />
      )}
      {task && task.video && (
        <VideoTask
          src={getVideoUrl(task)}
          socket={socket}
          name={name}
        />
      )}
      {task && task.audio && <AudioTask
        src={getAudioUrl(task)}
        name={name}
        socket={socket}
      />}
      {task && task.slide && !imgStyle && !viewerSettings.emptyCanvasTask && (
        <Epub
          socket={socket}
          name={name}
          type={'viewer'}
          task={task}
        />
      )}
      {(imgStyle || viewerSettings.emptyCanvasTask) && (
        <Canvas
          color={canvasColor}
          type="pupil"
          task={viewerSettings.emptyCanvasTask ? emptyCanvasTask : task}
          socket={socket}
          name={name}
          pic={viewerSettings.emptyCanvasTask ? emptyCanvasTask.src : imgStyle}
        />
      )}
    </div>
  )
}


export default Task
