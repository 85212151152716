import React from 'react'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as IconAudio } from '../img/audio.svg'
import { ReactComponent as IconAudioOff } from '../img/audio-off.svg'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import styles from './buttons.module.sass'


const ButtonAudio = ({ isMuted, mute }) => {
  return (
    <div className={styles.button} onClick={mute} data-for="audio" data-tip="Вкл./Выкл. микрофон учителя">
      {isMuted ? <IconAudioOff data-tip="Вкл./Выкл. микрофон учителя" data-for="audio"/> :
        <IconAudio data-tip="Вкл./Выкл. микрофон учителя" data-for="audio"/>}
      <ReactTooltip
        id="audio"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}

export default ButtonAudio

