import React from "react";

import styles from './progressRing.module.css'

class ProgressRing extends React.Component {
    constructor(props) {
        super(props);

        const { radius, stroke } = this.props;

        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render() {
        const { radius, stroke, progress, color } = this.props;
        const strokeDashoffset =
            this.circumference - (progress / 100) * this.circumference;

        return (
            <div className={styles.wrap}>
                <svg height={radius * 2} width={radius * 2}>
                    <circle
                        stroke={color}
                        fill="transparent"
                        strokeWidth={stroke}
                        strokeDasharray={
                            this.circumference + " " + this.circumference
                        }
                        style={{ strokeDashoffset }}
                        strokeWidth={stroke}
                        r={this.normalizedRadius}
                        cx={radius}
                        cy={radius}
                    />
                </svg>
            </div>
        );
    }
}

export default ProgressRing;
