import React from 'react'
import { buttonsType } from "../buttonsType";
import { ReactComponent as DefaultSVG } from "./img/viewer-reaction-default-btn.svg";
import { ReactComponent as LikeSVG } from "./img/viewer-reaction-like-btn.svg";
import { ReactComponent as GoodSVG } from "./img/viewer-reaction-good-btn.svg";
import { ReactComponent as SadSVG } from "./img/viewer-reaction-sad-btn.svg";
import { ReactComponent as AlarmSVG } from "./img/viewer-reaction-alarm-btn.svg";

import styles from './button.module.css'


const ReactionBtn = ({ reactionType, onClick }) => {
  const renderViewerReactionSVG = () => {
    switch (reactionType) {
      case buttonsType.VIEWER_REACTION_DEFAULT:
        return <DefaultSVG />
      case buttonsType.VIEWER_REACTION_LIKE:
        return  <LikeSVG />
      case buttonsType.VIEWER_REACTION_GOOD:
        return <GoodSVG />
      case buttonsType.VIEWER_REACTION_SAD:
        return <SadSVG />
      case  buttonsType.VIEWER_REACTION_ALARM:
        return <AlarmSVG />
      default:
        return null
    }
  }

  return (
    <button className={styles.btn} onClick={onClick}>
      {renderViewerReactionSVG()}
    </button>
  )
}

export default ReactionBtn