import React from 'react'
import { ReactComponent as BtnSVG } from "./img/pointer-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/pointer-btn-active.svg";
import styles from './button.module.css'
import ReactTooltip from 'react-tooltip';

const PointerBtn = ({isActive = false, isDisabled = false, onClick}) => {
  return (<>
    <button className={styles.btn} disabled={isDisabled} onClick={onClick} data-tip={'Указка'}
    data-for="pointer">
      {(!isActive) ? <BtnSVG/> : <BtnActiveSVG/>}
    </button>
              <ReactTooltip
              id="pointer"
              className={styles.tooltip}
              place="top"
              textColor="#393F61"
              backgroundColor="#fff"
              aria-haspopup="true"
            />
            </>
  )
}

export default PointerBtn
