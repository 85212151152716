import styles from './presenterVideo.module.css'
import stubImg from '../../../ViewerScreen/img/plashka_x2.png';
import cn from "classnames";
import TeacherUnavailable from "../../../ViewerScreen/components/TeacherUnavailable/TeacherUnavailable";
import React from "react";

const PresenterVideo = ({ videoRef, settings }) => {

  return <div className={styles.container}>
    <div className={cn(styles.videoContainer, { [styles.mirrored]: settings.videoMirrored })}>
      <video
        className={'local-video'}
        ref={videoRef}
        autoPlay
        playsInline
        muted
      />
    </div>

    { !settings.presenterAudio && !settings.presenterVideo &&
      <div className={ styles.stubContainer }>
        <img src={ stubImg }/>
      </div>
    }

    { settings.presenterAudio && !settings.presenterVideo &&
      <div className={styles.stubContainer}>
        <TeacherUnavailable
          isSoundMuted={
            !settings || (settings && !settings.presenterAudio)
          }
        />
      </div>
    }
  </div>
}

export default PresenterVideo