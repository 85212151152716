import React from 'react'
import styles from './checkboxNext.module.sass'


export default function CheckboxNext({
  text,
  value,
  id,
  onChange,
  disabled,
}) {
  return (
    <div className={styles.checkboxWrap}>
      <input
        type="checkbox"
        value={value}
        onChange={onChange}
        id={id}
        disabled={disabled}
        className={styles.checkbox}
      />
      <label className={styles.checkboxLabel} htmlFor={id}>
        {text}
      </label>
    </div>
  )
}