import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as IconDynamic } from './img/audio.svg'
import { ReactComponent as IconCamera } from './img/camera.svg'
import { ReactComponent as IconClose } from './img/close.svg'
import { ReactComponent as IconCameraDisabled } from './img/camera_disabled.svg'
import { ReactComponent as IconMicro } from './img/micro.svg'
import { ReactComponent as IconMicroDisabled } from './img/micro_disabled.svg'
import styles from './testLobby.module.scss'
import io from 'socket.io-client'


export default function SecondScreenLobby({
  microStatus = 'pending',
  cameraStatus = 'pending',
  videoStream,
  linkToLesson,
  pupilName,
}) {
  const roomId = useParams().roomId
  const [havePermission, setPermission] = useState(false)
  const [socket, setSocket] = useState(null)
  const [isVisibleMessage, setIsVisibleMessage] = useState('visible')
  const cameraStreamRef = useRef(null)
  const videoBlockRef = useRef(null)
  const pupilNameRef = useRef(null)
  const audioRef = useRef(null)
  const status = {
    pending: {
      micro: 'Проверяется...',
      camera: 'Проверяется...',
    },
    succeed: {
      micro: 'Подключен',
      camera: 'Подключена',
    },
    failed: {
      micro: 'Ошибка микрофона',
      camera: 'Ошибка камеры',
    },
  }

  useEffect(() => {
    let socket
    try {
      socket = io()
      socket.emit('viewer:came-to-lobby', { roomId })
      setSocket(socket)
    } catch (error) {
      console.log(error)
    }
  }, [pupilName])

  useEffect(() => {
    if (microStatus === 'succeed' && cameraStatus === 'succeed') {
      setPermission(true)
    } else {
      setPermission(false)
    }
  }, [microStatus, cameraStatus])

  useEffect(() => {
    if (socket) {
      if (microStatus === 'failed') {
        socket.emit('pupil: audio-problems', { roomId })
      }
    }
  }, [microStatus])

  useEffect(() => {
    if (socket) {
      if (cameraStatus === 'failed') {
        socket.emit('pupil: video-problems', { roomId })
      }
    }
  }, [cameraStatus])

  useEffect(() => {
    if (!videoStream) {
      return
    }
    cameraStreamRef.current.srcObject = videoStream
  }, [videoStream])

  const onLoadVideo = (e) => {
    videoBlockRef.current.style.backgroundColor = '#FFF'

    const diff = (videoBlockRef.current.scrollHeight - cameraStreamRef.current.scrollHeight) / 2
    pupilNameRef.current.style.color = diff > 47 ? '#393F61' : '#fff'
  }

  const playTestSound = () => {
    const audio = audioRef.current
    audio.play()
  }

  return (
    <>
      <div className={cn(styles.wrapper, styles.long)}>
        <div ref={videoBlockRef} className={styles.videoBlock}>
          <video ref={cameraStreamRef} onPlay={onLoadVideo} autoPlay playsInline muted/>
          <div className={styles.infoPanel}>
            <div ref={pupilNameRef} className={styles.name}>{pupilName}</div>
            <div className={styles.roundIcon}>
              {microStatus === 'succeed' ? <IconMicro className={styles.icon}/> :
                <IconMicroDisabled className={styles.icon}/>}
            </div>
          </div>
        </div>
        <div className={styles.infoBlock}>
          <div className={styles.content}>
            <div className={styles.titleBlock}>
            <span className={styles.title}>
              <h3>Готовы присоединиться?</h3>
            </span>
              {/*<br/>
            Пока никого нет*/}
            </div>
            <div className={styles.checkBlock}>
              <div className={cn(styles.checkDevice)}>
                <div className={styles.iconWrapper}>
                  <IconDynamic className={styles.icon}/>
                </div>
                <div className={styles.checkInfo}>
                  <span className={styles.bold}>Динамик</span>
                  <audio ref={audioRef} src="/images/test/test_music.wav"/>
                  <div className={styles.textButton} onClick={playTestSound}>Проиграть звук</div>
                </div>
              </div>
              <div className={cn(styles.checkDevice, styles[microStatus])}>
                <div className={styles.iconWrapper}>
                  {microStatus === 'succeed' ? <IconMicro className={styles.icon}/> :
                    <IconMicroDisabled className={styles.icon}/>}
                </div>
                <div className={styles.checkInfo}>
                  <span className={styles.bold}>Микрофон</span>
                  <br/>
                  {status[microStatus]['micro']}
                </div>
              </div>
              <div className={cn(styles.checkDevice, styles[cameraStatus])}>
                <div className={styles.iconWrapper}>
                  {cameraStatus === 'succeed' ? <IconCamera className={styles.icon}/> :
                    <IconCameraDisabled className={cn(styles.icon, styles.cameraDisabled)}/>}
                </div>
                <div className={styles.checkInfo}>
                  <span className={styles.bold}>Камера</span>
                  <br/>
                  {status[cameraStatus]['camera']}
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div className={cn(styles.button, {
                [styles.error]: !havePermission,
              })}>
                <Link to={{
                  pathname: linkToLesson,
                }}>
                  На урок!
                </Link>
              </div>
              {!havePermission &&
              <div><br/>Если не можешь понять как исправить проблему позвони в техподдержку 8 800 500 74 17</div>}
            </div>
            {/*<div className={styles.textButton}>
            Больше не спрашивать
          </div>*/}
          </div>
        </div>
      </div>
      <div className={styles.messageFox} style={{visibility: isVisibleMessage}}>
        <div className={styles.message}>
          <div className={styles.closeFox} onClick={() => setIsVisibleMessage('hidden')}><IconClose/></div>
          <div className={styles.text}>
            Чтобы разрешить подключение,
            <b> нажми кнопку "Разрешить" </b>
            в всплывающих сообщениях
          </div>
          <div className={styles.image}/>
        </div>
        <div className={styles.fox}/>
      </div>
    </>
  )
}