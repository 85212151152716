import {
  addStreamToConnection,
  createOffer,
  getLowQualityVideoAndAudioStreams,
  createPeerConnectionNext,
} from '../webrtc'

import { delay } from '../utils/util'

let senders = {}
let peerConnections = {}

export async function initViewerVideo({
  roomId,
  name,
  socket,

  videoStream,
  audioStream,

  videoRef,
  remoteVideoRef,
  mode,

  onStartFetchICEServer,
  onFinishFetchICEServer,
}) {
  videoRef.current.srcObject = videoStream

  if (typeof onStartFetchICEServer === 'function') {
    onStartFetchICEServer()
  }

  const { pc, connectionId } = await createPeerConnectionNext(socket, remoteVideoRef.current, { output: `video:${name}` })
  const { sender } = addStreamToConnection(pc, videoStream)
  senders['videoSender'] = sender
  peerConnections['videoSender'] = { pc, connectionId }
  pc.addTransceiver('audio', { direction: 'recvonly' })

  const offer = await createOffer(pc)

  // { pc, connectionId }
  const audioPeer = await createPeerConnectionNext(socket, null, { output: `audio:${name}` })
  const audioSender = addStreamToConnection(audioPeer.pc, audioStream)
  senders['audioSender'] = audioSender.sender
  peerConnections['audioSender'] = { pc: audioPeer.pc, connectionId: audioPeer.connectionId }
  const audioOffer = await createOffer(audioPeer.pc)

  if (typeof onFinishFetchICEServer === 'function') {
    onFinishFetchICEServer()
  }

  socket.emit(
    'viewer:init',
    {
      roomId,
      connectionId,
      offer: offer.sdp,
      name,
      mode,

      audio: {
        connectionId: audioPeer.connectionId,
        offer: audioOffer.sdp,
      }
    },
  )

  socket.emit('room:want-state')
}

export async function initViewerVideoNext({
  roomId,
  name,
  socket,

  videoStream,
  audioStream,

  videoRef,
  mode,

  onStartFetchICEServer,
  onFinishFetchICEServer,
}) {
  videoRef.current.srcObject = videoStream

  if (typeof onStartFetchICEServer === 'function') {
    onStartFetchICEServer()
  }

  const { pc, connectionId } = await createPeerConnectionNext(socket, null, { output: `video:${name}` })
  const { sender } = addStreamToConnection(pc, videoStream)
  senders['videoSender'] = sender
  peerConnections['videoSender'] = { pc, connectionId }
  pc.addTransceiver('audio', { direction: 'recvonly' })

  const offer = await createOffer(pc)

  // { pc, connectionId }
  const audioPeer = await createPeerConnectionNext(socket, null, { output: `audio:${name}` })
  const audioSender = addStreamToConnection(audioPeer.pc, audioStream)
  senders['audioSender'] = audioSender.sender
  peerConnections['audioSender'] = { pc: audioPeer.pc, connectionId: audioPeer.connectionId }
  const audioOffer = await createOffer(audioPeer.pc)

  if (typeof onFinishFetchICEServer === 'function') {
    onFinishFetchICEServer()
  }

  socket.emit(
    'viewer:init',
    {
      roomId,
      connectionId,
      offer: offer.sdp,
      name,
      mode,

      audio: {
        connectionId: audioPeer.connectionId,
        offer: audioOffer.sdp,
      }
    },
  )

  socket.emit('room:want-state')
}

export async function startInitiationVideo(options) {
  let isDone = false
  options.socket.on('await:video', () => {
    isDone = true
    console.log('init complete')
  })

  do {
    console.log('yet another try init video')
    await initViewerVideo(options)
    await delay(7500)
  }
  while (!isDone)
}

export async function changeVideo({ videoSource, videoRef }) {
  if (!videoSource) {
    return
  }
  const { videoStream } = await getLowQualityVideoAndAudioStreams({ video: videoSource })
  if (videoRef) {
    videoRef.current.srcObject = videoStream
  }

  await senders.videoSender.replaceTrack(videoStream.getVideoTracks()[0])
  
  return videoStream
}

export async function changeAudio({ audioSource }) {
  if (!audioSource) {
    return
  }
  const { audioStream } = await getLowQualityVideoAndAudioStreams({ audio: audioSource })
  await senders.audioSender.replaceTrack(audioStream.getAudioTracks()[0])

  return audioStream
}
