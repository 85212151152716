import React from 'react'
import ReactTooltip from 'react-tooltip'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import { ReactComponent as IconUpdate } from '../img/update.svg'
import { ReactComponent as IconUpdateDisabled } from '../img/update-disabled.svg'
import styles from './buttons.module.sass'


export default function ButtonUpdate({ onClick, disabled }) {
  return (
    <div
      className={styles.button}
      onClick={(event) => {
        if (disabled) {
          event.preventDefault()
        } else {
          onClick()
        }
      }}
      data-tip="Обновить урок" data-for="update"
    >
      {disabled ? <IconUpdateDisabled/> : <IconUpdate/>}
      <ReactTooltip
        id="update"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}