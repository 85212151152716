import React from "react";
import styles from './timer.module.css'


const Timer = ({timerPercent}) => {
  return (
    <div className={styles.container}>
      <div className={styles.timer} style={{width: timerPercent ? `${timerPercent}%` : 0}}/>
    </div>
  )
}

export default Timer