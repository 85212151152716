import { nanoid } from "nanoid";
import isEmpty from "lodash/isEmpty";

const CURTAIN_COLOR = '#FFFFFF'
const VIEWER_CURTAIN_COLOR = '#ddf3fc'
const PRESENTER_CURTAIN_FONT_COLOR = '#4da3f780'
const POINTER_COLOR = '#4da3f7'

//TODO: нужно было поменять местами названия pointer pointerBySelect, из-за этого некоторый нейминг стал странным, нужно прибраться в нейминге, а пока учитываем это)
export const epubPointer = ({
  container,
  type = 'presenter',
  prefId = 'char',
  onAddIndex,
  onAddSelectIndexes,
  onStartMakeNewSelect,
  onChangeIndexOfBorder,
  readOnly = false,
  charCount,
}) => {
  let instrument = '' //pointerBySelect, pointer, curtain
  const storePointerBySelect = new Set()
  let needClean = false
  let needSaveIndexes = false
  let isTouch = false

  let selectionsStorage = {}
  let curtainDirection = '' //right, left
  let curtainIndexOfBorder = null
  const curtainIndexes = []

  const getCharElementByIndex = (index) => {
    return container.getElementsByClassName(`${ prefId }_${ index }`)?.[0]
  }

  const clearStore = () => {
    for (const index of Array.from(storePointerBySelect)) {
      const charEl = getCharElementByIndex(index)

      if (charEl) {
        charEl.style.borderColor = 'transparent'
      }
    }
    storePointerBySelect.clear()
  }

  const clearSelections = (selectType = 'all') => {
    for (const selectObjId in selectionsStorage) {
      if (selectType === 'all' || selectionsStorage[selectObjId].type === selectType) {
        drawingBackground(selectionsStorage[selectObjId], 'transparent')
      }
    }
  }

  const makeNewSelectionFromRange = (range) => {
    const startCharEl = range.startContainer.parentElement
    const endCharEl = range.endContainer.parentElement

    const startIndex = Number(startCharEl.getAttribute('data-index') === null ? 'test' : startCharEl.getAttribute('data-index'))
    const endIndex = Number(endCharEl.getAttribute('data-index') === null ? 'test' : endCharEl.getAttribute('data-index'))

    const selectIndexes = []
    for (let index = startIndex; index <= endIndex; index++) {
      const charEl = getCharElementByIndex(index)
      if (!charEl) {
        continue
      }

      charEl.style.backgroundColor = type === 'presenter' ? '#4da3f7' : '#b1f6e6'

      selectIndexes.push(index)
    }

    const selectObj = {
      id: nanoid(5),
      selectIndexes,
      type,
    }

    selectionsStorage[selectObj.id] = selectObj

    if (onAddSelectIndexes) {
      onAddSelectIndexes(selectObj)
    }
  }

  const onMouseDownPointer = (event) => {
    const el = event.target
    const selection = document.getSelection()
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null
    if (!range || !range.intersectsNode(el)) {
      return
    }

    makeNewSelectionFromRange(range)
  }

  const onTouchStart = () => {
    isTouch = true
  }

  const onTouchEnd = () => {
    isTouch = false
  }

  const onSelectionChange = () => {
    if (!isTouch) {
      return
    }

    const selection = document.getSelection()
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null
    if (!range) {
      return
    }

    makeNewSelectionFromRange(range)
  }

  const onMouseDownPointerBySelect = () => {
    clearStore()
    needSaveIndexes = true
    if (onStartMakeNewSelect) {
      onStartMakeNewSelect()
    }
  }

  const onMouseUp = () => {
    needSaveIndexes = false
  }

  const onMouseMove = (event) => {
    if (!needSaveIndexes) {
      return
    }

    const charEl = event.target
    charEl.style.borderColor = POINTER_COLOR

    const index = Number(charEl.getAttribute('data-index') === null ? 'test' : charEl.getAttribute('data-index'))
    const lastIndex = Array.from(storePointerBySelect).pop()
    const delta = index - lastIndex

    if (delta > 1) {
      for (let i = lastIndex; i <= index; i++) {
        const lostCharEl = getCharElementByIndex(i)
        if (!lostCharEl) {
          continue
        }

        lostCharEl.style.borderColor = POINTER_COLOR

        if (onAddIndex && type === 'presenter') {
          onAddIndex(i)
        }

        storePointerBySelect.delete(i)
        storePointerBySelect.add(i)
      }
    } else {
      storePointerBySelect.delete(index)
      storePointerBySelect.add(index)
      if (onAddIndex && type === 'presenter') {
        onAddIndex(index)
      }
    }
  }

  const cleanTail = () => {
    if (!needClean) {
      return
    }

    const [firstEl] = storePointerBySelect
    const charEl = getCharElementByIndex(firstEl)

    if (charEl) {
      charEl.style.borderColor = 'transparent'
    }

    storePointerBySelect.delete(firstEl)

    setTimeout(cleanTail, 250)
  }

  const drawingBackground = (selectionObj, color = null) => {
    for (const index of selectionObj.selectIndexes) {
      const charEl = getCharElementByIndex(index)

      if (!charEl) {
        continue
      }

      if (color) {
        charEl.style.backgroundColor = color
      } else {
        charEl.style.backgroundColor = selectionObj.type === 'presenter' ? '#4da3f7' : '#b1f6e6'
      }
    }
  }

  const makeSelection = () => {
    for (const selectionObjId in selectionsStorage) {
      drawingBackground(selectionsStorage[selectionObjId])
    }
  }

  const drawCurtainLeft = (startIndex, endIndex) => {
    for (let i = startIndex; i <= endIndex; i++) {
      const charEl = getCharElementByIndex(i)

      if (!charEl) {
        continue
      }

      charEl.style.backgroundColor = type === 'viewer' ? VIEWER_CURTAIN_COLOR : CURTAIN_COLOR
      charEl.style.color = type === 'viewer' ? VIEWER_CURTAIN_COLOR : PRESENTER_CURTAIN_FONT_COLOR
      curtainIndexes.push(i)
    }
  }

  const drawCurtainRight = (startIndex, endIndex) => {
    for (let i = endIndex; i >= startIndex; i--) {
      const charEl = getCharElementByIndex(i)

      if (!charEl) {
        continue
      }

      charEl.style.backgroundColor = type === 'viewer' ? VIEWER_CURTAIN_COLOR : CURTAIN_COLOR
      charEl.style.color = type === 'viewer' ? VIEWER_CURTAIN_COLOR : PRESENTER_CURTAIN_FONT_COLOR
      curtainIndexes.unshift(i)
    }
  }

  const unDrawCurtain = (length, duration = 'left') => {
    for (let i = 0; i < length; i++) {
      const charIndex = duration === 'left' ? curtainIndexes.pop() : curtainIndexes.shift()

      const charEl = getCharElementByIndex(charIndex)

      if (!charEl) {
        continue
      }

      charEl.style.backgroundColor = 'transparent'
      charEl.style.color = '#000'
    }
  }

  const drawCurtain = (index) => {
    if (instrument !== 'curtain' || (index !== 0 && !index) || isNaN(index)) {
      return
    }


    if (curtainDirection === 'left') {
      if (curtainIndexes.length === 0) {
        drawCurtainLeft(0, index)
      } else if (curtainIndexes[curtainIndexes.length - 1] < index) {
        drawCurtainLeft(curtainIndexes[curtainIndexes.length - 1], index)
      } else {
        unDrawCurtain(curtainIndexes[curtainIndexes.length - 1] - index)
      }
    } else if (curtainDirection === 'right') {
      if (curtainIndexes.length === 0) {
        drawCurtainRight(index, charCount - 1)
      } else if (curtainIndexes[0] >= index) {
        drawCurtainRight(index, curtainIndexes[0])
      } else {
        unDrawCurtain(index - curtainIndexes[0], 'right')
      }
    }
  }

  const onMouseDownCurtain = (event) => {
    const charEl = event.target

    const index = Number(charEl.getAttribute('data-index') === null ? 'test' : charEl.getAttribute('data-index'))

    drawCurtain(index)

    if (onChangeIndexOfBorder) {
      onChangeIndexOfBorder(index)
    }
  }

  const removeAllEventListeners = () => {
    container.removeEventListener('touchstart', onTouchStart)
    container.removeEventListener('touchend', onTouchEnd)
    document.removeEventListener('selectionchange', onSelectionChange)
    container.removeEventListener('mousedown', onMouseDownPointer)
    container.removeEventListener('mousedown', onMouseDownPointerBySelect)
    container.removeEventListener('mouseup', onMouseUp)
    container.removeEventListener('mousemove', onMouseMove)
    container.removeEventListener('mousedown', onMouseDownCurtain)
  }

  const updateEventListeners = () => {
    removeAllEventListeners()
    if (readOnly) {
      return
    }

    if (type === 'presenter' && instrument === 'pointer') {
      container.addEventListener('mousemove', onMouseMove)
      container.addEventListener('mousedown', onMouseDownPointerBySelect)
      container.addEventListener('mouseup', onMouseUp)
    }

    if (instrument === 'pointerBySelect') {
      container.addEventListener('mousedown', onMouseDownPointer)
      container.addEventListener('touchstart', onTouchStart, false)
      container.addEventListener('touchend', onTouchEnd, false)
      document.addEventListener('selectionchange', onSelectionChange)
    }

    if (type === 'presenter' && instrument === 'curtain') {
      container.addEventListener('mousedown', onMouseDownCurtain)
    }
  }

  return {
    destroy: () => {
      needClean = false
      removeAllEventListeners()

      clearSelections('all')
      clearStore()
      unDrawCurtain(curtainIndexes.length, curtainDirection)
    },

    onGetStorage: (serverStorage) => {
      if (!isEmpty(selectionsStorage)) {
        clearSelections('all')
      }

      selectionsStorage = serverStorage.data
      makeSelection()
    },

    setInstrument: (nextInstrument) => {
      if (instrument === nextInstrument) {
        return
      }

      instrument = nextInstrument
      needClean = instrument === 'pointer'
      if (needClean) {
        cleanTail()
      } else {
        clearStore()
      }
      unDrawCurtain(curtainIndexes.length, curtainDirection)
      updateEventListeners()
    },

    setCurtainDirection: (direction) => {
      unDrawCurtain(curtainIndexes.length, curtainDirection)
      curtainDirection = direction
    },

    setCurtainIndexOfBorder: (index) => {
      curtainIndexOfBorder = index
      drawCurtain(index)
    },

    addIndex: (index) => {
      const charEl = getCharElementByIndex(index)
      if (!charEl) {
        return
      }

      charEl.style.borderColor = POINTER_COLOR

      storePointerBySelect.add(index)
    },

    addSelectIndexes: (selectObj) => {
      selectionsStorage[selectObj.id] = selectObj
      drawingBackground(selectObj)
    },

    clearStore,
  }
}
