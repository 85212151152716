import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import CheckboxNext from '../../../../../components/CheckboxNext/CheckboxNext'
import presenterScreenStyles from '../../../../presenterScreen.module.scss'

import { ReactComponent as IconSettings } from '../../img/settings.svg'
import { ReactComponent as IconUpdate } from '../../img/update.svg'
import cn from 'classnames'
import PopupNext from '../../../../../components/PopupNext/PopupNext'
import styles from './buttonSettings.module.sass'


const ButtonSettings = ({ onChangeSettingMediaDevice, onVideoMirroring, isVideoMirrored }) => {
  const [isOpen, setOpen] = useState(false)
  const [optionsAudioSource, setOptionsAudioSource] = useState([])
  const [optionsAudioOutput, setOptionsAudioOutput] = useState([])
  const [optionsVideoSource, setOptionsVideoSource] = useState([])
  const [defaultSources, setDefaultSources] = useState({})

  const openPopup = () => {
    console.log('open settings')
    setOpen(true)
  }
  const closePopup = () => {
    setOpen(false)
  }

  const getDevices = async () => {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices()
    console.log('deviceInfos', deviceInfos)
    let optionsAudioSource = []
    let optionsAudioOutput = []
    let optionsVideoSource = []
    const storedAudioSource = localStorage.getItem('audioSource')
    const storedVideoSource = localStorage.getItem('videoSource')
    const defaultValues = {}
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i]
      const option = {}
      option.deviceId = deviceInfo.deviceId
      if (deviceInfo.kind === 'audioinput') {
        if (deviceInfo.deviceId === storedAudioSource) {
          defaultValues.audioSource = deviceInfo.deviceId
          // setDefaultSources({ ...defaultSources, audioSource: deviceInfo.deviceId })
        }
        option.text =
          deviceInfo.label ||
          `microphone ${optionsAudioSource.length + 1}`
        optionsAudioSource.push(option)
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text =
          deviceInfo.label ||
          `speaker ${optionsAudioOutput.length + 1}`
        optionsAudioOutput.push(option)
      } else if (deviceInfo.kind === 'videoinput') {
        if (deviceInfo.deviceId === storedVideoSource) {
          defaultValues.videoSource = deviceInfo.deviceId
          // setDefaultSources({ ...defaultSources, videoSource: deviceInfo.deviceId })
        }
        option.text =
          deviceInfo.label || `camera ${optionsAudioOutput.length + 1}`
        optionsVideoSource.push(option)
      } else {
        console.log('Some other kind of source/device: ', deviceInfo)
      }
    }

    setDefaultSources(defaultValues)
    return { optionsAudioSource, optionsAudioOutput, optionsVideoSource }
  }

  useEffect(() => {
    (async () => {
      const newDevices = await getDevices()
      setOptionsAudioOutput(newDevices.optionsAudioOutput)
      setOptionsAudioSource(newDevices.optionsAudioSource)
      setOptionsVideoSource(newDevices.optionsVideoSource)
    })()
  }, [])


  function handleError(error) {
    console.log(
      'navigator.MediaDevices.getUserMedia error: ',
      error.message,
      error.name,
    )
  }

  const onSelectChange = ({ target }) => {
    if (!target) {
      return
    }
    const kind = target.id
    const source = target.value


    setDefaultSources({ ...defaultSources, [kind]: source })
    onChangeSettingMediaDevice({ kind, source })
  }

  const updateDevices = () => {
    (async () => {
      const newDevices = await getDevices()
      setOptionsAudioOutput(newDevices.optionsAudioOutput)
      setOptionsAudioSource(newDevices.optionsAudioSource)
      setOptionsVideoSource(newDevices.optionsVideoSource)
    })()
  }

  return (
    <div className={styles.wrap}>
      <button type="button" onClick={openPopup} className={styles.btn}>
        <IconSettings className={styles.icon} data-tip="Настройки" data-for="settings"/>
        <ReactTooltip
          id="settings"
          className={presenterScreenStyles.tooltip}
          place="right"
          textColor="#393F61"
          backgroundColor="#fff"
          aria-haspopup="true"
        />
      </button>
      <PopupNext isOpen={isOpen} onClose={closePopup} title="Настройки">
        <button
          onClick={updateDevices}
          className={cn('button', 'button_no-style', styles.updateDevices)}
        >
          <IconUpdate className={styles.iconUpdate}/>
          <span>Обновить список устройств</span>
        </button>
        <form>
          <div className={styles.selectField}>
            <label htmlFor="audioSource" className={styles.label}>
              Микрофон:{' '}
            </label>
            <select
              id="audioSource"
              value={defaultSources.audioSource || 'default'}
              onChange={onSelectChange}
              className={cn(styles.select, 'select-css')}
            >
              <option value={'default'} key={'default'}>По-умолчанию</option>
              {optionsAudioSource.map(
                (i) => (<option value={i.deviceId} key={i.deviceId}>
                  {i.text}
                </option>),
              )}
            </select>
          </div>
          <div className={styles.selectField}>
            <label htmlFor="videoSource" className={styles.label}>
              Источник видео:{' '}
            </label>
            <select
              id="videoSource"
              onChange={onSelectChange}
              value={defaultSources.videoSource || 'default'}
              className={cn(styles.select, 'select-css')}
            >
              <option value={'default'} key={'default'}>По-умолчанию</option>
              {optionsVideoSource.map((i) => (<option value={i.deviceId} key={i.deviceId}>
                {i.text}
              </option>))
              }
            </select>
          </div>
          <div className={styles.selectField}>
            <CheckboxNext value={isVideoMirrored} id="video-mirror" text="Отразить видео по горизонтали" onChange={onVideoMirroring} />
          </div>
        </form>
      </PopupNext>
    </div>
  )
}

export default ButtonSettings
