import React from 'react'
import { ReactComponent as BtnSVG } from "./img/pencil-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/pencil-btn-active.svg";
import presenterScreenStyles from '../../../../../PresenterScreen/presenterScreen.module.scss'
import styles from './button.module.css'
import ReactTooltip from 'react-tooltip';


const PencilBtn = ({isActive = false, isDisabled = false, onClick}) => {
  return (<>
    <button className={styles.btn} disabled={isDisabled} onClick={onClick} data-tip="Карандаш" data-for="pencil">
      {(!isActive) ? <BtnSVG/> : <BtnActiveSVG/>}
    </button>
          <ReactTooltip
          id="pencil"
          className={styles.tooltip}
          place="top"
          textColor="#393F61"
          backgroundColor="#fff"
          aria-haspopup="true"
        />
    </>
  )
}

export default PencilBtn
