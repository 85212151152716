import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './sidebar.module.sass'
import sidebarStyles from "./sidebar.module.sass";
import ButtonStartEnd from "./components/ButtonStartEnd";
import Clock from "../../../components/Clock/Clock";
import ButtonUserPressed from "./components/ButtonUserPressed";
import ButtonChat from "./components/ButtonChat";
import ButtonProblem from "./components/ButtonProblem";
import ButtonTalkingAll from "./components/ButtonTalkingAll";
import ButtonTalkingOne from "./components/ButtonTalkingOne";
import ButtonAudio from "./components/ButtonAudio";
import ButtonVideo from "./components/ButtonVideo";
import ButtonUpdate from "./components/ButtonUpdate";
import ButtonSettings from "./components/ButtonSetting/ButtonSettings";
import { useUser } from "../../../context/user";
import { finishDemoLesson } from "../../../api/groups";
import { getLowQualityVideoAndAudioStreams } from "../../../webrtc";


export default function Sidebar({
  isFixed = false,
  error,
  setError,
  errors,
  senders,
  presenterVideoRef,
  setAudioSource,
  audioSource,
  setVideoSource,
  videoSource,
  onChangeSoundSetting,
  localStream,
  messageHistory = [],
  isShowChat,
  setIsShowChat,
  settings,
  group,
  socket,
}) {
  const user = useUser()

  const [disableRefresh, setDisableRefresh] = useState(false)
  const [isShowFeedback, setIsShowFeedback] = useState(false)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [historyMessageCount, setHistoryMessageCount] = useState(0);

  useEffect(() => {
    setDisableRefresh(true)
  }, [])

  useEffect(() => {
    if (!disableRefresh) {
      return;
    }

    setTimeout(() => setDisableRefresh(false), 10000)
  }, [disableRefresh])

  useEffect(() => {
    if (settings?.finishedAt && !isShowFeedback) {
      setIsShowFeedback(true)
    }
  }, [settings?.finishedAt])

  useEffect(() => {
    if (isShowChat) {
      setUnreadMessagesCount(0);
    }
  }, [isShowChat]);

  useEffect(() => {
    if (!isShowChat && historyMessageCount < messageHistory.length) {
      setUnreadMessagesCount((prevState) => prevState + 1);
    }
    setHistoryMessageCount(messageHistory.length);
  }, [messageHistory]);

  const onStartLesson = () => {
    if (socket) {
      socket.emit('start-lesson');
    }
  };

  const onFinishLesson = async () => {
    let isConfirm = window && window.confirm('Вы уверены?');
    if (socket && isConfirm) {
      socket.emit('finish-lesson');
    }
    if (group.isDemo && !group.isDemoLessonFinished) {
      await finishDemoLesson(group.id);
    }
  };

  const handleChatShow = () => {
    setIsShowChat(!isShowChat);
  };

  const onCloseFeedbackPopUp = () => {
    setIsShowFeedback(false)
  }

  const onMuteAudio = () => {
    const newIsMuted = !settings.presenterAudio;
    localStream.getAudioTracks()[0].enabled = newIsMuted;
    if (newIsMuted) {
      socket.emit('teacher:audio:on');
    } else {
      socket.emit('teacher:audio:off');
    }
  };

  const muteVideo = () => {
    const newIsMuted = !settings.presenterVideo;
    localStream.getTracks()[1].enabled = newIsMuted;
    if (newIsMuted) {
      socket.emit('teacher:video:on');
    } else {
      socket.emit('teacher:video:off');
    }
  };

  const onRefresh = () => {
    //на сервере по этому сокету ничего не происходит, тк он для ученика... а в логах появляется ошибка
    // socket.emit('refresh_sockets');
    setDisableRefresh(true);

    setTimeout(() => document.location.reload(), 2500);
  }

  async function changeVideo({videoSource, videoRef}) {
    if (!videoSource) {
      return;
    }

    const {videoStream} = await getLowQualityVideoAndAudioStreams({video: videoSource});

    if (
      videoStream &&
      videoStream.getVideoTracks()[0] &&
      error === errors.stream
    ) {
      setError(null);
    }

    if (!videoStream || !videoStream.getVideoTracks()[0]) {
      setError(errors.stream);
    }
    videoRef.current.srcObject = videoStream;
    senders.videoSender &&
    senders.videoSender.replaceTrack(videoStream.getVideoTracks()[0]);
  }

  async function changeAudio({audioSource}) {
    if (!audioSource) {
      return;
    }
    const {audioStream} = await getLowQualityVideoAndAudioStreams({audio: audioSource});

    if (
      audioStream &&
      audioStream.getAudioTracks()[0] &&
      error === errors.stream
    ) {
      setError(null);
    }

    if (!audioStream || !audioStream.getAudioTracks()[0]) {
      setError(errors.stream);
    }

    senders.audioSender &&
    senders.audioSender.replaceTrack(audioStream.getAudioTracks()[0]);
  }

  const onChangeSettingMediaDevice = ({kind, source}) => {
    if (kind === 'audioSource') {
      setAudioSource(source);
      localStorage.setItem('audioSource', source);
      changeAudio({audioSource}).catch(err => console.error('changeAudio error', err));
    }

    if (kind === 'videoSource') {
      setVideoSource(source);
      localStorage.setItem('videoSource', source);
      changeVideo({videoSource, videoRef: presenterVideoRef}).catch(err => console.error('changeVideo error', err));
    }
  };

  const onVideoMirroring = () => {
    const newIsMirrored = !settings.videoMirrored;
    if (newIsMirrored) {
      socket.emit('teacher:mirrored:true');
    } else {
      socket.emit('teacher:mirrored:false');
    }
  };

  return (
    <div className={styles.fixedWrapper}>
      <div className={cn(styles.sidebarWrapper, styles.sidebar, {[styles.noFix]: isFixed})}>
        <ul className={sidebarStyles.group}>
          <li>
            <ButtonStartEnd
              onStartLesson={onStartLesson}
              onFinishLesson={onFinishLesson}
              settings={settings}
            />
          </li>
          <li className={sidebarStyles.timer}>
            {Boolean(settings.startedAt > 0) &&
            Boolean(!settings.finishedAt) ? (
              <Clock createdAt={settings.startedAt}/>
            ) : (
              '00:00:00'
            )}
          </li>
          <li>
            <ButtonUserPressed
              onClick={handleChatShow}
              isChatShown={isShowChat}
            />
          </li>
          <li>
            <ButtonChat
              onClick={handleChatShow}
              isChatShown={isShowChat}
              count={unreadMessagesCount}
            />
          </li>
          <li>
            {
              <ButtonProblem
                isShowFeedback={isShowFeedback}
                handleCloseFeedback={onCloseFeedbackPopUp}
                setShowFeedback={setIsShowFeedback}
                user={user}
                group={group}
              />
            }
          </li>
        </ul>
        <ul className={sidebarStyles.group}>
          <li>
            <ButtonTalkingAll
              onChange={onChangeSoundSetting}
              defaultValue={settings.sound === 'all'}
            />
          </li>
          <li>
            <ButtonTalkingOne
              onChange={onChangeSoundSetting}
              defaultValue={settings.sound === 'all'}
            />
          </li>
          <li>
            {localStream && (
              <ButtonAudio
                mute={onMuteAudio}
                isMuted={!settings.presenterAudio}
              />
            )}
          </li>
          <li>
            {localStream && (
              <ButtonVideo
                mute={muteVideo}
                isMuted={!settings.presenterVideo}
              />
            )}
          </li>
          <li>
            <ButtonUpdate
              disabled={disableRefresh}
              onClick={onRefresh}
            />
          </li>
          <li>
            {
              <ButtonSettings
                onChangeSettingMediaDevice={onChangeSettingMediaDevice}
                onVideoMirroring={onVideoMirroring}
                isVideoMirrored={settings.videoMirrored}
              />
            }
          </li>
        </ul>
      </div>
    </div>)
}
