import React, {
  useEffect,
  useRef,
  useState,
  useReducer, useMemo,
} from 'react'
import ReactTooltip from 'react-tooltip'
import { useHistory, useParams } from 'react-router-dom'
import cn from 'classnames'
import { uniqueNamesGenerator, colors, animals } from 'unique-names-generator'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { eventLog } from '../../api/apiEventLog'
import ChatMessage from '../../components/ChatMessage/ChatMessage'
import AudioTask from '../components/AudioTask/AudioTask'
import bigLessonSoonImg from '../../img/big_lessonSoon.jpg'
import callToSupportImg from '../../img/call_to_support.png'
import knockAnimation from '../components/ButtonChat/img/animation.gif'

import checkMobile from '../../utils/checkMobile'
import DraggableWrap from '../../components/DraggableWrap/DraggableWrap'
import isVisible from '../../utils/isVisible'
import OtherPupils, { hasCommonGroupAndGroupSoundIsAll } from '../../OtherPupils'
import ButtonChat from '../components/ButtonChat/ButtonChat'
import MessageFox from '../components/MessageFox/MessageFox'
import TeacherUnavailable from '../components/TeacherUnavailable/TeacherUnavailable'

import { ReactComponent as IconviewGallery } from '../img/view-gallery.svg'
import { ReactComponent as IconviewMosaic } from '../img/view-mosaic.svg'
import { ReactComponent as IconviewReport } from '../img/view-report.svg'
import { ReactComponent as IconEraser } from '../img/eraser.svg'
import { ReactComponent as IconMute } from '../img/icon-mute.svg'
import { ReactComponent as IconUnMute } from '../img/icon-unmute.svg'
import { ReactComponent as IconThemaDark } from '../img/dark-thema.svg'
import { ReactComponent as IconThemaLight } from '../img/light-thema.svg'
import { ReactComponent as IconBgTask } from '../img/bg-task.svg'
import { ReactComponent as IconInfo } from '../../img/info.svg'
import { ReactComponent as IconEllipsis } from '../../img/ellipsis.svg'
import plashka from '../img/plashka_x2.png'

import ButtonDoneNext from '../components/ButtonDoneNext/ButtonDone'
import VideoTask from '../components/VideoTask/VideoTask'

import Canvas from '../../components/Canvas/Canvas'
import ConnectProblem from '../../components/ConnectProblem/ConnectProblem'
import SettingMD from '../../components/SettingMD/SettingMd'
import Popup from '../../components/Popup/Popup'
import { Loader } from '../../components/Loader/Loader'

import Avatar from '../../components/Avatar/Avatar'
import CopyRight from '../../components/CopyRight/CopyRight'

import { reducerTimers } from '../../PresenterScreen/reduserTimers'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useVideoAudioStreamPublic from '../../hooks/useVideoAudioStreamPublic'
import useTimer from '../../hooks/useTimer'
import ChatWidget from '../ChatWidget'

import { getInformation } from '../../utils/navigator'

import styles from './viewerScreenMobile.module.css'
import stylesNext from '../viewerScreenNext.module.css'


import { changeAudio, changeVideo } from '../viewerFuncs'

import { getAudioUrl, getImgUrl, getVideoUrl } from '../../func'
import DropMenu from '../../components/Dropmenu'
import DesktopControlsNext from "../components/DesktopControlsNext/DesktopControlsNext";
import isEmpty from "lodash/isEmpty";
import Epub from "../../components/Epub/Epub";
import useViewerSocket from "../useViewerSocket";
import usePresenterVideo from "../usePresenterVideo";
import VideosContainer from "./components/VideosContainer/VideosContainer";
import Menu from "./components/Menu/Menu";
import PresenterVideo from "./components/PresenterVideo/PresenterVideo";
import LocalViewerVideo from "./components/LocalViewerVideo/LocalViewerVideo";
import Task from "./components/Task/Task";
import ViewerVideo from "./components/ViewerVideo/ViewerVideo";
import { viewTypes } from "./components/ViewStylePopupMenu/viewStyle";
import PersonVideoContainer from "./components/PersonVideoContainer/PersonVideoContainer";
import findIndex from "lodash/findIndex";
import emptyCanvasTask from "../../utils/emptyCanvasTask";
import {difference} from "lodash";


dayjs.extend(relativeTime)

function hasSelection(tags) {
  return tags && tags.indexOf('selected') > -1
}

function hasTag(tags, tag) {
  return tags && tags.indexOf(tag) > -1
}

export default function ViewerScreenMobile({mode = 'demo'}) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  })
  const urlParams = useParams()

  const history = useHistory()

  const [name, setName] = useState('')
  const [isNeedHelp, setNeedHelp] = useState(false)
  const [reactions, setReactions] = useState({})

  const videoRef = useRef(null)
  const remoteVideoRef = useRef(null)

  const [isPresenterSoundOff, setIsPresenterSoundOff] = useState(false)

  const [audioSource, setAudioSource] = useState(localStorage.getItem('audioSource'))
  const [videoSource, setVideoSource] = useState(localStorage.getItem('videoSource'))
  const [pupilStream, setPupilStream] = useState({})

  const [isMobile, setMobile] = useState(false)
  const [iceFetchPending, setIceFetchPending] = useState(false)

  const [timers, dispatchTimer] = useReducer(reducerTimers, {})

  const {
    socket,
    roomId: presenterRoomId,
    connectionState,
    directMode,
    error,
    setError,
    isChatMuted,
    isDemoLesson,
    isMuted,
    setMute,
    isPresenterCame,
    messages,
    setMessages,
    otherViewers: otherPupils,
    presenter,
    presentView,
    pupilId,
    roomStrId,
    settings,
    settingsByTag,
    tags,
    tariffPlanHashId,
    task,
    viewerDisplayName: displayName,
    setViewersDisplayName: setDisplayName,
    viewerSettings,
    setViewerSettings,
    viewersDisplayName: displayNamesSet,
    viewersMuted,
    needStopLocalStreaming,
    setNeedStopLocalStreaming,
  } = useViewerSocket({name, dispatchTimer})

  // const {stream: teacherStream, setStream: setTeacherStream} = usePresenterVideo({
  //   socket,
  //   videoRef: remoteVideoRef,
  //   isPresenterCame,
  //   roomId: presenterRoomId
  // })

  useTimer(socket, name, timers[name], dispatchTimer, 'viewer')
  const [thema, setThema] = useState(null)

  const [isTaskFull, setTaskFull] = useState(false)
  const [isTaskFullPrev, setTaskFullPrev] = useState(false)
  const [isShowMobileMenu, setShowMobileMenu] = useState(false)
  const [isFacesCollapse, setFacesCollapse] = useState(true)
  const [isInfoPopupOpen, setInfoPopupOpen] = useState(false)

  const [unreadMessages, setUnreadMessages] = useState(0)
  const [bubbleMessages, setBubbleMessages] = useState([])
  const [lastBubbleMessage, setLastBubbleMessage] = useState({})

  const roomId = urlParams.roomId || urlParams.lessonId

  const asideRef = useRef(null)
  const controlsRef = useRef(null)

  const [disableRefresh, setDisableRefresh] = useState(false)
  const containerRef = useRef(null)

  const hideFaces = () => {
    setFacesCollapse(true)
    if (asideRef.current) {
      setTimeout(() => {
        const asideVisible = isVisible(asideRef.current)
        if (!asideVisible) {
          asideRef.current.style = {
            left: 10,
            top: 10,
          }
        }
      }, 2000)
    }
  }

  const hideMobileMenu = (e) => {
    if (!isDesktopOrLaptop) {
      setShowMobileMenu(false)
    }
  }

  useEffect(() => {
    const listener = (e) => {
      const area = window.innerHeight - 142
      if (e.clientY >= area) {
        setShowMobileMenu(true)
      }

    }
    if (!isShowMobileMenu) {
      document.addEventListener('click', listener)
    } else {
      document.removeEventListener('click', listener)
    }

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [isShowMobileMenu])

  useEffect(() => {
    const localThema = localStorage.getItem('thema')
    if (isDesktopOrLaptop && localThema) {
      setThema(localThema)
    } else {
      setThema('light')
    }


  }, [])

  useEffect(() => {
    if (!isDesktopOrLaptop && thema !== 'dark') {
      setThema('dark')
    }

    if (isDesktopOrLaptop) {
      setTaskFull(false)
    }
  }, [isDesktopOrLaptop, thema])

  useEffect(() => {
    setMobile(checkMobile())
  }, [isDesktopOrLaptop])

  useEffect(() => {
    if (!disableRefresh) {
      return
    }

    setTimeout(() => setDisableRefresh(false), 10000)
  }, [disableRefresh])

  useEffect(() => {
    try {
      if (mode === 'auth') {
        setName(roomId)
      } else {
        const randomName = uniqueNamesGenerator({
          dictionaries: [colors, animals],
          length: 2,
        })
        setName(randomName)
        setDisplayName(randomName)
      }
    } catch (e) {
      setError(e.toString())
    }
  }, [roomId])

  useEffect(() => {
    if (!socket || !name) {
      return
    }
    socket.emit('viewer:got-name', name)
    return () => {
      console.log('clear socket')
    }
  }, [socket, name])

  useEffect(() => {
    try {
      /*
        Когда я слышу учителя
        Если он говорит с выбранным и я выбранный
      */
      if (
        // Если он говорит со всеми
        !directMode
        ||
        // Если он говорит с выбранным и я выбранный
        directMode && directMode.value === 'selected'
        && hasSelection(tags[name])
      ) {
        setIsPresenterSoundOff(false)
      } else {
        setIsPresenterSoundOff(true)
      }
    } catch (e) {
      setError(e.toString())
    }

    // video.muted = isSoundOff
  }, [directMode, name, tags])

  useEffect(() => {
    setDisableRefresh(true)
  }, [])

  useEffect(() => {
    if (!socket) {
      return
    }

    setTimeout(() => {
      getInformation(window.navigator)
        .then(data => {
          socket.emit('viewer-send-information', data)
        })
        .catch(error => {
          console.error(error)
        })
    }, 2000)
  }, [socket])

  useEffect(() => {
    if (!isEmpty(settings) && settings?.finishedAt !== null && settings?.finishedAt !== undefined && settings?.finishedAt !== 0 &&
      // franchiseeForPaySlug &&
      tariffPlanHashId &&
      isDemoLesson && pupilId) {
      // history.push(`/${franchiseeForPaySlug}/payment/${pupilId}`)
      // stopStreaming()
      setNeedStopLocalStreaming(true)
      history.push(`/payment/tariff-plan/${tariffPlanHashId}/${pupilId}`)
    } else if (settings?.finishedAt) {
      if (roomStrId) {
        // stopStreaming()
        setNeedStopLocalStreaming(true)
        history.push(`/lesson/${roomId}/end/${roomStrId}`)
      } else {
        // stopStreaming()
        setNeedStopLocalStreaming(true)
        history.push('/cabinet')
      }
    }
  }, [settings])

  const onStartFetchICEServer = () => setIceFetchPending(true)
  const onFinishFetchICEServer = () => setIceFetchPending(false)

  useEffect(() => {
    try {
      if (socket) {
        socket.emit('ask-chat-mute', {name, isChatMuted: isChatMuted})
      }
    } catch (e) {
      setError(e.toString())
    }

  }, [isChatMuted])

  useEffect(() => {
    if (!socket) {
      return
    }

    const handleAskForHelp = (data) => {
      console.log('viewer.ask-for-help', name, data)
      if (data.name === name) {
        setNeedHelp(data.doNeedHelp)
      }

      setReactions({
        ...reactions,
        [data.name]: data.doNeedHelp && data.reaction,
      })
    }
    socket.on('ask-for-help', handleAskForHelp)

    return () => {
      socket.off('ask-for-help', handleAskForHelp)
    }
  }, [socket, reactions])

  //TODO: добавить этот функционал
  // useEffect(() => {
  //   if (viewIndex !== presentView.viewIndex && hasTag(tags[name], presentView.tag)) {
  //     setViewIndex(presentView.viewIndex)
  //   }
  // }, [presentView])

  const onChatChangeMessage = (message) => {
    setMessages((prevState) => {
      const index =  findIndex(prevState, (prevMessage) => prevMessage.id === message.id)
      if (index === -1) {
        return prevState
      }
      prevState[index] = message
      return [...prevState]
    })
  }

  useEffect(() => {
    if (!socket) {
      return
    }
    const onReceiveMessage = (data) => handleMessages(data)

    socket.on('chat_receive_message', onReceiveMessage)
    socket.on('chat_change_message', onChatChangeMessage)

    return () => {
      socket.off('chat_receive_message', onReceiveMessage)
      socket.off('chat_change_message', onChatChangeMessage)
    }

  }, [socket])

  useEffect(() => {
    if (messages.length) {
      if (messages[messages.length - 1].from === 'system') {
        return
      }
      if (messages[messages.length - 1].isChatMuted && messages[messages.length - 1].userId !== name) {
        return
      }
      setUnreadMessages(prevState => prevState + 1)
    }
  }, [messages])

  useOnClickOutside({current: controlsRef.current}, hideMobileMenu)

  const handleMessages = (data, chat) => {
    setMessages((prevState) => [...prevState, data])

    if (data.mentioned?.some(msg => msg.roomId === roomId)) {
      setBubbleMessages((prevState) => ([
        ...prevState,
        data,
      ]))
      setLastBubbleMessage(data)
    }
  }
  const onNeedHelp = (reaction) => {
    let doNeedHelp = !isNeedHelp
    if (reaction && reaction === reactions[name]) {
      doNeedHelp = false
    } else {
      doNeedHelp = true
    }


    setReactions({
      ...reactions,
      [name]: doNeedHelp && reaction,
    })

    setNeedHelp(doNeedHelp)
    socket.emit('ask-for-help', {name, doNeedHelp, reaction})
  }
  const onCleanScreen = () => {
    if (!task) {
      return
    }

    // TODO стирать только то что нарисовал ученик ?
    if (!task.slide) {
      socket.emit(`canvas:viewer:clear-all`, { name, task: viewerSettings.emptyCanvasTask ? emptyCanvasTask : task })
    } else {
      socket.emit('epub:viewer:clear-all', { name, task: viewerSettings.emptyCanvasTask ? emptyCanvasTask : task })
    }
  }

  const onChangeSettingMediaDevice = async ({kind, source}) => {
    if (kind === 'audioSource') {
      setAudioSource(source)
      localStorage.setItem('audioSource', source)
      const newSource = await changeAudio({audioSource})
      newSource.getAudioTracks()[0].enabled = !isMuted
      setPupilStream({...pupilStream, audio: newSource})
    }
    if (kind === 'videoSource') {
      setVideoSource(source)
      localStorage.setItem('videoSource', source)
      const newSource = await changeVideo({videoSource})
      setPupilStream({...pupilStream, video: newSource})
    }
  }

  const getOtherPupilsVisible = (data) => {
    const {
      otherPupils,
      directMode,
      settings,
      settingsByTag,
      tags,
    } = data

    const res = otherPupils.map((otherName) => {
      if (
        directMode && directMode.value === 'selected'
        && !hasSelection(tags[name])
        && hasSelection(tags[otherName])
      ) {

        return null
      }

      /*
        Специальный кейс 2
        Когда у учителя приват с мной, и другой ученик не селектед
        - я его не слышу, даже если все другие истории выполняются
      */
      if (
        directMode && directMode.value === 'selected'
        && hasSelection(tags[name])
        && !hasSelection(tags[otherName])
      ) {

        return null
      }
      if ((settings && settings?.sound === 'all')
        || (hasCommonGroupAndGroupSoundIsAll({         // Когда мы в одной группе и учитель этой группе поставил настройку прямого общения
          myName: name,
          otherName: otherName,
          settingsByTag,
          tags,
        }))
      ) {

        if (directMode && directMode.value === 'selected'
          && !hasSelection(tags[name])
          && hasSelection(tags[otherName])
        ) {
        } else {
          return otherName
        }
      }
    })
    return compact(res)
    /* Когда один ученик слышит другого */
  }

  const otherPupilsVisible = useMemo(() => getOtherPupilsVisible({
    otherPupils,
    directMode,
    settings,
    settingsByTag,
    tags,
  }), [otherPupils, directMode, settings, settingsByTag, tags])

  const [otherPupilsVisibleFromRoomGroup, setOtherPupilsVisibleFromRoomGroup] = useState([])
  const [allOtherPupilsVisibleFromRoomGroup, setAllOtherPupilsVisibleFromRoomGroup] = useState([]) /* - для отображения просто всех учеников, которые состоят в группах, для того чтобы ученик который не в группе не видел тех которые в группах*/


  useEffect(() => {
    setOtherPupilsVisibleFromRoomGroup(getOtherPupilsVisibleFromRoomGroup(settings))
    setAllOtherPupilsVisibleFromRoomGroup(getAllOtherPupilsVisibleFromRoomGroup(settings))
  }, [name, settings])

  const getOtherPupilsVisibleFromRoomGroup = (settings) => {
    const pupils = settings?.roomGroups?.map(((roomGroup) => {
      if (roomGroup?.selectedPupils?.includes(name)) {
        return  roomGroup?.selectedPupils
      } else {
        return []
      }
    })).flat()

    return pupils
  }

  const getAllOtherPupilsVisibleFromRoomGroup = (settings) => {
    const pupils = settings?.roomGroups?.map(((roomGroup) => {
      return  roomGroup?.selectedPupils
    })).flat()

    return pupils
  }

  /* - для отображения просто всех учеников, которые состоят в группах, для того чтобы ученик который не в группе не видел тех которые в группах*/
  const otherPupilsWithOutGroup = difference(otherPupils, allOtherPupilsVisibleFromRoomGroup)
  const [isMutedTeacherSound, setIsMutedTeacherSound] = useState('')

  useEffect(() => {
    // сравниваем с активностью группы и наличия текущего ученика в ней,
    // по этому состоянию отключаем/включаем звук учителя

    // вначале всегда проверять какие настройки микрофона от учителя и уже потом включать его звук для выбранных учеников
    if (settings?.presenterAudio === true) {
      // пока не создали группы, все ученики смотрят на settings?.presenterAudio
      setIsMutedTeacherSound(true)

      if (viewerSettings.isSelected) {
        setIsMutedTeacherSound(true)
      }
      // если есть группы и у данного ученика группа не активна, т.е. свойство isSelected не присвоено
      if (!viewerSettings.isSelected && settings?.roomGroups?.length) {
        setIsMutedTeacherSound(false)
      }
    } else {
      setIsMutedTeacherSound(false)
    }

  },[otherPupilsVisibleFromRoomGroup, settings, viewerSettings, settings?.roomGroups])

  console.log('isMutedTeacherSound ',isMutedTeacherSound)




    return (
    <div className={styles.container} ref={containerRef}>
      {iceFetchPending && <Loader text={'Идёт создание урока...'}/>}

      <VideosContainer currentView={viewerSettings.viewType || viewTypes.GALLERY} settings={settings} otherVisibleViewersCount={otherPupilsVisible?.length || 0} >
        <PersonVideoContainer
          isMute={!isMutedTeacherSound}
          displayName={`${presenter ? presenter.name : ''} (Учитель)`}
          currentViewType={viewerSettings.viewType || viewTypes.GALLERY}
        >
          <PresenterVideo
            socket={socket}
            isPresenterCame={isPresenterCame}
            presenterRoomId={presenterRoomId}
            isMuted={!isMutedTeacherSound}
            isVideoMuted={!settings?.presenterVideo}
          />
        </PersonVideoContainer>

        <PersonVideoContainer
          displayName={`${displayName} (Я)`}
          isMute={viewerSettings.isSoundMute}
          currentViewType={viewerSettings.viewType || viewTypes.GALLERY}
          reaction={reactions[name]}
        >
          <LocalViewerVideo
            socket={socket}
            name={name}
            isMobile={true}
            roomId={roomId}
            mode={mode}
            connectionState={connectionState}
            onStartFetchICEServer={onStartFetchICEServer}
            onFinishFetchICEServer={onFinishFetchICEServer}
            videoSource={videoSource}
            audioSource={audioSource}
            setError={setError}
            viewerSettings={viewerSettings}
            needStopStreaming={needStopLocalStreaming}
            pupilStream={pupilStream}
            setPupilStream={setPupilStream}
          />
        </PersonVideoContainer>

        {
          otherPupilsVisibleFromRoomGroup?.length > 0
              ?
              otherPupilsVisibleFromRoomGroup.map(otherViewerName => {
                    // показываем только тех учеников которые в одной группе с учеником
                    if (otherViewerName !== name) {
                      return (
                          <PersonVideoContainer
                              hide={viewerSettings.viewType === viewTypes.REPORT || !otherPupilsVisible || !otherPupilsVisible.includes(otherViewerName)}
                              isMute={viewersMuted.includes(otherViewerName)}
                              currentViewType={viewerSettings.viewType || viewTypes.GALLERY}
                              displayName={displayNamesSet[otherViewerName]}
                              reaction={reactions[otherViewerName]}
                          >
                            <ViewerVideo
                                socket={socket}
                                viewer={{name: otherViewerName, displayName: displayNamesSet[otherViewerName], connectedAt: true}}
                                muted={viewerSettings.viewType === viewTypes.REPORT || !otherPupilsVisible || !otherPupilsVisible.includes(otherViewerName) || viewersMuted.includes(otherViewerName)}
                                hide={viewerSettings.viewType === viewTypes.REPORT || !otherPupilsVisible || !otherPupilsVisible.includes(otherViewerName)}
                            />
                          </PersonVideoContainer>
                      )
                    }
                  }
              )
              :
              otherPupilsWithOutGroup.map(otherViewerName => (
                  <PersonVideoContainer
                      hide={viewerSettings.viewType === viewTypes.REPORT || !otherPupilsVisible || !otherPupilsVisible.includes(otherViewerName)}
                      isMute={viewersMuted.includes(otherViewerName)}
                      currentViewType={viewerSettings.viewType || viewTypes.GALLERY}
                      displayName={displayNamesSet[otherViewerName]}
                      reaction={reactions[otherViewerName]}
                  >
                    <ViewerVideo
                        socket={socket}
                        viewer={{name: otherViewerName, displayName: displayNamesSet[otherViewerName], connectedAt: true}}
                        muted={viewerSettings.viewType === viewTypes.REPORT || !otherPupilsVisible || !otherPupilsVisible.includes(otherViewerName) || viewersMuted.includes(otherViewerName)}
                        hide={viewerSettings.viewType === viewTypes.REPORT || !otherPupilsVisible || !otherPupilsVisible.includes(otherViewerName)}
                    />
                  </PersonVideoContainer>
              ))
        }
      </VideosContainer>

      <div className={cn(styles.taskContainer, {[styles.hide]: viewerSettings.viewType === viewTypes.REPORT || (viewerSettings.viewType === viewTypes.MOSAIC && otherPupils?.length)})}>
        <Task
          socket={socket}
          settings={settings}
          name={name}
          task={task}
          viewerSettings={viewerSettings}
          connectionState={connectionState}
          error={error}
        />
      </div>

      <Menu
        socket={socket}
        name={name}
        task={viewerSettings.emptyCanvasTask ? emptyCanvasTask : task}
        viewerSettings={viewerSettings}
        setViewerSettings={setViewerSettings}
        messages={messages}
        unReadMessages={unreadMessages}
        onOpenChat={() => setUnreadMessages(0)}
        isChatMuted={isChatMuted}
        pupilId={pupilId}
        displayName={displayName}
        timer={timers[name]}
        onClickReaction={onNeedHelp}
        onCleanScreen={onCleanScreen}
        onChangeSettingMediaDevice={onChangeSettingMediaDevice}
      />
    </div>
  )
}
