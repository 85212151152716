import superagent from 'superagent'

export async function getPupilgroups(query) {
  const resp = await superagent.get('/api/pupilgroup/list').query(query)
  return resp.body
}
export async function getPupilgroup(pupilId) {
  const resp = await superagent.get(`/api/pupilgroup/${pupilId}`)
  return resp.body
}
export async function createPupilgroup(pupil) {
  const resp = await superagent.post('/api/pupilgroup')
    .send(pupil)
  return resp.body
}
export async function updatePupilgroup(pupilId, pupil) {
  const resp = await superagent.put(`/api/pupilgroup/${pupilId}`)
    .send(pupil)
  return resp.body
}
export async function deactivatePupilgroup(pupilId) {
  const resp = await superagent.delete(`/api/pupilgroup/${pupilId}`)
  return resp.body
}
export async function getForDemo(hashId) {
  const resp = await superagent.get(`/api/pupilgroup/get-for-demo/${hashId}`)
  return resp.body
}

export async function getPupilByHash(hashId) {
  const resp = await superagent.get(`/api/pupilgroup/get-pupil-by-hash/${hashId}`)
  return resp.body
}

export default {
  getPupilgroups,
  getPupilgroup,
  createPupilgroup,
  updatePupilgroup,
  deactivatePupilgroup,
  getPupilByHash
}
