import React from 'react'
import { ReactComponent as BtnSVG } from "./img/micro-on-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/micro-off-btn.svg";
import styles from './button.module.css'


const MicroBtn = ({isMuted = false, isDisabled = false, onClick}) => {
  return <button className={styles.btn} disabled={isDisabled} onClick={onClick}>
    { (!isMuted) ? <BtnSVG /> : <BtnActiveSVG /> }
  </button>
}

export default MicroBtn
