import React from 'react'
import { Redirect } from "react-router-dom"
import checkRole from '../utils/checkRole'

export default function checkRedirects(user) {
  if (!user) {
    return null
  }
  if(sessionStorage.getItem('redirectUrl')) {
    window.location.replace(sessionStorage.getItem('redirectUrl'));
  }
  if (
    checkRole(user, 'admin')
    || checkRole(user, 'franchisee-admin')
    || checkRole(user, 'presenter')
  ) {
    return <Redirect to={{ pathname: "/admin" }} />
  }
  // if (checkRole(user, 'manager')) return <Redirect to={{ pathname: "/admin" }} />
  return <Redirect to={{ pathname: "/" }} />
}