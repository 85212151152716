import React from 'react'
import styles from './popupBtnSelect.module.css'
import { ReactComponent as ArrowRightSVG } from './img/arrow-right.svg'


const PopupBtnSelect = ({
  children,
  title,
  svgIcon,
  value,
  valueText,
  selectId,
  onSelectChange,
}) => {

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {svgIcon}
        <div className={styles.titleAndDescriptionContainer}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{valueText}</span>
        </div>
      </div>
      <ArrowRightSVG/>
      <select className={styles.select} value={value} id={selectId} onChange={onSelectChange}>
        {children}
      </select>
    </div>
  )
}


export default PopupBtnSelect