import React, { useRef, useEffect, useState } from 'react'
import throttle from 'lodash/throttle'
import intersection from 'lodash/intersection'
import intersectionWith from 'lodash/intersectionWith'

import get from 'lodash/get'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";
import usePortal from 'react-useportal'

import './OtherPupils.css'
import styles from './ViewerScreen/viewerScreenNext.module.css'
import Avatar from "./components/Avatar/Avatar";
import { createPeerConntection, createOffer, createPeerConnectionNext } from './webrtc'
import checkPupilSpeaking from './utils/checkPupilSpeaking'
import { ReactComponent as IconUnMute } from "./ViewerScreen/img/icon-unmute.svg";
import {difference} from "lodash";

function hasGroups(tags) {
  if (!tags || tags.length === 0) {
    return false
  }
  if (tags.length > 1) {
    return true
  }
  return tags[0] !== 'selected'
}

function muteAudio(elem) {
  if (!elem) {
    return
  }
  let prevValue = elem.volume
  elem.volume = 0
  if (elem.volume === prevValue) {
    elem.muted = true
  }
}

function unmuteAudio(elem) {
  if (!elem) {
    return
  }
  elem.volume = 1
  if (elem.muted) {
    elem.muted = false
  }
}

function hasSelection(tags) {
  return tags && tags.indexOf('selected') > -1
}

export function hasCommonGroupAndGroupSoundIsAll({
  myName,
  otherName,
  settingsByTag,
  tags,
}) {
  if (!tags[myName] || !tags[otherName] || !settingsByTag) {
    return false
  }

  const commonTags = intersection(tags[myName], tags[otherName])

  // console.log(
  //   'hasCommonGroupAndGroupSoundIsAll',
  //   { myName, otherName },
  //   commonTags,
  //   settingsByTag,
  // )

  for (const tag of commonTags) {
    if (
      tag !== 'selected' && settingsByTag[tag]
      && settingsByTag[tag].sound === 'all'
    ) {
      return true
    }
  }
  return false
}

function OtherPupil({
  socket,

  myName,
  name = '',
  displayName = '',
  onAudioElement,
  isSpeaking,
  directMode,
  settings,
  settingsByTag,
  tags,
  classItem,
  classItemName,
  isOnlyAvatar,
  reaction,
  isMuted,
}) {
  const audioRef = useRef(null)
  const videoRef = useRef(null)
  const [isShowVideo, setShowVideoFlag] = useState(false)
  const [soundMode, setSoundMode] = useState('silent')
  const location = useLocation()
  const isVideo = true // location.hash === '#video'

  const { Portal } = usePortal()

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  // const commonTags = intersectionWith(tags[myName], tags[name], (a, b) => a !== 'selected' && b !== 'selected' && a === b)

  const isDesktop = isVideo && isDesktopOrLaptop;

  useEffect(() => {
    // if (isVideo) {      
    //   if (name &&  audioRef.current && videoRef.current) {
    //     onAudioElement(name, audioRef.current, videoRef.current)
    //   }
    // } else 
    if (name && audioRef.current) {
      onAudioElement(name, audioRef.current)
    }
  }, [name, audioRef])
  // tags влияют на кол-во людей. не нужно дополнительно по ним ещё реагировать

  useEffect(() => {
    console.log('ask video for', name, videoRef)
    if (!name || !videoRef.current) {
      return
    }

    const video = videoRef.current
    // Видео получаем только если есть видео элемент
    let _pc = null
    createPeerConnectionNext(
      socket,
      video,
      { input: `video:${name}` },
    ).then(({ pc, connectionId }) => {
      _pc = pc
      pc.addTransceiver('video', { direction: 'recvonly' })
      console.log('asking video', name, connectionId)

      createOffer(pc).then((offer) => {
        socket.emit('ask-video', { name, connectionId, offer: offer.sdp })
      })
    })

    return () => {
      // clear video
      if (_pc) {
        _pc.close()
      }
    }
  }, [name, videoRef, isShowVideo])

  useEffect(() => {
    if (!audioRef.current) {
      return
    }
    const audio = audioRef.current

    /*
      Специальный кейс 1
      Когда у учителя приват с селектед, и другой ученик селектед, а я нет
      - я его не слышу, даже если все другие истории выполняются
    */
    if (
      directMode && directMode.value === 'selected'
      && !hasSelection(tags[myName])
      && hasSelection(tags[name])
    ) {
      setSoundMode('silent')
      muteAudio(audio)
      setShowVideoFlag(false)
      return
    }

    /*
      Специальный кейс 2
      Когда у учителя приват с мной, и другой ученик не селектед
      - я его не слышу, даже если все другие истории выполняются
    */
    if (
      directMode && directMode.value === 'selected'
      && hasSelection(tags[myName])
      && !hasSelection(tags[name])
    ) {
      setSoundMode('silent')
      muteAudio(audio)
      setShowVideoFlag(false)
      return
    }


    // console.log(
    //   'my tags', hasGroups(tags[myName]),
    //   'other tags', tags[name],
    //   'settings.sound', settings && settings.sound,
    // )

    /* Когда один ученик слышит другого */
    if (
      // Когда учитель поставил глобальную галочку, что ученики слышат друг друга

      // И при этом я и другой ученик не в группе
      // Поправка от Хозяина — вроде как, условия на группы не обязательно. Галочка видна всегда
      // и работает насквозь.
      (
        settings && settings.sound === 'all'
        // && !hasGroups(tags[myName])
        // && !hasGroups(tags[name])
      )

      ||

      // Когда у учителя приват с selected, и оба ученика selected
      // (
      //   directMode && directMode.value === 'selected'
      //   && hasSelection(tags[myName])
      //   && hasSelection(tags[name])
      // )

      // ||

      // Когда мы в одной группе и учитель этой группе поставил настройку
      // прямого общения
      (
        hasCommonGroupAndGroupSoundIsAll({
          myName,
          otherName: name,
          settingsByTag,
          tags,
        })
      )
    ) {
      setSoundMode('noisy')
      unmuteAudio(audio)
      setShowVideoFlag(true)
    } else {
      setSoundMode('silent')
      muteAudio(audio)
      setShowVideoFlag(false)
    }

  }, [myName, name, audioRef, directMode, settingsByTag, tags, settings])


  // if (isDesktop && !commonTags.length) {
  //   return null
  // }

  const firstCharAt = name[0]?.toUpperCase()
  const nameSplit = name.split('_')
  const bgColor = nameSplit[0]

  // const classNameAvatar = isSpeaking ? 'other-pupil_avatar other-pupil_avatar__isSpeaking' : 'other-pupil_avatar'
  const clAvatar = classNames({
    'other-pupil_avatar': true,
    'other-pupil_avatar__isSpeaking': soundMode === 'noisy' && isSpeaking,
  })
  const clAudio = classNames({
    'other-pupil_audio': true,
    'other-pupil_audio__debug': Boolean(localStorage.debugIQ),
  })


  const isSpeakingAva = soundMode === 'noisy' && isSpeaking



  // console.log('OtherPupil', name, 'showVideo=', isShowVideo)

  const blockCl = classNames(classItem, {
    'face_speaking': isSpeaking,
    'display-none': !isShowVideo || isOnlyAvatar
  })
  return (
    <>
      {isOnlyAvatar && isShowVideo &&
        <span className={styles.isOnlyAvatar}>
          <Avatar name={name} isRound isSpeaking={isSpeakingAva} reaction={reaction} />
        </span>
      }
      {isShowVideo && <div className={blockCl}>
      {Boolean(localStorage.debugIQ) && soundMode}
          {reaction && (
              <div className="face-item-reaction">
                  <span
                      className={`icon icon_${reaction} icon_dark`}
                  />
              </div>
          )}

          {isMuted && (
            <div className="face-item-mute-icon">
              <IconUnMute
                className={classNames(
                    "icon"
                )}
            />
            </div>

          )}
          <div className={classNames('face-item-name', classItemName)}>
            <span>
              {displayName}
            </span>
          </div>
          {
            isShowVideo ? (
              <>
              <div className="video-gradient"/>
              <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                className={styles.video}
              />
              </>
            ) :
              <div className="face-item-avatar"><Avatar name={name} noBg isRound={false} /></div>
          }
    </div>}
          <Portal>
          <audio
            className={clAudio}
            ref={audioRef}
            controls
            volume="true"
            autoPlay
          />
          </Portal>
    </>
    )
}

export default function OtherPupils({
  myName,
  displayNamesSet,
  socket,
  otherPupils,
  directMode,
  settings,
  settingsByTag,
  tags,
  classItem,
  classItemName,
  isOnlyAvatar,
  reactions,
  viewersMuted,
  otherPupilsVisibleFromRoomGroup,
  allOtherPupilsVisibleFromRoomGroup
}) {
  const [speakingName, setSpeekingName] = useState(false)
  const location = useLocation()
  const isVideo = true // location.hash === '#video'

  const setSpeekingNameThrottled = throttle(setSpeekingName, 800);
  const resetSpeekingNameThrottled = throttle(setSpeekingName, 3000, {leading: false});

  /* - для отображения просто всех учеников, которые состоят в группах, для того чтобы ученик который не в группе не видел тех которые в группах*/
  const otherPupilsWithOutGroup = difference(otherPupils, allOtherPupilsVisibleFromRoomGroup)

  const onAudioElement = async (name, audioElement) => {
    if (!audioElement) {
      return
    }

    console.log('onAudioElement name=', name)

    // Аудио дорожку получаем всегда
    const { pc, connectionId } = await createPeerConnectionNext(
      socket,
      audioElement,
      { input: `audio:${name}` },
      (stream) => checkPupilSpeaking(stream, (isSpeaking) => {
        if (isSpeaking) {
          setSpeekingNameThrottled(name)
          resetSpeekingNameThrottled.cancel()
        } else {
          resetSpeekingNameThrottled(false)
        }
      }
    ))
    pc.addTransceiver('audio', { direction: 'recvonly' })
    createOffer(pc)
      .then((offer) => socket.emit('ask-audio', { name, connectionId, offer: offer.sdp }))
  }

    if (isVideo) {
        return <>
            {
                otherPupilsVisibleFromRoomGroup?.length > 0
                    ?
                    otherPupilsVisibleFromRoomGroup.map(i => {
                      // показываем только тех учеников которые в одной группе с учеником
                          if (i !== myName) {
                            return (
                                <OtherPupil
                                    reaction={reactions[i]}
                                    isOnlyAvatar={isOnlyAvatar}
                                    socket={socket}
                                    isMuted={viewersMuted && viewersMuted.includes(i)}
                                    classItem={classItem}
                                    classItemName={classItemName}
                                    myName={myName}
                                    key={i}
                                    name={i}
                                    displayName={displayNamesSet && displayNamesSet[i] || ''}
                                    onAudioElement={onAudioElement}
                                    isSpeaking={i === speakingName}
                                    directMode={directMode}
                                    settings={settings}
                                    settingsByTag={settingsByTag}
                                    tags={tags}
                                />
                            )
                          }
                        }
                    )
                    :
                    otherPupilsWithOutGroup.map(i => (
                          <OtherPupil
                              reaction={reactions[i]}
                              isOnlyAvatar={isOnlyAvatar}
                              socket={socket}
                              isMuted={viewersMuted && viewersMuted.includes(i)}
                              classItem={classItem}
                              classItemName={classItemName}
                              myName={myName}
                              key={i}
                              name={i}
                              displayName={displayNamesSet && displayNamesSet[i] || ''}
                              onAudioElement={onAudioElement}
                              isSpeaking={i === speakingName}
                              directMode={directMode}
                              settings={settings}
                              settingsByTag={settingsByTag}
                              tags={tags}
                          />
                    ))
            }
        </>
    }
    return (
        <div className="other-pupils">
            {
                otherPupilsVisibleFromRoomGroup?.length > 0
                    ?
                    otherPupilsVisibleFromRoomGroup.map(i => {
                          // показываем только тех учеников которые в одной группе с учеником
                          if (i !== myName) {
                            return (
                                <OtherPupil
                                    socket={socket}

                                    classItem={classItem}
                                    classItemName={classItemName}
                                    myName={myName}
                                    key={i}
                                    name={i}
                                    onAudioElement={onAudioElement}
                                    isSpeaking={i === speakingName}
                                    directMode={directMode}
                                    settings={settings}
                                    settingsByTag={settingsByTag}
                                    tags={tags}
                                />
                            )
                          }
                        }
                    )
                    :
                    otherPupilsWithOutGroup.map(i => (
                        <OtherPupil
                            socket={socket}

                            classItem={classItem}
                            classItemName={classItemName}
                            myName={myName}
                            key={i}
                            name={i}
                            onAudioElement={onAudioElement}
                            isSpeaking={i === speakingName}
                            directMode={directMode}
                            settings={settings}
                            settingsByTag={settingsByTag}
                            tags={tags}
                        />
                    ))
            }
        </div>
    )
}
