import React, { useEffect } from 'react'


//Если отвалиться шаринг текущей вкладки, нужно доделать этот элемент, чтобы он отправлял чанки на бек, и открывать этот элемент в попапе
const RecordLesson = () => {
  const getMediaStream = async () => {
   try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: {mediaSource: "screen"},
        audio: {sampleRate: 44100}
      })

     console.log('получил стрим', captureStream)
    } catch (err) {
     console.log('случилась ошибка', err)
   }
  }

  useEffect(() => {
    getMediaStream()
  }, [])

  return <div style={{background: 'red', height: '100vh', width: '100vw'}}>
    Хочу начать запись -_-
  </div>
}

export default RecordLesson