import React, { useRef, useState, useEffect } from 'react'
import { nanoid } from 'nanoid'
import io from 'socket.io-client'
import cn from 'classnames'

import styles from './connectTest.module.scss'
import useBrowserInfo from './useBrowserInfo'

import {
  createPeerConntectionEx,
  addStreamToConnection,
  createOffer,
  initSignaling,
  createPeerConnectionNext,
} from './webrtc'

// import {
//   postError
// } from './api/log'

function pushText(textAreaRef, someName, someThing){
  if (!textAreaRef.current) {
    console.log('log', someName, someThing)
    return
  }

  const textArea = textAreaRef.current
  textArea.value += `${someName}: ${someThing}\n\n`
}

const lutTr = {
  pending: 'Идёт проверка',
  failed: 'Ошибка',
  succeed: 'Всё отлично'
}

// function submitError(errorText) {
//   console.log('push error', errorText)
//   postError(errorText)
// }

export default function ConnectTest() {
  const [checkState, setCheckState] = useState('succeed')
  const [roomId, setRoomId] = useState(null)
  const [videoAccess, setVideoAccess] = useState({
    localVideo: false,
    remoteVideo: false
  })

  const localVideoRef = useRef(null)
  const remoteVideoRef = useRef(null)
  const textAreaRef = useRef(null)
  const audioRef = useRef(null)

  const [error, setError] = useState()

  const onError = (error) => {
    setCheckState('failed')
    setError(error)
    // submitError(error)
  }

  const { osName, browserName, version, navigatorString } = useBrowserInfo()

  useEffect(() => {
    if (!localVideoRef.current || !remoteVideoRef.current || !textAreaRef.current) {
      return
    }

    let socket = null
    
    try {
      const remoteVideo = remoteVideoRef.current
      remoteVideo.onloadedmetadata = (e) => {
        setVideoAccess({...videoAccess, remoteVideo: true})
        console.log('on meta loaded')
        setCheckState('succeed')
      }

      socket = io()
      initSignaling(socket, setError)

      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      })
      .then(async (stream) => {

        localVideoRef.current.srcObject = stream
        setVideoAccess({...videoAccess, localVideo: true})

        const { pc, connectionId } = createPeerConntectionEx(
          socket, 
          remoteVideo,
        )

        addStreamToConnection(pc, stream)

        const offer = await createOffer(pc)
        pushText(textAreaRef, 'offer', offer.sdp)

        const roomId = nanoid()
        setRoomId(roomId)
        socket.emit('presenter:init', { roomId, connectionId, offer: offer.sdp })
      })
      .catch((e) => {
        onError(e.toString())
      })
    } catch (e) {
      onError(e.toString())
    }

    return () => {
      if (socket && socket.close()) {
        socket.close()
      }      
    }
  }, [])

  const logStyle = {
    display: 'none',
  }
  const statusCl = cn(styles.bTestStatus, styles[checkState])

  const playTestSound = () => {
    const audio = audioRef.current
    audio.play()
  }
  return (
    <div className={cn("sample-vector",styles.page)}>
      <div className={statusCl} >
        <div className={styles.icon}/>
        <span>{lutTr[checkState]}</span>
      </div>
      <div className={styles.content}>
        <h1>Тестирование устройств</h1>
        {/*{ error && <div className="presenter-error">*/}
        {/*  { error }*/}
        {/*</div> }*/}
        <div className={styles.versionsBlock}>
          Операционная система: <span className={styles.boldText}>{osName}</span> <br />
          Ваш браузер: <span className={styles.boldText}>{browserName} v{version}</span>
        </div>
        <div className={styles.musicBlock}>
          Воспроизведение аудио:
          <audio ref={audioRef} src="/images/test/test_music.wav" />
          <div onClick={playTestSound} className={cn(styles.musicButton, styles.blueText)}>Проиграть <div className={styles.icon}/></div>
        </div>
        <div className={styles.videoInfo}>
          Вы должны увидеть видео с камеры
          {/*Вы должны увидеть два видео: <br />*/}
          {/*<span className={styles.plainText}>одно с камеры, и второе с камеры, но вернувшееся с сервера</span>*/}
        </div>
        <div className={styles.bVideoTest}>
          <div className={styles.videoPlaceholder}>
            <video className={styles.localVideo} ref={localVideoRef} autoPlay playsInline muted />
            <div className={cn(styles.videoTitle, {
              [styles.success]:videoAccess.localVideo
            })}>
              <div className={styles.icon}/>
              <span>Видео с камеры</span>
            </div>
          </div>
          <div className={cn(styles.videoPlaceholder, "hide")}>
            <video className={styles.localVideo} ref={remoteVideoRef} autoPlay playsInline muted />
            <div className={cn(styles.videoTitle, {
              [styles.success]:videoAccess.remoteVideo
            })}>
              <div className={styles.icon}/>
              <span>Видео с сервера</span>
            </div>
          </div>
        </div>
        {checkState !== 'pending' &&
        <div className={styles.comment}>
          <div className={styles.commentBlock}>
            <div className={styles.commentText}>
              {checkState === 'failed' &&
              <span>Если у вас не работает видео,<span className={styles.plainText}> сделайте <span className={styles.blueText}>скриншот <div className={styles.icon}/></span>
                и отправьте его менеджеру на <span className={styles.blueText}>support@iq007.ru</span></span></span>
              }
              {checkState === 'succeed' &&
              <span>Ура! У вас всё работает. Если возникнут вопросы,
                задайте их менеджеру на <span className={styles.blueText}>support@iq007.ru</span></span>
              }
            </div>
          </div>
          <div className={styles.commentFox}>
            <img src={checkState==='failed' ? "/images/test/fox_1.png" :"/test/fox.png"} alt="fox"/>
          </div>
        </div>}
        <div>
          <textarea className="log-text-area" style={logStyle} ref={textAreaRef} spellCheck="false" />
        </div>
      </div>
    </div>
  )
}