import { useEffect, useState } from 'react'
import 'rangy/lib/rangy-classapplier'
import 'rangy/lib/rangy-highlighter'
import 'rangy/lib/rangy-serializer'
import { epubPointer } from "../utils/epubPointer";

export default function useHightLight(
  ref,
  socket,
  name,
  task,
  type,
  isPreview = false,
  charCount
) {

  const [instrument, setInstrument] = useState() //pointer, pointerBySelect, curtain
  const [pointerState, setPointerState] = useState(null)
  const [curtainDirection, setCurtainDirection] = useState('left')

  useEffect(() => {
    if (!ref || !ref.current || !socket || !name || !task) {
      return
    }

    const pointerOnAddIndex = (index) => {
      socket.emit('epub:pointer:add-index', { name, index })
    }
    const pointerOnAddSelectIndexes = (selectObj) => {
      socket.emit(`epub:${type}:add-selection`, { name, task, selectObj })
    }

    const pointerOnStartNewSelect = () => {
      socket.emit('epub:pointer:start-new-select', { name })
    }

    const onChangeIndexOfBorder = (index) => {
      socket.emit('epub:presenter:curtain-index-of-border', { name, task, index })
    }

    const pointer = epubPointer({
      container: ref.current,
      type,
      onAddIndex: pointerOnAddIndex,
      slideNum: task.index,
      onAddSelectIndexes: pointerOnAddSelectIndexes,
      onStartMakeNewSelect: pointerOnStartNewSelect,
      name,
      readOnly: isPreview,
      charCount,
      onChangeIndexOfBorder,
    })

    const askTaskStorage = () => {
      socket.emit('room:task-storage:ask', { name, task })
    }

    const onGetTaskStorage = ({ name: viewerName, taskStorage }) => {
      if (viewerName !== name) {
        return
      }

      pointer.onGetStorage(taskStorage)
      pointer.setInstrument(taskStorage.mode)
      pointer.setCurtainDirection(taskStorage.curtainData?.direction || '')
      pointer.setCurtainIndexOfBorder(taskStorage.curtainData?.indexOfBorder || null)
      setInstrument(taskStorage.mode)
      setCurtainDirection(taskStorage.curtainData?.direction || 'left')
    }

    const onChangeInstrument = ({ name: viewerName, mode }) => {
      if (viewerName !== name) {
        return
      }

      setInstrument(mode)
      pointer.setInstrument(mode)
    }

    const onAddCurtainIndexOfBorder = ({ name: viewerName, index }) => {
      if (viewerName !== name) {
        return
      }

      pointer.setCurtainIndexOfBorder(index)
    }

    const onChangeCurtainDirection = ({ name: viewerName, direction }) => {
      if (viewerName !== name) {
        return
      }

      pointer.setCurtainDirection(direction)
      setCurtainDirection(direction)
    }

    const pointerAddIndexFromPresenter = ({name, index}) => {
      pointer.addIndex(index)
    }

    const pointerStartNewSelectFromTeacher = ({ name }) => {
      pointer.clearStore()
    }
    const pointerAddSelectIndexes = (data) => {
      const {name: viewerName, selectObj} = data

      if (viewerName !== name) {
        return
      }

      pointer.addSelectIndexes(selectObj)
    }

    askTaskStorage()
    socket.on('room:task-storage', onGetTaskStorage)

    if (type === 'viewer' || type === 'supervisor') {
      socket.on('epub:pointer:add-index', pointerAddIndexFromPresenter)
      socket.on('epub:pointer:start-new-select', pointerStartNewSelectFromTeacher)
      socket.on('epub:presenter:add-selection', pointerAddSelectIndexes)
      socket.on('epub:teacher:mode', onChangeInstrument)
      socket.on('epub:presenter:curtain-index-of-border', onAddCurtainIndexOfBorder)
      socket.on('epub:presenter:curtain-direction', onChangeCurtainDirection)
    }

    if (type === 'presenter' || type === 'supervisor') {
      socket.on('epub:viewer:add-selection', pointerAddSelectIndexes)
      socket.on('epub:teacher:mode', onChangeInstrument)
    }

    if (isPreview) {
      socket.on('epub:viewer:add-selection', pointerAddSelectIndexes)
      socket.on('epub:presenter:add-selection', pointerAddSelectIndexes)
    }

    setPointerState(pointer)
    return () => {
      pointer.destroy()
      socket.off('epub:pointer:add-index', pointerAddIndexFromPresenter)
      socket.off('epub:pointer:start-new-select', pointerStartNewSelectFromTeacher)
      socket.off('epub:viewer:add-selection', pointerAddSelectIndexes)
      socket.off('epub:presenter:add-selection', pointerAddSelectIndexes)
      socket.off('room:task-storage', onGetTaskStorage)
      socket.off('epub:teacher:mode', onChangeInstrument)
      socket.off('epub:presenter:curtain-index-of-border', onAddCurtainIndexOfBorder)
      socket.off('epub:presenter:curtain-direction', onChangeCurtainDirection)
    }
  }, [ref, ref?.current, socket, name, task, isPreview, charCount])

  return { instrument, setInstrument, pointerState, curtainDirection, setCurtainDirection }
}
