import superagent from "superagent";

export async function createPupilReactionByPupilGroupHashId({payload, pupilGroupHashId}) {
  const resp = await superagent.post(`/api/pupil-reactions/by-hash-id/${pupilGroupHashId}`).send(payload);
  return resp.body;
}

export async function getPupilReactionsByPupil(pupilId) {
  const resp = await superagent.get(`/api/pupil-reactions/list/by-pupil/${pupilId}`)
  return resp.body;
}