import React, { useEffect, useState } from 'react'


export default function useBrowserInfo() {

  const [browserName, setBrowserName] = useState('')
  const [version, setVersion] = useState('unknown version')
  const [osName, setOsName] = useState('')
  const [navigatorString, setNavigatorString] = useState('')

  useEffect(() => {
    let nVer = navigator.appVersion
    let nAgt = navigator.userAgent
    let browserName = 'unknown browser'
    let fullVersion = '' + parseFloat(navigator.appVersion)
    let majorVersion = parseInt(navigator.appVersion, 10)
    let nameOffset, verOffset, ix

    if ((verOffset = nAgt.indexOf('OPR/')) !== -1) {
      browserName = 'Opera'
      fullVersion = nAgt.substring(verOffset + 4)
    } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browserName = 'Opera'
      fullVersion = nAgt.substring(verOffset + 6)
      if ((verOffset = nAgt.indexOf('Version')) !== -1)
        fullVersion = nAgt.substring(verOffset + 8)
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer'
      fullVersion = nAgt.substring(verOffset + 5)
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome'
      fullVersion = nAgt.substring(verOffset + 7)
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browserName = 'Safari'
      fullVersion = nAgt.substring(verOffset + 7)
      if ((verOffset = nAgt.indexOf('Version')) !== -1)
        fullVersion = nAgt.substring(verOffset + 8)
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox'
      fullVersion = nAgt.substring(verOffset + 8)
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset)
      fullVersion = nAgt.substring(verOffset + 1)
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName
      }
    }

    if ((ix = fullVersion.indexOf(';')) !== -1)
      fullVersion = fullVersion.substring(0, ix)
    if ((ix = fullVersion.indexOf(' ')) !== -1)
      fullVersion = fullVersion.substring(0, ix)

    majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion)
      majorVersion = parseInt(navigator.appVersion, 10)
    }

    let OSName = 'Unknown OS'
    if (nVer.indexOf('Win') !== -1) OSName = 'Windows'
    if (nVer.indexOf('Mac') !== -1) OSName = 'MacOS'
    if (nVer.indexOf('X11') !== -1) OSName = 'UNIX'
    if (nVer.indexOf('Linux') !== -1) OSName = 'Linux'

    setBrowserName(browserName)
    setVersion(majorVersion.toString())
    setOsName(OSName)
    setNavigatorString(nVer)

  }, [])

  return { browserName, version, osName, navigatorString }
}