import React, { useEffect, useMemo, useState } from 'react'
import { createGrade } from '../../../api/grades'
import Input from '../../../components/Input/Input'
import KeyBoard from '../../../components/KeyBoard/KeyBoard'
import RadioGroup from '../../../components/RadioGroup/RadioGroup'
import styles from './taskReview.module.sass'
import cn from 'classnames'
import { createIqTokenHistoryRecord } from "../../../api/iqTokensHistory";


const SCORE_MAX = 20
const CORRECTNESS_MAX = 20


export default function TaskReview({
  task,
  closeGradeModal,
  pupil,
  setCurrentGrade,
  className,
  grade,
  updateGrades,
  onChangeTask,
  roomStrId,
  socket,
}) {
  const [gradeValues, setGradeValues] = useState({
    gradeBinary: 'pass',
    mistakes: 0,
    score: 0,
  })
  const [gradeState, setGradeState] = useState({})

  const keyBoardValScore = useMemo(
    () => Array.from(
      { length: (isNaN(Number(task?.result?.max)) ? SCORE_MAX : Number(task?.result?.max)) + 1 },
      (_, index) => index),
    [SCORE_MAX, task?.result?.max] )

  const keyBoardValMistakes = useMemo(
    () => Array.from(
      { length: (isNaN(Number(task?.result?.max)) ? CORRECTNESS_MAX : Number(task?.result?.max)) + 1 },
      (_, index) => index),
    [SCORE_MAX, task?.result?.max] )

  useEffect(() => {
    if(!grade){
      return
    }

    setGradeState(grade)

    if (grade.kind === 'score') {
      setGradeValues({ ...gradeValues, score: grade.teacherGrade })
    }
    if (grade.kind === 'binary') {
      setGradeValues({ ...gradeValues, gradeBinary: grade.teacherGrade })
    }
  }, [grade])


  const handleChangeGradeForm = (field, value) => {
    let valueNext = value
    if (!isNaN(Number(value)) && task.result?.max) {
      const upperLimit = task.result.max
      valueNext = Math.max(
        0,
        Math.min(
          Number(value),
          upperLimit || Infinity,
        ),
      )
    }
    setGradeValues({
      ...gradeValues,
      [field]: valueNext,
    })
    if (setCurrentGrade) {
      setCurrentGrade({
        ...gradeValues,
        [field]: valueNext,
      })
    }
  }

  const onGradeNumBoardDown = (field, value) => {
    const currentValue = gradeValues[field]
    // const res = Number(`${currentValue}${value}`)
    const res = Number(`${value}`)
    handleChangeGradeForm(field, res)
  }

  const onGradeSubmit = async () => {
    const { gradeBinary, mistakes, score } = gradeValues
    let teacherGrade
    switch (task.result.kind) {
      case 'binary':
        teacherGrade = gradeBinary
        break
      case 'correctness':
        teacherGrade = mistakes
        console.log('mistakes', mistakes)
        break
      case 'score':
        teacherGrade = score
        break
      default:
        break
    }

    const [error, result, grade] = await createGrade({
      task,
      pupilId: pupil.pupilId,
      teacherGrade: String(teacherGrade),
    })
    console.log('@@ createGrade', { error, result, grade })
    if (grade){
      setGradeState(grade)
      updateGrades({ name: pupil.name, grade })
    }
    closeGradeModal()
  }

  const giveIqTokens = () => {
    const { iqTokens } = gradeValues
    const iqTokenHistoryRecordToSave = {
      pupilId: pupil.pupilId,
      count: Number(iqTokens || '2'),
      description: 'Начислено на уроке',
      operation: 'add',
      vectorStrId: task.vectorStrId,
      roomStrId,
    }

    createIqTokenHistoryRecord(iqTokenHistoryRecordToSave).then(() => {
      socket.emit('ask-pupil-tasks', {name: pupil.name, vectorId: task.vectorStrId})
      onChangeTask(pupil.name ,{ ...task, iqTokenCount: iqTokenHistoryRecordToSave.count, vectorId: task.vectorId, vectorStrId: task.vectorStrId })
    })
  }

  return (
    <div className={cn(styles.gradeModalContent, className)}>
      { task.type === 'giveIqTokens' && task.iqTokenCount === 0  && (
        <>
          <div className={styles.gradeModalTitle}>Сколько IQ ученик<br/>заработал?</div>
          <div className={styles.gradeModalBinary}>
            <RadioGroup
              options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              ]}
              onChange={(value) => {
                handleChangeGradeForm('iqTokens', value)
              }}
              defaultChecked={gradeValues.iqTokens || '2'}
              name="iqTokens"
            />
          </div>
          <button
            className={styles.gradeButton}
            onClick={giveIqTokens}
          >
            Выдать
          </button>
        </>
      ) }
      {task.type === 'giveIqTokens' && task.iqTokenCount > 0 && <div className={styles.gradeModalTitle}>Вы уже выдали ученику {task.iqTokenCount} IQ на этом уроке за прохождение этого занятия</div>}
      { (task.result && task.result.kind === 'binary') && (
          <>
            <div className={styles.gradeModalTitle}>Ученик справился?</div>
            <div className={styles.gradeModalBinary}>
              <RadioGroup options={[
                { value: 'pass', label: 'Да' },
                { value: 'fail', label: 'Нет' },
              ]}
                onChange={(value) => {
                  handleChangeGradeForm('gradeBinary', value)
                }}
                defaultChecked={gradeValues.gradeBinary}
                name="gradeBinary"
              />
            </div>
          </>
        )
      }
      {
        task.result && task.result.kind === 'correctness' && (
          <>
            <div className={styles.gradeModalTitle}>Сколько ошибок?</div>
            <Input
              type="number"
              name="mistakes"
              id="gradeValue"
              value={gradeValues.mistakes}
              min="0"
              max={task.result.max || CORRECTNESS_MAX}
              onChange={(e) => {
                handleChangeGradeForm('mistakes', e.target.value)
              }}
            />
            <KeyBoard values={keyBoardValMistakes} handleChange={(value) => onGradeNumBoardDown('mistakes', value)} isNum/>
          </>
        )
      }
      {
        task.result && task.result.kind === 'score' && (
          <>
            <div className={styles.gradeModalTitle}>Сколько баллов?</div>
            <Input
              className={styles.gradeModalInput}
              type="number"
              name="score"
              id="gradeValue"
              value={gradeValues.score}
              min="0"
              max={task.result.max || SCORE_MAX}
              onChange={(e) => {
                handleChangeGradeForm('score', e.target.value)
              }}
            />
            <KeyBoard values={keyBoardValScore} handleChange={(value) => onGradeNumBoardDown('score', value)} isNum/>
          </>
        )
      }
      { task.result &&
        <button
          className={styles.gradeButton}
          onClick={onGradeSubmit}
        >
          {Number(task.slideNum) === Number(gradeState.taskId) && task.vectorStrId === gradeState.vectorHashId ? 'Переоценить' : 'Оценить'}
        </button>
      }
    </div>
  )
}
