import React, { useState, useRef } from "react";
import { useEffect } from "react";
import cn from "classnames";
import Stories from "react-insta-stories";
import usePortal from "react-useportal";
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from 'react-responsive'
import carousel from "./carousel.js";

import img0_0 from './img/pic_phone_desktop.png'
import img0_1 from './img/slide_01.png'
import img0_2 from './img/slide_02.png'
import img0_3 from './img/slide_03.png'
import img0_4 from './img/slide_04.png'
import img0_5 from './img/slide_05.png'
import img0_6 from './img/slide_06.png'
import img0_7 from './img/slide_07.png'
import img0_8 from './img/slide_08.png'
import img0_9 from './img/slide_09.png'
import img0_10 from './img/slide_10.png'
import img0_11 from './img/slide_11.png'
import img0_12 from './img/slide_12.png'
import img0_13 from './img/slide_13.png'

import img1_0 from './img/58_M_fitted.png'
import img1_1 from './img/58_M.png'
import img1_2 from './img/59_1.png'
import img1_3 from './img/51_vnutr_2_710х473.jpg'
import img1_4 from './img/57_1.png'
import img1_5 from './img/57_M.png'
import img1_6 from './img/57_2.png'

import img2_1 from './img/44_4.png'
import img2_2 from './img/44_1.png'
import img2_3 from './img/39_oblozhka.jpg'
import img2_4 from './img/анонс_810х456.png'
import img2_5 from './img/44_3.png'
import img2_6 from './img/44_2.png'

import img3_1 from './img/810x456obl.jpeg'
import img3_2 from './img/710x473.jpeg'
import img3_3 from './img/710x473_2.jpg'
import img3_4 from './img/710x473_2.jpeg'

import img4_1 from './img/Depositphotos_164480970_xl-2015.jpg'
import img4_2 from './img/50_oblozchka_810_456.jpg'
import img4_3 from './img/внутрь2_710х473.png'
import img4_4 from './img/Depositphotos_140331388_xl-2015.jpg'
import img4_5 from './img/Depositphotos_208069708_xl-2015.jpg'
import img4_6 from './img/40_00.jpg'

import { ReactComponent as Close } from "./img/close.svg";
import { ReactComponent as Prev } from "./img/prev.svg";
import { ReactComponent as Next } from "./img/next.svg";

import styles from "./stories.module.css";

const Story = ({ header, img, children, isSlideUi }) => {
    return (
        <div className={cn(styles.story, { [styles.storyUi]: isSlideUi })}>
            <div className={styles.storyImg}>
                <img src={img} alt="" />
            </div>
            {
                header && <div className={styles.storyHeader}>{header}</div>
            }
            {
                children && <div className={styles.storyContent}>{children}</div>
            } 
        </div>
    );
};

const stories = [
    {
        title: "Интерфейс \n онлайн-урока",
        img: img0_0,
        stories: [
            {
                content: () => (
                    <Story
                        img={img0_1}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_2}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_3}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_4}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_5}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_6}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_7}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_8}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_9}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_10}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_11}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_12}
                        isSlideUi
                    />
                ),
            },
            {
                content: () => (
                    <Story
                        img={img0_13}
                        isSlideUi
                    />
                ),
            },
            
            
            
            
            
        ],
    },
    {
        title: "Как скорочтение помогает с математикой?",
        img: img1_0,
            // "https://iq007.ru/imgproxy/insecure/fit/296/198/ce/0/plain/local:///blog-images/5df72355122f1136455202.png",
        stories: [
            {
                content: () => (
                    <Story
                        header="Как скорочтение помогает с математикой?"
                        img={img1_1}
                    >
                        <p>Навык быстро и правильно работать с текстом развивает способность шире и динамичнее мыслить, а значит, и математические вычисления будут даваться легче и быстрее.</p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Скорочтение улучшает память"
                        img={img1_2}
                    >
                        <p>
                        Легко запомнить стих, выучить параграф из учебника, вспомнить все решенные примеры на уроке.
                        </p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Тренирует внимательность"
                        img={img1_3}
                    >
                        <p>Проще сконцентрироваться на решении и не реагировать на отвлекающие факторы.</p>
                        </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Развивает пространственное и образное мышление"
                        img={img1_4}
                    >
                        <p>Усвоить информацию проще, если подключается визуализация и зрительная память, - вспомните геометрию!</p>
                        </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Тренирует способность фильтровать потоки информации"
                        img={img1_5}
                    >
                        <p>Умение выделить главное и сосредоточиться на этом — одна из основ математики и всех точных наук в целом!</p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Повышает уверенность в себе и своих силах"
                        img={img1_6}
                    >
                        <p>Появляется уверенность в своих сила смелость и азарт к решению сложных задач!</p>
                    </Story>
                ),
            },
        ],
    },
    {
        title: "5 упражнений для поднятия самооценки у ребенка",
        img:
            "https://iq007.ru/imgproxy/insecure/fit/296/198/ce/0/plain/local:///blog-images/5c8f291d08a79508965387.png",
        stories: [
            {
                content: () => (
                    <Story
                        header="5 упражнений для поднятия самооценки у ребенка"
                        img={img2_1}
                    >
                        <p>Объективная самооценка — это один из самых важных психологических аспектов развития ребенка. Он должен понимать, на что он способен, чего заслуживает и кем является.</p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Игра «Копилка достижений»"
                        img={img2_2}
                    >
                        <ul>
                            <li>Оформите с ребенком красивую коробку</li>
                            <li>
                                Вместе <b>записывайте на листочках успехи</b>{" "}
                                ребенка за день и бросайте в эту «копилку»
                            </li>
                        </ul>
                        <p>Находите даже самые незначительные поводы для похвалы!</p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story header="Арт-терапия" img={img2_3}>
                        <ul>
                            <li>
                                Творите вместе: рисуйте, лепите, делайте
                                аппликации
                            </li>
                            <li>
                                Обращайте внимание на то, что получается у
                                ребенка лучше всего
                            </li>
                            <li>Обсуждайте результаты и хвалите за работу</li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story header="Упражнение «Солнышко»" img={img2_4}>
                        <ul>
                            <li>
                                Попросите ребенка нарисовать солнце и
                                рассказать, какое оно
                            </li>
                            <li>
                                А потом пусть представит, что солнышко, — это он
                                и расскажет, какие хорошие качества есть у него
                            </li>
                            <li>Подпишите этими словами лучики</li>
                            <li>
                                Показывайте рисунок ребенку каждый раз, когда он
                                в себе сомневается
                            </li>
                        </ul>
                        <p>
                            Не отрицайте ничего хорошего, что говорит о себе
                            ребенок, — это не подходящий момент для критики!
                        </p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Домашняя доска почета"
                        img={img2_5}
                    >
                        <ul>
                            <li>
                                Создайте доску почета для ребенка и определите
                                период ее действия: неделю или две
                            </li>
                            <li>Фиксируйте успехи ребенка на доске</li>
                            <li>
                                Позвольте ребенку тоже вносить записи, если он
                                захочет
                            </li>
                            <li>
                                В конце выбранного периода вместе пересмотрите
                                записи и похвалите его за достижения
                            </li>
                            <li>
                                Сохраните доску, чтобы обращаться к ней, если
                                ребенок потерпел неудачу
                            </li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Ролевая игра «Придумай себе образ (имя)»"
                        img={img2_6}
                    >
                        <ul>
                            <li>
                                Предложите ребенку придумать себе образ или имя
                                (можно оставить свое)
                            </li>
                            <li>Спросите, почему ребенок сделал такой выбор</li>
                        </ul>

                        <p>
                            В обсуждении вы сможете выяснить, что беспокоит
                            ребенка и что бы он хотел поменять.
                        </p>
                    </Story>
                ),
            },
        ],
    },
    {
        title: "Плюсы и минусы онлайн-обучения",
        img:
            "https://iq007.ru/imgproxy/insecure/fit/296/198/ce/0/plain/local:///blog-images/5ebe68d6ec156068882779.jpeg",
        stories: [
            {
                content: () => (
                    <Story
                        header="Плюсы и минусы онлайн-обучения"
                        img={img3_1}
                    >
                        <p>Онлайн-обучение сейчас — самый удобный, а для кого-то даже единственный способ получать новые знания и продолжать развитие. Чего ждать от онлайн-обучения?</p>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Плюсы онлайн-обучения"
                        img={img3_2}
                    >
                        <ul>
                            <li>Экономия времени и сил на дорогу</li>
                            <li>Экономия денег — онлайн-курсы дешевле очных</li>
                            <li>Гибкое расписание</li>
                            <li>Индивидуальная программа</li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Еще плюсы онлайн-обучения"
                        img={img3_3}
                    >
                        <ul>
                            <li>
                                Закрепление знаний — к материалу можно
                                возвращаться сколько угодно раз
                            </li>
                            <li>
                                Тренировка самостоятельности и работы в команде
                            </li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Минусы онлайн-обучения"
                        img={img3_4}
                    >
                        <ul>
                            <li>
                                Нужно время, чтобы привыкнуть к новому формату
                            </li>
                            <li>Поначалу нужен родительский контроль</li>
                            <li>
                                Второстепенность учителя — контент и тесты могут
                                выходить на первый план
                            </li>
                        </ul>
                        <p>
                            Онлайн занятия в школе IQ007 — только плюсы и
                            никаких минусов! ;)
                        </p>
                    </Story>
                ),
            },
        ],
    },
    {
        title: "Развитие креативности у детей",
        img:
            "https://iq007.ru/imgproxy/insecure/fit/296/198/ce/0/plain/local:///blog-images/5e612f39b1cf9746307530.jpg",
        stories: [
            {
                content: () => (
                    <Story
                        header="Развитие креативности у детей"
                        img={img4_1}
                    >
                        <p>Зачем нужна креативность?</p>
                        <ul>
                            <li>Чтобы находить нестандартные решения жизненных и интеллектуальных задач</li>
                            <li>И уметь смотреть на мир под другим углом</li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Как развить креативность?"
                        img={img4_2}
                    >
                        <p>Игры и интерактивы</p>
                        <ul>
                           
                            <li>Развлекательные игры на воображение</li>
                            <li>Сюжетные конструкторы</li>
                            <li>Головоломки</li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Развитию креативности способствуют"
                        img={img4_3}
                    >
                            <p>Совместные творческие занятия</p>
                            <ul>
                                <li>Сценки из любимых мультиков и фильмов по ролям или чтение стихов</li>
                                <li>Рисование, лепка, конструирование моделей, компьютерные игры, чтение, письмо — любое творчество</li>
                            </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Игры и интерактивы"
                        img={img4_4}
                    >
                        <ul>
                            <li>Развлекательные игры на воображение</li>
                            <li>Сюжетные конструкторы</li>
                            <li>Головоломки</li>
                        </ul>
                    </Story>
                ),
            },
            {
                content: () => (
                    <Story
                        header="Важно!"
                        img={img4_6}
                    >
                        <ul>
                            <li>Не критикуйте и не оценивайте ребенка</li>
                            <li>
                                Позвольте ему быть самостоятельным и
                                устанавливать правила
                            </li>
                            <li>
                                Не навязывайте то, к чему у него не лежит душа
                            </li>
                            <li>
                                Показывайте, что можно заниматься чем угодно
                            </li>
                        </ul>
                    </Story>
                ),
            },
        ],
    },
];

const MyStoriesItem = ({ index, handleChangeIndex }) => {
    const [currentId, setCurrentId] = useState(0);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
      })
    

    const next = () => {
        if (currentId + 1 < stories[index].stories.length - 1) {
            setCurrentId(currentId + 1);
        } else {
            handleChangeIndex(index + 1);
        }
    };
    const prev = () => {
        if (currentId - 1 >= 0) {
            setCurrentId(currentId - 1);
        } else {
            handleChangeIndex(index - 1);
        }
    };

    useEffect(() => {
        setCurrentId(0);
    }, [index]);


    let width = 446
    let height = 759

    if (!isDesktopOrLaptop) {
        if (window.innerWidth >=640) {
            width = 360
            height = 640
        } else {
            width = window.innerWidth
            height = window.innerHeight
        }
        
    }
    return (
        <>
            <Stories
                width={width}
                height={height}
                stories={stories[index].stories}
                currentIndex={currentId}
                onStoryStart={(id) => setCurrentId(id)}
                onAllStoriesEnd={next}
            />
            <button className={cn(styles.carouselPrev, 'mobile-hidden')} onClick={prev}>
                <Prev className="icon" />
            </button>
            <button onClick={next} className={cn(styles.carouselNext, 'mobile-hidden')}>
                <Next className="icon" />
            </button>
        </>
    );
};

const MyStoriesWrap = () => {
 
    const [modalIndex, setModalIndex] = useState(undefined);

    const carouselRef = useRef(0);
    const count = 4;

    const { openPortal, closePortal, isOpen, Portal } = usePortal();

    useEffect(() => {
        if (!carouselRef.current) {
            return;
        } else {
            carousel(carouselRef.current, count);
        }
    }, [carouselRef]);

    const body = document.body;
    
    const handleShowModal = (e, index) => {
        openPortal(e);
        setModalIndex(index);

        
        body.classList.add("o-hidden");
    };

    const closeModal = (e) => {
        closePortal(e);
        setModalIndex(undefined);
        body.classList.remove("o-hidden");
    };

    const handlers = useSwipeable({ onSwiped: closeModal });

    const handleChangeIndex = (index) => {
        if (index < 0) {
            setModalIndex(stories.length - 1);
            return;
        }

        if (index > stories.length - 1) {
            setModalIndex(0);
            return;
        }
        setModalIndex(index);
    };
    return (
        <div className={styles.wrap} ref={carouselRef}>
            <div className={styles.top}>
                <span>Посмотрите наши статьи</span>
                <div className={cn("mobile-hidden", styles.carouselButtons)}>
                    <button
                        disabled={stories.length <= 4}
                        className={cn("prev", styles.carouselPrev)}
                    >
                        <Prev className="icon" />
                    </button>
                    <button
                        disabled={stories.length <= 4}
                        className={cn("next", styles.carouselNext)}
                    >
                        <Next className="icon" />
                    </button>
                </div>
            </div>
            <div className={styles.galleryItemsWrap}>
                <ul className={styles.galleryItems}>
                    {stories.map((item, index) => (
                        <li>
                            <div
                                className={styles.item}
                                onClick={(e) => handleShowModal(e, index)}
                            >
                                <img src={item.img} alt="" />
                                <div className={styles.itemTitle}>
                                    {item.title}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {isOpen && (
                <Portal>
                    <div className={styles.portal} onClick={closeModal}>
                        <div
                            className={styles.portalInner}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button
                                className={cn(
                                    "button_no-style",
                                    styles.portalClose
                                )}
                                onClick={closeModal}
                            >
                                <Close />
                            </button>
                            <div className="" {...handlers}>
                                <MyStoriesItem
                                    key={modalIndex}
                                    index={modalIndex}
                                    handleChangeIndex={handleChangeIndex}
                                />
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    );
};

export default MyStoriesWrap;
