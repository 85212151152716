import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import ReactTooltip from 'react-tooltip'
import { nanoid } from 'nanoid'

import TaskReview from '../TaskReview/TaskReview'

import { ReactComponent as MuteIcon } from './img/mute.svg'
import { ReactComponent as UnMuteIcon } from './img/unmute.svg'
import { ReactComponent as MuteChatIcon } from './img/muteChatIcon.svg'
import { ReactComponent as UnMuteChatIcon } from './img/unMuteChatIcon.svg'
import lessonSoonImg from '../../../img/lessonSoon.png'

import { ReactComponent as NeedHelpIcon } from './img/handUp.svg'
import { ReactComponent as NotHelpIcon } from './img/handDown.svg'
import { ReactComponent as Info } from './img/info.svg'
import { ReactComponent as InfoHover } from './img/infoHover.svg'

import { ReactComponent as TimerOn } from './img/timer.svg'
import { ReactComponent as TimerOff } from './img/timerOff.svg'

import useTimer from '../../../hooks/useTimer'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

import styles from './PupilForPresenter.module.css'
import {
  getImgStyle,
  getImgUrl,
} from '../../../func'

import { connectSpeekingFlag } from '../../../soundMeter'
import Canvas from '../../../components/Canvas/Canvas'
import SelectTask from '../../../components/SelectTask/SelectTask'

import {
  createOffer, createPeerConnectionNext,
} from '../../../webrtc'
import { getLastTask } from '../../../api/vectors'
import Epub from "../../../components/Epub/Epub";


const TASK_KINDS = ['correctness', 'score', 'binary']

export default function PupilForPresenter({
  pupil,
  task = null,
  onDirectRequested,
  isDirectEnabled,
  directMuteWith,
  setDirectMuteWith,
  isNeedHelp,
  isMuted,
  isChatMuted,
  onNeedHelpChanged,
  onShowLargeTask,
  timer = {},
  dispatchTimer,
  directTag,
  selectedGroupId,
  onSelectPupil,
  reaction = 'hand',
  showGradeBtn,
  isOpenedGradeModal,
  setOpenedGradeModal,
  grade = {},
  updateGrades,
  lastBreadCrumbs,
  tags,
  vectorDirs,
  onChangeTask,
  settings = {},
  roomStrId,
  socket,
  vectors,
  group,
  vectorTasks,
  setVectorTasks,
  viewerSettings = {},
}) {

  const { name, displayName, connectedAt } = pupil
  const videoRef = useRef(null)
  const audioRef = useRef(null)
  const gradeModalRef = useRef(null)
  const helpRef = useRef(null)
  const [isSpeaking, setSpeekingFlag] = useState(false)
  const [isShowHands, setShowHands] = useState(false)
  const [isHelpYes, setHelpYes] = useState(false)
  const [isVideoTaskStart, setVideoTaskStart] = useState(false)

  const [helpTextShow, setHelpTextShow] = useState(false)

  const [audioId] = useState(nanoid(6))

  const [lastTask, setLastTask] = useState(null) //последнее задание, которе было открыто до перезагрузки страницы или же на последнем уроке

  const [startTimer, stopTimer, plusTime, minusTime, timerStart] = useTimer(socket, name, timer, dispatchTimer)

  const openGradeModal = () => setOpenedGradeModal(pupil.pupilId)
  const closeGradeModal = () => setOpenedGradeModal(null)

  useOnClickOutside(gradeModalRef, closeGradeModal)

  const throttleSetSpeakingFalseFlag = throttle(setSpeekingFlag, 2500)

  const throttleSetSpeakingTrueFlag = throttle(setSpeekingFlag, 1500)

  useEffect(() => {
    if (!pupil || !pupil.pupilId || !pupil.groupId) {
      return
    }

    getLastTask(pupil.groupId, pupil.pupilId).then((lastTaskResp) => setLastTask(lastTaskResp))
      .catch(err => console.error('getLastTask error', err))
  }, [pupil])

  useEffect(() => {
    if (!isNeedHelp && (isShowHands || isHelpYes)) {
      setShowHands(false)
      setHelpYes(false)
    }
  }, [isNeedHelp])

  useEffect(() => {
    if (!audioRef.current.id) {
      return
    }
    // const audio = audioRef.current
    const audio = document.getElementById(audioRef.current.id)
    if (!audio) {
      return
    }

    if (!directMuteWith || directMuteWith === name) {
      // включить звук
      audio.volume = 1.0
    } else {
      // приглушить звук
      audio.volume = 0.15
    }
  }, [directMuteWith, audioRef])

  useEffect(() => {
    if (!audioRef.current.id) {
      return
    }
    // const audio = audioRef.current
    const audio = document.getElementById(audioRef.current.id)
    if (!audio) {
      return
    }

    if (!directTag || (tags && tags.indexOf(directTag) > -1)) {
      // включить звук
      audio.volume = 1.0

    } else {
      // приглушить звук
      audio.volume = 0.15
    }
  }, [directTag, tags.length, audioRef])

  // ask video
  useEffect(() => {
    console.log('ask vide', name)
    if (!videoRef.current || !name || !socket) {
      return
    }

    createPeerConnectionNext(socket, videoRef.current, { input: `video:${name}` })
      .then(({ pc, connectionId }) => {
        pc.addTransceiver('video', { direction: 'recvonly' })
        pc.onnegotiationneeded = (event => {
          console.log('onnegotiationneeded', event)
        })
        createOffer(pc)
          .then((offer) => socket.emit('ask-video', { name, connectionId, offer: offer.sdp }))
      })
  }, [videoRef, name, socket, connectedAt])

  // ask audio
  useEffect(() => {
    if (!audioRef.current || !name || !socket) {
      return
    }

    let timerId
    let flagPrev = isSpeaking

    createPeerConnectionNext(
      socket,
      audioRef.current,
      { input: `audio:${name}` },
      connectSpeekingFlag((flag) => {
        if (flag) {
          throttleSetSpeakingTrueFlag(flag)
        } else {
          throttleSetSpeakingFalseFlag(flag)
        }
      }),
    ).then(({ pc, connectionId }) => {
      pc.addTransceiver('audio', { direction: 'recvonly' })
      console.log('asking audio', name, connectionId)
      createOffer(pc)
        .then((offer) => socket.emit('ask-audio', { name, connectionId, offer: offer.sdp }))
    })
  }, [audioRef, name, socket, connectedAt])


  useEffect(() => {
    if (!socket || !task || !task.video) {
      return
    }
    socket.on('video:state', onChangeRemoteState)
    return () => {
      socket.off('video:state', onChangeRemoteState)
    }
  }, [socket, task?.video, isVideoTaskStart])

  useEffect(() => {
    helpRef.current.addEventListener('mouseover', () => {
      setHelpTextShow(true)
    })
    helpRef.current.addEventListener('mouseleave', () => {
      setHelpTextShow(false)
    })

    return () => {
      try {
        if (helpRef.current) {
          helpRef.current.removeEventListener('mouseover')
          helpRef.current.removeEventListener('mouseleave')
        }
      } catch (e) {
        console.log('help err')
      }
    }
  }, [helpRef])

  const onChangeRemoteState = (state) => {
    const { isStart } = state
    if (name === state.name && isStart !== isVideoTaskStart) {
      setVideoTaskStart(isStart)
    }
  }

  const onDirectButton = () => {
    if (directMuteWith) {
      // если бесшумный режим включен, его всегда отключить
      setDirectMuteWith(null)
      if (!isDirectEnabled) {
        onDirectRequested(name)
      }
    } else {
      onDirectRequested(name)
    }
  }

  const onDirect2Button = () => {
    // если включен уже директ с текущим
    // студентом и нужно только добавить приглушение
    if (isDirectEnabled && directMuteWith !== name) {
      setDirectMuteWith(name)
      return
    }

    // либо директ не включен, либо директ не со мной
    // переключить директ на меня или выключить
    onDirectRequested(name)

    if (directMuteWith === name) {
      // отключить
      setDirectMuteWith(null)
    } else {
      setDirectMuteWith(name)
    }
  }


  const tagsClass = tags.map(i => styles[`tag_${i}`])
  const cn = classNames(tagsClass, {
    [styles.presenterPupil]: true,
    [styles.presenterPupilDirect]: isDirectEnabled,
    [styles.presenterPupilSpeaking]: isSpeaking,
    [styles.presenterPupilSilent]: directMuteWith && directMuteWith !== name,
    [styles[`tag_${selectedGroupId}`]]: selectedGroupId && tags.indexOf('selected') > -1,
  })

  const needHelpIconCn = classNames({
    [styles.needHelpIcon]: true,
    [styles.needHelpIconActive]: isNeedHelp,
    [styles.needHelpIconActiveYes]: isHelpYes,
  })


  let directLabel
  let direct2Label
  if (directMuteWith === name) {
    directLabel = 'Прямое общение'
    direct2Label = 'Отключить прямое тихое общение'
  } else if (isDirectEnabled) {
    directLabel = 'Отключить прямое общение'
    direct2Label = 'Прямое тихое общение'
  } else {
    directLabel = 'Прямое общение'
    direct2Label = 'Прямое тихое общение'
  }
  // const directLabel = isDirectEnabled ? 'Отключить прямое общение' : 'Прямое общение
  // const direct2Label = (directMuteWith === name) ? 'Отключить прямое общение '

  const onToggleLarge = () => {
    onShowLargeTask(pupil)
  }

  const onNeedHelpClickNo = () => {
    onNeedHelpChanged({ name, doNeedHelp: false })
    setShowHands(false)
  }
  const onNeedHelpClickYes = () => {
    onNeedHelpChanged({ name, doNeedHelp: 'yes' })
    setShowHands(false)
    setHelpYes(true)
  }

  const handleTimerInit = (e) => {
    e.stopPropagation()
    if (timer) {
      dispatchTimer({ type: 'setTimer', payload: { 'name': name, isStart: true } })
    }
  }

  const toggleHands = () => {
    if (isHelpYes) {
      onNeedHelpClickNo()
      setHelpYes(false)
    } else {
      setShowHands(true)
    }
  }

  const onToggleVideoTask = (e) => {
    e.stopPropagation()
    setVideoTaskStart(!isVideoTaskStart)
    if (socket) {
      socket.emit('video:state', ({ name, isStart: !isVideoTaskStart }))
    }
  }

  const mutePupil = (e) => {
    e.stopPropagation()
    socket.emit('ask-mute-pupil', { name, isMuted })
  }

  const muteChatPupil = (e) => {
    e.stopPropagation()
    socket.emit('ask-chat-mute-pupil', { name, isChatMuted })
  }

  const timerPercent = timer.time_int ? (timer.count / timer.time_int) * 100 : 100

  const cnTimerLine = classNames({
    [styles.timerLine]: true,
    [styles.timerLineDanger]: timer && timerPercent > timer.dangerPercent,
  })

  const cnTimerSlot = classNames({
    [styles.timerSlot]: true,
    [styles.timerSlotDanger]: timer && timerPercent > timer.dangerPercent,
    [styles.timerPointer]: timer && !timer.time_int,
  })

  let imgStyle = null
  /*
    Когда рисовать картинку?
  */
  if (task && task.type !== 'giveIqTokens' && (!task.slide || task.file)) {
    imgStyle = getImgStyle(task)
  }

  const taskKind = get(task, 'result.kind')

  if (task && taskKind && !TASK_KINDS.includes(taskKind)) {
    task.result.kind = 'correctness'
  }

  useEffect(() => {
    let time_int = timer.time_int - timer.count
    if (!isNaN(time_int) && timerStart) {
      socket.emit("timer-on", { name, time_int });
    }
  }, [socket])

  return (
    <div className={cn}>
      <div className={styles.videoWrap}>
        <video ref={videoRef} className={styles.video} autoPlay muted playsInline/>
        <audio id={audioId} ref={audioRef} className={styles.audio} volume="true" autoPlay/>
        <div className={styles.videoWrapControls} onClick={onSelectPupil}>
              <span className={styles.name} title={displayName}>
                {displayName}
              </span>
          {/* Состояние mute у ученика */}
          <span className={`${styles.slot} ${styles.muteSlot}`} onClick={mutePupil}>
                {isMuted ? <MuteIcon data-tip="Включить микрофон ученику" data-for="mute"/> : <UnMuteIcon data-tip="Выключить микрофон ученику" data-for="mute"/>}
              </span>
          <span className={`${styles.slot} ${styles.muteChatSlot}`} onClick={muteChatPupil}>
                {isChatMuted ? <MuteChatIcon data-tip="Включить чат ученику" data-for="mute"/> : <UnMuteChatIcon data-tip="Выключить чат ученику" data-for="mute"/>}
            {/*<UnMuteChatIcon></UnMuteChatIcon>*/}
              </span>
          <ReactTooltip
            id="mute"
            className={styles.tooltip}
            place="right"
            textColor="#393F61"
            backgroundColor="#fff"
            aria-haspopup="true"
          />
          {/*
              TO DO Скрыт неработающий функционал
              <button className={`${styles.slot} ${styles.goalSlot}`} title="">
                <Goal />
              </button> */}
          {/*
                Вкл/выкл прямое общение.
                Но кажется сейчас у нас прямое общение чисто через выделение
              <button onClick={onDirect2Button} className={`${styles.slot} ${styles.directSlot}`} title={directLabel}>
                { !isDirectEnabled ? <DirectOn /> : <DirectOff /> }
              </button>*/}
          <div className={needHelpIconCn} onClick={(e) => e.stopPropagation()}>
            {
              !isShowHands ?
                <span
                  onClick={toggleHands}
                  className={classNames(`icon`, `icon_${reaction}`, `icon_dark`, styles.needHelpIconInner)}
                />
                :
                <>
                  <div className={styles.helpIconNo}>
                    <NotHelpIcon className={styles.needHelpIconInner} onClick={onNeedHelpClickNo}/>
                  </div>
                  <div className={styles.helpIconYes}>
                    <NeedHelpIcon className={styles.needHelpIconInner} onClick={onNeedHelpClickYes}/>
                  </div>
                </>
            }
          </div>
          <div className={styles.timerWithButtons}>
            {Boolean(timer) && timer.time_int >= 0 &&
            <div className={styles.timerButtons}>
              <button className={styles.timerButtonPlus} onClick={plusTime}>+30 сек</button>
              <button className={styles.timerButtonMinus} onClick={minusTime}>-30 сек</button>
            </div>
            }
            <div className={cnTimerSlot}>
              <span className={styles.timerValue}>{timer.value}</span>
              <button onClick={handleTimerInit} className="reset-button">
                {timer.isOff ? <TimerOff className="icon-svg"/> : <TimerOn className="icon-svg"/>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.vectorSelectSlot, { [styles.vectorSelectSlotSpeak]: isSpeaking })}>
        {Boolean(timer) && timer.time_int >= 0 &&
        <div className={cnTimerLine} style={{ width: `${timerPercent}%` }}/>
        }
        <div className={classNames(styles.selectTask, { [styles.selectTaskSpeak]: isSpeaking })} style={{display: !settings?.startedAt ? 'none' : 'block'}}>
            <SelectTask
              task={task}
              lastTask={lastTask}
              isPupilForPresenter
              names={[name]}
              vectorDirs={vectorDirs}
              onChangeTask={onChangeTask}
              socket={socket}
              vectors={vectors}
              group={group}
              tags={tags}
              vectorTasks={vectorTasks}
              setVectorTasks={setVectorTasks}
              isSpeaking={isSpeaking}
          />
        </div>
        <div ref={helpRef} className={styles.questSlot}>
          {settings?.startedAt ? <>{ isSpeaking ? <InfoHover/> : <Info/> }</> : <></>}
        </div>
        {helpTextShow && lastBreadCrumbs && <div className={classNames(styles.helpText)}>
          {lastBreadCrumbs ? lastBreadCrumbs.join(' > ') : 'Нет данных о предыдущем задании'}
        </div>}
      </div>
      <div className={styles.taskContainerWrap}>
        <div className={styles.taskContainer}>
          {settings?.startedAt && settings.startedAt !== 0 ? <>{task && (
            <div
              onClick={onToggleLarge}
              className={styles.task}
              // style={imgStyle}
            >
              {task.video &&
                <div className={styles.taskVideoControls}>
                  <button type="button" onClick={onToggleVideoTask}>
                    {isVideoTaskStart ? 'Закрыть видео' : 'Показать видео'}
                  </button>
                </div>
              }

              {task.audio &&
                //пока завязано на сокетах, обработчиках и стайте для видео, тк у них логика одна и та же
                <div className={styles.taskVideoControls}>
                  <button type="button" onClick={onToggleVideoTask}>
                    {isVideoTaskStart ? 'Закрыть аудио' : 'Включить аудио'}
                  </button>
                </div>
              }
              {
                task.slide && !imgStyle && (
                  <Epub
                    socket={socket}
                    name={name}
                    task={task}
                    readOnly={true}
                    isPreview={true}
                    needCallToSupport={viewerSettings?.needCallToSupport}
                    type='presenter'
                  />
                )
              }
              {imgStyle && <Canvas
                color="#4DA3F7"
                type="teacher"
                pic={getImgUrl(task)}
                task={task}
                socket={socket}
                name={name}
                showButtons={false}
                readOnly={true}
                hasStyles={false}
                isPreview={true}
                needCallToSupport={viewerSettings?.needCallToSupport}
              />}
              {
                task.type === 'giveIqTokens' && <img src={task.src} style={{position: 'absolute', maxWidth: '100%', maxHeight: '100%', margin: 'auto', left: 0, right: 0}}/>
              }
            </div>
          )}</> : <img src={lessonSoonImg} style={{width: '100%'}}/>}
        </div>
        {showGradeBtn && isOpenedGradeModal && task && (
          <div ref={gradeModalRef} className={styles.gradeModal}>
            <TaskReview
              task={task}
              closeGradeModal={closeGradeModal}
              pupil={pupil}
              grade={grade}
              updateGrades={updateGrades}
              onChangeTask={onChangeTask}
              roomStrId={roomStrId}
              socket={socket}
            />
          </div>
        )}
        <div>
          {showGradeBtn && task && task.type === 'giveIqTokens' && task.iqTokenCount === 0 && <button
            className={styles.gradeButton}
            onClick={openGradeModal}>
            Выдать IQ
          </button>}
          {showGradeBtn && task && task.result && (
            <button className={styles.gradeButton} onClick={openGradeModal}>{Number(task.slideNum) === Number(grade.taskId) && task.vectorStrId === grade.vectorHashId ? 'Переоценить задание' : 'Оценить задание'}</button>
          )}
        </div>
      </div>
    </div>
  )
}
