import React from 'react'
import ReactTooltip from 'react-tooltip'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import { ReactComponent as IconUserPressed } from '../img/userPressed.svg'
import { ReactComponent as IconUserPressedFill } from '../img/userPressedFill.svg'
import styles from './buttons.module.sass'


export default function ButtonUserPressed({ onClick, isChatShown }) {
  return (
    <div
      className={styles.button}
      onClick={onClick}
      data-tip="ученики" data-for="userPressed"
    >
      {isChatShown ? <IconUserPressed data-tip="ученики" data-for="userPressed"/> :
        <IconUserPressedFill data-tip="ученики" data-for="userPressed"/>}
      <ReactTooltip
        id="userPressed"
        className={presenterScreenStyles.tooltip}
        place="right"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}