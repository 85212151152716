import superagent from 'superagent'

export async function getVectors() {
  const resp = await superagent.get('/api/vector/list-for-lesson')
  return resp.body
}

export async function getVectorsDirs() {
  const resp = await superagent.get('/api/vector/v2/list-for-lesson')
  return resp.body
}

export async function getTitleVectorList() {
  const resp = await superagent.get('/api/vector/title-list-for-lesson')
  return resp.body
}

export async function fetchVectorTasks(vectorId) {
  const resp = await superagent.get(`/api/vector/tasks/${vectorId}`) //может принять как id так и strId, он же fileId
  return resp.body
}
