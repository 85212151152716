import React, { useState } from 'react'
import cn from 'classnames'
import styles from './input.module.scss'

import { ReactComponent as Up } from './up.svg'
import { ReactComponent as Down } from './down.svg'


const Input = (props) => {
  const increment = () => {
    try {
      const { onChange, value } = props
      onChange({
        target: {
          value: Number(value + 1),
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const decrement = () => {
    try {
      const { onChange, value } = props
      onChange({
        target: {
          value: Number(value - 1),
        },
      })
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className={styles.wrap}>
      <input {...props} className={cn(styles.input, {[styles.fill]: props.value !== null})}/>
      {/*{props.type === 'number' && (*/}
      {/*  <div className={styles.numberArrow}>*/}
      {/*    <button onClick={increment} className={cn('button_no-style', styles.button)}>*/}
      {/*      <Up className="icon"/>*/}
      {/*    </button>*/}
      {/*    <button onClick={decrement} className={cn('button_no-style', styles.button)}>*/}
      {/*      <Down className="icon"/>*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

export default Input
