import React from 'react'
import ButtonsContainer from "../ButtonsContainer/ButtonsContainer";
import MicroBtn from "../ButtonsContainer/buttons/MicroBtn";
import UpdateBtn from "../ButtonsContainer/buttons/UpdateBtn";
import SettingsBtn from "../ButtonsContainer/buttons/SettingsBtn";
import ViewStyleBtn from "../ButtonsContainer/buttons/ViewStyleBtn";


const MoreMenu = ({
  onClickSettingBtn,
  onClickUpdateBtn,
  onClickMicroBtn,
  onClickViewStyleBtn,

  viewerSettings = {},
}) => {
  return (
    <ButtonsContainer justifyContent={'center'}>
      <MicroBtn
        onClick={onClickMicroBtn}
        isMuted={viewerSettings?.isSoundMute}
      />
      <UpdateBtn
        onClick={onClickUpdateBtn}
      />
      <SettingsBtn
        onClick={onClickSettingBtn}
      />
      <ViewStyleBtn
        onClick={onClickViewStyleBtn}
        currentViewType={viewerSettings.viewType}
      />
    </ButtonsContainer>
  )
}

export default MoreMenu