import React, { useState } from 'react'
import styles from './emojiBlock.module.css'
import { ReactComponent as IconClose } from '../../img/close.svg';
import { ReactComponent as IconHand } from "../../../img/hand-1.svg";
import { ReactComponent as IconGlad } from "../../../img/glad-1.svg";
import { ReactComponent as IconGood } from "../../../img/good-1.svg";
import { ReactComponent as IconSad } from "../../../img/sad-1.svg";
import { ReactComponent as IconAlarm } from "../../../img/ALARM-1.svg";


const EmojiBlock = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleEmojiBlock = () => {
    setIsOpen(!isOpen)
  }

  return isOpen ? <div className={ styles.blockContainer }>
    <span className={ styles.title }>Что значат эмодзи:</span>
    <button className={ styles.closeButton } onClick={ toggleEmojiBlock }><IconClose/></button>
    <div>
      <ul className={ styles.list }>
        <li className={ styles.item }>
          <IconHand/> Поднял руку
        </li>
        <li className={ styles.item }>
          <IconGlad/> Всё хорошо
        </li>
        <li className={ styles.item }>
          <IconGood/> Всё понятно
        </li>
        <li className={ styles.item }>
          <IconSad/> Всё плохо
        </li>
        <li className={ styles.item }>
          <IconAlarm/> Я закончил
        </li>
      </ul>
    </div>
  </div> : <button className={styles.buttonOpenBlock} onClick={toggleEmojiBlock}>
    <IconGlad/>
    <span>Что значат эмодзи</span>
  </button>
}

export default EmojiBlock