import React from 'react'
import { ReactComponent as IconMute } from '../../img/icon-mute.svg'
import { ReactComponent as IconUnMute } from '../../img/icon-unmute.svg'
// import styles from './buttonMicro.module.css'
// import styles from '../ButtonEraser/buttonEraser.module.css'
import styles from './buttonMicro.module.css'
import cn from "classnames";

const ButtonMicro = ({isMute = false, onClick = () => {}}) => {
  const renderIcon = () => {
    const iconClass = cn('icon', styles.icon)
    return !isMute ? <IconMute className={iconClass}/> : <IconUnMute className={iconClass}/>
  }


  return <button
    onClick={onClick}
    type={'button'}
    className={styles.button}
    data-tip={'Вкл/выкл микрофон'}
    data-for="global"
  >
    {renderIcon()}
  </button>
}

export default ButtonMicro