import React from 'react'
import { ReactComponent as BtnSVG } from "./img/chat-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/chat-btn-active.svg";
import styles from './button.module.css'


const ChatBtn = ({isActive = false, isDisabled = false, onClick, unReadMessagesCount }) => {
  return <button className={styles.btn} disabled={isDisabled} onClick={onClick}>
    {Boolean(unReadMessagesCount) && <span className={styles.messagesNotifications}>{unReadMessagesCount}</span>}
    { (!isActive || isDisabled) ? <BtnSVG /> : <BtnActiveSVG /> }
  </button>
}

export default ChatBtn
