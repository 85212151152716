import React from 'react'
import { ReactComponent as BtnSVG } from "./img/update-btn.svg";
import { ReactComponent as BtnActiveSVG } from "./img/update-process-btn.svg";
import styles from './button.module.css'


const UpdateBtn = ({isActive = false, isDisabled = false, onClick}) => {
  return <button className={styles.btn} disabled={isDisabled} onClick={onClick}>
    { (!isActive) ? <BtnSVG /> : <BtnActiveSVG /> }
  </button>
}

export default UpdateBtn
