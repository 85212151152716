import React from "react";
import cn from 'classnames'
import styles from './buttonsContainer.module.css'


const ButtonsContainer = ({children, justifyContent = 'space-between'}) => {
  return (
    <div className={cn(styles.container, {
        [styles.justifyContentSpaceBetween]: justifyContent === 'space-between',
        [styles.justifyContentCenter]: justifyContent === 'center',
      }
    )}
    >
      {children}
    </div>
  )
}

export default ButtonsContainer