import React from "react";
import cn from 'classnames'
import styles from './personVideoContainer.module.css'
import { ReactComponent as MicroOnSVG } from "./img/micro-on.svg";
import { ReactComponent as MicroOffSVG } from "./img/micro-off.svg";
import { viewTypes } from "../ViewStylePopupMenu/viewStyle";

import { ReactComponent as DefaultSVG } from "../ButtonsContainer/buttons/img/viewer-reaction-default-btn.svg";
import { ReactComponent as LikeSVG } from "../ButtonsContainer/buttons/img/viewer-reaction-like-btn.svg";
import { ReactComponent as GoodSVG } from "../ButtonsContainer/buttons/img/viewer-reaction-good-btn.svg";
import { ReactComponent as SadSVG } from "../ButtonsContainer/buttons/img/viewer-reaction-sad-btn.svg";
import { ReactComponent as AlarmSVG } from "../ButtonsContainer/buttons/img/viewer-reaction-alarm-btn.svg";


const PersonVideoContainer = ({
  children,
  className,
  hide,
  isMute,
  displayName,
  currentViewType,
  reaction,
}) => {
  const renderReaction = () => {
    switch (reaction) {
      case 'hand':
        return <DefaultSVG/>
      case 'good':
        return <LikeSVG/>
      case 'glad':
        return <GoodSVG/>
      case 'sad':
        return <SadSVG/>
      case 'alarm':
        return <AlarmSVG/>
      default:
        return null
    }
  }

  return (
      <div className={cn(styles.container, className, {[styles.hide]: hide})}>
        {children}

        {reaction && <span
          className={cn(styles.reaction, {[styles.reactionGalleryView]: currentViewType === viewTypes.GALLERY})}>{renderReaction()}</span>}
        {currentViewType !== viewTypes.GALLERY && <span className={styles.displayName}>{displayName}</span>}
        <span className={styles.microIcon}>{isMute ? <MicroOffSVG/> : <MicroOnSVG/>}</span>
      </div>
  )
}

export default PersonVideoContainer
