import findIndex from 'lodash/findIndex'
import React, { useEffect, useRef, useState, useReducer } from 'react'
import ReactTooltip from 'react-tooltip'
import { useHistory, useParams } from 'react-router-dom'
import cn from 'classnames'
import { uniqueNamesGenerator, colors, animals } from 'unique-names-generator'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { eventLog } from '../api/apiEventLog'
import ChatMessage from '../components/ChatMessage/ChatMessage'
import AudioTask from './components/AudioTask/AudioTask'
import bigLessonSoonImg from '../img/big_lessonSoon.jpg'
import callToSupportImg from '../img/call_to_support.png'
import knockAnimation from './components/ButtonChat/img/animation.gif'

import checkMobile from '../utils/checkMobile'
import DraggableWrap from '../components/DraggableWrap/DraggableWrap'
import isVisible from '../utils/isVisible'
import OtherPupils, { hasCommonGroupAndGroupSoundIsAll } from '../OtherPupils'
import ButtonChat from './components/ButtonChat/ButtonChat'
import MessageFox from './components/MessageFox/MessageFox'
import TeacherUnavailable from './components/TeacherUnavailable/TeacherUnavailable'

import { ReactComponent as IconviewGallery } from './img/view-gallery.svg'
import { ReactComponent as IconviewMosaic } from './img/view-mosaic.svg'
import { ReactComponent as IconviewReport } from './img/view-report.svg'
import { ReactComponent as IconEraser } from './img/eraser.svg'
import { ReactComponent as IconMute } from './img/icon-mute.svg'
import { ReactComponent as IconUnMute } from './img/icon-unmute.svg'
import { ReactComponent as IconThemaDark } from './img/dark-thema.svg'
import { ReactComponent as IconThemaLight } from './img/light-thema.svg'
import { ReactComponent as IconBgTask } from './img/bg-task.svg'
import { ReactComponent as IconInfo } from '../img/info.svg'
import { ReactComponent as IconEllipsis } from '../img/ellipsis.svg'
import plashka from './img/plashka_x2.png'

import ButtonDoneNext from './components/ButtonDoneNext/ButtonDone'
import VideoTask from './components/VideoTask/VideoTask'

import Canvas from '../components/Canvas/Canvas'
import ConnectProblem from '../components/ConnectProblem/ConnectProblem'
import SettingMD from '../components/SettingMD/SettingMd'
import Popup from '../components/Popup/Popup'
import { Loader } from '../components/Loader/Loader'

import Avatar from '../components/Avatar/Avatar'
import CopyRight from '../components/CopyRight/CopyRight'

import { reducerTimers } from '../PresenterScreen/reduserTimers'
import useOnClickOutside from '../hooks/useOnClickOutside'
import useVideoAudioStreamPublic from '../hooks/useVideoAudioStreamPublic'
import useTimer from '../hooks/useTimer'
import ChatWidget from './ChatWidget'

import { getInformation } from '../utils/navigator'

import stylesNext from './viewerScreenNext.module.css'

import { changeAudio, changeVideo } from './viewerFuncs'

import { getAudioUrl, getImgUrl, getVideoUrl } from '../func'
import DropMenu from '../components/Dropmenu'
import DesktopControlsNext from './components/DesktopControlsNext/DesktopControlsNext'
import isEmpty from 'lodash/isEmpty'
import Epub from '../components/Epub/Epub'
import useViewerSocket from './useViewerSocket'
import usePresenterVideo from './usePresenterVideo'
import emptyCanvasTask from "../utils/emptyCanvasTask";

dayjs.extend(relativeTime)

function hasSelection(tags) {
  return tags && tags.indexOf('selected') > -1
}

function hasTag(tags, tag) {
  return tags && tags.indexOf(tag) > -1
}

const canvasColor = '#ff2b2b'

export default function ViewerScreenDesktop({ mode = 'demo' }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  })
  const urlParams = useParams()

  const history = useHistory()

  // name - id ученика
  const [name, setName] = useState('')
  const [isNeedHelp, setNeedHelp] = useState(false)
  const [reactions, setReactions] = useState({})

  const videoRef = useRef(null)
  const remoteVideoRef = useRef(null)
  const remoteVideoFullRef = useRef(null)

  // const [isSoundOff, setSoundOffFlag] = useState(false)

  console.log('name123',name)

  const [audioSource, setAudioSource] = useState(
    localStorage.getItem('audioSource')
  )
  const [videoSource, setVideoSource] = useState(
    localStorage.getItem('videoSource')
  )
  const [pupilStream, setPupilStream] = useState({})

  const [isMobile, setMobile] = useState(false)
  const [iceFetchPending, setIceFetchPending] = useState(false)

  const [timers, dispatchTimer] = useReducer(reducerTimers, {})

  const {
    socket,
    roomId: presenterRoomId,
    connectionState,
    directMode,
    error,
    setError,
    isChatMuted,
    isDemoLesson,
    isMuted,
    setMute,
    isPresenterCame,
    messages,
    setMessages,
    otherViewers: otherPupils,
    presenter,
    presentView,
    pupilId,
    roomStrId,
    settings,
    settingsByTag,
    tags,
    tariffPlanHashId,
    task,
    viewerDisplayName: displayName,
    setViewersDisplayName: setDisplayName,
    viewerSettings,
    viewersDisplayName: displayNamesSet,
    viewersMuted,
    needStopLocalStreaming,
  } = useViewerSocket({ name, dispatchTimer })

  const { stream: teacherStream, setStream: setTeacherStream } =
    usePresenterVideo({
      socket,
      videoRef: remoteVideoRef,
      isPresenterCame,
      roomId: presenterRoomId,
    })

  console.log('viewerSettings',viewerSettings)

  useTimer(socket, name, timers[name], dispatchTimer, 'viewer')
  const [thema, setThema] = useState(null)

  const [isTaskFull, setTaskFull] = useState(false)
  const [isTaskFullPrev, setTaskFullPrev] = useState(false)
  const [isShowMobileMenu, setShowMobileMenu] = useState(false)
  const [isFacesCollapse, setFacesCollapse] = useState(true)
  const [isInfoPopupOpen, setInfoPopupOpen] = useState(false)

  const [isShowChat, setShowChat] = useState(false)

  const [unreadMessages, setUnreadMessages] = useState(0)
  const [bubbleMessages, setBubbleMessages] = useState([])
  const [lastBubbleMessage, setLastBubbleMessage] = useState({})

  const toggleChatShow = () => {
    if (isShowOpenChatTutorial) {
      setIsShowOpenChatTutorial(false)
    }
    setShowChat(!isShowChat)
  }

  const roomId = urlParams.roomId || urlParams.lessonId

  const asideRef = useRef(null)
  const controlsRef = useRef(null)

  const [showChatFlag, setShowChatFlag] = useState(true)
  const [disableRefresh, setDisableRefresh] = useState(false)
  const [isShowOpenChatTutorial, setIsShowOpenChatTutorial] = useState(true)

  useEffect(() => {
    const flag = localStorage.getItem('IQ007_CHAT_FLAG')
    if (flag === 'enable') {
      setShowChatFlag(true)
    }
  }, [])

  const logButtonTopClick = (button) => {
    const data = {
      userId: pupilId,
      from: 'ViewerScreen',
      action: `click on ${button}`,
      description: 'clickButtonTop',
      meta: {},
    }
    eventLog(data).catch((err) => console.log('eventLog error', err))
  }

  const showInfoPopup = () => {
    setInfoPopupOpen(true)
  }

  const closeInfoPopup = () => {
    setInfoPopupOpen(false)
  }

  const showFaces = () => {
    setFacesCollapse(false)
  }

  const hideFaces = () => {
    setFacesCollapse(true)
    if (asideRef.current) {
      setTimeout(() => {
        const asideVisible = isVisible(asideRef.current)
        if (!asideVisible) {
          asideRef.current.style = {
            left: 10,
            top: 10,
          }
        }
      }, 2000)
    }
  }

  const hideMobileMenu = (e) => {
    if (!isDesktopOrLaptop) {
      setShowMobileMenu(false)
    }
  }

  useEffect(() => {
    const listener = (e) => {
      const area = window.innerHeight - 142
      if (e.clientY >= area) {
        setShowMobileMenu(true)
      }
    }
    if (!isShowMobileMenu) {
      document.addEventListener('click', listener)
    } else {
      document.removeEventListener('click', listener)
    }

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [isShowMobileMenu])

  useEffect(() => {
    const localThema = localStorage.getItem('thema')
    if (isDesktopOrLaptop && localThema) {
      setThema(localThema)
    } else {
      setThema('light')
    }
  }, [])

  useEffect(() => {
    if (!isDesktopOrLaptop && thema !== 'dark') {
      setThema('dark')
    }

    if (isDesktopOrLaptop) {
      setTaskFull(false)
    }
  }, [isDesktopOrLaptop, thema])

  const viewValues = [
    {
      value: 'gallery',
      label: 'Галерея',
    },
    {
      value: 'mosaic',
      label: 'Мозаика',
    },
    {
      value: 'report',
      label: 'Учитель',
    },
  ]

  const [viewIndex, setViewIndex] = useState(0) // gallery, mosaic, report
  // const [presentView, setPresentView] = useState({})

  const ViewIcon = () => {
    const currentView = viewValues[viewIndex]
    const classIcon = cn('icon', `icon_${thema}`, stylesNext.viewIcon)
    switch (currentView.value) {
      case 'gallery':
        return <IconviewGallery className={classIcon} />
      case 'mosaic':
        return <IconviewMosaic className={classIcon} />
      case 'report':
        return <IconviewReport className={classIcon} />
      default:
        break
    }
  }

  useEffect(() => {
    setMobile(checkMobile())
  }, [isDesktopOrLaptop])

  useEffect(() => {
    if (!disableRefresh) {
      return
    }

    setTimeout(() => setDisableRefresh(false), 10000)
  }, [disableRefresh])

  useEffect(() => {
    try {
      if (mode === 'auth') {
        setName(roomId)
      } else {
        const randomName = uniqueNamesGenerator({
          dictionaries: [colors, animals],
          length: 2,
        })
        setName(randomName)
        setDisplayName(randomName)
      }
    } catch (e) {
      setError(e.toString())
    }
  }, [roomId])

  useEffect(() => {
    if (!socket || !name) {
      return
    }
    socket.emit('viewer:got-name', name)

    return () => {
      console.log('clear socket')
    }
  }, [socket, name])

  // useEffect(() => {
  //   if (!remoteVideoRef.current) {
  //     return
  //   }

  //   try {
  //     const video = remoteVideoRef.current
  //     /*
  //       Когда я слышу учителя
  //       Если он говорит с выбранным и я выбранный
  //     */
  //     if (
  //       // Если он говорит со всеми
  //       !directMode ||
  //       // Если он говорит с выбранным и я выбранный
  //       (directMode &&
  //         directMode.value === 'selected' &&

  //         hasSelection(tags[name]))
  //     ) {
  //       setSoundOffFlag(false)
  //     } else {
  //       setSoundOffFlag(true)
  //     }
  //   } catch (e) {
  //     setError(e.toString())
  //   }

  //   // video.muted = isSoundOff
  // }, [remoteVideoRef, directMode, name, tags])

  const refreshHandle = () => {
    if (!socket || disableRefresh) {
      return
    }
    socket.emit('refresh_sockets', { roomId })
    setDisableRefresh(true)
    setTimeout(() => {
      document.location.reload()
    }, 2000)
  }

  useEffect(() => {
    setDisableRefresh(true)
  }, [])

  useEffect(() => {
    if (!socket) {
      return
    }

    setTimeout(() => {
      getInformation(window.navigator)
        .then((data) => {
          socket.emit('viewer-send-information', data)
        })
        .catch((error) => {
          console.error(error)
        })
    }, 2000)
  }, [socket])

  useEffect(() => {
    if (
      !isEmpty(settings) &&
      settings?.finishedAt !== null &&
      settings?.finishedAt !== undefined &&
      settings?.finishedAt !== 0 &&
      // franchiseeForPaySlug &&
      tariffPlanHashId &&
      isDemoLesson &&
      pupilId
    ) {
      // history.push(`/${franchiseeForPaySlug}/payment/${pupilId}`)
      stopStreaming()
      history.push(`/payment/tariff-plan/${tariffPlanHashId}/${pupilId}`)
    } else if (settings?.finishedAt) {
      if (roomStrId) {
        stopStreaming()
        history.push(`/lesson/${roomId}/end/${roomStrId}`)
      } else {
        stopStreaming()
        history.push('/cabinet')
      }
    }
  }, [settings])

  const onStartFetchICEServer = () => setIceFetchPending(true)
  const onFinishFetchICEServer = () => setIceFetchPending(false)

  const hookVideoAudioResult = useVideoAudioStreamPublic({
    socket,
    videoRef,
    remoteVideoRef,
    roomId,
    name,
    mode,
    connectionState,
    audioSource,
    videoSource,
    isMobile,
    onStartFetchICEServer,
    onFinishFetchICEServer,
  })

  const { isVideoDone, localAudioStream, stopStreaming } = hookVideoAudioResult

  useEffect(() => {
    if (!needStopLocalStreaming) {
      return
    }

    stopStreaming()
  }, [needStopLocalStreaming])

  useEffect(() => {
    setError(hookVideoAudioResult.error)
  }, [hookVideoAudioResult.error])

  useEffect(() => {
    if (settings?.finishedAt > 0 && pupilStream.audio && pupilStream.video) {
      pupilStream.audio.getTracks().forEach(function (track) {
        track.stop()
      })
      pupilStream.video.getTracks().forEach(function (track) {
        track.stop()
      })
    }
  }, [settings])

  useEffect(() => {
    setPupilStream(hookVideoAudioResult.pupilStream)
  }, [hookVideoAudioResult.pupilStream])

  useEffect(() => {
    try {
      if (pupilStream.audio) {
        pupilStream.audio.getAudioTracks()[0].enabled = !isMuted
      }
      if (socket) {
        socket.emit('ask-mute', { name, isMuted: isMuted })
      }
    } catch (e) {
      setError(e.toString())
    }
  }, [isMuted])

  useEffect(() => {
    try {
      if (socket) {
        socket.emit('ask-chat-mute', { name, isChatMuted: isChatMuted })
      }
    } catch (e) {
      setError(e.toString())
    }
  }, [isChatMuted])

  useEffect(() => {
    if (!socket) {
      return
    }

    const handleAskForHelp = (data) => {
      console.log('viewer.ask-for-help', name, data)
      if (data.name === name) {
        setNeedHelp(data.doNeedHelp)
      }

      setReactions({
        ...reactions,
        [data.name]: data.doNeedHelp && data.reaction,
      })
    }
    socket.on('ask-for-help', handleAskForHelp)

    return () => {
      socket.off('ask-for-help', handleAskForHelp)
    }
  }, [socket, reactions])

  useEffect(() => {
    if (
      viewIndex !== presentView.viewIndex &&
      hasTag(tags[name], presentView.tag)
    ) {
      setViewIndex(presentView.viewIndex)
    }
  }, [presentView])

  useEffect(() => {
    if (!isDesktopOrLaptop) {
      let stream = teacherStream
      const video = remoteVideoRef.current
      const videoFull = remoteVideoFullRef.current

      if (viewValues[viewIndex].value === 'report') {
        setTaskFullPrev(isTaskFull)
        setTaskFull(true)

        if (!teacherStream && video) {
          stream = video.srcObject
          setTeacherStream(stream)
        }
        if (videoFull) {
          video.muted = true
          videoFull.srcObject = stream
        }
      } else if (isTaskFullPrev) {
        setTaskFull(isTaskFullPrev)
        setTaskFullPrev(false)

        if (videoFull) {
          video.muted = false
          videoFull.srcObject = null
        }
      }
    }
  }, [viewIndex, isDesktopOrLaptop])

  const onChatChangeMessage = (message) => {
    setMessages((prevState) => {
      const index = findIndex(
        prevState,
        (prevMessage) => prevMessage.id === message.id
      )
      if (index === -1) {
        return prevState
      }
      prevState[index] = message
      return [...prevState]
    })
  }

  useEffect(() => {
    if (!socket) {
      return
    }
    const onReceiveMessage = (data) => handleMessages(data)

    socket.on('chat_receive_message', onReceiveMessage)
    socket.on('chat_change_message', onChatChangeMessage)

    return () => {
      socket.off('chat_receive_message', onReceiveMessage)
      socket.off('chat_change_message', onChatChangeMessage)
    }
  }, [socket, isShowChat])

  useEffect(() => {
    if (isShowChat) {
      setUnreadMessages(0)
      setBubbleMessages([])
    }
  }, [isShowChat])

  useEffect(() => {
    if (!isShowChat && messages.length) {
      if (messages[messages.length - 1].from === 'system') {
        return
      }
      if (
        messages[messages.length - 1].isChatMuted &&
        messages[messages.length - 1].userId !== name
      ) {
        return
      }
      setUnreadMessages((prevState) => prevState + 1)
    }
  }, [messages])

  useOnClickOutside({ current: controlsRef.current }, hideMobileMenu)

  const handleMessages = (data, chat) => {
    setMessages((prevState) => [...prevState, data])

    if (!isShowChat && data.mentioned?.some((msg) => msg.roomId === roomId)) {
      setBubbleMessages((prevState) => [...prevState, data])
      setLastBubbleMessage(data)
    }
  }

  const closeBubbleMessage = (id) => {
    setBubbleMessages((prevState) =>
      prevState.filter((message) => message.id !== id)
    )
  }

  const onNeedHelp = (reaction) => {
    let doNeedHelp = !isNeedHelp
    if (reaction && reaction === reactions[name]) {
      doNeedHelp = false
    } else {
      doNeedHelp = true
    }

    setReactions({
      ...reactions,
      [name]: doNeedHelp && reaction,
    })

    // const onNeedHelp = () => {
    //   const doNeedHelp = !isNeedHelp
    setNeedHelp(doNeedHelp)
    socket.emit('ask-for-help', { name, doNeedHelp, reaction })
  }

  const mute = () => {
    const newIsMuted = !isMuted
    setMute(newIsMuted)
  }

  // const labelPupil = otherPupils[0] ? 'Слышу всех' : 'Слышу учителя';
  // const labelPupil = !isSoundOff && settings && settings.presenterAudio ? 'Слышу учителя' :
  //   (teacherStream === null) ? 'Не слышу учителя' :
  //   'Не слышу учителя'
  // // Тут логика будет сложнее.
  //
  // let labelPresenter = !(isMuted || isSoundOff) && settings && settings.presenterAudio ? 'Слышит меня' : 'Не слышит меня'
  // if (isDirectOn) {
  //   labelPresenter = 'Говорит со мной'
  // }
  //
  // const clPresenter = cn(
  //   styles.faceLabelRight,
  //   {
  //     [styles.faceLabelDanger]: (isSoundOff || isMuted) && !isDirectOn,
  //     [styles.faceLabelSuccess]: isDirectOn,
  //   },
  // )

  let imgStyle = null
  if (task && task.type !== 'giveIqTokens' && (!task.slide || task.file)) {
    imgStyle = getImgUrl(task)
  }

  const onCleanScreen = () => {
    if (!task) {
      return
    }

    // TODO стирать только то что нарисовал ученик ?
    if (!task.slide) {
      socket.emit(`canvas:viewer:clear-all`, { name, task: viewerSettings.emptyCanvasTask ? emptyCanvasTask : task })
    } else {
      socket.emit('epub:viewer:clear-all', { name, task: viewerSettings.emptyCanvasTask ? emptyCanvasTask : task })
    }
  }

  const toggleThema = () => {
    if (thema === 'dark') {
      setThema('light')
      localStorage.setItem('thema', 'light')
    } else {
      setThema('dark')
      localStorage.setItem('thema', 'dark')
    }

    logButtonTopClick('changeTheme')
  }

  const gotoNextView = () => {
    const maxIndex = viewValues.length - 1
    if (viewIndex < maxIndex) {
      setViewIndex(viewIndex + 1)
    } else {
      setViewIndex(0)
    }

    logButtonTopClick('change view')
  }

  const onChangeSettingMediaDevice = async ({ kind, source }) => {
    if (kind === 'audioSource') {
      setAudioSource(source)
      localStorage.setItem('audioSource', source)
      const newSource = await changeAudio({ audioSource })
      newSource.getAudioTracks()[0].enabled = !isMuted
      setPupilStream({ ...pupilStream, audio: newSource })
    }
    if (kind === 'videoSource') {
      setVideoSource(source)
      localStorage.setItem('videoSource', source)
      const newSource = await changeVideo({ videoSource, videoRef })
      setPupilStream({ ...pupilStream, video: newSource })
    }
  }

  const timer = timers[name]
  let timerPercent = 0
  if (timer) {
    timerPercent = timer.time_int ? (timer.count / timer.time_int) * 100 : 100
  }

  console.log('directMode в ученике', directMode)
  console.log('tags в ученике', tags)

  const getOtherPupilsVisible = (data) => {
    const { otherPupils, directMode, settings, settingsByTag, tags } = data

    const res = otherPupils.map((otherName) => {
      if (
        directMode &&
        directMode.value === 'selected' &&
        !hasSelection(tags[name]) &&
        hasSelection(tags[otherName])
      ) {
        return null
      }

      /*
        Специальный кейс 2
        Когда у учителя приват с мной, и другой ученик не селектед
        - я его не слышу, даже если все другие истории выполняются
      */
      if (
        directMode &&
        directMode.value === 'selected' &&
        hasSelection(tags[name]) &&
        !hasSelection(tags[otherName])
      ) {
        return null
      }
      if (
        (settings && settings?.sound === 'all') ||
        hasCommonGroupAndGroupSoundIsAll({
          // Когда мы в одной группе и учитель этой группе поставил настройку прямого общения
          myName: name,
          otherName: otherName,
          settingsByTag,
          tags,
        })
      ) {
        if (
          directMode &&
          directMode.value === 'selected' &&
          !hasSelection(tags[name]) &&
          hasSelection(tags[otherName])
        ) {
        } else {
          return otherName
        }
      }
    })
    return compact(res)
    /* Когда один ученик слышит другого */
  }

  const otherPupilsVisible = getOtherPupilsVisible({
    otherPupils,
    directMode,
    settings,
    settingsByTag,
    tags,
  })

  const getOtherPupilsVisibleFromRoomGroup = (settings) => {
    const pupils = settings?.roomGroups?.map(((roomGroup) => {
      if (roomGroup?.selectedPupils?.includes(name)) {
        // settings?.presenterAudio === false
        // console.log('settings?.presenterAudio внутри', settings?.presenterAudio)

        return  roomGroup?.selectedPupils
      } else {
        return []
      }
    })).flat()

    return pupils
  }

  const getAllOtherPupilsVisibleFromRoomGroup = (settings) => {
    const pupils = settings?.roomGroups?.map(((roomGroup) => {
      return  roomGroup?.selectedPupils
    })).flat()

    return pupils
  }

  const [otherPupilsVisibleFromRoomGroup, setOtherPupilsVisibleFromRoomGroup] = useState([]) /* - для отображения учеников по разным группам, чтобы ученики не видели друг друга, если они в разных группах*/
  const [allOtherPupilsVisibleFromRoomGroup, setAllOtherPupilsVisibleFromRoomGroup] = useState([]) /* - для отображения просто всех учеников, которые состоят в группах, для того чтобы ученик который не в группе не видел тех которые в группах*/

  const [isMutedTeacherSound, setIsMutedTeacherSound] = useState('')


  useEffect(() => {
    setOtherPupilsVisibleFromRoomGroup(getOtherPupilsVisibleFromRoomGroup(settings))
    setAllOtherPupilsVisibleFromRoomGroup(getAllOtherPupilsVisibleFromRoomGroup(settings))
  }, [name, settings])

  useEffect(() => {
    // сравниваем с активностью группы и наличия текущего ученика в ней,
    // по этому состоянию отключаем/включаем звук учителя

    // вначале всегда проверять какие настройки микрофона от учителя и уже потом включать его звук для выбранных учеников
    if (settings?.presenterAudio === true) {
      // пока не создали группы, все ученики смотрят на settings?.presenterAudio
      setIsMutedTeacherSound(true)

      if (viewerSettings.isSelected) {
        setIsMutedTeacherSound(true)
      }
      // если есть группы и у данного ученика группа не активна, т.е. свойство isSelected не присвоено
      if (!viewerSettings.isSelected && settings?.roomGroups?.length) {
        setIsMutedTeacherSound(false)
      }
    } else {
      setIsMutedTeacherSound(false)
    }
  },[otherPupilsVisibleFromRoomGroup, settings, viewerSettings, settings?.roomGroups])

  console.log('settings isMutedTeacherSound',isMutedTeacherSound)

  const closeChat = () => {
    setShowChat(false)
    setShowMobileMenu(true)
  }

  const muteMicroHandler = ()=>{
    if(!socket){
      return
      }
    socket.emit('room:viewers-settings:viewer:set', { name, viewerSettings:{isSoundMute:!viewerSettings?.isSoundMute} })
    // setSoundOffFlag(!isSoundOff)
  }

  return (
    <>
      <div
        className={cn(stylesNext.wrap, [`thema-${thema}`], {
          [stylesNext.chatOpen]: showChatFlag && isShowChat,
          [stylesNext.wrapThema]: thema,
          [stylesNext.wrapMobile]: !isDesktopOrLaptop,
          [stylesNext.wrapDesktop]: isDesktopOrLaptop,
          [stylesNext.wrapMobileMenu]: !isDesktopOrLaptop && isShowMobileMenu,
          [stylesNext[`wrap_${viewValues[viewIndex].value}`]]:
            isDesktopOrLaptop,
          [stylesNext[`wrap_report`]]: viewValues[viewIndex].value === 'report',
        })}
      >
        <div className={stylesNext.contentBlock}>
          {iceFetchPending && <Loader text={'Идёт создание урока...'} />}
          <ConnectProblem isShow={connectionState === 'disconnected'} />
          {error && (
            <div className={cn('presenter-error', stylesNext.errorPosition)}>
              {error}
            </div>
          )}
          {isDesktopOrLaptop && (
            <DesktopControlsNext
              theme={thema}
              remoteVideoRef={remoteVideoRef}
              onChangeSettingMediaDevice={onChangeSettingMediaDevice}
              currentView={viewValues[viewIndex].value}
              changeTheme={toggleThema}
              gotoNextView={gotoNextView}
              doneNextTimer={timer}
              reaction={reactions[name]}
              isNeedHelp={isNeedHelp}
              onNeedHelp={onNeedHelp}
              isMicroMute={viewerSettings?.isSoundMute}
              muteMicroHandler={muteMicroHandler}
              onClickEraser={onCleanScreen}
              handlerUpdate={refreshHandle}
              updateButtonDisabled={disableRefresh}
              task={viewerSettings.emptyCanvasTask ? emptyCanvasTask : task}
              socket={socket}
              name={name}
            />
          )}
          <div className={stylesNext.contentWrap}>
            <div className={stylesNext.mobileHelp}>
              Помощь: <a href='tel: 88005007417'>8 800 500 74 17</a>
            </div>
            <div className={cn(stylesNext.content)}>
              <div
                className={cn(stylesNext.taskWrap, {
                  [stylesNext.faces]: !isDesktopOrLaptop,
                  [stylesNext.taskWrapFull]: isTaskFull,
                })}
              >
                {!isDesktopOrLaptop && !isTaskFull && (
                  <>
                    <div
                      className={cn(
                        stylesNext.facesItem,
                        stylesNext.faceTeacher
                      )}
                    />
                    <div
                      className={cn(
                        stylesNext.facesItem,
                        stylesNext.facesItemMe
                      )}
                    />
                    {!isDesktopOrLaptop &&
                      !isTaskFull &&
                      otherPupilsVisible.map(() => (
                        <div className={stylesNext.facesItem} />
                      ))}
                  </>
                )}
                <div
                  className={cn(stylesNext.task, {
                    [stylesNext.facesItem]: !isDesktopOrLaptop,
                  })}
                >
                  {viewerSettings.needCallToSupport ||
                  connectionState === 'disconnected' ||
                  error ? (
                    <div
                      className={cn(
                        stylesNext.taskContainer,
                        stylesNext.lessonSoonContainer
                      )}
                    >
                      <img
                        src={callToSupportImg}
                        style={{ height: '100%', maxWidth: '100%' }}
                      />
                    </div>
                  ) : (
                    <>
                      {settings?.startedAt ? (
                        <>
                          {!task && (
                            <IconBgTask className={cn('position-center')} />
                          )}
                          {task && task.copyright && (
                            <CopyRight
                              isShowMobileMenu={isShowMobileMenu}
                              isDesktopOrLaptop={isDesktopOrLaptop}
                              html={task.copyright}
                            />
                          )}

                          {task && task.video && (
                            <VideoTask
                              src={getVideoUrl(task)}
                              socket={socket}
                              name={name}
                            />
                          )}

                          {task && task.audio && (
                            <AudioTask
                              src={getAudioUrl(task)}
                              name={name}
                              socket={socket}
                            />
                          )}
                          {task && task.slide && !imgStyle && !viewerSettings.emptyCanvasTask && (
                            <Epub
                              socket={socket}
                              name={name}
                              type={'viewer'}
                              task={task}
                            />
                          )}
                          {(imgStyle || viewerSettings.emptyCanvasTask) && (
                            <div className={stylesNext.taskContainer}>
                              <Canvas
                                color={canvasColor}
                                type='pupil'
                                task={viewerSettings.emptyCanvasTask ? emptyCanvasTask : task}
                                socket={socket}
                                name={name}
                                pic={viewerSettings.emptyCanvasTask ? emptyCanvasTask.src : imgStyle}
                              />
                            </div>
                          )}
                          {task && task.type === 'giveIqTokens' && (
                            <img
                              src={task.src}
                              style={{
                                height: '100%',
                                maxWidth: '100%',
                                position: 'absolute',
                                margin: 'auto',
                                left: 0,
                                right: 0,
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <div
                          className={cn(
                            stylesNext.taskContainer,
                            stylesNext.lessonSoonContainer
                          )}
                        >
                          <img
                            src={bigLessonSoonImg}
                            style={{ height: '100%', maxWidth: '100%' }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {
                // мобильный вид демонстрации учителя
                <video
                  id='rrr' // TODO хммм
                  className={stylesNext.videoTeacherFull}
                  ref={remoteVideoFullRef}
                  playsInline
                  autoPlay
                  muted
                />
              }
              <DraggableWrap
                bounds='#root'
                onClick={(e) => {
                  if (e.target.classList.contains('js-click')) {
                    setTaskFull(false)
                  }
                  if (e.target.classList.contains(stylesNext.buttonShowFaces)) {
                    showFaces()
                  }
                  if (e.target.classList.contains(stylesNext.buttonHideFaces)) {
                    hideFaces()
                  }
                }}
                disabled={!isTaskFull}
              >
                <div
                  className={cn(stylesNext.aside, {
                    [stylesNext.asideTaskFull]: isTaskFull,
                    [stylesNext.asideTaskNoFull]: !isTaskFull,
                  })}
                  ref={asideRef}
                >
                  {isTaskFull && (
                    <div
                      className={cn('js-click', stylesNext.toggleTaskFull)}
                    />
                  )}

                  <div
                    className={cn(stylesNext.faces, {
                      [stylesNext.facesCollapse]: isFacesCollapse,
                      [stylesNext.facesAvatar]:
                        isDesktopOrLaptop &&
                        viewValues[viewIndex].value === 'report',
                    })}
                  >
                    <div
                      className={cn(
                        stylesNext.facesItem,
                        stylesNext.faceTeacher
                      )}
                    >
                      {!isVideoDone && (
                        <div className={stylesNext.plashkaContainer}>
                          <img src={plashka} />
                        </div>
                      )}

                      {teacherStream === null && (
                        <div className={stylesNext.plashkaContainer}>
                          <img src={plashka} />
                        </div>
                      )}
                      {(
                        (isVideoDone &&
                          get(remoteVideoRef, 'current.srcObject') &&
                          settings &&
                          !settings?.presenterVideo &&
                          settings?.presenterAudio)) && (
                        <TeacherUnavailable
                          isSoundMuted={
                            !settings || (settings && !settings?.presenterAudio)
                          }
                        />
                      )}

                      {isVideoDone &&
                        get(remoteVideoRef, 'current.srcObject') &&
                        settings &&
                        !settings?.presenterVideo &&
                        !settings?.presenterAudio && (
                          <div className={stylesNext.plashkaContainer}>
                            <img src={plashka} />
                          </div>
                        )}

                      {/*{isVideoDone && get(remoteVideoRef, 'current.srcObject') && settings && settings.presenterVideo ? settings.presenterAudio ?*/}
                      {/*    <div className="video-gradient"/> : <div className={stylesNext.plashkaContainer}>*/}
                      {/*    <img className={stylesNext.plashkaContainerImg} src={plashka}/>*/}
                      {/*</div> :*/}
                      {/*  <TeacherUnavailable isSoundMuted={!settings || settings && !settings.presenterAudio}/>*/}
                      {/*}*/}
                      {console.log('settings?.presenterAudio BEFORE!!!', settings?.presenterAudio)}
                      <video
                        // style={{ display: 'none' }}
                        className={cn({
                          [stylesNext.videoHide]:
                            !settings ||
                            (settings && !settings?.presenterVideo),
                          [stylesNext.videoMirrored]:
                            settings && settings?.videoMirrored,
                        })}
                        ref={remoteVideoRef}
                        playsInline
                        autoPlay
                        // muted={!settings?.presenterAudio}
                        muted={!isMutedTeacherSound}
                      />
                      {presenter &&
                        presenter.name &&
                        settings?.presenterVideo &&
                        settings?.presenterAudio && (
                          <span
                            className={cn(
                              'face-item-name',
                              stylesNext.facesItemName
                            )}
                          >
                            <span>{presenter.name}</span>
                          </span>
                        )}
                    </div>
                    <div
                      className={cn(
                        stylesNext.facesItem,
                        stylesNext.facesItemMe
                      )}
                    >
                      {isVideoDone && get(videoRef, 'current.srcObject') ? (
                        <div className='video-gradient' />
                      ) : (
                        <div className='face-item-avatar'>
                          <Avatar name={name} noBg isRound={false} />
                        </div>
                      )}
                      {reactions[name] && (
                        <div
                          className='face-item-reaction cursor-pointer'
                          onClick={() => onNeedHelp(reactions[name])}
                        >
                          <span
                            className={`icon icon_${reactions[name]} icon_dark`}
                          />
                        </div>
                      )}
                      <video
                        className={stylesNext.video}
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                      />

                      {isMuted && (
                        <div className='face-item-mute-icon'>
                          <IconUnMute className={cn('icon')} />
                        </div>
                      )}
                      <div
                        className={cn(
                          'face-item-name',
                          stylesNext.facesItemName
                        )}
                      >
                        <span>{displayName} (я)</span>
                      </div>
                    </div>

                    <OtherPupils
                      isOnlyAvatar={
                        isDesktopOrLaptop &&
                        viewValues[viewIndex].value === 'report'
                      }
                      viewersMuted={viewersMuted}
                      reactions={reactions}
                      classItem={stylesNext.facesItem}
                      classItemName={stylesNext.facesItemName}
                      displayNamesSet={displayNamesSet}
                      myName={name}
                      socket={socket}
                      otherPupils={otherPupils}
                      directMode={directMode}
                      settings={settings}
                      settingsByTag={settingsByTag}
                      tags={tags}
                      otherPupilsVisibleFromRoomGroup={otherPupilsVisibleFromRoomGroup}
                      allOtherPupilsVisibleFromRoomGroup={allOtherPupilsVisibleFromRoomGroup}
                    />

                    {isTaskFull && isFacesCollapse && (
                      <div
                        className={cn(
                          stylesNext.buttonShowFaces,
                          stylesNext.buttonToggleFaces,
                          {
                            'display-none':
                              otherPupils.length === 0 &&
                              viewValues[viewIndex].value === 'report',
                          }
                        )}
                        onClick={showFaces}
                      ></div>
                    )}

                    {isTaskFull && !isFacesCollapse && (
                      <div
                        className={cn(
                          stylesNext.buttonToggleFaces,
                          stylesNext.buttonHideFaces
                        )}
                        onClick={hideFaces}
                      />
                    )}
                    {!isDesktopOrLaptop && !isTaskFull && (
                      <div
                        className={cn(stylesNext.facesItem, stylesNext.task)}
                        onClick={(e) => {
                          e.stopPropagation()
                          setTaskFull(true)
                        }}
                      />
                    )}
                  </div>
                </div>
              </DraggableWrap>
              {showChatFlag && isShowChat && isDesktopOrLaptop && (
                <ChatWidget
                  socket={socket}
                  isChatMuted={isChatMuted}
                  displayName={displayName}
                  onClose={closeChat}
                  viewMode={viewValues[viewIndex].value}
                  messages={messages}
                  theme={thema + 'Theme'}
                  isMobile={!isDesktopOrLaptop}
                  userId={pupilId}
                  roomName={name}
                />
              )}
              {showChatFlag && isDesktopOrLaptop && !isShowChat && (
                <div
                  style={{
                    alignSelf: 'flex-end',
                    marginBottom: 30,
                    marginLeft: 19,
                  }}
                >
                  <ButtonChat
                    count={unreadMessages}
                    onClick={toggleChatShow}
                    theme={viewIndex === 2 ? !thema : thema}
                  />
                </div>
              )}
            </div>
          </div>
          {!isDesktopOrLaptop && (
            <div
              className={cn(stylesNext.controls, {
                [stylesNext.controlsShow]: isShowMobileMenu,
                [stylesNext.foxShown]: !!bubbleMessages.length,
              })}
              ref={controlsRef}
            >
              {isShowOpenChatTutorial &&
                isShowMobileMenu &&
                showChatFlag &&
                unreadMessages > 0 && (
                  <div className={stylesNext.tutorialContainer}>
                    <div className={stylesNext.tutorial}>
                      <img src={knockAnimation} />
                      <p>
                        Нажми, чтобы
                        <br />
                        прочитать чат
                      </p>
                    </div>
                    <div className={stylesNext.triangle} />
                  </div>
                )}
              <div className={stylesNext.controlsLeft}>
                <DropMenu
                  thema={thema}
                  classInner={stylesNext.dropMenu}
                  classOpen={stylesNext.dropMenuOpen}
                  toggleClass={cn(stylesNext.control, stylesNext.menuControl)}
                  slotToggle={
                    <span
                      className={cn('button', 'button_no-style')}
                      style={{ maxHeight: '26px' }}
                    >
                      <IconEllipsis
                        className={cn('icon', stylesNext.controlIcon)}
                      />
                    </span>
                  }
                >
                  <button
                    className={cn('button', 'button_no-style')}
                    onClick={showInfoPopup}
                  >
                    <IconInfo className={cn('icon', stylesNext.controlIcon)} />
                  </button>
                  <Popup
                    isOpen={isInfoPopupOpen}
                    onClose={closeInfoPopup}
                    widthInnerAuto
                  >
                    <div className={stylesNext.infoPopupInner}>
                      Телефон техподдержки: <br /> 8 800 500 74 17
                    </div>
                  </Popup>
                  <SettingMD
                    videoElement={remoteVideoRef.current}
                    onChangeSettingMediaDevice={onChangeSettingMediaDevice}
                  />
                </DropMenu>
                <button
                  className={cn(
                    'button',
                    'button_no-style',
                    stylesNext.control,
                    stylesNext.menuControl
                  )}
                  type='button'
                  onClick={onCleanScreen}
                >
                  <IconEraser className={cn('icon', stylesNext.controlIcon)} />
                </button>
              </div>
              <div className={cn(stylesNext.buttonDone)}>
                <div className={stylesNext.buttonDoneInner}>
                  <div className={stylesNext.buttonDoneLeft}>
                    {isNeedHelp === 'yes' && <span>Сейчас подойду</span>}
                  </div>
                  <div className={stylesNext.menuControl}>
                    <ButtonDoneNext
                      isDesktopOrLaptop={isDesktopOrLaptop}
                      className={cn(stylesNext.control, stylesNext.controlBig)}
                      reaction={reactions[name]}
                      handleClick={onNeedHelp}
                      active={isNeedHelp}
                      timer={timer}
                      thema={thema}
                    />
                  </div>

                  <div
                    className={cn(stylesNext.timerValue, {
                      [stylesNext.timerValueMobile]: !isDesktopOrLaptop,
                      [stylesNext.timerValueMobileNobg]:
                        !isDesktopOrLaptop && isShowMobileMenu,
                      color_danger: timer && timerPercent > timer.dangerPercent,
                    })}
                  >
                    {timer && timer.value}
                  </div>
                </div>
              </div>
              <div
                className={stylesNext.controlsRight}
                style={{ display: 'flex' }}
              >
                {showChatFlag && (
                  <button
                    className={cn(
                      'button',
                      'button_no-style',
                      stylesNext.control,
                      stylesNext.menuControl
                    )}
                    type='button'
                    onClick={toggleChatShow}
                  >
                    <ButtonChat
                      count={unreadMessages}
                      theme={viewIndex === 2 ? !thema : thema}
                    />
                  </button>
                )}

                <button
                  className={cn(
                    'button',
                    'button_no-style',
                    stylesNext.control,
                    stylesNext.menuControl
                  )}
                  type='button'
                  onClick={mute}
                >
                  {isMuted ? (
                    <IconUnMute
                      className={cn('icon', stylesNext.controlIcon)}
                    />
                  ) : (
                    <IconMute className={cn('icon', stylesNext.controlIcon)} />
                  )}
                </button>
              </div>
            </div>
          )}
          <ReactTooltip
            id='global'
            className='tooltip'
            textColor='#4DA3F7'
            backgroundColor='#fff'
            aria-haspopup='true'
          />
          {isDesktopOrLaptop && (
            <a className={stylesNext.sosLink} href={'tel:88005007417'}>
              Помощь: 8 800 500 74 17
            </a>
          )}
        </div>
        {showChatFlag && isShowChat && !isDesktopOrLaptop && (
          <ChatWidget
            socket={socket}
            isChatMuted={isChatMuted}
            displayName={displayName}
            onClose={closeChat}
            viewMode={viewValues[viewIndex].value}
            messages={messages}
            theme={thema + 'Theme'}
            isMobile={!isDesktopOrLaptop}
            userId={pupilId}
            roomName={name}
          />
        )}
      </div>
      {isDesktopOrLaptop ? (
        <div
          className={cn(stylesNext.bubblePanel, {
            [stylesNext.panelUp]: !!viewIndex,
          })}
        >
          {bubbleMessages.map((message) => (
            <ChatMessage
              key={message.id}
              message={message}
              type='cloud-outside'
              onClose={closeBubbleMessage}
            />
          ))}
        </div>
      ) : (
        <MessageFox
          count={unreadMessages}
          onClick={toggleChatShow}
          isShow={!!bubbleMessages.length}
          message={lastBubbleMessage.text}
          author={lastBubbleMessage.userName}
        />
      )}
    </>
  )
}

function DesktopControls({
  isDesktopOrLaptop,
  viewValues,
  viewIndex,
  gotoNextView,
  ViewIcon,
  toggleThema,
  thema,
  labelPupil,
  otherPupilsVisible,
  name,
}) {
  return (
    <div
      className={cn(stylesNext.top, {
        [stylesNext.topAvatar]:
          isDesktopOrLaptop && viewValues[viewIndex].value === 'report',
      })}
    >
      <div className={stylesNext.topLeft}>
        <button
          onClick={gotoNextView}
          className={cn(
            'button_no-style',
            stylesNext.viewToggle,
            stylesNext[`viewToggle_${viewValues[viewIndex].value}`]
          )}
          data-tip={viewValues[viewIndex].label}
          data-for='global'
        >
          <ViewIcon />

          {/* <span className={stylesNext.viewLabel}>
              {viewValues[viewIndex].label}
          </span> */}
        </button>
        <button
          onClick={toggleThema}
          className={cn(
            'button_no-style',
            stylesNext.viewToggle,
            stylesNext[`viewToggle_${thema}`]
          )}
          data-tip={'Сменить тему'}
          data-for='global'
        >
          {thema === 'dark' ? (
            <IconThemaDark className={cn('icon', `icon_${thema}`)} />
          ) : (
            <IconThemaLight className={cn('icon', `icon_${thema}`)} />
          )}
          {/* <span className={stylesNext.viewLabel}>
              Сменить тему
          </span> */}
        </button>
        <p className={stylesNext.helpPhone}>
          Техподдержки: <a href='tel: 88005007417'>8 800 500 74 17</a>
        </p>
      </div>

      <div className={stylesNext.topRight}>
        <span className={stylesNext.labelPupil}>{labelPupil}</span>
        {/* <Avatar name={name} reaction={reactions[name]} /> */}
        {isDesktopOrLaptop && viewValues[viewIndex].value === 'report' && (
          <span className={stylesNext.topAvatarWrap}>
            {otherPupilsVisible.map(() => (
              <span className={stylesNext.isOnlyAvatar}>
                <Avatar name={name} isRound />
              </span>
            ))}
          </span>
        )}
      </div>
    </div>
  )
}
