import cn from 'classnames'
import React, { useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import presenterScreenStyles from '../../PresenterScreen/presenterScreen.module.scss'

import { ReactComponent as CurtainAngle } from './img/curtain-angle.svg'
import { ReactComponent as CurtainAngleLight } from './img/curtain-angle-light.svg'
import { ReactComponent as LeftRight } from './img/left-right.svg'
import { ReactComponent as LeftRightLight } from './img/left-right-light.svg'
import { ReactComponent as RightLeft } from './img/right-left.svg'
import { ReactComponent as RightLeftLight } from './img/right-left-light.svg'
import { ReactComponent as TopBottom } from './img/top-bottom.svg'
import { ReactComponent as TopBottomLight } from './img/top-bottom-light.svg'
import { ReactComponent as BottomTop } from './img/bottom-top.svg'
import { ReactComponent as BottomTopLight } from './img/bottom-top-light.svg'
import styles from './curtainMenu.module.sass'


export default function CurtainMenu({
  selected,
  currentDirection,
  handleChangeCurtainDirection,
  handleMode,
  handleClear,
  readOnly = false,
  slideType = 'canvas', //canvas, epub
  curtainTop=false,
}) {
  const [showMenu, setShowMenu] = useState(false)
  const wrapRef = useRef(null)

  const handleClose = () => {
    setShowMenu(false)
  }

  const handleToggle = (event) => {
    if (readOnly) {
      return
    }

    event.stopPropagation()
    setShowMenu(!showMenu)
  }

  const handleModeDirection = (direction) => {
    handleMode()
    handleChangeCurtainDirection(direction)
  }

  const closeCurtain = () => {
    console.log('closeCurtain')
    handleClear()
  }

  useOnClickOutside({ current: wrapRef.current }, handleClose)

  return (
    <div className={styles.curtainMenuWrap} ref={wrapRef} onClick={handleToggle}>
      <div className={cn(styles.modeOptionBtn, { [styles.selected]: selected })}
           data-tip="Инструмент «Шторка»" data-for="curtainMenu">
        {currentDirection === 'right' ?
          selected ? <RightLeftLight/> : <RightLeft/>
          : currentDirection === 'left' ?
            selected ? <LeftRightLight/> : <LeftRight/>
            : currentDirection === 'bottom' ?
              selected ? <BottomTopLight/> : <BottomTop/>
            : selected ? <TopBottomLight/> : <TopBottom/>}
        {selected ? <CurtainAngleLight className={styles.angle}/> : <CurtainAngle className={styles.angle}/>}
        <ReactTooltip
          id="curtainMenu"
          className={presenterScreenStyles.tooltip}
          place="right"
          textColor="#393F61"
          backgroundColor="#fff"
          aria-haspopup="true"
          multiline={true}
        />
      </div>
      <ul className={cn(styles.dropdownChildren, { [styles.hide]: !showMenu, [styles.top]: curtainTop })}>
        {slideType === 'canvas' && <li onClick={ () => handleModeDirection('top') } className={ styles.child }>
          <div className={ cn(styles.childIcon, styles.childIconTop) }/>
          Шторка сверху вниз
        </li> }
        {slideType === 'canvas' && <li onClick={ () => handleModeDirection('bottom') } className={ styles.child }>
          <div className={ cn(styles.childIcon, styles.childIconBottom) }/>
          Шторка снизу вверх
        </li> }
        <li onClick={() => handleModeDirection('left')} className={styles.child}>
          <div className={cn(styles.childIcon, styles.childIconLeft)}/>
          Шторка слева направо
        </li>
        <li onClick={() => handleModeDirection('right')} className={styles.child}>
          <div className={cn(styles.childIcon, styles.childIconRight)}/>
          Шторка справа налево
        </li>
        <li onClick={() => closeCurtain()} className={styles.child}>
          <div className={cn(styles.childIcon, styles.closeCurtain)}/>
          закрыть шторку
        </li>
      </ul>
    </div>
  )
}
