import React, { useState, useEffect } from 'react'

function Device({ device }) {
  return (
    <div className="sample-device" >
      <div>
        <span>{device.kind}</span> — <span>{device.label}</span>
      </div>
      <div className="sample-device_id">{device.deviceId}</div>
    </div>
  )
}
export default function DeviceTest() {
  const [devices, setDevices] = useState([])
  const [error, setError] = useState()

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(setDevices).catch(setError)
  }, [])

  return (
    <div className="sample-vector">
      <h1>Test Devices</h1>
      { error && <div className="presenter-error">
        { error }
      </div> }
      { devices.map((i,n) => <Device key={n} device={i} />)}
    </div>
  )
}