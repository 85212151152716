import React from "react";
import styPreScr from "../../presenterScreen.module.scss";
import cn from "classnames";
import { ReactComponent as Pencil } from "../../../components/Canvas/img/pencil.svg";
import { ReactComponent as PencilLight } from "../../../components/Canvas/img/pencil-light.svg";
import { ReactComponent as Pointer } from "../../../components/Canvas/img/pointer.svg";
import { ReactComponent as PointerLight } from "../../../components/Canvas/img/pointer-light.svg";
import { ReactComponent as MagnifierIcon } from "../../../components/Canvas/img/magnifier.svg";
import { ReactComponent as MagnifierIconLight } from "./../../../components/Canvas/img/magnifier-light.svg";
import CurtainMenu from "./../../../components/CurtainMenu/CurtainMenu";

const CanvasTools = ({
  handleTools = () => {},
  selectedGroupId,
  getTooltip = () => {},
  toolsByGroupId,
  curtainDirection,
  handleChangeCurtainDirection = () => {},
  closeCurtain = () => {},
  curtainTop,
}) => {
  return (
    <div className={styPreScr.buttons}>
      <button
        className={cn(styPreScr.modeOptionBtn, {
          [styPreScr.selected]: toolsByGroupId[selectedGroupId]?.pencil,
        })}
        onClick={() => handleTools("pencil")}
        data-tip="Инструмент «Карандаш»"
        data-for="pencil"
      >
        {toolsByGroupId[selectedGroupId].pencil ? <PencilLight /> : <Pencil />}
        {getTooltip("pencil")}
      </button>
      <button
        className={cn(styPreScr.modeOptionBtn, {
          [styPreScr.selected]: toolsByGroupId[selectedGroupId].pointer,
        })}
        onClick={() => handleTools("pointer")}
        data-tip="Инструмент «Указка»"
        data-for="pointer"
      >
        {toolsByGroupId[selectedGroupId].pointer ? (
          <PointerLight />
        ) : (
          <Pointer />
        )}
        {getTooltip("pointer")}
      </button>
      <CurtainMenu
        handleChangeCurtainDirection={handleChangeCurtainDirection}
        currentDirection={curtainDirection[selectedGroupId]}
        handleMode={() => handleTools("curtain")}
        handleClear={closeCurtain}
        selected={toolsByGroupId[selectedGroupId].curtain}
        curtainTop={curtainTop}
      />
      <button
        className={cn(styPreScr.modeOptionBtn, {
          [styPreScr.selected]: toolsByGroupId[selectedGroupId].magnifier,
        })}
        onClick={() => handleTools("magnifier")}
        data-tip="Инструмент «Лупа»"
        data-for="magnifier"
      >
        {toolsByGroupId[selectedGroupId].magnifier ? (
          <MagnifierIconLight />
        ) : (
          <MagnifierIcon />
        )}
        {getTooltip("magnifier")}
      </button>
    </div>
  );
};

export default CanvasTools;
