import React, { useState } from 'react'
import cn from "classnames";
import styles from "./header.module.css";

import { ReactComponent as Logo } from "./img/logo.svg";

const Header = () => {
	const [isAside, setAside] = useState(false);
  const toggleAside = () => {
    setAside(!isAside);
  };
    return (
			<header className="section">
				<div className={styles.top}>
					<div className={styles.left}>
						<Logo />
						<div className={styles.slogan}>
							Школа скорочтения и развития интеллекта
						</div>
					</div>
					<div className="right">
						<a href="tel:8 800 2222 007" className={styles.tel}>
							8 800 2222 007
						</a>
					</div>
				</div>
				<div className={cn(styles.bottom)}>
					<button
						className={cn("mobile-visible", styles.buttonSide, {
								[styles.close]: isAside,
						})}
						onClick={toggleAside}
					>
						<span className={styles.buttonSideLine1} />
						<span className={styles.buttonSideLine2} />
					</button>
					<div className={styles.bottom_txt}>
						<h1 className={styles.bottom_txtIq}>IQ 007 Online</h1>
						<h2 className={cn(styles.bottom_txtH2, 'mobile-hidden')}>
								Учим детей быстро читать и заниматься с удовольствием — онлайн!
						</h2>
					</div>
				</div>
			</header>
    );
};

export default Header;
