import { useState, useEffect } from "react";


const useTools = ({
  socket,
  name,
  task,
  personType, //viewer, presenter
}) => {
  const [tools, setTools] = useState({})

  const onViewerToolsAsk = ({name: viewerName, tools: toolsFromStorage}) => {
    if (viewerName !== name) {
      return
    }

    setTools(toolsFromStorage)
  }

  useEffect(() => {
    if (!socket || !name || !task) {
      return
    }

    if (personType === 'viewer') {
      //TODO: по факту мы 2 раза, а возможно и более раз, делам setState - не хорошо так(
      socket.on('canvas:viewer:tools:ask', onViewerToolsAsk)
      socket.on('canvas:viewer:tools', onViewerToolsAsk)

      socket.emit('canvas:viewer:tools:ask', { name, task })
    }

    return () => {
      socket.off('canvas:viewer:tools:ask', onViewerToolsAsk)
      socket.off('canvas:viewer:tools', onViewerToolsAsk)
    }
  }, [socket, name, task, personType])

  const changeTools = (toolName) => {
    const toolsForSet = {...tools}

    toolsForSet[toolName] = !toolsForSet[toolName]

    if (toolName === 'pencil') {
      toolsForSet.pointer = false
      // toolsForSet.magnifier = false
    }
    if (toolName === 'pointer') {
      toolsForSet.pencil = false
      // toolsForSet.magnifier = false
    }
    // if (toolName === 'magnifier') {
    //   toolsForSet.pencil = false
    //   toolsForSet.pointer = false
    // }

    setTools(toolsForSet)

    socket.emit('canvas:viewer:tools', { name, tools: toolsForSet, task })
  }

  return {
    tools,
    changeTools,
  }
}

export default useTools
