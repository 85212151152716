import isVisible from './isVisible'

export default function (element, dragzone) {
    console.log('@grag', element)
    if (!element) {
        return false
    }
    if (!dragzone) {
        dragzone = element;
    }
    let isDrag = false;

    dragzone.style.cursor = 'move'

    function dragFunc(event) {
        isDrag = true;
        let shiftX = event.clientX - element.getBoundingClientRect().left;
        let shiftY = event.clientY - element.getBoundingClientRect().top;

        element.style.position = "fixed";
        element.style.zIndex = 1000;
        element.style.transition = "none";
        // document.body.append(element);

        moveAt(event.pageX, event.pageY);

        function moveAt(pageX, pageY) {
            if (isDrag) {
                element.style.left = pageX - shiftX + "px";
                element.style.top = pageY - shiftY + "px";
            }
        }

        function onMouseMove(event) {
            moveAt(event.pageX, event.pageY);
        }

        function onMouseOut(e) {
          let mouseX = 0;
          let mouseY = 0;
          mouseX = e.pageX;
          mouseY = e.pageY;
          if ((mouseY >= 0 && mouseY <= window.innerHeight) && (mouseX >= 0 && mouseX <= window.innerWidth)) return;
          clean()
        }

        function clean(e) {
            document.removeEventListener("mousemove", onMouseMove);
            document.ontouchend = null;
            isDrag = false;

            if (!isVisible(element)) {
                element.style.left = 10 + "px";
                element.style.top = 10 + "px";
            }
        }

        // передвигаем элемент при событии mousemove
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseout", onMouseOut)

        document.ontouchmove = (e) => {
            e.pageX = e.targetTouches[0].pageX;
            e.pageY = e.targetTouches[0].pageY;
            e.clientX = e.targetTouches[0].clientX;
            e.clientY = e.targetTouches[0].clientY;
            onMouseMove(e);
        };

        // отпустить элемент, удалить ненужные обработчики
        dragzone.onmouseup = clean;
        document.ontouchend = clean;
    }

    function handleTouchStart(e) {
        // e.preventDefault();
        // var touchobj = e.changedTouches[0];
        // e.clientX = parseInt(touchobj.clientX);
        // e.clientY = parseInt(touchobj.clientY);

        console.log('handleTouchStart', e)
        e.clientX = e.targetTouches[0].clientX;
        e.clientY = e.targetTouches[0].clientY;
        e.pageX = e.targetTouches[0].pageX;
        e.pageY = e.targetTouches[0].pageY;

        dragFunc(e);
    }



    const init = () => {
        console.log('DRAG INIT')
        dragzone.onmousedown = dragFunc;

        dragzone.ondragstart = function () {
            return false;
        };

        dragzone.ontouchstart = handleTouchStart



    }

    init()
    return {
        isDrag,
        init: () => {
            init()
        },
        destroy: () => {
            console.log('drag destroy')
            dragzone.onmousedown = null;
            dragzone.onmouseup = null;
            document.ontouchend = null;
            dragzone.ontouchstart = null;
            isDrag = false;
            element.style = null;
            document.ontouchmove = null
        },
    };
}
