import React from 'react'
import { buttonsType } from "../ButtonsContainer/buttonsType";
import ButtonsContainer from "../ButtonsContainer/ButtonsContainer";
import ReactionBtn from "../ButtonsContainer/buttons/ReactionBtn";


const ViewerReactionsMenu = ({ onClickReaction = (reactionName) => {} }) => {
  return (
    <ButtonsContainer>
      <ReactionBtn onClick={ () => onClickReaction('hand') } reactionType={buttonsType.VIEWER_REACTION_DEFAULT} />
      <ReactionBtn onClick={ () => onClickReaction('good') } reactionType={buttonsType.VIEWER_REACTION_LIKE} />
      <ReactionBtn onClick={ () => onClickReaction('glad') } reactionType={buttonsType.VIEWER_REACTION_GOOD} />
      <ReactionBtn onClick={ () => onClickReaction('sad') } reactionType={buttonsType.VIEWER_REACTION_SAD} />
      <ReactionBtn onClick={ () => onClickReaction('alarm') } reactionType={buttonsType.VIEWER_REACTION_ALARM} />
    </ButtonsContainer>
  )
}

export default ViewerReactionsMenu