import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import styles from './radiogroup.module.css'


const RadioGroup = ({ options, onChange, name, defaultChecked }) => {
  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <form action="">
      <div className={styles.wrap}>
        {options.map((i, index) => (
          <div className={styles.item}
               key={nanoid(3)}
          >
            {/*{console.log('i.value', i.value)}*/}
            {/*{console.log('defaultChecked', defaultChecked)}*/}
            {/*{console.log('i.value === defaultChecked', i.value === defaultChecked)}*/}
            <input
              className={styles.input}
              type="radio"
              name={name}
              id={`${name}_${index}`}
              value={i.value}
              onChange={handleChange}
              defaultChecked={i.value === defaultChecked}
            />
            <label
              htmlFor={`${name}_${index}`}
              className={styles.label}
            >
              {i.label}
            </label>
          </div>
        ))}
      </div>
    </form>
  )
}

RadioGroup.defaultProps = {
  options: [],
}

export default RadioGroup
