import React from 'react'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import viewerScreenStyles from '../../viewerScreenNext.module.css'
import { ReactComponent as IconUpdate } from './img/update.svg'
import styles from './buttonUpdate.module.scss'

export default function ButtonUpdate({ theme = 'dark', onClick, disabled }) {
  return (
    <div
      className={cn(styles.button, styles[theme])}
      onClick={(event) => {
        if (disabled) {
          event.preventDefault()
        } else {
          onClick()
        }
      }}
      data-tip="Обновить урок" data-for="update-pupil"
    >
     <IconUpdate className={cn('icon', styles.icon, {[styles.disabled]: disabled})} />
      <ReactTooltip
        id="update-pupil"
        className={viewerScreenStyles.tooltip}
        place="top"
        textColor="#393F61"
        backgroundColor="#fff"
        aria-haspopup="true"
      />
    </div>
  )
}