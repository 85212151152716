import superagent from 'superagent'

export async function postError(errorText) {
  const resp = await superagent.post('/api/log/error')
    .send({
      errorText
    })

  return resp.body
}

export async function postErrorLog(payload) {
  const resp = await superagent.post('/api/log/error').send(payload)

  return resp.body
}