import cn from 'classnames'
import { nanoid } from 'nanoid'
import React, { useState, useEffect, useRef } from 'react'
import ChatMessage from '../components/ChatMessage/ChatMessage'
import useSimpleTimer from '../hooks/useSimpleTimer'

import styles from '../components/ChatWidget/chatWidget.module.css'
import mobileStyles from './mobileChatWidget.module.css'
import localStyles from './chatWidget.module.css'


export default function ChatWidget({
  socket,
  displayName,
  theme = 'darkTheme',
  onClose,
  viewMode,
  messages,
  isMobile,
  userId,
  isChatMuted,
  roomName,
}) {
  const chatBodyRef = useRef(null)

  const [messageInput, setMessageInput] = useState('')
  const [showCloseTutorial, setShowCloseTutorial] = useState(false)

  const [seconds, timerStart, timerReset, timerStop] = useSimpleTimer(10)

  useEffect(() => {
    if (seconds > 0){
      return
    }
    setShowCloseTutorial(true)
  }, [seconds])

  useEffect(() => {
    setShowCloseTutorial(false)
    timerReset()
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight - chatBodyRef.current.clientHeight

    return () => timerStop()
  }, [messages])

  const handleInput = ({ target }) => {
    setMessageInput(target.value)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      sendMessage()
    }
  }

  const sendMessage = () => {
    if (!socket || !messageInput.trim()) {
      return
    }

    socket.emit('chat_sent_message', {
      message: { text: messageInput, id: nanoid(6) },
      userName: displayName || 'Ученик',
      from: 'viewer',
      userId,
      roomName,
      isChatMuted: isChatMuted,
    })

    setMessageInput('')
  }

  const isNewAuthor = (message, index) => {
    return index === 0 || message.userName !== messages[index - 1].userName || messages[index - 1].from === 'system'
  }

  if (isMobile) {
    return (
      <div className={mobileStyles.container}>
        <div className={cn(styles.chatBody, localStyles.chatBody, mobileStyles.chatBody)} ref={chatBodyRef}>
          {messages.map((message, index) => {
            console.log(`message from ${message.from}`)
            if (message.deleted) {
              return
            }

            if (message.from === 'system' && !message.showForAll) {
              return // пока будем скипать системные сообщения
            }
            if (message.isChatMuted && message.userId !== userId) {
              return // скипаем сообщение от замьюченых
            }

            return (
              <ChatMessage message={message}
                           key={message.id}
                           type="cloud"
                           theme={theme}
                           currentUser={userId}
                           isMobile={isMobile}
                           isNewAuthor={isNewAuthor(message, index)}
              />
            )
          })}
        </div>
        { isChatMuted &&
          <p className={styles.muteChat}>Теперь тебя слышит только учитель.</p>
        }
        <div className={cn(styles.chatControls, localStyles.chatControls, mobileStyles.chatControls)}>
        <textarea
          onChange={handleInput}
          value={messageInput}
          onKeyDown={onKeyDown}
          className={styles.chatInput}
          placeholder="Сообщение"
        />
          <div className={cn(styles.sendButton, localStyles.sendButton)} onClick={sendMessage}/>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.fixedWrapper}><div className={cn(
      styles.chatWrapper,
      localStyles.chatWrapper,
      localStyles[viewMode],
      {
        [localStyles.mobileView]: isMobile,
      },
      styles[theme],
      localStyles[theme],
    )}
    >
      {!isMobile && <>
        <div className={cn(styles.closeBlock, localStyles.closeBlock)}>
          <div className={styles.closeIcon} onClick={onClose}/>
          {showCloseTutorial &&
            <div className={styles.tutorialContainer}>
              <div className={styles.tutorial}>
                <p>
                  Нажми, чтобы
                  <br/>
                  закрыть чат
                </p>
              </div>
              <div className={styles.triangle}/>
            </div>
          }
        </div>
        <div className={localStyles.mobileHeader}>
          <div className={localStyles.backIcon} onClick={onClose}/>
          Вернуться к уроку
        </div>
        {showCloseTutorial && <div className={cn(styles.tutorialContainer, styles.tutorialMobileContainer)}>
          <div className={styles.mobileTriangle}/>
          <div className={cn(styles.tutorial, styles.tutorialMobile)}>
            <p>
              Нажми стрелку, чтобы
              <br/>
              вернуться в урок
            </p>
          </div>
        </div>}
      </>}
      <div className={cn(styles.chatBody, localStyles.chatBody)} ref={chatBodyRef}>
        {messages.map((message, index) => {
          console.log(`message from ${message.from}`)
          if (message.deleted) {
            return
          }

          if (message.from === 'system' && !message.showForAll) {
            return // пока будем скипать системные сообщения
          }
          if (message.isChatMuted && message.userId !== userId) {
            return // скипаем сообщение от замьюченых
          }

          return (
            <ChatMessage message={message}
             key={message.id}
             type="cloud"
             theme={theme}
             currentUser={userId}
             isMobile={isMobile}
             isNewAuthor={isNewAuthor(message, index)}
            />
          )
        })}
      </div>
      { isChatMuted &&
        <p className={styles.muteChat}>Теперь тебя слышит только учитель.</p>
      }
      <div className={cn(styles.chatControls, localStyles.chatControls)}>
        <textarea
          onChange={handleInput}
          value={messageInput}
          onKeyDown={onKeyDown}
          className={styles.chatInput}
          placeholder="Сообщение"
        />
        <div className={cn(styles.sendButton, localStyles.sendButton)} onClick={sendMessage}/>
      </div>
    </div></div>
  )
}

