import React from 'react'
import LoaderSpinner from 'react-loader-spinner'
import styles from './loader.module.css'

export const Loader = (props) => {
  const { text } = props
  return <div className={styles.loaderWrapper}>
    <LoaderSpinner
      type="TailSpin"
      color="#00BFFF"
      height={100}
      width={100}
    />
    {text && <p>{text}</p>}
  </div>
}
