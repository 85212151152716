export default function(el, count = 4) {
    const carousel = el
    /* этот код помечает картинки, для удобства разработки */
    let i = 1;
    let width
    for(let li of carousel.querySelectorAll('li')) {
      li.style.position = 'relative';
      i++;

      if (!width) {
          width = li.offsetWidth
      }
    }

   

    let list = carousel.querySelector('ul');
    let listElems = carousel.querySelectorAll('li');

    let position = 0; // положение ленты прокрутки

    carousel.querySelector('.prev').onclick = function() {
      // сдвиг влево
      position += width * count;
      // последнее передвижение влево может быть не на 3, а на 2 или 1 элемент
      position = Math.min(position, 0)
      list.style.marginLeft = position + 'px';
    };

    carousel.querySelector('.next').onclick = function() {
      // сдвиг вправо
      position -= width * count;
      // последнее передвижение вправо может быть не на 3, а на 2 или 1 элемент
      position = Math.max(position, -width * (listElems.length - count));
      list.style.marginLeft = position + 'px';
    };
}