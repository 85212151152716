import { useEffect, useState } from "react";
import { createOffer, createPeerConnectionNext } from "../webrtc";


const usePupilMedia = ({
  socket,
  name,
  mediaType, //audio, video
  mediaRef,
  connectedAt,
  isLocalViewer= false,
}) => {
  const [stream, setStream] = useState(null)

  useEffect(() => {
    if (!socket || !name || !mediaType || !connectedAt || !mediaRef?.current || isLocalViewer) {
      return
    }

    console.log('получаю медиа другого ученика!')

    createPeerConnectionNext(socket, mediaRef.current, {input: `${mediaType}:${name}`}, (streamRes) => setStream(streamRes))
      .then(({pc, connectionId}) => {
        pc.addTransceiver(mediaType, {direction: 'recvonly'})
        pc.onnegotiationneeded = (event => {
          console.log('onnegotiationneeded', event)
        })
        createOffer(pc)
          .then((offer) => socket.emit(`ask-${mediaType}`, {name, connectionId, offer: offer.sdp}))
      })

    return () => {
      setStream(null)
    }

  }, [socket, name, mediaType, mediaRef, connectedAt])

  return {stream, setStream}
}

export default usePupilMedia
