import React from 'react'
import { postErrorLog } from './api/log'


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    postErrorLog({ meta: { error, errorInfo }, errorText: error.toString() }).then().catch()
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{ padding: '20px' }}>
          <h2>Произошла ошибка</h2>
          <div className="presenter-error">
            {this.state.error && this.state.error.toString()}
            {/*{this.state.errorInfo.componentStack} */}
          </div>
          <div>
            <p>Пожалуйста, обратитесь в техническую поддержку 8 800 500 74 17</p>
          </div>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary
