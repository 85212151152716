import superagent from 'superagent'


const API_TOKEN = '60e1342bc42dcec9736310428b234e4a8c0a8d77'
const fioSuggestionsUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio'
const addressSuggestionsUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'


export async function getNameSuggestions(value) {
  const resp = await superagent.post(fioSuggestionsUrl)
    .send({ query: value, count: 5, parts: ['NAME'] })
    .set('Content-Type', 'application/json')
    .set('Authorization', `Token ${API_TOKEN}`)
  return resp.body?.suggestions
}

export async function getSurnameSuggestions(value) {
  const resp = await superagent.post(fioSuggestionsUrl)
    .send({ query: value, count: 5, parts: ['SURNAME'] })
    .set('Content-Type', 'application/json')
    .set('Authorization', `Token ${API_TOKEN}`)
  return resp.body?.suggestions
}

export async function getPatronymicSuggestions(value) {
  const resp = await superagent.post(fioSuggestionsUrl)
    .send({ query: value, count: 5, parts: ['PATRONYMIC'] })
    .set('Content-Type', 'application/json')
    .set('Authorization', `Token ${API_TOKEN}`)
  return resp.body?.suggestions
}

export async function getFullFioSuggestions(value) {
  const resp = await superagent.post(fioSuggestionsUrl)
    .send({ query: value, count: 5 })
    .set('Content-Type', 'application/json')
    .set('Authorization', `Token ${API_TOKEN}`)
  return resp.body?.suggestions
}

export async function getCitySuggestions(value) {
  const resp = await superagent.post(addressSuggestionsUrl)
    .send({ query: value, count: 5, 'from_bound': { 'value': 'city' }, 'to_bound': { 'value': 'city' } })
    .set('Content-Type', 'application/json')
    .set('Authorization', `Token ${API_TOKEN}`)
  return resp.body?.suggestions
}

export const suggestionType = { NAME: 'NAME', SURNAME: 'SURNAME', PATRONYMIC: 'PATRONYMIC', FULL_FIO: 'FULL_FIO', CITY: 'CITY' }
export const getSuggestionsFuncs = {
  'NAME': getNameSuggestions,
  'SURNAME': getSurnameSuggestions,
  'PATRONYMIC': getPatronymicSuggestions,
  'FULL_FIO': getFullFioSuggestions,
  'CITY': getCitySuggestions,
}