import { nanoid } from "nanoid"
import debounce from 'lodash/debounce'
import getRenderedSize from './utils/getRenderedSize'


const POINT_PER_TICK = 2.5 //0.7
const DELAY_BETWEEN_FRAMES = 40 //ms
const DELAY_BETWEEN_FRAMES_FOR_PREVIEW = 1500 //ms


export const createDrawingContext = ({
  storageKey = '',
  name,
  canvas,
  readOnly = false,
  imageSize,
  type,
  color,
  isPreview = true,
  onLineUpdate,
  onLineAdd,
}) => {
  console.log('createDrawingContext!')

  let isMoreOneTouch = false
  let isDestroyed = false
  let modeLine = ''
  let LINES_LENGTH = 0
  let needUpdate = false
  const delayBetweenFrames = isPreview ? DELAY_BETWEEN_FRAMES_FOR_PREVIEW : DELAY_BETWEEN_FRAMES
  const pointPerTick = POINT_PER_TICK

  const createFuncRest = (line) => {
    const resetUpdated = () => {
      line.isUpdated = false
    }
    const debouncedResetUpdated = debounce(resetUpdated, 1000)
    return debouncedResetUpdated
  }

  let lines = { }

  let currentLine = null
  const ctx = canvas.getContext("2d")

  const scale = {
    canvasWidth: 0,
    canvasHeight: 0,
    sizeInfo: null,
  }

  const updateCanvasSize = (canvasWidth, canvasHeight) => {
    if (scale.canvasWidth === canvasWidth && scale.canvasHeight === canvasHeight) {
      return
    }

    scale.canvasWidth = canvasWidth
    scale.canvasHeight = canvasHeight
    scale.sizeInfo = getRenderedSize(
      true,
      scale.canvasWidth, scale.canvasHeight,
      imageSize.width, imageSize.height
    )
  }

  const canvas2img = (x, y) => {
    // понять rate
    // привести координаты
    // учесть офсет
    const sizeInfo = scale.sizeInfo

    const xImg = x - sizeInfo.left
    const xImgPercenr = xImg / sizeInfo.width
    const yImgPercenr = y / sizeInfo.height

    const xImgOrigin = xImgPercenr * imageSize.width
    const yImgOrigin = yImgPercenr * imageSize.height

    return [xImgOrigin, yImgOrigin]
  }

  const img2canvas = (x, y) => {
    const sizeInfo = scale.sizeInfo
    if (!sizeInfo) {
      return [0, 0]
    }

    const xImgPercenr = x / imageSize.width
    const yImgPercenr = y / imageSize.height

    const xImg = xImgPercenr * sizeInfo.width
    const yImg = yImgPercenr * sizeInfo.height

    return [sizeInfo.left + xImg, yImg]
  }

  const drawPath = (ctx, line) => {
    if (!line || !line.path) {
      console.error('drawPath', line)
      return true
    }

    if (line.mode === 'pointer' && isPreview) {
      return true
    }

    if (!line.visible) {
      return true
    }

    const {path, mode, color, tick, isUpdated} = line
    let pathToDraw = path
    if (mode === 'pointer') {
      const points = Math.floor(line.tick * pointPerTick)
      pathToDraw = path.slice(points)

      if (path.length > 50 || !isUpdated) {
        line.tick++
      }


      if (pathToDraw.length === 0) {
        return false
      }
    }

    ctx.strokeStyle = color
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round'
    ctx.lineWidth = (mode === 'pointer') ? 4 : 2


    ctx.beginPath()

    for (let i = 0; i < pathToDraw.length; i++) {
      const [imgX, imgY] = pathToDraw[i]
      const [x, y] = img2canvas(imgX, imgY)
      if (i === 0) {
        ctx.moveTo(x, y)
      } else {
        ctx.lineTo(x, y)
      }
    }

    ctx.stroke()

    return true
  }

  const onMouseDown = (e) => {
    if (!modeLine) {
      return
    }

    const point = canvas2img(e.offsetX, e.offsetY)
    currentLine = {
      id: nanoid(5),
      mode: modeLine,
      color,
      type,
      path: [point],
      tick: 0,
      visible: true,
      isUpdated: true,
    }
    onLineAdd(currentLine)
  }

  const onMouseUp = () => {
    if (currentLine) {
      lines[currentLine.id] = currentLine
      currentLine = null
    }
  }

  const onMouseMove = (e) => {
    if (currentLine) {
      const point = canvas2img(e.offsetX, e.offsetY)
      currentLine.path.push(point)
      const debouncedResetUpdateLine = createFuncRest(currentLine)
      debouncedResetUpdateLine(currentLine)
      onLineUpdate({
        id: currentLine.id,
        point,
      })
    }
  }

  const onTouchStart = (e) => {
    if(e.touches.length > 1 || !modeLine) {
      isMoreOneTouch = true
      return
    }

    const rect = e.target.getBoundingClientRect();
    const x = e.targetTouches[0].clientX - rect.left;
    const y = e.targetTouches[0].clientY - rect.top;

    const point = canvas2img(x, y)
    currentLine = {
      id: nanoid(5),
      mode: modeLine,
      color,
      type,
      path: [point],
      tick: 0,
      visible: true,
      isUpdated: true,
    }
    onLineAdd(currentLine)
  }

  const onTouchMove = (e) => {
    if (isMoreOneTouch) {
      return
    }

    e.preventDefault()

    const rect = e.target.getBoundingClientRect();
    const x = e.targetTouches[0].clientX - rect.left;
    const y = e.targetTouches[0].clientY - rect.top;


    if (currentLine) {
      const point = canvas2img(x, y)
      currentLine.path.push(point)
      const debouncedResetUpdateLine = createFuncRest(currentLine)
      debouncedResetUpdateLine(currentLine)
      onLineUpdate({
        id: currentLine.id,
        point,
      })
    }

    // e.preventDefault()
  }

  const onTouchEnd = (e) => {
    if (currentLine) {
      lines[currentLine.id] = currentLine
      currentLine = null
    }

    isMoreOneTouch = false
  }

  if (!readOnly) {
    canvas.addEventListener('mousedown', onMouseDown)
    canvas.addEventListener('mouseup', onMouseUp)
    canvas.addEventListener('mouseleave', onMouseUp)
    canvas.addEventListener('mousemove', onMouseMove)
    canvas.addEventListener('touchstart', onTouchStart, false)
    canvas.addEventListener('touchmove', onTouchMove, () => isMoreOneTouch ? { passive: false } : false)
    canvas.addEventListener('touchend', onTouchEnd, false)
  }

  const update = () => {
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    updateCanvasSize(canvasWidth, canvasHeight)

    ctx.clearRect(0, 0, canvasWidth, canvasHeight)

    const linesLength = Object.keys(lines).length
    ctx.globalCompositeOperation = 'destination-out'
    ctx.fillStyle = 'rgba(255, 255, 255, 0.05)'
    ctx.beginPath()
    ctx.fillRect(0, 0, canvasWidth, canvasHeight)
    ctx.fill();
    // Set the default mode.
    ctx.globalCompositeOperation = 'source-over'

    const keysToRemove = []
    for (const lineId in lines) {
      const line = lines[lineId]
      const result = drawPath(ctx, line)
      if (!result) {
        keysToRemove.push(lineId)
      }
    }

    if (currentLine) {
      drawPath(ctx, currentLine)
    }


    for (const key of keysToRemove) {
      delete lines[key]
    }

    LINES_LENGTH = linesLength
    needUpdate = false
    // }

    if (isDestroyed) {
      return
    }
    setTimeout(() => window.requestAnimationFrame(update), delayBetweenFrames)
    // window.requestAnimationFrame(update)
  }
  update()
  // window.requestAnimationFrame(update)

  return {
    img2canvas,
    canvas2img,

    addLine: (line) => {
      lines[line.id] = line
    },

    updateLine: ({id, point}) => {
      const debouncedResetUpdateLine = createFuncRest(lines[id])

      if (lines[id]) {
        lines[id].path.push(point)
        debouncedResetUpdateLine()
      }
    },

    setModeLine: (tools) => {
      if (tools.pencil) {
        modeLine = 'pencil'
      } else if (tools.pointer) {
        modeLine = 'pointer'
      } else {
        modeLine = ''
      }
    },

    destroy: () => {
      isDestroyed = true

      canvas.removeEventListener('mousedown', onMouseDown)
      canvas.removeEventListener('mouseup', onMouseUp)
      canvas.removeEventListener('mousemove', onMouseMove)
      canvas.removeEventListener('touchstart', onTouchStart)
      canvas.removeEventListener('touchmove', onTouchMove)
      canvas.removeEventListener('touchend', onTouchEnd)
    },

    clear: () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },

    onGetStorage: (serverStorage) => {
      lines = { ...serverStorage.data }
    },
  }
}
