import { isNumber } from 'lodash'
import React, { useEffect, useState } from 'react'

import ReactTooltip from 'react-tooltip'
import Checkbox from '../../../../components/CheckBox/Checkbox'
import PopupNext from '../../../../components/PopupNext/PopupNext'
import TeacherButton from '../../../../components/TeacherButton/TeacherButton'
import presenterScreenStyles from '../../../presenterScreen.module.scss'
import styles from './buttons.module.sass'
import { ReactComponent as StartDefault } from '../img/Start_Default.svg'
import { ReactComponent as StartHover } from '../img/Start_Hover.svg'
import { ReactComponent as StopDefault } from '../img/Stop_Default.svg'
import { ReactComponent as StopHover } from '../img/Stop_Hover.svg'
import { ReactComponent as Highlight } from '../img/arrow_with_circle.svg'
import cn from "classnames";


export default function ButtonStartEnd({onStartLesson, onFinishLesson, settings}) {
  const [isHover, setIsHover] = useState(false)
  const [isOpenTutorial, setIsOpenTutorial] = useState(true)

  useEffect(() => {
    const noShowStarStopLessonTutorial = localStorage.getItem('noShowStarStopLessonTutorial')

    if (noShowStarStopLessonTutorial) {
      localStorage.removeItem('noShowStarStopLessonTutorial')
    }

    // if (!noShowStarStopLessonTutorial) {
    //   setIsOpenTutorial(true)
    // }
  }, [])

  useEffect(() => {
    if (Boolean(settings?.startedAt > 0)) {
      setIsOpenTutorial(false)
    }
  }, [settings?.startedAt])

  const onNoShow = (value) => {
    localStorage.setItem('noShowStarStopLessonTutorial', value)
  }

  const closeTutorial = () => {
    setIsOpenTutorial(false)
  }

  return (
    <div className={ styles.wrapWidget }>
      { isOpenTutorial && settings && isNumber(settings.startedAt) &&
        <Highlight style={ {position: 'absolute', top: '-14px', left: '-13px', zIndex: '9999'} }/> }
      { settings.startedAt === 0 &&
        <div data-tip="Начать урок" data-for="start" className={ cn(styles.buttonLesson, styles.startButton) }>
          <button
            //data-delay-hide='100'
            onClick={ onStartLesson }
            onMouseEnter={ () => setIsHover(true) }
            onMouseLeave={ () => setIsHover(false) }
            className={ styles.buttonLesson }
            // data-tip="Начать урок" data-for="start"
          >
            {/*{ isHover && <StartHover className={ styles.pointer }/> }*/ }
            {/*{ !isHover && <StartDefault className={ styles.pointer }/> }*/ }
          </button>
          <ReactTooltip
            id="start"
            className={ presenterScreenStyles.tooltip }
            place="right"
            textColor="#393F61"
            backgroundColor="#fff"
            aria-haspopup="true"
          />
        </div> }
      { Boolean(settings.startedAt > 0) && Boolean(!settings.finishedAt) &&
        <div data-tip="Завершить урок" data-for="stop" className={ cn(styles.buttonLesson, styles.stopLesson) }>
          <button
            //data-delay-hide='100'
            onClick={ onFinishLesson }
            onMouseEnter={ () => setIsHover(true) }
            onMouseLeave={ () => setIsHover(false) }
            className={ styles.buttonLesson }
            // data-tip="Завершить урок" data-for="stop"
          >
            {/*{ isHover && <StopHover className={ styles.pointer }/> }*/ }
            {/*{ !isHover && <StopDefault className={ styles.pointer }/> }*/ }
          </button>
          <ReactTooltip
            id="stop"
            className={ presenterScreenStyles.tooltip }
            place="right"
            textColor="#393F61"
            backgroundColor="#fff"
            aria-haspopup="true"
          />
        </div> }
      <PopupNext
        isOpen={ isOpenTutorial && settings && isNumber(settings.startedAt) }
        onClose={ closeTutorial }
        title="Добро пожаловать!"
      >
        <p className={ styles.paragraphCenterText }>
          Чтобы <b>начать</b> или <b>закончить</b> урок, нажмите
          <br/>
          кнопку в левом верхнем углу
        </p>
        <div className={ styles.imgContainer }>
          <StartDefault style={ {height: '60px', width: '60px'} }/>
          <StopDefault style={ {height: '60px', width: '60px'} }/>
        </div>
        <div className={ styles.buttonContainer }>
          <TeacherButton onClick={ () => closeTutorial() } title="Всё понятно, перейти к уроку" fill/>
          {/*<Checkbox*/ }
          {/*  label="Больше не показывать"*/ }
          {/*  onChange={onNoShow}*/ }
          {/*  defaultValue={!isOpenTutorial}*/ }
          {/*/>*/ }
        </div>
      </PopupNext>
    </div>
  )
}
