import React, { useRef } from 'react'
import cn from 'classnames'
import styles from './viewerVideo.module.css'
import useViewerMedia from "../../../useViewerMedia";


const ViewerVideo = ({ className, socket, viewer, muted, hide }) => {
  const { name, displayName, connectedAt } = viewer
  const videoRef = useRef(null)
  const audioRef = useRef(null)

  useViewerMedia({ socket, name, mediaType: 'video', mediaRef: videoRef, connectedAt })
  useViewerMedia({ socket, name, mediaType: 'audio', mediaRef: audioRef, connectedAt })

  return (
    <div className={cn(styles.container, className, {[styles.hide]: hide})}>
      <video
        className={styles.video}
        ref={videoRef}
        autoPlay
        playsInline
        muted
      />
      <audio
        className={styles.audio}
        ref={audioRef}
        autoPlay
        muted={!muted}
      />
    </div>
  )
}

export default ViewerVideo