import React, { useState } from 'react'
import { requestRecoveryEmail } from '../api/auth'
import { AdminInput } from '../components/AdminInput/AdminInput'
import AdminButton from '../components/AdminButton/AdminButton'


export default function PasswordRecovery({ goToDefaultState }) {
  const [login, setLogin] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [isRecoverSent, setRecoverSent] = useState(false)
  const [isLoaderShown, setLoaderShow] = useState(false)

  const changeInputLogin = (login) => {
    setLoginError(false)
    setLogin(login.value)
  }

  const onClickRequestPassword = (e) => {
    setLoaderShow(true)
    e.preventDefault()
    // оправляем почту
    requestRecoveryEmail({ email: login }).then(data => {
      if (data === 'sent') {
        setRecoverSent(true)
      } else {
        setRecoverSent(false)
        setLoginError(true)
      } // 'not_found', 'sent'
      setLoaderShow(false)
    }).catch(e => {
      console.log('problems', e)
      setLoaderShow(false)
    })
  }


  return (
    <>
      {isLoaderShown ?
        <div className="loader"/> :
        <>
          <h1 className="title-login">{isRecoverSent ? 'Пароль отправлен на ваш Email' : 'Восстановить пароль'}</h1>
          <div className="mb-60"/>
          {isRecoverSent ?
            <div className="form-recovery-password">
              <div className="recover-info">Проверьте почту и войдите с новым паролем</div>
              <div className="mb-60"/>
              <AdminButton
                title="Вернуться к входу"
                onClick={goToDefaultState}
                fill
              />
            </div> :
            <form className="form-login" onSubmit={onClickRequestPassword}>
              <AdminInput
                type="text"
                name="username"
                value={login}
                onChangeInput={changeInputLogin}
                isError={loginError}
                label="Email"/>
              {loginError ? <div className="login-error">Логин не найден</div> : <div className="mb-60"/>}
              <AdminButton
                title={isRecoverSent ? 'Вернуться к входу' : 'Отправить'}
                onClick={isRecoverSent ? goToDefaultState : onClickRequestPassword}
                fill
              />
              {isRecoverSent &&
              <>
                <div className="mb-20"/>
                <button className="link-login" onClick={goToDefaultState}>Вернуться к входу</button>
              </>}
            </form>}
        </>
      }
    </>
  )
}
