import React from 'react'
import cn from 'classnames'
import styles from './browserBanner.module.scss'

export default function BrowserBanner ({ currentBrowser, inner, onClose }) {
  const iconCurrentBr = currentBrowser.toLowerCase().split(' ').join('-')

  return (
    <div className={cn(styles.wrapper, {
      [styles.inner]: inner
    })}>
      <div className={styles.content}>
        <div className={styles.foxImage} />
        <div className={styles.info}>
        Вы используете браузер<div className={cn(styles[iconCurrentBr], styles.icon)}/><span className={styles.blueText}>{currentBrowser}</span>.<br/>
        Для корректной работы сервиса рекомендуется использовать браузер <span className={styles.blueText}><div className={cn(styles.chromeIcon, styles.icon)}/>Google Chrome</span>.
        </div>
      </div>
      <div className={styles.closeIcon} onClick={onClose}></div>
    </div>
  )
}